.fepAndEab {
    &__button {
        color: $white;
        background-color: #176DB7;
        padding: $space $space-2;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        font-size: 14px;
        border-radius: .6em;
        margin-left: auto;

        &:hover {
            cursor: pointer;
            background-color: #2186dd;
        }
    }
}