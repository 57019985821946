.viewmore {
    &__row {
        margin-top: 40px;
        margin-bottom: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    &__my-container {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        display: none;
    }

    &__nopadding {
        padding: 0;
    }

    &__col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-left: 15px;
    }

    &__col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
    }

    &__text-left {
        text-align: left !important;
    }

    &__img-responsive {
        display: block;
        max-width: 100%;
        height: auto;
    }

    &__btn {
        width: 100%;
        text-align: center;
        padding-bottom: 20px;
    }

    &__myButton1 {
        border: none;
        background-color: transparent;
        color: #366fe3;
        font-weight: bolder;
        font-family: inherit;
        cursor: pointer;
        font-size: 20px;
    }

    &__icon {
        font-size: 20px !important;
        color: #366fe3;
        cursor: pointer;
    }
}

.cmp-viewmore-column {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.cmp-viewmore-column .column {
    padding-right: 3%;
}

.cmp-viewmore-column img {
    height: 130px;
    width: 100%;
    margin-left: 0;
    margin-top: 10%;
}

.cmp-viewmore-column p {
    font-size: 14px;
    word-break: break-word;
}

.cmp-viewmore-column  h4 {
    font: normal 700 18px/1.5 "Arial", Helvetica, Arial, sans-serif;
    padding-top: 10px;
    word-break: break-word;
}