.category {
    &--filters {
        @include clearfix;

        &-list {
            display: inline-block;
            padding: $space $space $space-4;

            @include media($mobile-only) {
                width: 33.33%;
                float: left;
            }

            @include media($tablet) {
                padding-left: $space-5;
                padding-right: $space-5;
                padding-top: $space-3;
            }

            @include media($desktop) {
                padding-left: $space-7;
                padding-right: $space-7;
            }

            &:first-of-type {
                @include media($tablet) {
                    padding-left: 0;
                }
            }

            &-item {
                @include featured-copy-reg;
                color: $white;
                transition: all 0.5s;

                &:hover {
                    opacity: 0.5;
                }

                &--active {
                    color: $black;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
}