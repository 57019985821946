.custom-header {
    &__favorite {
        color: white;
        position: relative;
        right: 60%;
        top: 28%;
        width: fit-content;
        font-size: 115%;
        cursor: pointer;

        @include media($tablet) {
            text-align: center;
            width: 100%;
        }
    }

    &__popuptext {
        color:black;
        text-align: justify;
    }

    &__popuparrow {
        display: none;
        content: "";
        position: absolute;
        bottom: -13%;
        left: 40%;
        margin-left: -5px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 10px solid white;
    }

    &__show {
        display: none;
        left: -645%;
        top: 110%;
        width: 800%;
        background: white;
        height: 1200%;
        position: absolute;
        z-index: 11111;
        border: 1px solid #000000;
        overflow:auto;
        padding: 20% 25%;
        border-radius: 6px;
        border-style: solid;
        border-color: grey;

        @media only screen and (max-width: 1128px) and (min-width: 360px) {
            width: 500%;
            left: -344%;
        }
    }

    &__button {
        color: white;
        position: absolute;
        margin-left: 35%;
        margin-right: 42%;
        background: steelblue;
        padding: 2px;
        width: 22%;
        border-radius: 6px;
        display: block;
    }

    &__flag {
        fill: white;
        position: absolute;
        top: 16%;
        right: 100%;
    }
}
