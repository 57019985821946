.vin {
    &__table1 {
        display: none;
    }

    &__custom-padding {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }

    &__btn1 {
        background-color: #008CBA; /* Green */
        border: none;
        color: white;
        padding: 6px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 4px;
    }

    &__body-text {
        font-family: "Times New Roman", Times, serif;
    }

    &__padding-table-columns {
        td {
            padding: 0 40px 0 0; /* Only right padding*/
        }
    }

    &__custom-padding {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }

    &__row-custom-border {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &__row-custom-padding {
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: #E8E8E8;
    }

    &__text-bold {
        font-weight: bold;
    }

    &__table-column-padding {
        padding-right: 200px;
    }

    &__column-spacing {
        cellspacing: 30px;
    }

    &__custom-padding-table-columns {
        td {
            padding: 0 100px 0 0;
        }
    }

    &__custom-padding-bill-columns {
        td {
            padding: 0 700px 0 0; /* Only right padding*/
        }
    }

    &__container-fluid {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    &__row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    &__col-md-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }

    &__col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }

    &__col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }

    &__form-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 1rem;
    }

    &__error-field {
        color: $apple-blossom;
        @include featured-copy-bold;
        margin-top: $space;

        &--hidden {
            display: none;
        }
    }

    &__form-control {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &::-ms-expand {
            background-color: transparent;
            border: 0;
        }

        &:focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }

        &::-webkit-input-placeholder {
            color: #6c757d;
            opacity: 1;
        }

        &::-moz-placeholder {
            color: #6c757d;
            opacity: 1;
        }

        &:-ms-input-placeholder {
            color: #6c757d;
            opacity: 1;
        }

        &::-ms-input-placeholder {
            color: #6c757d;
            opacity: 1;
        }

        &::placeholder {
            color: #6c757d;
            opacity: 1;
        }

        &:disabled, &[readonly] {
            background-color: #e9ecef;
            opacity: 1;
        }
    }
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}

h1, .h1 {
    font-size: 2.5rem;
}

h2, .h2 {
    font-size: 2rem;
}

h3, .h3 {
    font-size: 1.75rem;
}

h4, .h4 {
    font-size: 1.5rem;
}

h5, .h5 {
    font-size: 1.25rem;
}

h6, .h6 {
    font-size: 1rem;
}