.table-search {
    position: sticky;
    top: 0;
    padding: 0px 0px 0px 0px;
    font-size: 20px;
    z-index: 5;

    &__contained {
        font-size: $space-2+2;
        height: 24px;
        max-width: 1000px;
        position: relative;
        display: inline-flex;
        width: 100%;
        border-radius: 4px;
        top: 0%;
        margin-bottom: 10px;
        margin-left: 66%;
    }

    &__input-group {
        display: inline-flex;
        width: 50%;

        @include span-columns(12);

        @include media($tablet) {
            @include span-columns(5);
        }
    }

    &__input {
        width: auto;
        padding: 6px 0px 8px $space-3;
        float: left;
        color: $black;

        @include media($desktop) {
        }

        &:focus {
            border-color: $cornflower-blue;
        }
    }

    &__button {
        color: $white;
        background-color: $viking;
        padding: 4px 10px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        font-size: 14px;

        &:hover {
            cursor: pointer;
            background-color: $scooter;
        }
    }

    &__nextbutton {
        color: $white;
        background-color: $viking;
        padding: $space $space-2;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        font-size: 14px;

        &:hover {
            cursor: pointer;
            background-color: $scooter;
        }
    }

    &__heading {
        color: #1c1c1f;
        margin: -30px;
        margin-left: -100px;
        font-size: 15px;
    }
}
