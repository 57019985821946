.data-deletion {
    &__section {
        @include media($tablet) {
            padding: 0 15%;
        }
    }

    &-container {
        width: 60%;
        margin: 0 auto;
    }

    &__heading-container {
        width: 100%;
        padding: $space*8 0;

        @include media($large) {
            padding: $space*12 0;
        }
    }

    &__main-heading {
        @include main-heading;
        margin-bottom: $space*2;
    }

    &__sub-heading {
        font-weight: bold;
    }

    &-search {
        &-field-container {
            display: block;
            align-items: center;
            margin-bottom: $space-8;

            @include media($tablet) {
                display: flex;

                &>div {
                    width: 20%;
                }

                &>div:first-of-type {
                    width: 80%;
                }
            }

            .button-control {
                text-align: right;
            }

            .button {
                padding: $space $space*4;
            }
        }

        &-btn {
            text-align: center;
        }
    }

    &__result-table {
        border: 1px solid $silver;

        &__header {
            padding: 10px;
            font-weight: bold;
            background-color: $alto;
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: center;

            &:first-of-type {
                width: 10%;
            }

            &:nth-of-type(2) {
                width: 40%
            }

            &:nth-of-type(3) {
                width: 30%;
            }

            &:last-child {
                width: 20%;
            }

            &-container {
                display: flex;
                justify-content: space-between;
                background-color: $alto;
            }

            &__container {
                display: flex;
            }
        }

        &__row {
            display: flex;
            text-align: center;
            border-bottom: 1px solid $silver;

            &:last-child {
                border-bottom: none;
            }
        }

        &__column {
            padding: 10px 0;
            border-right: 1px solid $silver;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &:first-child {
                width: 10%;
            }

            &:nth-of-type(2) {
                width: 40%
            }

            &:nth-of-type(3) {
                width: 30%;
            }

            &:last-child {
                width: 20%;
                border-right: none;
                padding: 2px 0;
            }

            & .button {
                padding: $space $space-2;

                @include media($tablet) {
                    padding: $space $space-6;
                }
            }

            & .delete-btn--disabled {
                color: $scorpion;
                background-color: $dusty-gray;
            }
        }
    }

    &__modal-content {
        &__container {
            text-align: center;
            padding: $space-8;
        }

        &__text {
            @include section-reg;
            margin-bottom: $space-8;
        }

        &__btns {
            display: flex;
            justify-content: space-around;

            & .button {
                padding: $space $space-8;
            }
        }
    }

    &__msg {
        &-container {
            margin-top: $space-8;
            padding: $space-2;
            text-align: center;
        }
    }

    .form {
        $input-height: 24px;

        &__input {
            position: relative;
            padding-top: $space*4;
            padding-bottom: $space*4;

            &.hidden {
                padding: 0;
            }

            &--inline {
                padding-bottom: 0;

                .form__input-reset {
                    bottom: $space*2;
                }
            }

            &.error {
                .form__input-field:focus {
                    border-bottom-color: $guardsman-red;
                }
            }
        }

        &__input-field {
            width: 100%;
            padding: 0 0 $space 0;
            background: none;
            border: none;
            border-bottom: 1px solid $dusty-gray;
            transition: border-bottom ease $speed-2;

            .form__input.error & {
                border-bottom-color: $guardsman-red;
            }

            &:focus,
            .form__input.focused & {
                outline: none;
                border-bottom-color: $azure-radiance;
                animation: unset;
            }

            &[data-required] + .form__input-label .form__input-label-text:after {
                display: inline;
                content: "*";
            }
        }

        &__input-prefix {
            color: $dove-gray;
            visibility: hidden;
            left: 0;
            position: absolute;
            top: $space*4;

            .form__input-field:focus ~ &,
            .form__input-field--has-value ~ & {
                visibility: visible;
            }
        }

        &__input-label {
            @include featured-copy-reg;
            position: absolute;
            display: flex;
            align-items: center;
            top: $space * 3.5;
            left: 0;
            color: $dove-gray;
            transition: all ease $speed-2;
            width: 100%;

            &:hover {
                cursor: text;
            }

            .form__input-field:focus + &,
            .form__input-field:required:valid + &,
            .form__input-field--has-value + & {
                top: 0;
                transform: scale(0.99, 0.99);
                transform-origin: top left;
            }


            .form__input-field:focus + &,
            .form__input.focused & {
                color: $azure-radiance;
            }

            .form__input.error .form__input-field:focus + &,
            .form__input.error & .form__input-label-text {
                color: $guardsman-red;
            }
        }

        &__input-label-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(100% - #{$space*4});
            display: inline-block;
            margin-right: $space;
        }

        &__input-error {
            display: none;
            color: $guardsman-red;

            .form__input.error & {
                display: block;
            }
        }

        &__input-reset {
            display: none;
            position: absolute;
            right: $space;
            top: $space*4;
            height: $input-height;
            @include pseudo-icon($icon-exit, $position: 'after');
            cursor: pointer;

            &:active,
            &--visible {
                display: block;
            }
        }
    }

    .tooltip {
        position: relative;
        display: inline-block;
        color: $guardsman-red;

        .tooltiptext {
            visibility: hidden;
            width: $space*31;
            background-color: black;
            color: $white;
            text-align: center;
            border-radius: $space;
            padding: $space;

            /* Position the tooltip */
            position: absolute;
            z-index: 1;
        }
    }

    .tooltip:hover .tooltiptext {
        visibility: visible;
    }

    .alert-box--danger {
        border: 1px solid #ebcccc;
    }

    .alert-box--success {
        border: 1px solid #d0e9c6;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d
