.search-nav {
    &__container {
        box-shadow: 0px 0px $space-2 #000000;
        font-size: $space-2+2;
        height: 48px;
        max-width: 1000px;
        position: relative;
        padding: 8px $space-2;
        width: 100%;
        border-radius: 4px;
        top: 0%;
        background: $black;
    }

    &__input-group {
        display: flex;

        @include span-columns(12);

        @include media($tablet) {
            @include span-columns(5);
        }
    }

    &__input {
        width: 75%;
        padding: 8px 0px 8px $space-3;
        float: left;
        color: $black;

        @include media($desktop) {
            width: 80%;
        }

        &:focus {
            border-color: $cornflower-blue;
        }
    }

    &__button {
        color: $white;
        background-color: $viking;
        padding: $space $space-2;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        font-size: 14px;

        &:hover {
            cursor: pointer;
            background-color: $scooter;
        }
    }

    &__button-disabled {
        color: $white;
        background-color: $viking;
        padding: $space $space-2;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        font-size: 14px;
    }

    &__loader {
        position: relative;
        z-index: 1;
        width: 50px;
        height: 50px;
        margin: auto;
        border: 5px solid #f3f3f3;
        border-radius: 50%;
        border-top: 5px solid black;
        background-position: center;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    &__loader-text {
        text-shadow: 0 0 black;
        text-align: center;
    }
}