.partnerships {
    margin-bottom: $space-3;

    &__heading {
        @include section-reg;
        color: $mine-shaft;
        padding-bottom: $space-2;
        margin-bottom: $space-3;
        font-size: 26px;
        border-bottom: 1px dashed $dusty-gray;
    }

    &__img {
        width: 100%;
        height: auto;
    }

    &__link-list {
        &__link {
            display: block;

            &-container {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
        }

        &__item {
            max-width: $space-20+$space-5;
            text-align: center;
            vertical-align: middle;
            padding: 0 $space-3;
            margin-bottom: $space-3;
        }
    }
}
