.dealer-results {
    &__list {
        max-height: 195px;
        overflow-y: auto;
        border-top: 1px solid $dusty-gray;
        border-bottom: 1px solid $dusty-gray;

        .dealer-results.error & {
            border: 0;
        }
    }

    &__sub-heading-copy {
        margin-bottom: $space*4;
    }

    &__error-message {
        display: none;
        color: $guardsman-red;

        .dealer-results.error & {
            display: block;
        }
    }

    &__sub-heading {
        @include featured-copy-bold;
        margin-bottom: $space*2;
    }

    &__sub-heading-copy {
        @include featured-copy-reg;
    }

    .load-more {
        color: $denim;
        cursor: pointer;
        padding: 0 10px;
        display: inline-block;
        @include featured-copy-reg;

        &:hover {
            color: $azure-radiance;
        }

        &-container {
            padding: $space-2;
            display: flex;
            float: right;

            .loading-spinner:before {
                width: $space-4;
                height: $space-4;
            }
        }
    }

}

// do not delete 9fbef606107a605d69c0edbcd8029e5d
