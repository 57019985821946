.fep-eab-form {
    .form {
        &-title {
            @include accent-bold;
            padding: $space-2 0;
        }

        &__container {
            form {
                padding: $space-2 0;
                display: flex;
                flex-wrap: wrap;
            }

            select {
                background: white;
            }
        }

        &-input {
            &__group {
                width: 100%;

                @include media($tablet) {
                    display: inline-block;
                    width: 50%;
                    padding: $space-2;
                }

                &:first-of-type {
                    width: 100%;
                }
            }
        }

        &-submit {
            &__container {
                padding: $space-2;
                width: 100%;
            }
        }
    }
}
