.featured-link-list {
    margin-bottom: $space-4;

    &__block-container {
        display: block;
        font-size: 0;
    }

    &__block {
        border: 1px solid $mischka;
        border-radius: $space;
        display: inline-block;
        font-size: 12px;
        margin-bottom: $space-2;
        margin-right: $space-2;
        vertical-align: top;
        width: 165px;
    }

    &__img-block {
        text-align: center;
    }

    &__img {
        display: block;
        width: 100%;
    }

    &__content-block {
        display: block;
    }

    &__block-list-container {
        display: block;
    }

    &__block-list {
        display: block;
    }

    &__block-link {
        color: $boston-blue;
        padding: 0 $space;
        display: block;

        &:before {
            color: $scorpion;
            content: "\00bb";
        }
    }

    a {
        color: $boston-blue;

        &:hover {
            color: $pacific-blue;
        }
    }
}
