.faq-v2 {
    margin-bottom: $space-4;

    &__color-light {
        color: #f1f1f1;
    }

    &__color-dark {
        color: #202020;
    }

    &__list {
        list-style: none;
        margin: 0;
    }

    &__title {
        font-size: 24px;
        font-family: $secondary-font-family;
        font-weight: 500;
        margin-bottom: $space-2;
    }

    &__subtitle {
        font-size: 20px;
        font-family: $secondary-font-family;
        font-weight: 500;
        margin-bottom: $space-2;
    }

    &__componentBorder {
        border-bottom: 1px solid;
        border-top: 1px solid;
    }

    &__question {
        @include featured-copy-bold;
        margin-bottom: $space-2;
        cursor: pointer;
        border: none;
        padding: $space-4;
        outline: none;
        padding-bottom: 10px;

        &:hover {
            background-color: $very-light-grey;
        }

        &:before {
            font-family: 'micro-icons';
            content: $icon-caret-down-thin;
            padding-top: 2px;
            -webkit-transition: all 0.3s ease-in 0s;
            -moz-transition: all 0.3s ease-in 0s;
            -o-transition: all 0.3s ease-in 0s;
            transition: all 0.3s ease-in 0s;
            float: right;
        }
    }

    &__question_faq_v3 {
        @include featured-copy-bold;
        margin-bottom: $space-2;
        color: $tundora;
        cursor: pointer;
        border: none;
        padding: $space-4;
        outline: none;

        &:before {
            font-family: 'micro-icons';
            content: $icon-caret-down-thin;
            font-size: 75%;
            padding-top: 2px;
            -webkit-transition: all 0.3s ease-in 0s;
            -moz-transition: all 0.3s ease-in 0s;
            -o-transition: all 0.3s ease-in 0s;
            transition: all 0.3s ease-in 0s;
            float: right;
        }
    }

    &__icon {
        &--down-arrow-key {
            &:before {
                content: $icon-caret-down-thin;
                padding-top: 0.7%;
            }
        }

        &--right-arrow-key {
            &:before {
                content: $icon-caret-right-thin;
                padding-top: 0.7%;
            }
        }

        &--plus {
            &:before {
                content: $icon-plus;
            }
        }
    }

    &__panel {
        padding: 0 $space-6;
        overflow: hidden;
        margin-bottom: $space-2;

        &-content {
            padding: $space-4 0px;
            margin-bottom: $space-2;
        }
    }

    &__accordion_active_icon {
        &--down-arrow-key {
            &:before {
                content: $icon-caret-down-thin;
                padding-top: 0.7%;
                -webkit-transform: rotate(180deg);
                moz-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
            }
        }

        &--right-arrow-key {
            &:before {
                content: $icon-caret-right-thin;
                padding-top: 0.7%;
                -webkit-transform: rotate(90deg);
                moz-transform: rotate(90deg);
                -o-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                transform: rotate(90deg);
            }
        }

        &--plus {
            &:before {
                transform: none;
                content: $icon-minus;
            }
        }
    }

    &__spacing {
        &--top {
            padding-top: $space-4;
        }

        &--bottom {
            padding-bottom: $space-4;
        }

        &--both {
            padding-top: $space-4;
            padding-bottom: $space-4;
        }
    }

    &__without--background-color {
        &__question {
            background-color: $white;
            padding: 0px;
            font-size: 16px;
            font-weight: bold;

            &:hover {
                background-color: $white;
            }

            &:before {
                float: left;
                margin-right: $space-2;
                font-family: 'micro-icons';
                padding-top: 2px;
                -webkit-transition: all 0.3s ease-in 0s;
                -moz-transition: all 0.3s ease-in 0s;
                -o-transition: all 0.3s ease-in 0s;
                transition: all 0.3s ease-in 0s;
            }
        }
    }

    &__hover-black:hover {
        background: none;
    }
}