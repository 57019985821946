.accordion {
    margin: 0px 0px $space-4 $space-4;

    &__list, &__link-list-v2 {
        list-style: disc;
    }

    &__title {
        cursor: pointer;
        font-size: 16px;
        color: $blue-light;
        font-weight: $font-weight-medium;

        &:hover {
            color: $dove-gray;
        }
    }

    &__panel {
        display: none;
        font-size: 14px;
        padding: 10px;

        ol {
            list-style: decimal;
        }

        ul {
            list-style: disc;
        }
    }

    &__active {
        .accordion__panel {
            display: block;
        }
    }
}
