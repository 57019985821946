.form {
    &__message {
        &-container {
            padding: $space-2 $space-2;
            text-align: center;
            font-weight: $font-weight-bold;
            position: relative;
            margin-top: $space;

            .form__message {
                padding-right: $space-4;
            }

            .icon-cross {
                position: absolute;
                right: 0;
                margin-right: 10px;
                opacity: 0.5;

                &:hover {
                    opacity: 1;
                    cursor: pointer;
                }
            }
        }

        &--error {
            color: $apple-blossom;
            background-color: $bizarre;
            border: 1px solid $apple-blossom;
        }

        &--success {
            color: $killarney;
            background-color: #dff0d8;
            border: 1px solid $killarney;
        }
    }
}
