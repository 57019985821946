.wrapperG-Class {
    @include outer-container(100%);
    @include mobile-container-width();
    margin: 0 auto;

    @include media($tablet) {
        @include tablet-container-width();
    }

    @include media($desktop) {
        @include desktop-container-width();
        max-width: 580px;
    }

    &__display {
        display: flex;
    }

    .wrapperG-Class & {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .wrapperG-Class__display {
        display: block;
    }
}
