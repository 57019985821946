.airbag-availability {
    color: $mine-shaft;
    justify-content: center;

    &__heading {
        @include featured-heading;
        margin-bottom: $space-2;
    }

    &__title {
        text-align: center;
        padding-top: $space;
        font-family: $secondary-font-name;
        font-size: 36px;
    }

    &__sub-title {
        font-family: Arial, sans-serif;
    }

    &__section {
        width: 50%;
        margin: 0 auto;
    }

    &__group {
        padding: $space 0px;
        margin-left: 5%;
        width: 85%;
    }

    &__input_feild {
        width: 70%;
    }

    &__result_display {
        margin: 2% 10% 2% 10%;
        width: 80%;
        text-align: center;
        border: 2px solid black;
    }

    &__group_btn {
        padding: $space-2 0px;
    }

    &__label {
        display: block;
        @include featured-copy-bold;
        margin-bottom: $space;
    }

    &__table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 5px;
    }

    &__result_table {
        width: 50%;
        margin: 2% 15% 2% 25%;
        border-spacing: 0 5px;
    }

    &__result_column {
        border: 1px solid black;
        background-color: #176db7;
        padding: 10px 5px;
    }

    &__result_array_column {
        border: 1px solid black;
        padding: 10px 5px;
    }

    &__result_column_text {
        color: white;
        text-align: center;
        font-weight: bold;
    }

    &__table_column {
        text-align: right;
    }

    &__table_text {
        text-align: center;
        font-family: Arial, sans-serif;
        font-size: 13px;
        color: black;
        text-align: center;
    }

    &__control {
        width: 100%;
        border: 1px solid $very-light-grey;
        border-radius: $space;
        height: $space-6+4;
        padding: $space+1 $space-2+2;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }
    }

    &__text {
        font-size: $space-3;
        font-weight: bold;
        width: 100%;
    }

    &__btn {
        background-color: #000000;
        border: none;
        color: white;
        padding: 5px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 0 auto;
        cursor: pointer;
        border-radius: 4px;
    }

    &__hide {
        visibility: hidden;
    }

    &__error-field {
        color: $apple-blossom;
        @include featured-copy-bold;
        margin-top: $space;

        &--hidden {
            display: none;
        }
    }

    .media-text {
        padding-top: 10px;
    }
}