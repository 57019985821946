.check-form {
    &__group {
        padding: $space-2 0px;
    }

    &__heading {
        font-size: 24px;
        margin-top: 20px;
        margin-bottom: 10px;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.1;
        color: inherit;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }

    &__subheading {
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 10px;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.1;
        color: inherit;
        display: block;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }

    &__label {
        display: block;
        @include featured-copy-bold;
        margin-bottom: $space;
    }

    &__textarea {
        height: auto;
        width: 100%;
        padding: 6px 12px;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        overflow: auto;
    }

    &__uploadbox {
        box-sizing: border-box;
        display: block;
        font-weight: 400;
        font-size: .984375rem;
        line-height: 1.5;
        font-family: Corporate S Pro;
        color: #373a3c;
    }

    &__uploadboxexpanded {
        margin-top: 1rem;
        margin-bottom: 1rem;
        box-shadow: 0 2px 4px;
        border-radius: 4px;
        overflow: hidden;
        box-sizing: border-box;
        display: block;
    }

    &__data-item {
        transition: border-width .25s,border-color .25s,background-color .25s;
        padding-left: 0!important;
        padding-right: 0!important;
        padding-top: 0!important;
        padding-bottom: 0!important;
        box-sizing: border-box;
        display: block;
    }

    &__item-group {
        margin-bottom: 0!important;
        box-sizing: border-box;
    }

    &__final-item {
        background: #fff;
        overflow: hidden;
        box-sizing: border-box;
        display: block;
        text-align: center;
    }

    &__item-file-list:not(:empty) {
        border-top: 1px solid #eee;
        box-sizing: border-box;
        display: block;
    }

    &__trigger-input {
        visibility: hidden;
        width: 0;
        height: 0;
    }

    &__item-file-action {
        padding-right: 1rem!important;
        padding-bottom: 0.75rem!important;
        text-align: right;
        box-sizing: border-box;
        display: block;
    }

    &__button-forfile {
        border-color: transparent;
        padding: 1px 5px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
        color: #fff;
        background-color: #5cb85c;
        display: inline-block;
        margin-bottom: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        user-select: none;
        font-family: inherit;
        -webkit-appearance: button;
        text-transform: none;
        overflow: visible;
        font: inherit;
        margin: 0;
        box-sizing: border-box;
        -webkit-writing-mode: horizontal-tb !important;
        text-rendering: auto;
        letter-spacing: normal;
        word-spacing: normal;
        text-indent: 0px;
        text-shadow: none;
        align-items: flex-start;
    }

    &__full-page {
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -webkit-text-size-adjust: 100%;
        box-sizing: border-box;
    }

    &__final-button {
        padding: 0.3rem 1.2rem;
        font-size: inherit;
        border-color: transparent;
        color: #fff;
        background-color: #4285f4;
    }

    &__data-control {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
        height: 34px;
        padding: 6px 12px;
    }


    &__border {
        padding-left: 2.5%;
    }

    &__table {
        width: 80%;
    }

    &__table_column {
        padding-right: 25px;
        width: 35%;
        vertical-align: middle;
    }

    &__sub-title {
        @include featured-copy-bold;
        margin-top: 20px;
        padding-bottom: 5px;
    }

    &__asterisk_field {
        color: red;
    }

    &__control {
        border: 1px solid $very-light-grey;
        border-radius: $space;
        height: $space-6+4;
        width: 100%;
        padding-left: 5px;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }
    }

    &__sample {
        border: 1px solid $very-light-grey;
        border-radius: $space;
        height: $space-6+4;
        width: 100%;
        padding-left: 5px;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }
    }

    &__specification {
        border: 1px solid $very-light-grey;
        border-radius: $space;
        height: $space-6+4;
        width: 100%;
        padding-left: 5px;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }
    }

    &__error-field {
        color: $apple-blossom;
        @include featured-copy-bold;
        margin-top: $space;

        &--hidden {
            display: none;
        }
    }

    &__table_column1 {
        padding-right: 25px;
        width: 25%;
        vertical-align: middle;
    }

    &__has-error {
        .check-form__label {
            color: $cinnabar;
        }

        .check-form__control {
            border-color: $cinnabar;
        }
    }

    &__checkbox-label {
        min-height: 20px;
        font-size: larger;
        margin-bottom: 0;
        font-weight: 400;
        cursor: pointer;
    }

    &__group-new {
        min-height: 20px;
        min-width: 900px;
        padding: 19px;
        margin-bottom: 20px;
        background-color: #f5f5f5;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    }

    &__group-space {
        margin-bottom: 20px;
        background-color: #f5f5f5;
    }

    &__image {
        width: 100%;
    }

    &__fileName {
        width: 75%;
        font-weight: 700;
        padding-left: 2%;
        padding-bottom: 1.50%;
        padding-top: 1.50%;
    }

    &__fileSize {
        width: 10%;
        font-weight: 400;
        color: rgba(55,58,60,.6);
    }

    &__fileButton {
        text-align: right;
    }

    &__fileButton1 {
        text-align: right;
        position: relative;
        left: 630%;
    }

    &__fileButton2 {
        text-align: right;
        position: relative;
        left: 1250%;
    }

    &__fileList {
        border-bottom: 1px solid #eee;
    }

    &__button {
        color: #176db7;
    }

    &__display:hover {
        cursor: not-allowed;
    }

    &__item-file-download {
        border-top: 1px solid #eee;
        padding-bottom: 0.75rem!important;
        text-align: left;
        box-sizing: border-box;
        display: block;
    }

    &__warning {
        background-color: #176db7;
        color: #fff;
        text-align: center;
    }

    &__lastcheck {
        min-height: 20px;
        font-size: larger;
        margin-bottom: 0;
        font-weight: 400;
        cursor: pointer;
    }
}