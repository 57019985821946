:host {
    box-sizing: border-box;
}

.bulk-can-upload {
    width: 100%;
    min-height: 20px;
}

.bulk-can-upload-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.bulk-can-upload-header-container {
    width: 100%;
    border: none;
    border-bottom: 1px solid #c6c6c6;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    font-family: serif;
    font-size: 16px;

    h1 {
        font-family: corporateacondpro, Georgia, serif;
        font-size: 30px;
        font-weight: 400;
        line-height: calc(30px + 8px);
        overflow-wrap: break-word;
        text-align: center;
        margin: 0 0;
    }

    p {
        word-wrap: break-word;
        font-family: MBCorpoS, Arial, serif;
        font-size: 14px;
        line-height: calc(14px + 8px);
        @media (max-width: 767.98px) {
            width: 100%;
        }
    }

    color: #5a5a5a;
}

.bulk-can-upload-content-container {
    width: 81.25%;
    display: flex;
    justify-content: center;
    padding: 16px 0px;

    @media (max-width: 767.98px) {
        width: 100%;
    }
}

.bulk-can-upload-content {
    width: 70%;
    @media (max-width: 767.98px) {
        width: 100%;
        padding: 0 15px;
    }

    & p#bulk-upload-message {
        font-size: 16px;
        font-family: MBCorpoS, Arial, serif;
        color: black;
    }

    & p#bulk-upload-message.error-message {
        color: red;
        text-align: center;
    }

    & p#bulk-upload-message.success-message {
        color: green;
        text-align: center;
    }
}

.bulk-can-upload__loader-backdrop {
    height: 100%;
    background: #00000026;
    top: 0px;
    right: 0px;
    position: absolute;
    content: "";
    width: 100%;

    & .bulk-can-upload__loader {
        position: fixed;
        left: 54%;
        top: 50%;
        z-index: 1;
        width: 60px;
        height: 60px;
        margin: -75px 0 0 -75px;
        border: 5px solid #f3f3f3;
        border-radius: 50%;
        border-top: 5px solid #3498db;
        background-position: center;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

}

.bulk-can-upload-submit-container {
    padding: 16px 0px;
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: end;

    & button {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        background-color: #176db7;
        padding: 4px 32px;
        text-decoration: none;
        cursor: pointer;
        font-weight: 700;
        font-size: 16px;
        color: white;
        line-height: calc(16px + 8px);
        max-height: 32px;

        &:disabled {
            background-color: #717171;
            min-height: 32px;
            min-width: 116px;
            cursor: not-allowed;
        }
    }
}

.bulk-can-upload-upload-container {
    padding: 16px 0px;
    display: flex;
    flex-direction: column;
    align-items: start;

    & label {
        font-family: 'micro-icons', Arial, Helvetica, sans-serif;
        text-decoration: none;
        font-size: 16px;
        line-height: calc(16px + 8px);
    }

    & label#bulk-can-file-upload-btn {
        border-radius: 2px;
        background-color: #e3e3e3;
        padding: 4px 32px;
        cursor: pointer;
        font-weight: 700;
        color: black;
        row-gap: 16px;
    }
}

.upload-button-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px;
    border-bottom: 1px solid #c6c6c6;

    & label {
        &:nth-child(2) {
            font-size: 12px;
            overflow-wrap: break-word;
            line-height: 12px;
            padding-left: 6px;
        }
    }
}
