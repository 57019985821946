.contact-us {
    margin-bottom: $space-4;

    &-highlights {
        border: 1px solid $alto;
        border-radius: 0 0 3px 2px;
        margin-top: $space-4;
    }

    &__heading-container {
        display: block;
    }

    &__gheading {
        background: $black;
        color: $white;
        font-size: 18px;
        padding: $space $space-2;
        font-family: $secondary-font-family;
    }

    &__list-heading {
        font-size: 14px;
        font-weight: bold;
    }

    &__list {
        padding: $space-3;

        ol {
            list-style: decimal;
        }

        ul {
            list-style: disc;
        }

        &-para {
            margin-bottom: $space-2;
        }
    }
}
