.dropdown {
    margin: $space-2 0px;
    width: 100%;

    &:after {
        content: '';
        clear: both;
        display: table;
    }

    &-container {
        position: relative;
        display: inline-block;
        float: right;
    }

    &__button {
        color: $mine-shaft;
        font-size: 14px;
        background-color: $white;
        padding: 9px $space-2;
        border: 1px solid transparent;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            outline: 0;
            background-color: $gallery;
            border: 1px solid $silver-chalice;
        }
    }

    &-active {
        background-color: $gallery;
        border: 1px solid $silver-chalice;
    }

    &__content {
        display: none;
        position: absolute;
        min-width: $space*32;
        overflow: auto;
        border: 1px solid rgba(0,0,0,.15);
        box-shadow: 0 $space $space-2 rgba(0,0,0,.175);
        z-index: 1;
        background: $white;
        right: 0px;
        white-space: nowrap;

        &-anchor {
            font-size: 14px;
            color: $mine-shaft;
            padding: $space $space-3;
            text-decoration: none;
            display: block;

            &:hover {
                background-color: $wild-sand;
                color: $mine-shaft;
            }
        }
    }

    &-show {
        display: block;
    }

    &__feedback-icon {
        font-family: "micro-icons";
        font-size: $space-3;

        &:before {
            content: '\e94a';
        }
    }

    &__caret-icon {
        font-family: "micro-icons";
        font-size: $space-2;
        margin-left: $space;

        &:before {
            content: '\e93d';
        }
    }

    &__star-icon {
        font-family: "micro-icons";
        font-size: $space-3 - 1;

        &:before {
            content: '\e93f';
        }
    }

    &__print-icon {
        font-family: "micro-icons";

        &:before {
            content: '\e93e';
        }
    }

    &__margin-left {
        margin-left: 1px;
    }
}

.print-dropdown {
    .dropdown {
        &__button {

            &:hover {
                border: 1px solid $boston-blue;
                border-radius: 4px;
                background-color: $boston-blue;
                color: $white;
            }
        }

        &-active {
            border: 1px solid $boston-blue;
            background-color: $boston-blue;
            color: $white;
        }
    }
}

@media print {
    .col-3, .dropdown, .hero-image,.warranty-categories, .sub-nav__level--left, .sub-nav__level--right {
        display: none;
    }

    body, .top-nav__item--heading, .breadcrumb__link, .favorites-list__item-anchor, .active {
        color: $black;
    }

    a[href]:after {
        content: " (" attr(href) ")";
        color: $black;
    }

    .top-nav__link[href]:after {
        content: none;
    }

    .sub-nav__level--right {
        float: right;
        width: auto;
    }

    .top-nav {
        &__print {
            display: block;

            &__title__container {
                font-size: 24px;
                font-family: $secondary-font-family;
                color: $black;
                display: inline-block;
                float: right;
            }

            &__logo__container {
                display: inline-block;
            }
        }
    }

    .button-link-list {
        display: none;
    }
}
