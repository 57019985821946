.button-matrix {
    &__container {
        display: flex;
        flex-wrap: wrap;

        @include media($tablet) {
            align-items: center;
        }
    }

    &__item {
        border: 1px solid $black;
        margin-bottom: $space-4;
        padding: $space;
        text-align: center;
        width: 100%;

        @include media($tablet) {
            text-align: left;
            margin-left: -1px;
            @include span-columns(4 of 12);
            @include omega;
        }
    }

    &__anchor {
        display: block;
        padding: $space $space-2;

        @include media($tablet) {
            display: inline-block;
        }

        &:hover {
            background-color: $boston-blue;
            color: $white;
        }

        &:focus {
            background-color: $boston-blue;
            color: $white;
        }
    }

    &__hide {
        display: none;
    }
}
