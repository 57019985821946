.text-desc-link-list {
    margin-bottom: $space-4;

    &__title {
        font-size: 24px;
        font-family: $secondary-font-family;
        font-weight: $font-weight-medium;
        padding-top: $space-4;
        padding-bottom: $space-2;
    }

    &__content-container {
        padding: 0px $space-2;
    }

    &__description {
        font-size: 14px;
        margin-bottom: $space-2;
        padding: 10px;

        ol {
            list-style: decimal;
        }

        ul {
            list-style: disc;
        }
    }

    &__link {
        border-bottom: 1px solid $alto;
        padding-bottom: $space-2;

        &-anchor {
            color: $scorpion;

            &:hover {
                color: $white;
            }
        }

        &:hover {
            background-color: $boston-blue;
        }
    }
}
