.video {
    &__title {
        font-family: "CorpA Reg", sans-serif;
        font-size: 24px;
        border-bottom: 1px solid $gallery;
        margin: $space-8 0 $space-4;
        padding-bottom: $space-2;
    }

    &__container {
        border-radius: $space;
        margin-bottom: $space-3;
        position: relative;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }

    &__description {
        font-size: 14px;
    }

    &__img, &__video-block {
        width: 100%;
    }

    &__img {
        opacity: 0;
    }
}
