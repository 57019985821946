@use "sass:math";

.order-request-listing {
    &>.tabs-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: $space-2;
        flex-direction: row;
        border-bottom: 1px solid $mercury;
    
        &>.tabs {
            padding: $space-2 0;
            margin-right: $space-8;
            position: relative;
            font-size: 16px;
            font-weight: $font-weight-bold;
            line-height: $space-4;
            letter-spacing: 0em;
            text-align: left;
            cursor: pointer;
        }

        &>.active-tab {
            border-bottom: 1px solid transparent;
        }

        &>.active-tab::before {
            content: "";
            display: block;
            position: absolute;
            bottom: -2px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 5px;
            background: $color-mbusa-primary-10;
        }
    } 

    .content {
        height: 100%;
        width: 100%;
        display: none;
    }

    .active-content {
        display: block;
    }

    &__title {
        color: $color-neutral-80;
        margin: $space-3 0 $space-4;
        text-align: center;
        float: unset;
        width: 100%;

        @include media($large) {
            margin: $space-6 0;
        }

        &--light {
            color: $color-neutral-10;
        }
    }

    &__sub-heading {
        float: unset;
        margin-bottom: $space-4;
    }

    &__no-item {
        font-weight: $font-weight-regular;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    &__cards {
        display: flex;
        flex-wrap: wrap;
        margin: $space;

        .order-request-card {
            margin-bottom: $space-6 + 0.125;
            
            @include media($xlarge) {
                @include span-columns(4);
                @include omega(3n);
            }

            @include media($large-only) {
                @include span-columns(3);
                @include omega(2n);
            }

            @include media($mobile-only) {
                @include span-columns(2);
                @include omega(2n);
            }

            .saved-build-card-ap {
                position: relative;
                border: 1px solid $color-neutral-40;
                background-color: $color-neutral-10;
                overflow: hidden;

                &__wrapper {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }

                &__card-body {
                    padding: $space-4 + 0.25 $space-4 + 0.25 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                }

                &__ctas {
                    @include media($xxlarge) {
                        display: flex;
                        gap: $space + 0.1875;
                    }

                    @include media($mobile-only) {
                        display: flex;
                        gap: $space + 0.1875;
                    }

                    &--wrapper {
                        padding: 0 0 $space-2 + 0.125;
                    }
                }

                &__cta {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    margin-bottom: $space-3 + 0.0625;
                    width: 100%;
                }

                &__icons {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: $space-4 + 0.25;
                    max-height: 20px;
                }

                &__info {
                    display: block;
                    border-top: 1px solid $color-neutral-40;
                    text-align: right;
                    padding: $space-3 + 0.0625 0;

                    &--recommended {
                        text-align: left;
                    }
                }

                &__info-wrapper {
                    padding: 0 $space-4 + 0.25;

                    &--empty {
                        padding-top: 52px;
                    }
                }

                &__info-dealer-availability {
                    color: $color-utility-20;
                    text-align: center;
                }

                &__info-dealer-name {
                    color: $color-neutral-60;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                &__info-features {
                    min-height: 63px;

                    @include media($xlarge-only) {
                        min-height: 80px;
                    }

                    &--heading {
                        @include font('20-16-bold');
                        margin-top: $space-4 + 0.25;
                    }

                    &--empty {
                        min-height: auto;
                    }
                }

                &__info-feature {
                    @include font('20-16');
                    color: $color-neutral-60;
                }

                &__hero {
                    margin-bottom: $space-4 + 0.25;
                    margin-left: -$space-4 + 0.25;
                    margin-right: -$space-4 + 0.25;
                    text-align: center;

                    &--no-carousel {
                        margin-bottom: 44px;
                    }

                    img {
                        @include media($xlarge-only) {
                            height: 124px;
                        }

                        height: 180px;
                        object-fit: cover;
                    }

                    .inventory-favorites-card-ap__backplate {
                        height: 124px;
                        position: absolute;
                        left: 0;
                        width: 100%;
                    }
                }

                &__vehicle-info {
                    text-align: center;
                    color: $color-neutral-80;
                    margin-top: $space-6 + 0.125;
                    margin-bottom: $space-4 + 0.25;
                    min-height: 60px;

                    &--out-of-date {
                        text-align: center;
                        margin-bottom: $space-6 + 0.125;
                        min-height: 60px;
                        opacity: 0.5;
                    }

                    &--recommended {
                        margin-bottom: 69px;

                        @include media($large) {
                            margin-bottom: auto;
                        }
                    }

                    &--model-recommended {
                        margin-bottom: 83px;

                        @include media($large) {
                            margin-bottom: auto;
                        }
                    }
                }

                &__vehicle-info-model {
                    @include font('20-16');
                    text-align: center;
                    color: $color-neutral-60;

                    &--recommended {
                        @include font('20-24-bold');
                        margin-bottom: $space-3 + 0.0625;
                        color: $color-neutral-80;
                    }
                }

                &__vehicle-info-title {
                    @include font('20-16-bold');
                }

                &__vehicle-info-cost {
                    @include font('20-32');
                    margin-top: $space-3 + 0.0625;

                    &--wrapper {
                        @include section-reg;
                        margin-bottom: $space-2 + 0.125;
                    }

                    &--coming-soon {
                        margin-bottom: 42px;
                    }
                }

                &__mask {
                    position: relative;
                    width: 100%;
                    overflow: hidden;
                    height: 180px;

                    @include media($xlarge) {
                        height: 163px;
                    }

                    @include media($xxlarge) {
                        height: 185px;
                    }
                }

                &__masked-item {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%) scale(1.3);
                    width: 100%;
                }

                &__label {
                    @include section-reg;

                    .inventory-favorites-card-ap__not-available-overlay & {
                        opacity: 0.5;
                    }
                }

                // new card

                &--unavailable {
                    border: 1px solid $color-utility-10;

                    .carousel-nav,
                    .vehicle-grid-visuals__image-label,
                    .vehicle-grid-visuals__image-detail,
                    .vehicle-grid-highlights,
                    .vehicle-grid-features {
                        opacity: 0.6;
                    }

                    .vehicle-grid-card .inventory-favorites-card-ap__not-available-overlay .vehicle-grid-highlights__info-cost--wrapper {
                        padding-bottom: 0;
                    }
                }

                &--main .vehicle-grid-card .vehicle-grid-highlights__card-type--primary {
                    font-size: 24px;
                }

                &--recommendation-model {
                    .vehicle-grid-features__info-copy {
                        &--header {
                            font-weight: $font-weight-bold;
                            margin-bottom: $space-4;
                        }

                        &--description {
                            font-size: $space-2 + 0.125;
                            color: $color-neutral-60;
                        }

                    }

                    .vehicle-grid-card .vehicle-grid-highlights__info-title--model-title {
                        font-size: $space-6 + 0.125;
                        line-height: $space-40;
                        font-family: $font-family-10;
                        font-weight: $font-weight-regular;
                        margin-top: $space + 0.1875;
                    }

                    .vehicle-grid-features__info-copy {
                        margin-bottom: $space-40;
                    }
                }

                .vehicle-grid-card &__share-cta.vehicle-grid-footer__cta--button {
                    width: 100%;
                    justify-content: center;
                }

                .vehicle-grid-card .vehicle-grid-footer__cta--button.link {
                    font-weight: $font-weight-regular;
                }

                // Uncomment when share cta is implemented
                // &__share-cta::after {
                //     @include icon($icon-share);
                //     font-size: $space-4 + 4;
                // }

                .vehicle-grid-footer__ctas {
                    display: flex;
                    flex-direction: column;
                }

                .vehicle-grid-footer__cta {
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3):last-child {
                        grid-column: 1 / -1;
                    }

                    &:nth-child(4)::before {
                        content: '';
                        border-left: solid $space-2 $color-neutral-40;
                        height: $space-4 + 0.25;
                    }
                }

                .vehicle-grid-footer__status {
                    grid-column: 1 / -1;
                }
            }

            &__lock-icon {
                position: absolute;

                &:after {
                    @include icon($icon-check-badge);
                    font-size: 20px;
                    margin-left: $space;
                }
            }

            &__status {
                text-align: end;
            }
        }
    }

    &__error {
        margin-top: $space-2;
        color: $guardsman-red;
        text-align: center;
        font-size: 24px;
        border: 1px solid;
        border-color: $alto;
        border-radius: $space;
        background: $alto;
        padding: $space-2;
    }

    &__loadspinner-center { display: block; margin: auto; }

    &__no-item {
        font-weight: $font-weight-regular;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: calc(100vh - 75vh);
    }
}
