.fleet-tab {
    .tab {
        &__list {
            background: $silver;
            color: $black;
            cursor: pointer;
            display: inline-block;
            flex-basis: 0;
            flex-grow: 1;
            margin-right: $space-2;
            padding: $space-2;
            text-align: center;

            &--active {
                background: $wild-sand;
            }

            &:last-child {
                margin-right: 0;
            }

            &-container {
                border: 1px solid $silver-chalice;
                border-bottom: none;
                display: flex;
            }
        }

        &__content-container {
            background-color: $wild-sand;
            border: 1px solid $silver-chalice;
            border-top: none;
            display: flex;
            margin-bottom: $space-4;
        }

        &__content {
            margin-top: $space-4;
            width: 100%;
        }

        &__links {
            cursor: pointer;
        }
    }

    .contact-form__input-group {
        display: block;
    }
}
