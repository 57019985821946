.fluid-hero {
    &__container {
        width: 100%;
        max-width: none;
    }

    &__image-container {
        width: 100%;
    }
}
