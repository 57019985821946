.discovertalent-form {
    &__section {
        display: block;

        .pop-up {
            padding-top: 150px;

            &__content-header {
                background-color: $astral;
                color: $white;
            }

            &__content-body {
                background: $white;
            }

        }
    }

    &__block {
        background-color: $wild-sand;
        border: 1px solid $mercury;
        box-shadow: inset 0 1px 1px rgba($black,.05);
        padding: $space-4;
    }

    &__title {
        border-bottom: 1px solid $alto;
        font-size: $space-4;
        text-align: center;
        margin-bottom: $space-4;
        width: 100%;
    }

    &__group {
        @include span-columns(12);
        margin-bottom: $space-3;
    }

    &__label {
        @include span-columns(4);
        padding-left: $space;
        padding-top: 7px;
        text-align: right;
        margin-right: $space;
        font-size: 14px;
        font-weight: bold;
    }

    &__input-group-container {
        @include span-columns(6);
        padding: $space $space 0 $space;
    }

    &__input-group {
        display: table;
        font-size: 14px;
    }

    &__icon {
        background: $gallery;
        border: 1px solid $very-light-grey;
        border-right: 0;
        border-radius: $space;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        display: table-cell;
        padding: $space $space-2;
        vertical-align: middle;
        width: 1%;
    }

    &__control {
        background: $white;
        border: 1px solid $very-light-grey;
        border-radius: $space;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        display: table-cell;
        padding: 6px $space-2;
        float: left;
        width: 100%;
        font-family: $primary-font-name;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }

        &-container {
            display: inline-block;
        }
    }

    &__asterisk {
        color: red;
    }

    &__has-error {
        .contact-form__label {
            color: $cinnabar;
        }

        .contact-form__control {
            border-color: $cinnabar;
        }

        .contact-form__icon {
            background-color: rgba($cinnabar, 0.1);
            border-color: $cinnabar;
            color: $cinnabar;
        }
    }

    &__help-block {
        color: $cinnabar;
    }

    &__error-field {
        color: $apple-blossom;
        @include featured-copy-bold;
        margin-top: $space;

        &--hidden {
            display: none;
        }
    }

    &__section {
        display: block;

        .pop-up {
            padding-top: 150px;

            &__content-header {
                background-color: $astral;
                color: $white;
            }

            &__content-body {
                background: $white;
            }
        }
    }

    &__heading {
        @include featured-heading;
        margin-bottom: $space-2;
        text-align: center;
    }

    &__heading_two {
        font-weight: 500;
        margin-bottom: 14px;
        line-height: 20px;
        font-size: 12px;
        text-align: center;
    }

    &__heading_three {
        font-weight: 500;
        margin-bottom: 14px;
        line-height: 20px;
        font-size: 12px;
        text-align: center;
        white-space: pre;
        position: relative;
        top: 5px;

        @include media($mobile-only) {
            margin-left: 0px;
            white-space: pre-line;
            top: auto;
        }
    }

    &__heading_four {
        font-weight: 500;
        margin-bottom: 14px;
        line-height: 20px;
        font-size: 12px;
        text-align: center;
        white-space: pre;
        position: relative;
        top: 5px;

        @include media($mobile-only) {
            margin-left: 0px;
            white-space: pre-line;
            top: auto;
        }
    }

    &__number_circle {
        border-radius: 50%;
        width: 32px;
        height: 32px;
        padding: 8px;
        background: #d4ebf2;
        border: 2px solid #666;
        color: #666;
        text-align: center;
        font: 12px Arial, sans-serif;
        margin-left: 24%;
        float: left;

        @include media($mobile-only) {
            margin-left: 0px;
            margin-left: 0px;
        }
    }
}

.discovertalent-form-data {
    color: $mine-shaft;

    &__heading {
        @include featured-heading;
        margin-bottom: $space-2;
    }

    &__group {
        padding: $space-2 0px;

        &:nth-last-child(2) {
            padding-bottom: $space-4;
            border-bottom: 1px solid $dusty-gray;
        }

        &:last-child {
            padding: $space-4 0px;
        }
    }

    &__group1 {
        padding: $space-2 0px;

        &:nth-last-child(2) {
            padding-bottom: $space-4;
            border-bottom: 1px solid $dusty-gray;
        }

        &:last-child {
            padding: $space-4 0px;
        }

        @include media($mobile-only) {
            text-align-last: center;
        }
    }

    &__heading {
        @include featured-heading;
        margin-bottom: $space-2;
        text-align: center;
    }

    &__heading_two {
        font-weight: 500;
        margin-bottom: 14px;
        line-height: 20px;
        font-size: 12px;
        text-align: center;
    }

    &__heading_three {
        font-weight: 500;
        margin-bottom: 14px;
        line-height: 20px;
        font-size: 12px;
        text-align: center;
        white-space: pre;
        position: relative;
        top: 5px;

        @include media($mobile-only) {
            margin-left: 0px;
            white-space: pre-line;
            top: auto;
        }
    }

    &__heading_four {
        font-weight: 500;
        margin-bottom: 14px;
        line-height: 20px;
        font-size: 12px;
        text-align: center;
        white-space: pre;
        position: relative;
        top: 5px;

        @include media($mobile-only) {
            margin-left: 0px;
            white-space: pre-line;
            top: auto;
        }
    }

    &__number_circle {
        border-radius: 50%;
        width: 32px;
        height: 32px;
        padding: 8px;
        background: #d4ebf2;
        border: 2px solid #666;
        color: #666;
        text-align: center;
        font: 12px Arial, sans-serif;
        margin-left: 24%;
        float: left;

        @include media($mobile-only) {
            margin-left: 0px;
            margin-left: 0px;
        }
    }

    &__label {
        display: block;
        @include featured-copy-bold;
        margin-bottom: $space;
    }

    &__control {
        width: 100%;
        border: 1px solid $very-light-grey;
        border-radius: $space;
        height: $space-6+4;
        padding: $space+1 $space-2+2;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }
    }

    textarea {
        resize: none;
        height: auto;
    }

    .button {
        @include featured-copy-reg;
        border-radius: 0px;
        padding: $space $space-4;

        &:hover {
            cursor: pointer;
        }
    }

    &__error-field {
        color: $apple-blossom;
        @include featured-copy-bold;
        margin-top: $space;

        &--hidden {
            display: none;
        }
    }
}
