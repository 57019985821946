.alternate-link-list-download {
    &__mainHeading {
        font-family: $secondary-font-name;
        font-size: $space-4;
        font-weight: 500;
        color: $scorpion;
        margin: 0px 0px $space-4 $space-2;
    }

    .link-list {
        border: 1px solid $alto;
        border-radius: 3px;

        &__heading {
            font-family: $secondary-font-name;
            font-size: $space-4;
            font-weight: 500;
            color: $white;
            background-color: $black;
            padding: $space 0px 0px $space-2;
            padding-bottom: $space;
            border-radius: 3px 3px 0px 0px;
        }

        &__content-description {
            margin-bottom: 0px;
        }

        &__item {
            &-text {
                font-weight: 700;
            }

            &-link {
                border-bottom: 1px solid $alto;

                &:last-child {
                    border-bottom: none;
                }

                &:hover {
                    background-color: $white;
                    color: $pacific-blue;
                }
            }
        }

        &__icon-list {
            color: $silver ;
        }

        &__icon-nav {
            color: $pacific-blue;
        }

        &__divider {
            width: 5px;
            height: auto;
            display: inline-block;
        }

        &__btn_column {
            text-align: center;
            padding: 2%;
        }

    }

    &__control {
        width: 100%;
        border: 1px solid $very-light-grey;
        border-radius: $space;
        height: $space-6+4;
        padding: $space+1 $space-2+2;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }
    }

    &__error-field {
        color: $apple-blossom;
        @include featured-copy-bold;
        margin-top: $space;

        &--hidden {
            display: none;
        }
    }
}