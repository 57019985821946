.read-only-screen {
    &__heading {
        font-size: 20px;
        font-family: inherit;
        font-weight: 500;
        margin-bottom: $space-2;
        color: black;
        background: lightgray;
        border: 1px thick red;
        border-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        display: table-cell;
        padding: 6px $space-2;
        float: left;
        position: relative;
        width: 102%;
        height: 20%;
        margin-left: -10px;
        margin-top: -5px;
    }

    &__group {
        display: flex;
        padding-right: 30px;
        flex-direction: row;
        justify-content: start;
        test-align: left;
        width: 900px;
        line-height: 26px;
        margin-bottom: 10px;
    }

    &__label {
        @include span-columns(4);
        padding-left: 2px;
        padding-top: 0px;
        text-align: left;
        margin-right: $space;
        font-size: 14px;
        font-weight: bold;
        width: 100%;
    }

    &__control {
        background: $white;
        border: 1px solid $very-light-grey;
        border-radius: 2px;
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
        display: table-cell;
        padding: 6px $space-2;
        float: left;
        width: 100%;
        font-family: $primary-font-name;
        margin-top: 3%;
        margin-left: -25%;
        margin-right: 7%;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &-container {
            display: inline-block;
        }
    }

    &__design {
        background: whitesmoke;
        border: 1px transparent;
        border-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        display: table-cell;
        padding: 6px $space-2;
        float: left;
        width: 100%;
    }

    &__layout {
        background: whitesmoke;
        border: 1px transparent;
        border-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        display: table-cell;
        padding: 6px $space-2;
        float: left;
        width: 100%;
    }

    &__heading1 {
        font-size: 20px;
        font-family: inherit;
        font-weight: 500;
        margin-bottom: $space-2;
        color: black;
        background: lightgray;
        border: 1px thick red;
        border-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        display: table-cell;
        padding: 6px $space-2;
        float: left;
        position: relative;
        width: 100.8%;
        height: 20%;
        margin-left: -10px;
        margin-top: -5px;
    }

    &__display {
        background: white;
        display: table-cell;
        padding: 6px $space-2;
        float: left;
        width: 100%;
    }

    &__design1 {
        background: whitesmoke;
        border: 1px transparent;
        border-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        display: table-cell;
        padding: 6px $space-2;
        float: left;
        width: 102%;
        position: relative;
        left: -1%;
    }

    &__group1 {
        padding-right: 30px;
        flex-direction: row;
        justify-content: start;
        test-align: left;
        width: 1000px;
        line-height: 26px;
        margin-bottom: 10px;
    }

    &__label1 {
        @include span-columns(4);
        padding-left: 2px;
        padding-top: 0px;
        text-align: left;
        margin-right: $space;
        font-size: 14px;
        width: 100%;
    }

    &__group2 {
        padding-right: 30px;
        flex-direction: row;
        justify-content: start;
        test-align: left;
        width: 150px;
        line-height: 26px;
        margin-bottom: 10px;
    }

    &__heading2 {
        font-size: 20px;
        font-family: inherit;
        font-weight: 500;
        margin-bottom: $space-2;
        color: black;
        background: lightgray;
        border: 1px thick red;
        border-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        display: table-cell;
        padding: 6px $space-2;
        float: left;
        position: relative;
        width: 110%;
        height: 20%;
        margin-left: -10px;
        margin-top: -5px;
    }
}