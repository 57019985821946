.g-Class-StickyNav-SideBar {
    &__fixed-section {
        right: 0%;
        bottom: 33%;
        z-index: 99999;
        position: fixed;
    }

    &__container {
        display: flex;
    }

    &__link-container {
        background-color: rgb(102, 102, 102);
        margin-left: auto;
        position: relative;
        padding: 0 10 0 10;
        opacity: 0;
        visibility: hidden;
    }

    &__link {
        position: relative;
        top: 8;
        color: white;
    }

    &__image {
        margin: 0;
        width: 35% !important;
        position: relative;
        margin-left: auto;
        padding: 10;
        background-color: rgb(102, 102, 102);
        border-bottom-width: 1px;
        border-bottom-color: white;
        border-style: solid;
    }

    &__mobile {
        @include media($mobile-only) {
            display: none !important;
        }
    }
}

.g-Class-StickyNav-SideBar__container:hover .g-Class-StickyNav-SideBar__link-container {
    opacity: 1;
    visibility: visible;
}