.order-confirmation-modal {
    &__heading {
        color: $color-neutral-80;
        margin: $space-3 0 $space-4;
        margin-bottom: $space-2;
        text-align: center;
        float: unset;
        width: 100%;

        @include media($large) {
            margin: $space-6 0;
            margin-bottom: $space-2;
        }

        &--light {
            color: $color-neutral-10;
        }
    }

    &__sub-heading {
        float: none;
    }

    &__action {
        padding: $space-2;
        display: flex;

        &>.button {
            margin-right: $space-2;
            margin-left: $space-2;
        }

        &__button-reject {
            background-color: $color-utility-10;
            color: $color-neutral-10;
        }
    }

    &__production-date-input {
        margin: $space-2;
    }
}
