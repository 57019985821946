.more-info-bar {
    $cta-max-height: 35; // 35 is the max-height of the CTA
    height: 100%;
    position: absolute;
    top: calc(100% - #{$cta-max-height}px);
    transition: top $speed-7;
    width: 100%;

    @include media($large) {
        max-height: #{$cta-max-height}px;
        height: auto;
        transition: max-height $speed-7;
    }

    &.active {
        top: 0;

        @include media($large) {
            bottom: 0;
            max-height: 40%;
            top: auto;
        }

        .more-info-bar__toggle {
            text-align: left;
            width: 100%;
            display: block;

            @include media($large) {
                display: flex;
                padding: $space-2;
                padding-left: $space;
                width: auto;
            }

            &:after {
                @include icon($icon-exit);
            }
        }

        .more-info-bar__toggle-copy {
            @extend .offscreen;
        }
    }

    &__toggle {
        @include copy-wide-reg;
        background-color: rgba(0, 0, 0, 0.7);
        color: $silver-chalice;
        cursor: pointer;
        display: flex;
        justify-content: center;
        line-height: 1.3; // Value on mbusa
        max-height: #{$cta-max-height}px; // padding-top + line-height + padding-bottom ($space-2 + (12px*1.3) + $space-2)
        padding: $space-2 $space-2;

        &:after {
            @include icon($icon-arrow-up-square);
            padding-left: $space;
        }

        &:hover {
            color: $white;
        }
    }

    &__content {
        background-color: rgba(0, 0, 0, 0.7);
        color: $white;
        height: calc(100% - #{$cta-max-height}px);
        line-height: 1;
        padding: $space-3;
        position: relative;

        @include media($large) {
            padding: $space-2;
            min-height: 70px;
        }
    }

    &__copy-container {
        @include media($large) {
            width: 60%;
        }
    }

    &__caption-description {
        color: $silver-chalice;
        line-height: 1;
        padding-top: $space;
    }

    &__model {
        color: $white;
        text-decoration: underline;
    }

    &__cta-container {
        bottom: 0;
        margin: $space-2 0;
        position: absolute;
        right: $space-4;

        @include media($large) {
            bottom: 50%;
            margin: 0;
            right: 0;
            transform: translateY(+50%);
        }
    }

    &__cta-list-item {
        border-left: 1px solid $blitz;
        display: inline-block;
        padding: 0 $space-2;

        @include media($large) {
            width: 85px;
            padding: $space-2;
            text-align: center;
            vertical-align: top;
        }
    }

    &__cta {
        color: $silver-chalice;
        display: block;

        &:hover {
            color: $white;
        }

        &.download {
            &:after {
                @include icon($icon-caret-down);
                border-bottom: 1px solid $silver-chalice;
                font-size: 70%;
                margin-left: $space-2;

                @include media($large) {
                    display: block;
                    width: 9px;
                    margin: 0 auto;
                    padding-left: 0;
                }
            }

            &:hover:after {
                border-bottom: 1px solid $white;
            }
        }

        @include media($large) {
            line-height: 1.2;
        }
    }
}
