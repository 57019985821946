.vehicle-end-date-update {
    color: $mine-shaft;

    &__heading {
        @include featured-heading;
        margin-bottom: $space-2;
    }

    &__group {
        min-height: 20px;
        padding: 19px;
        margin-bottom: 20px;
        background-color: #f5f5f5;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    }

    &__group_input {
        padding: $space-2 0px;
    }

    &__group_btn {
        padding: $space-2 0px;
        text-align: center;
    }

    &__asterisk_field {
        color: red;
    }

    &__label {
        display: block;
        @include featured-copy-bold;
        margin-bottom: $space;
    }

    &__control {
        width: 100%;
        border: 1px solid $very-light-grey;
        border-radius: $space;
        height: $space-6+4;
        padding: $space+1 $space-2+2;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }
    }

    &__control_textarea {
        height: auto;
    }

    &__control_document {
        background: $white;
        padding: 6px $space-2;
        width: 100%;
        font-family: $primary-font-name;
    }

    &__control_table {
        border: 1px solid $very-light-grey;
        border-radius: $space;
        height: $space-6+4;
        padding: $space+1 $space-2+2;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }
    }

    &__title {
        font-size: $space-4;
        width: 100%;
    }

    &__text {
        font-size: $space-3;
        font-weight: bold;
        width: 100%;
    }

    &__hide {
        visibility: hidden;
    }

    &__table_column {
        width: 35%;
        vertical-align: middle;
    }

    &__table_column_checkbox {
        width: 35%;
    }

    &__table_column_padding {
        padding-left: 15px;
    }

    &__table {
        width: 80%;
    }

    &__error-field {
        color: $apple-blossom;
        @include featured-copy-bold;
        margin-top: $space;

        &--hidden {
            display: none;
        }
    }

    &__btn_column {
        text-align: center;
    }

    &__reset_btn {
        background-color: #D3D3D3;
        color: #333;
        border-color: #ccc;
        display: inline-block;
        margin-bottom: 0;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        white-space: nowrap;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.428571429;
        border-radius: 4px;
    }

    &__section {
        display: block;

        .pop-up {
            padding-top: 150px;

            &__content-header {
                background-color: $astral;
                color: $white;
            }

            &__content-body {
                background: $white;
            }

        }
    }

    &__control_textarea {
        height: auto;
        border: 1px solid $very-light-grey;
        border-radius: $space;
        padding: $space+1 $space-2+2;
        width: 100%;
    }

    &__input-symbol-dollar {
        position: relative;
        left: 3%;

        &:after {
            content: "$";
            font-size: 104% !important;
            font-weight: 400;
            left: 3%;
            position: absolute;
            top: -31%;
            padding-right: 3% !important;
        }
    }

    &__abc-input {
        padding-left: 5% !important;
    }
}