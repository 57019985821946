.forgot-password-form {
    .form {
        &__block {
            @include span-columns(12);
            @include shift(0);
            float: none;
        }

        &__error-block {
            &-container {
                @include span-columns(12);
                margin: $space-2 0px;
                border: 1px solid transparent;
                border-radius: 4px;
                background-color: $bizarre;
                color: $apple-blossom;
            }
        }
    }
}
