.bluetecupdate-form {
    &__title {
        @include featured-copy-bold;
        padding: $space-8 0 $space-3 0;
    }

    &__container {
        text-align: center;

        form {
            width: 100%;
            text-align: left;
            margin: 0 auto;

            @include media($tablet) {
                width: 53%;
            }
        }
    }

    &__input {
        width: 100%;
        border: 1px solid #cccccc;
        border-radius: 5px;
        height: 34px;
        padding: 6px 12px;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &-group {
            .button {
                min-width: 100px;
                opacity: 1;

                &[disabled=disabled] {
                    opacity: 0.5;

                    &:hover {
                        cursor: not-allowed;
                        background: $denim;
                    }
                }
            }

            @include media($tablet) {
                &:last-of-type {
                    text-align: center;
                    flex-grow: 1;
                }
            }
        }

        &-label {
            display: block;
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }
    }

    &__submit-container {
        display: block;

        @include media($tablet) {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
        }
    }

    &__error-field {
        color: $guardsman-red;

        &--hidden {
            opacity: 0;
        }
    }

    &__recaptcha {
        &-container {
            margin-bottom: $space-4;

            @include media($tablet) {
                margin-bottom: 0;
            }

            &--hidden {
                display: none;
            }
        }
    }

    &__information {
        text-align: left;
        display: inline-block;
        width: 100%;
        text-align: center;

        @include media($tablet) {
            width: 53%;
        }

        &-container {
            &--hidden {
                display: none;
            }
        }

        .dealer-widget-container {
            text-align: left;
            padding-top: $space-4;
            padding-bottom: $space-8;
            margin: $space-4 0;
            border-bottom: 1px solid $dusty-gray;
            border-top: 1px solid $dusty-gray;
        }

        .preferred-dealer__address-container-dealer {
            padding-left: 0;
        }

        .preferred-dealer-plugin__sub-heading-container {
            text-align: center;
        }

        .preferred-dealer-plugin__sub-heading, .preferred-dealer-plugin__sub-heading-copy {
            display: inline-block;
        }
    }

    &__table {
        margin: $space-6 0;
        border: 1px solid $silver;
        @include featured-copy-reg;

        &-container {
            text-align: center;
        }

        &-row {
            display: flex;
            align-items: stretch;
            width: 100%;
            border-top: 1px solid $silver;

            &:last-of-type {
                .bluetecupdate-form__column-info {
                    padding: 0 $space-2;
                }
            }
        }

        &-column {
            width: 80%;
            float: left;
            padding: $space;

            &:first-of-type {
                border-right: 1px solid $silver;
            }

            &:last-of-type {
                width: 20%;
                border-left: 1px solid $silver;
            }
        }

        &-heading {
            width: 80%;
            float: left;
            border-right: 1px solid $silver;
            padding: $space 0;

            &:last-of-type {
                width: 20%
            }

            &-row {
                background: $alto;
                display: inline-block;
                width: 100%;
                font-weight: $font-weight-bold;
            }
        }
    }

    &__message {
        display: block;
        @include call-out-text-bold-italic;
        margin-bottom: $space-2;
    }

    &--recaptcha-hidden {
        .bluetecupdate-form__input-group {
            text-align: left;
        }
    }

    &__column {
        &-info {
            background: $seashell;
            border: 1px solid $silver;
            display: none;
            text-align: left;
            margin-top: $space;

            &--open {
                display: block;
            }

            &-row, &-heading {
                text-align: center;
                padding: $space;
                border-bottom: 1px solid $silver;
                display: inline-block;
                width: 100%;
            }

            &-heading {
                background: $alto;
                font-weight: $font-weight-bold;
            }

            &-column {
                @include span-columns(5);
                @include omega(2n);

                &:last-of-type {
                    border-bottom: none;
                }

                &:nth-of-type(2n+1) {
                    @include span-columns(7);
                }

                .icon-check {
                    color: $fruit-salad;
                }

                .icon-cross {
                    color: $guardsman-red;
                }
            }

            &-icon {
                font-size: $space-2;
                display: block;
                margin-top: $space;
            }
        }

        &-title {
            cursor: pointer;


            .icon-arrow-down-square {
                float: right;
                margin: $space+2 $space+1 0px;
                font-size: $space-2+1;
            }
        }
    }

    &__redirect-link {
        color: $denim;
        cursor: pointer;

        &:hover {
            color: $azure-radiance;
        }

        &-container {
            margin: $space-2 0;
        }
    }
}

form[id=bluetecupdate-form-personal-info] {
    width: 100%;
    visibility: visible;
    opacity: 1;
    transition: opacity 1s ease-in-out;

    .bluetecupdate-form {
        &__address {
            @include span-columns(12);

            @include media($tablet) {
                @include span-columns(6);

                &:nth-of-type(2n) {
                    margin-right: 0;
                }
            }

            &-subsection {
                display: inline-block;
                width: 100%;
            }

            &-section {
                @include span-columns(12);

                @include media($tablet) {
                    @include span-columns(4);
                }
            }
        }

        &__input {
            &-group {
                padding: 0;
                @include span-columns(12);

                @include media($tablet) {
                    padding: $space 0;
                    @include span-columns(6);

                    &:nth-of-type(2n) {
                        margin-right: 0;
                    }
                }

                &:last-of-type, &--address {
                    @include span-columns(12);
                }

                &:last-of-type {
                    text-align: left;
                }
            }

            &-radio-group {
                @include copy-narrow-bold;
                margin-bottom: 2px;
            }

            &-label {
                @include copy-narrow-bold;
                margin-bottom: $space;
            }
        }

        &__recaptcha-container {
            &--hidden {
                display: none;
            }
        }

        &__submit-container {
            width: 100%;

            @include media($tablet) {
                .bluetecupdate-form__input-group:last-of-type {
                    margin-left: $space-2;
                }
            }
        }
    }
}

form[id=bluetecupdate-unsubscribe-form], form[id=bluetecupdate-resend-confirmation-form] {
    width: 100%;

    .bluetecupdate-form {
        &__input-group {
            @include span-columns(8);

            &:last-of-type {
                @include span-columns(4);
            }
        }
    }
}

form[hidden] {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s ease-in-out;
}

.preferred-dealer-plugin--search-bar {
    form {
        @include media($tablet) {
            width: 65%;
        }
    }
}