.chalkboard {

    &__chalkboardTable {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;

        .fixed {
            table-layout: fixed;
            border: 1px solid #ddd;
            padding: 8px;

            td {
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: break-word;
            }

            th {
                cursor: pointer;
                padding-top: 12px;
                padding-bottom: 12px;
                text-align: left;
                background-color: #04AA6D;
                color: white;
            }

            tr {
                &:hover {
                    background-color: #ddd;
                }
            }
        }

    }
}
