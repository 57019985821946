.favorites-list {
    font-size: 14px;

    &__container {
        margin-top: $space-2;
    }

    &__item {
        &-anchor {
            color: $boston-blue;
            cursor: pointer;

            &:hover {
                color: $pacific-blue;
            }
        }
    }
}
