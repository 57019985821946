.col {
    &-3 {
        @include span-columns(12);

        @include media($tablet) {
            @include span-columns(3);
        }
    }

    &-6 {
        @include span-columns(12);

        @include media($tablet) {
            @include span-columns(6);
        }
    }

    &-9 {
        @include span-columns(12);

        @include media($tablet) {
            @include span-columns(9);
        }
    }

    &-12 {
        @include span-columns(12);

        @include media($tablet) {
            @include span-columns(12);
        }
    }
}
