.subscribe-form {
    &__container {
        border-bottom: 1px solid #9b9b9b;
        color: $mine-shaft;
        @include clearfix;

        &:last-of-type {
            border-bottom: none;
        }
    }

    &__row {
        @include media($tablet) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__subscription-block {
        @include media($tablet) {
            @include span-columns(6);
        }
    }

    &__brand-block {
        position: relative;
        padding-left: $space-4;

        @include media($large-only) {
            @include span-columns(3);
            @include omega(4n);
        }

        @include media($desktop) {
            @include span-columns(2);
            @include omega(6n);
        }

        .subscribe-form__form-radio {
            left: 0;
            position: absolute;
            top: 0;
        }
    }

    &__ext-block {
        @include media($tablet) {
            @include span-columns(3);
        }
    }

    &__block-container {
        @include span-columns(12);
    }

    &__phone-block {
        @include media($tablet) {
            @include span-columns(9);
        }
    }

    &__rank-block {
        @include media($tablet) {
            @include span-columns(4);
            @include omega(3n);
        }
    }

    &__title {
        @include featured-heading;
        margin-top: $space-4;
        margin-bottom: $space-2;
    }

    &__subtitle {
        @include section-reg;
        margin-top: $space-4;
        margin-bottom: $space-2;
    }

    &__text {
        @include featured-copy-reg;
        margin-bottom: $space-4;

        &-bold {
            font-weight: 700;
        }

        &-left {
            text-align: center;

            @include media($tablet) {
                text-align: left;
            }
        }

        &-right {
            text-align: center;

            @include media($tablet) {
                text-align: right;
            }
        }

        &-center {
            text-align: center;
        }
    }

    &__label {
        @include featured-copy-reg;
        display: inline-block;
        margin-bottom: $space;
        vertical-align: middle;

        &-bold {
            font-weight: 700;
        }
    }

    &__select-container {
        position: relative;
        max-width: 140px;
    }

    &__form {
        &-group {
            margin-top: $space-3;
            margin-bottom: $space-3;
        }

        &-options {
            margin-bottom: $space-2;
        }

        &-radio {
            margin-left: 0;
        }

        &-control {
            @include featured-copy-reg;
            width: 100%;
            padding: 6px 12px;
            background-color: $white;
            background-image: none;
            border: 1px solid $silver;
            border-radius: 4px;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

            &:focus {
                border-color: $azure-radiance;
                outline: 0;
                box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
            }

            &:not(&-textarea) {
                height: 34px;
            }

            &-select {
                background: $denim;
                border-radius: 0;
                border-color: $denim;
                color: $white;
                height: auto;
                padding: $space $space-5 $space $space;
                -webkit-appearance: none;
                -moz-appearance: none;
                -ms-appearance: none;
                -o-appearance: none;
                appearance: none;

                &-icon {
                    color: #fff;
                    position: absolute;
                    right: $space-2;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                }
            }
        }

        &-error-field {
            color: $guardsman-red;
            display: block;
            @include featured-copy-reg;

            &.d-none {
                display: none;
            }
        }

        &-slider {
            appearance: none;
            -webkit-appearance: none;
            background: linear-gradient(180deg,$mercury 0,$seashell);
            border-radius: 4px;
            box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
            height: $space-2;
            margin: $space-2 0;
            outline: none;
            width: 210px;

            &::-webkit-slider-thumb {
                appearance: none;
                -webkit-appearance: none;
                background: $denim;
                border-radius: 50%;
                cursor: pointer;
                height: $space-4;
                width: $space-4;
            }

            &::-moz-range-thumb {
                background: $denim;
                border-radius: 50%;
                cursor: pointer;
                height: $space-4;
                width: $space-4;
            }

            &-item {
                display: inline-block;
                margin-bottom: $space-3;
                padding: 0 $space-4;
                @include featured-copy-reg;

                &:first-of-type {
                    padding-left: 0;
                }

                &:last-of-type {
                    padding-right: 0;
                }
            }
        }
    }

    &__btn {
        border-radius: 0;
        border: none;
        cursor: pointer;
        min-width: 110px;
        @include featured-copy-reg;

        &-reset {
            color: $white;
            background: $black;

            &:hover {
                background: $blitz;
            }
        }
    }

    &__margin-b-0 {
        margin-bottom: 0;
    }
}
