.alternate-link-list {
    &__mainHeading {
        font-family: $secondary-font-name;
        font-size: $space-4;
        font-weight: 500;
        color: $scorpion;
        margin: 0px 0px $space-4 $space-2;
    }

    .link-list {
        border: 1px solid $alto;
        border-radius: 3px;

        &__heading {
            font-family: $secondary-font-name;
            font-size: $space-4;
            font-weight: 500;
            color: $white;
            background-color: $black;
            padding: $space 0px 0px $space-2;
            padding-bottom: $space;
            border-radius: 3px 3px 0px 0px;
        }

        &__content-description {
            margin-bottom: 0px;
        }

        &__item {
            &-text {
                font-weight: 700;
            }

            &-link {
                border-bottom: 1px solid $alto;

                &:last-child {
                    border-bottom: none;
                }

                &:hover {
                    background-color: $white;
                    color: $pacific-blue;
                }
            }
        }

        &__icon-list {
            color: $silver ;
        }

        &__icon-nav {
            color: $pacific-blue;
        }
    }
}
