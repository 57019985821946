.overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.9);
    top: 0;
    left: 0;
    z-index: 901;

    &--hidden {
        display: none;
    }

    &__loader {
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 16px solid $white;
        border-right: 16px solid transparent;
        border-bottom: 16px solid $white;
        border-left: 16px solid transparent;
        width: 120px;
        height: 120px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    &__message {
        color: white;
        margin-top: $space-4;
        @include featured-copy-bold;
        width: 100%;
        text-align: center;
    }

    &__container {
        position: fixed;
        z-index: 999;
        top: calc(50% - 80px);
        left: calc(50% - 60px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        &--dog {
            left: calc(50% - 161px);
        }
    }

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}