.notification {
    @keyframes marquee {
        0% {
            -ms-transform: translate(0, 0);
            transform: translate(0, 0);
        }

        100% {
            -ms-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
        }
    }

    &__marquee {
        margin: 0 auto;
        white-space: nowrap;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0;
        display: block;
        background-color: wheat;
        margin-left: -90px;

        span {
            display: inline-block;
            padding-left: 100%;
            text-indent: 0;
            font-size: initial;
            will-change: transform;
            color: black;
            animation: marquee 28s linear infinite;
        }
    }

    &__search {
        text-align: left;
        display: block;
        width: 100%;
        margin-right: $space+2;

        @include media($desktop) {
            display: inline-block;
        }

        @include media($large-desktop) {
            display: inline-block;
            margin-right: $space-2;
        }
    }
}
