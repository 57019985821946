.corporate-sales-report-form, .can-search-and-update {
    .button {
        &:hover {
            cursor: pointer;
        }

        &__container {
            margin: $space-3 0;

            button {
                margin: $space 0;

                @include media($tablet) {
                    margin: 0;
                }
            }
        }
    }

    .form {
        &__container {
            text-align: center;
            padding: $space-4;
        }

        &-submit {
            &__container {
                width: 100%;
                padding: $space-2 0;

                button[disabled] {
                    cursor: not-allowed;
                    background: $alto;

                    &:hover {
                        background: $alto;
                    }

                    &:focus {
                        background: $alto;
                    }
                }

                input {
                    border: none;
                }
            }
        }

        &-title {
            @include call-out-text-bold;
            margin: $space-2 0;
        }

        &-input {
            width: 60%;
            display: inline-block;
            margin: $space 0;
            padding: $space;

            &:focus {
                border: 2px solid $cornflower-blue;
            }

            &__container {
                display: block;

                @include media($desktop) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }

            &-group {
                display: block;
                width: 100%;
                font-size: 0;

                @include media($desktop) {
                    width: 40%;

                    &:first-of-type {
                        width: 50%;
                    }
                }

                label {
                    width: 40%;
                    display: inline-block;
                    font-size: 12px;
                    text-align: left;
                }

                &--separator {
                    position: relative;
                    display: block;
                    width: 100%;
                    margin: $space-4 0;
                    min-height: 1px;

                    @include media($desktop) {
                        width: 10%;
                        min-height: $space-32+$space-4;
                    }

                    .line {
                        position: absolute;
                        left: 0%;
                        top: 0;
                        bottom: 0;
                        width: 100%;
                        background: $silver;
                        z-index: 1;

                        @include media($desktop) {
                            left: 49%;
                            width: 1px;
                        }
                    }

                    .wordwrapper {
                        text-align: center;
                        height: $space-4;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 50%;
                        margin-top: -($space-2+2);
                        z-index: 2;
                    }

                    .word {
                        color: $silver;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        padding: 3px;
                        @include copy-narrow-bold;
                        background: $white;
                        display: inline-block;
                    }
                }
            }
        }
    }

    #canSearch {
        .form {
            &-submit__container {
                text-align: left;
            }
        }

        #canListInputFields {
            @include media($desktop) {
                label {
                    width: 25%;
                }

                input {
                    width: 75%;
                }
            }
        }
    }

    .hidden {
        display: none;
    }

    button {
        &:focus {
            background: $denim;
        }
    }

    .tab {
        &__items-list {
            display: block;
            text-align: center;
            border-bottom: 1px solid $silver;

            @include media($tablet) {
                @include highlight-copy-reg;
                display: flex;
                justify-content: space-between;
            }
        }

        &__item {
            width: 50%;
            position: relative;

            &:hover {
                .button {
                    color: $azure-radiance;
                }
            }

            &:first-of-type {
                &:after {
                    border-right: 1px solid #c7c7c7;
                    content: '';
                    height: 28px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    -moz-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                }
            }

            &-link {
                color: $scorpion;
                display: block;
                width: 100%;
                padding: $space-3;

                &:hover {
                    cursor: pointer;
                    color: $azure-radiance;
                }
            }
        }

        &__content {
            padding: $space-4 0;
        }
    }

    .download-button-container {
        padding: $space-4 0;
        text-align: center;
    }
}

.corporate-sales-report-form, .can-search-and-update, .fep-eab-filter, .fleet-program-status-maintenance {
    .sort-table {
        &__container {
            .search {
                width: 75%;
                padding: $space;
                display: inline-block;
                margin-left: 4%;

                &-container {
                    text-align: right;
                    font-size: 0;

                    label {
                        @include featured-copy-bold;
                        display: inline-block;
                        width: 21%;
                    }
                }
            }

            table {
                min-width: auto;
                overflow: visible;
                width: 100%;
                margin: $space-4 0;
                text-align: center;

                tr:nth-child(odd) {
                    background-color: $white;
                }

                tr:nth-child(even) {
                    background-color: $seashell;
                }

                td:first-child {
                    @include copy-narrow-bold;
                }

                td {
                    padding: $space-3;
                    @include copy-narrow-reg;
                }

                td.print {
                    color: $denim;

                    &:hover {
                        color: $azure-radiance;
                        cursor: pointer;
                    }
                }

                td, th {
                    border: 1px solid $mercury;
                    word-wrap: break-word;
                }

                th {
                    text-transform: uppercase;
                    background-color: $silver;
                    @include highlight-copy-bold;
                    padding: $space-2;
                    cursor: pointer;

                    .icon {
                        margin: 0 $space;

                        &:before {
                            content: $icon-descendant;
                        }
                    }
                }

                .asc {
                    .icon {
                        &:before {
                            content: $icon-sort-descending;
                        }
                    }
                }

                .desc {
                    .icon {
                        &:before {
                            content: $icon-sort-down;
                        }
                    }
                }
            }

            .pagination {
                &-container {
                    display: inline-block;
                    width: 100%;
                }

                &-info {
                    display: inline-block;
                }

                &-wrap {
                    user-select: none;
                    display: flex;
                    float: right;
                    align-items: stretch;
                    border: 1px solid $mercury;

                    a {
                        text-decoration: none;
                    }
                }

                &-layout {
                    display: flex;
                    padding: 0;
                    margin: 0;
                    list-style-type: none;

                    li {
                        a.page {
                            display: block;
                            border: none;
                            line-height: 24px;
                            padding: 0;
                            text-align: center;
                            font-weight: $font-weight-bold;
                            color: $denim;
                            background-color: transparent;
                            padding: $space $space-2;
                            border-right: 1px solid $mercury;
                            min-width: $space-7;

                            &:first-of-type {
                                border-left: 1px solid $mercury;
                            }

                            &:hover {
                                background-color: $alto;
                            }
                        }

                        &.active {
                            a.page {
                                background-color: $denim;
                                color: $white;
                                border-right: 1px solid $denim;
                            }
                        }
                    }
                }

                &-next, &-prev {
                    color: $denim;
                    padding: $space $space-2;
                    display: flex;
                    align-items: center;

                    &:hover {
                        background: $alto;
                    }

                    &.disabled {
                        color: $silver;
                        cursor: default;

                        &:hover {
                            color: $silver;
                            background: transparent;
                        }
                    }
                }
            }

            .hidden {
                display: none;
            }
        }

        &-tools {
            display: flex;
            justify-content: space-between;

            .entries-amount {
                display: inline-block;

                select {
                    padding: 4px;
                }

                span {
                    @include featured-copy-bold;
                }
            }
        }

        &__table-section {
            overflow-x: auto;
        }
    }
}

.can-search-and-update {
    .sort-table__table-section {
        max-height: 400px;

        th {
            cursor: default;
        }
    }

    .form {
        &__container {
            padding: 0;
        }
    }

    .pop-up {
        &__content-body {
            overflow: auto;
            max-height: 250px;
        }

        table {
            width: 100%;
            text-align: center;
            border: 1px solid $silver;

            caption {
                margin: $space 0;
                @include call-out-text-bold;
            }

            th {
                text-transform: uppercase;
                @include highlight-copy-bold;
                padding: $space-2;
                background: $alto;
            }

            tr {
                border-top: 1px solid $silver;
            }

            td, th {
                border-right: 1px solid $silver;

                &:last-of-type {
                    border-right: none;
                }
            }
        }
    }

    .pop-up[type=update] {
        padding-top: $space-2;

        .pop-up {
            &__content {
                width: 85%;

                &-body {
                    max-height: 450px;
                    overflow: auto;
                }
            }
        }
    }

    .button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.fleet-program-status-maintenance {
    #table-container {
        padding: $space-3;
    }
}