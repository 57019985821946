.alert-box {
    font-size: 14px;
    font-family: $default-font-family;
    border-radius: $space;
    margin-bottom: $space-2;
    margin-top: $space-2;

    &__container {
        padding: $space-2;
    }

    &__title {
        font-weight: $font-weight-bold;
    }

    &__close-icon {
        float: right;
        font-family: "micro-icons";
        cursor: pointer;
        margin-top: $space-2;
        margin-right: $space-2;
        text-shadow: 0 1px 0 $white;
        opacity: 0.2;

        &:hover {
            color: $black;
            opacity: 0.5;
        }

        &:before {
            content: '\e91e';
        }
    }

    &--success {
        background-color: #dff0d8;
        border-color: #d0e9c6;

        .alert-box__title {
            color: #3c763d;
        }
    }

    &--info {
        background-color: #d9edf7;
        border-color: #bcdff1;

        .alert-box__title {
            color: #31708f;
        }
    }

    &--warning {
        background-color: #fcf8e3;
        border-color: #faf2cc;

        .alert-box__title {
            color: #8a6d3b;
        }
    }

    &--danger {
        background-color: #f2dede;
        border-color: #ebcccc;

        .alert-box__title {
            color: #a94442;
        }
    }

    &--close {
        display: none;
    }
}
