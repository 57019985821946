.instructor-form {
    color: $mine-shaft;

    &__heading {
        @include featured-heading;
        margin-bottom: $space-2;
        text-align: center;
        margin-top: -8%;
    }

    &__center {
        text-align: center;
    }

    &__group {
        min-height: 20px;
        padding: 19px;
        margin-bottom: 20px;
    }

    &__group_input {
        padding: $space-2 0px;
    }

    &__input_group1 {
        font-size: 14px;
    }

    &__group_input1 {
        padding-right: 15px;
    }

    &__group_btn {
        padding: $space-2 0px;
        text-align: center;
    }

    &__asterisk_field {
        color: red;
    }

    &__label {
        display: block;
        @include featured-copy-bold;
        margin-bottom: $space;
    }

    &__control {
        border: 1px solid $very-light-grey;
        border-radius: $space;
        height: $space-6+4;
        width: 100%;
        padding-left: 5px;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }
    }

    &__control1 {
        border: 1px solid $very-light-grey;
        border-radius: $space;
        height: $space-6+4;
        width: 100%;
        padding-left: 5px;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }
    }

    &__control_textarea {
        height: auto;
    }

    &__control_document {
        background: $white;
        padding: 6px $space-2;
        width: 100%;
        font-family: $primary-font-name;
    }

    &__control_table {
        border: 1px solid $very-light-grey;
        border-radius: $space;
        height: $space-6+4;
        padding: $space+1 $space-2+2;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }
    }

    &__title {
        font-size: $space-4;
        width: 100%;
    }

    &__text {
        font-size: $space-3;
        font-weight: bold;
        width: 100%;
    }

    &__hide {
        visibility: hidden;
    }

    &__table_column {
        padding-right: 25px;
        vertical-align: middle;
    }

    &__table_column1 {
        width: 35%;
        vertical-align: middle;
    }

    &__table_column_checkbox {
        width: 35%;
    }

    &__table_column_padding {
        padding-left: 15px;
    }

    &__table {
        width: 80%;
    }

    &__error-field {
        color: $apple-blossom;
        @include featured-copy-bold;
        margin-top: $space;

        &--hidden {
            display: none;
        }
    }

    &__btn_column {
        text-align: center;
    }

    &__section {
        display: block;

        .pop-up {
            padding-top: 150px;

            &__content-header {
                background-color: $astral;
                color: $white;
            }

            &__content-body {
                background: $white;
            }

        }
    }

    &__control_textarea {
        height: auto;
        border: 1px solid $very-light-grey;
        border-radius: $space;
        padding: $space+1 $space-2+2;
        width: 100%;
    }

    &__sub-title {
        @include featured-copy-bold;
        margin-top: 20px;
        padding-bottom: 5px;
    }
}
