.map {
    height: 480px !important;
    width: 120%;
    margin-left: -10%;

    &__calender {
        position: absolute;
        width: 16%;
        padding: 1%;
        top: -3%;
        margin-left: 4%;
        height: 50px;
        background-color: white;
    }

    &__calender::before {
        content: "";
        position: absolute;
        background: #939393;
        height: 24%;
        width: 95%;
        margin-top: -6%;
        margin-left: -42%;
        transform: skewX(23deg);
        transform-origin: top;
        z-index: -1;
    }
}

@media (max-width: 767px) {
    .map__calender {
        top: 39px;
        margin-left: 2%;
        width: 13%;
        padding-top: 3%;
        position: relative;
    }
}

@media (max-width: 767px) {
    .map__calender::before {
        content: "";
        margin-top: -22%;
        margin-left: -28%;
        width: 80%;
        height: 22%;
        position: absolute;
    }
}

.address {
    display: none;
}