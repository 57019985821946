.rich-text {
    &__headerDiv {
        border-top-color: transparent!important;
        cursor: pointer;
        border-bottom: 1px solid #ddd;
        border-top-width: 0;
        background-color: #fff;
        padding: 8px 20px;
        transition: border-width .25s,border-color .25s,background-color .25s;
        font-size: 15px;
    }

    &__headerDiv:hover {
        background-color: #F8F8F8;
    }

    &__headerDiv1 {
        margin-bottom: 0.375rem;
        justify-content: space-between!important;
        display: flex;
    }

    &__rowSpanStyle {
        font-weight: 700;
    }

    &__dot {
        height: 4px;
        width: 4px;
        background-color: #E8E8E8;
        border-radius: 50%;
        display: inline-block;
    }

    &__dateTimeStyle {
        font-weight: 700;
        align-items: center!important;
        display: flex;
    }

    &__messageStyle {
        -webkit-box-pack: justify!important;
        justify-content: space-between!important;
        display: flex;
    }

    &__headerDiv2 {
        border-top-color: transparent;
        border-bottom: 1px solid #ddd;
        border-top-width: 0;
        background-color: #fff;
        padding: 8px 0px;
        transition: border-width .25s,border-color .25s,background-color .25s;
        padding-top: 0.5rem!important;
        padding-bottom: 0.5rem!important;
        text-align: right;
    }

    &__headerDiv5 {
        display: inline-flex;
        flex: 1 1 auto;
        justify-content: flex-end;
        max-width: 25%;
        padding: 0px 20px;
    }

    &__ulStyle {
        padding-left: 0;
    }

    &__liStyle {
        display: flex;
        justify-content: space-between;
        padding: 0px 20px;
    }

    &__liItems {
        font-weight: 700;
        color: #373a3c;
        font-size: .9140625rem;
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    &__applicationStatus {
        color: rgba(55, 58, 60, 0.6);
        margin-left: 7px;
    }

    &__appDtls {
        color: #888;
    }

    &__pointer {
        color: #dedede;
    }

    &__AssetsList {
        color: #4285f4;
        font-weight: 400;
        font-size: .984375rem;
        line-height: 1.5;
        font-family: Corporate S Pro;
    }

    &__AssetsDates {
        color: rgba(55,58,60,.6);
        font-size: .984375rem;
        font-weight: 400;
        line-height: 1.5;
        font-family: Corporate S Pro;
    }

    &__DateHeading {
        text-align: right;
    }

    &__cmtBox-container {
        width: 940px;
        display: flex;
        flex-direction: column;
        margin: auto;
        border-top: none;
        box-shadow: 0px 0px 4px #888888;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
        font-family: Corporate S Pro;
        border-top: 1px solid #d1cccc !important;
    }

    &__vue-container {
        border-top: 1px solid #d1cccc !important;
    }

    &__ui-data-item {
        background-color: #ececec;
        padding: 8px 20px;
    }

    &__ui-media {
        display: flex;
        align-items: flex-start;
        flex-flow: row wrap;
    }

    &__ui-conversation-message {
        padding: 0;
    }

    &__ui-media-figure {
        color: #fff;
        background-color: #4285f4;
        font-size: 1.125rem;
        font-weight: 700;
        border-radius: 5px;
        min-width: 2.4rem;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__ui-conversation-message-sent {
        color: #a9a9a9;
        margin-top: 1rem !important;
        font-size: 14px;
        padding-top: 1%;
    }

    &__body-style {
        background-color: whitesmoke;
        margin-top: 100px;
    }

    &__div-style {
        width: 92.9% !important;
        font-family: sans-serif;
        padding-bottom: 5px;
        padding-top: 20px;
    }

    &__extErrorStyle {
        margin-left: -25%;
        position: relative;
        top: 32px;
        left: 56px;
        color: #A94442;
        font: normal 700 12px/1.5 "Arial",Helvetica,Arial,sans-serif;
    }

    &__addFileStyle {
        margin-left: -12%;
        position: relative;
        top: 32px;
        left: -8px;
        color: #A94442;
        font: normal 700 12px/1.5 "Arial",Helvetica,Arial,sans-serif;
    }

    &__span-style {
        margin: 5px;
        display: inline-block;
        vertical-align: middle;
    }

    &__button-style {
        border: none;
        background: none;
        cursor: pointer;
        display: inline-block;
        float: left;
        height: 24px;
        padding: 3px 5px;
        width: 28px;
        stroke-linecap: round;
        fill: none;
        stroke-width: 2;
        margin-left: 11px;
    }

    &__button-style:hover {
        color: blue;
    }

    &__span-style:hover {
        color: blue;
    }

    &__textarea-style {
        position: relative;
        padding: 0.75rem 1rem;
        border-bottom: 1px solid #e3dede;
        background-color: #fff;
        color: #000000;
        border-top: 0px;
        font-size: 16px;
        min-height: 100%;
        box-sizing: border-box;
        line-height: 1.42;
        height: 150px;
        outline: none;
        overflow-y: auto;
        tab-size: 4;
        text-align: left;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    &__placeholderCls:empty:before {
        content: attr(data-placeholder);
        font-style: italic;
        pointer-events: none;
        left: 15px;
        position: absolute;
        right: 15px;
        color: #b8b8b8;
        font-size: 15px;
    }

    &__select-style {
        border: none;
        background: none;
        cursor: pointer;
        display: inline-block;
        float: left;
        height: 24px;
        padding: 3px 5px;
        line-height: 22px;
    }

    &__attachWarning {
        text-align: center;
        font-size: 14px;
        color: red;
    }

    &__child {
        font: initial;
    }

    &__loader-submit {
        position: fixed;
        left: 50%;
        top: 50%;
        z-index: 1;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 16px solid #3498db;
        background-position: center;
        background-color: rgba(0,0,0,0.4);
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    &__small-style {
        font-size: 12px;
    }

    &__normal-style {
        font-size: 15px;
    }

    &__large-style {
        font-size: 20px;
    }

    &__huge-style {
        font-size: 24px;
    }

    &__file-label {
        font-size: 98%;
        cursor: pointer;
        color: rgba(55,58,60,.6);
        padding: 4px;
        line-height: 2.5;
        text-align: center;
        font-weight: 700;
        background: #fff;
        overflow: hidden;
        border-bottom: 1px solid #ddd;
    }

    &__edit-file {
        font-size: 98%;
        cursor: pointer;
        color: rgba(55,58,60,.6);
        padding: 4px;
        line-height: 2.5;
        text-align: center;
        font-weight: 700;
        background: #fff;
        overflow: hidden;
    }

    &__Submitbtn {
        font-weight: 100;
        white-space: nowrap;
        cursor: pointer;
        border: 1px solid transparent;
        border-radius: 4px;
        user-select: none;
        font-size: 13px;
        padding: 4px;
        width: 117px;
        color: #fff;
        background-color: #4285f4;
        margin-top: 1%;
    }

    &__m-r-a {
        margin-right: 150px !important;
    }

    &__filecolor {
        color: blue;
    }

    &__ChooseFiles {
        margin-top: 1%;
    }

    &__footer-btn {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 12px 0px;
    }

    &__sizeValidation {
        font-size: 12px;
        margin-left: -25%;
        position: relative;
        top: 32px;
        left: 56px;
    }
}