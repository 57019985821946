.cac-suggestion {
    color: $mine-shaft;

    &__heading {
        @include featured-heading;
        margin-bottom: $space-2;
    }

    &__group {
        padding: 0px 0px $space-3 0px;
        text-align: left;
    }

    &__group_center {
        padding: 0px 0px $space-3 0px;
        text-align: center;
    }

    &__group_btn {
        padding: $space-2 0px;
        text-align: center;
    }

    &__label {
        display: inline-block;
        @include featured-copy-bold;
        margin-bottom: $space;
    }

    &__textarea_border {
        width: 100%;
        border: 1px solid black;
    }

    &__control {
        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }
    }

    &__data-control {
        border: 1px solid $very-light-grey;
        border-radius: $space;
        height: $space-6+4;
        width: 25%;
        padding-left: 5px;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }
    }

    &__sub-title {
        @include featured-copy-bold;
        margin-top: 20px;
        padding-bottom: 5px;
    }

    &__asterisk_field {
        color: red;
    }

    &__control_table {
        border: 1px solid $very-light-grey;
        border-radius: $space;
        height: $space-6+4;
        padding: $space+1 $space-2+2;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }
    }

    &__table {
        width: 100%;
    }

    &__table_label {
        width: 40%;
        text-align: right;
    }

    &__table_input {
        width: 60%;
        padding-left: 15px;
    }

    &__title {
        font-size: $space-4;
        width: 100%;
    }

    &__text {
        font-size: $space-3;
        font-weight: bold;
        width: 100%;
    }

    &__btn {
        background-color: #000000;
        border: none;
        color: white;
        padding: 5px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 0 auto;
        cursor: pointer;
        border-radius: 4px;
    }

    &__hide {
        visibility: hidden;
    }

    &__error-field {
        color: $apple-blossom;
        @include featured-copy-bold;
        margin-top: $space;

        &--hidden {
            display: none;
        }
    }

    &__section {
        display: block;

        .pop-up {
            padding-top: 150px;

            &__content-header {
                background-color: $astral;
                color: $white;
            }

            &__content-body {
                background: $white;
            }

        }
    }

    &__panel {
        margin-bottom: 20px;
        background-color: #fff;
        border: 1px solid transparent;
        border-radius: 4px;
        -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
        box-shadow: 0 1px 1px rgba(0,0,0,.05);
        border-color: #080808;
        width: 100%;
    }

    &__panel-heading {
        padding: 10px 15px;
        border-bottom: 1px solid transparent;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        color: #333;
        background-color: #f5f5f5;
        text-align: center;
        border-color: #ddd;
        text-align: left;
    }

    &__panel-body {
        padding: 15px;
    }

    &__button {
        border-radius: 2px;
        color: currentColor;
        display: inline-block;
        font-family: "Arial",Helvetica,Arial,sans-serif;
        font-size: 12px;
        font-weight: 700;
        line-height: 1.83333;
        padding: 5px 10px;
        text-decoration: none;
        height: 30px;
        width: 850px;
        margin-right: 85;
        background-color: #3371FF;
    }

    &__btn_reset {
        border-radius: 2px;
        color: currentColor;
        display: inline-block;
        font-family: "Arial",Helvetica,Arial,sans-serif;
        font-size: 12px;
        font-weight: 700;
        line-height: 1.83333;
        padding: 5px 10px;
        text-decoration: none;
        height: 30px;
        width: 850px;
        margin-right: 85;
        background-color: #3371FF;
        margin-top: 10px;
    }
}