.accordion-rich-text {
    &__list {
        padding: $space+2 0;
    }

    &__title {
        @include subsection-reg;
        color: $scorpion;
        font-size: $space-4;

        &:hover {
            color: $blue-light;
        }

        &:before {
            font-family: 'micro-icons';
            font-size: $space-3;
            content: $icon-arrow-right-square;
            float: right;
        }
    }

    &__label {
        @include copy-narrow-reg;
    }

    &__separator {
        margin: $space-4 0;
    }

    &__panel {
        display: block;
        @include clearfix;
        padding: $space-2 $space-3;
    }

    &__column {
        &-1 {
            @include span-columns(12);
            @include media($desktop) {
                @include span-columns(8);
            }
        }

        &-2 {
            @include omega-reset(2n);
            @include span-columns(12);
            margin-top: $space-2;
            @include media($desktop) {
                @include span-columns(4);
                @include omega(2n);
                float: right;
                margin-top: 0;
            }

            &-btn {
                &-container {
                    @include clearfix;
                    @include media ($mobile-only) {
                        text-align: center;
                    }
                }

                &:last-of-type {
                    @include media($tablet) {
                        float: right;
                    }
                }

                &:first-of-type {
                    color: $denim;
                    @include media($tablet) {
                        float: left;
                    }
                    @include media($mobile-only) {
                        display: block;
                        margin-bottom: $space-2;
                    }
                }
            }
        }
    }

    .accordion__active {
        .accordion-rich-text__title:before {
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
        }
    }
}
