.complete-bordered-link-list {
    .link-list {
        border: 1px solid $alto;
        border-radius: 3px;

        &__heading {
            font-family: $secondary-font-name;
            font-size: $space-4;
            font-weight: 500;
            color: $mine-shaft;
            padding: $space-2 $space-3;
        }

        &__content-description {
            margin-bottom: 0px;
            margin-left: $space-3;
        }

        &__item {
            &-text {
                font-weight: 700;
            }
        }

        &__icon-list {
            color: $silver ;
        }

        &__icon-nav {
            color: $pacific-blue;
        }
    }
}
