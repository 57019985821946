.search-nav-brc {
    &__container {
        font-size: $space-2+2;
        height: 48px;
        max-width: 1000px;
        position: relative;
        padding: 8px $space-2;
        border-radius: 4px;
        top: 0%;
        display: inline-flex;
        margin-bottom: 10px;
        place-content: flex-end;
        margin-left: -10px;
    }

    &__containers {
        font-size: $space-2+2;
        height: 48px;
        max-width: 1000px;
        position: relative;
        display: inline-flex;
        padding: 8px $space-2;
        border-radius: 4px;
        top: 0%;
        margin-bottom: 10px;
        margin-left: 65%;

        @include media($mobile-only) {
            margin-left: 0;
        }
    }

    &__contained {
        font-size: $space-2+2;
        height: 48px;
        max-width: 1000px;
        position: relative;
        display: inline-flex;
        padding: 8px $space-2;
        width: 100%;
        border-radius: 4px;
        top: 0%;
        margin-bottom: 10px;
        margin-left: 70%;
    }

    &__input-group {
        width: 100%;
        justify-content: flex-end;
        display: inline-flex;

        @include span-columns(12);

        @include media($tablet) {
            @include span-columns(5);
            width: 100%;
        }
    }

    &__input {
        width: auto;
        padding: 8px 0px 8px $space-3;
        float: left;
        color: $black;

        @include media($desktop) {
        }

        &:focus {
            border-color: $cornflower-blue;
        }
    }

    &__button {
        color: $white;
        background-color: $viking;
        padding: $space $space-2;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        font-size: 14px;

        &:hover {
            cursor: pointer;
            background-color: $scooter;
        }
    }
}
