.g-class-media-richtext {
    &__list {
        display: flex;
        border-bottom-width: 1px;
        border-style: solid;
        padding-bottom: 3%;
        padding-top: 3%;
    }

    &__image {
        width: 12%;
        padding: 0px 20px 0px 0px;
    }
}