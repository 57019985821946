.can-applications__container {
    .can_form {
        width: 60%;
    }

    .can-block {
        font-size: 0;
        margin-bottom: 10px;

        label {
            display: inline-block;
            font-size: 14px;
            margin-left: 10%;
            width: 40%;
        }

        input {
            display: inline-block;
            font-size: 14px;
            padding: 4px 2px;
            width: 50%;
        }
    }

    .can_results {
        max-height: 300px;
        margin: 0 $space-4;
        overflow: auto;

        table {
            margin-bottom: $space-4;
            text-align: left;
            width: 100%;

            th {
                background: $silver;
                font-size: 14px;
                font-weight: bold;
                border: 1px solid $mercury;
                padding: $space-2;
            }

            tbody {
                tr {
                    td {
                        font-weight: normal;
                        border: 1px solid $mercury;
                        padding: $space-3;

                        .icon-dark-printer {
                            color: initial;
                            cursor: pointer;
                            font-size: 14px;
                        }
                    }

                    &:nth-child(even) {
                        background: $white;
                    }
                }
            }
        }
    }
}

.btn_container {
    margin-left: 50%;
    margin-bottom: 20px;
    width: 50%;

    .button {
        cursor: pointer;
    }
}

.canApplication {
    &__wrapper {
        font-size: 0;

        &--hidden {
            display: none;
        }
    }

    &__left {
        display: inline-block;
        vertical-align: top;
        width: 100%;
    }

    &__right {
        display: inline-block;
        margin-left: 5%;
        vertical-align: top;
        width: 70%;
    }

    &__accordion {
        &-list {
            margin-bottom: $space-2;

            &--active {
                .canApplication__accordion-panel {
                    display: block;
                }
            }
        }

        &-title {
            display: inline-block;
            vertical-align: middle;

            &-block {
                background: $silver;
                cursor: pointer;
                display: block;
                font-size: 14px;
                padding: $space;
            }
        }

        &-panel {
            display: none;
            font-size: 0;
        }

        &-confirmation {
            display: inline-block;
            vertical-align: middle;

            &-block {
                display: inline-block;
                margin-right: $space-2;

                input {
                    cursor: pointer;
                    display: inline-block;
                }

                label {
                    cursor: pointer;
                    display: inline-block;
                }
            }
        }
    }

    &__block {
        display: inline-block;
        font-size: 14px;
        margin-bottom: $space-2;
        vertical-align: top;
        width: 33.33%;

        &--full {
            display: block;
            margin: $space-2 $space-4 0 $space-4;
            width: auto;

            .canApplication__label {
                display: inline-block;
            }

            .canApplication__input {
                display: inline-block;
                width: 58%;
            }
        }
    }

    &__label {
        display: block;
    }

    &__input, &__select, &__textarea {
        display: block;
        width: 75%;
    }

    &__input {
        &--error {
            border: 1px solid $guardsman-red;
        }
    }

    &__footer {
        text-align: right;

        button {
            cursor: pointer;
            margin-left: $space-8;
        }
    }

    &__addInfo {
        font-size: 10px;
        display: block;
    }

    &__instructions {
        color: initial;
        margin-top: $space-4;

        &-block {
            font-size: 12px;
        }

        &-confirmation {
            display: block;

            &-block {
                font-size: 10px;
            }

            input {
                display: inline-block;
                vertical-align: top;
            }

            label {
                display: inline-block;
                vertical-align: top;
            }
        }
    }

    &__accNumber, &__comments, &__updatedBy, &__typeOfVehicle, &__fleetSize {
        display: none;
    }

    &__form {
        &--short {
            .canApplication {
                &__accNumber, &__comments, &__updatedBy, &__typeOfVehicle, &__fleetSize {
                    display: inline-block;
                }
            }

            .short {
                display: none;
            }

            #printForm, #approve, #reject {
                display: none;
            }
        }
    }

    &__update-error {
        padding: $space-2 0;
        text-align: center;
        font-weight: $font-weight-bold;
        font-size: $space-2+2;
        margin-top: $space;
    }
}

.fleet-program-status-maintenance {
    .pop-up {
        padding-top: $space-2;

        &__content {
            width: 85%;

            &-body {
                max-height: 450px;
                overflow: auto;

                .canApplication__left {
                    width: 25%;
                }
            }
        }
    }
}

.can-search-and-update {
    .pop-up {
        &__content {
            &-body {
                .canApplication__left {
                    width: 25%;
                }
            }
        }
    }
}
