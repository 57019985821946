/**
 * Footer specific styling
 * This file defines medium & large sized behavior of the accordion WITHIN the context of footer
 * Because the the layout of footer in medium breakpoint, column-link-list items could not be wrapped in a container
 * Each of the items in footer (4 column link lists + stay connected + other sites) are a flex items, and it's width (for medium & large) is controlled using calc()
 */

.custom-footer {
    // padding-top: $space-4;
    background-color: $white-smoke;
    display: block;

    &--variant {
        padding-top: 0;
    }

    &__container {
        padding-top: $space-4;
        margin-top: $space-3;

        @include media($tablet) {
            padding-top: $space-6;
        }
    }

    @include media($tablet) {
        &--variant {
            padding-top: 0;
        }
    }

    @media only print {
        padding-top: 0;
        border-top: $silver 1px solid;
        background-color: unset;
    }

    [data-accordion-name="footer-accordion"] {
        @media only print {
            display: none;
        }
    }

    // &__container {
    //     display: inline-block;
    // }

    .legal {
        @media only print {
            display: none;
        }
    }

    &__accordion-container {
        display: flex;
        flex-wrap: wrap;
        flex: 1;

        &.wrapper {
            @include media($mobile-only) {
                width: 100%;
            }
        }
    }

    &__accordion-item {
        width: 100%;

        @include media($large) {
            margin-right: $gutter;
            width: calc((100% / 3) - (40px / 3));

            &:nth-of-type(3) {
                margin-right: 0;
            }
        }

        @include media($xlarge) {
            width: calc(25% - 15px);

            &:nth-of-type(3) {
                margin-right: $gutter;
            }

            &:nth-of-type(4) {
                margin-right: 0;
            }
        }

        &.stay-connected__stay-in-touch {
            margin-right: 0;
            width: 100%;
        }

        &.stay-connected__other-sites {
            margin-right: 0;
            width: 100%;
        }
    }

    &__accordion-item-heading {
        @include section-reg;
        border-bottom: 1px dashed $dusty-gray;

        @include media($tablet) {
            border-top: none;
            margin-bottom: $space-3;

            button {
                font: inherit;
            }
        }
    }

    &__accordion-item-content {
        @include media($tablet) {
            margin-bottom: $space-3;
            border-top: none;
            padding: 0;
        }
    }

    &__accordion-item-cta {
        @include media($tablet) {
            padding: 0;
        }
    }

    .wrapper {
        max-width: 1170px;
    }
}
