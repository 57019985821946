@charset "UTF-8";
/**
 * Do not edit directly
 * Generated on Mon, 13 Dec 2021 16:16:12 GMT
 *
 * do not delete 9fbef606107a605d69c0edbcd8029e5d
 */
html {
  box-sizing: border-box;
}

*, *::after, *::before {
  box-sizing: inherit;
}

/**
 map-deep-get($map, $keys...)

 Get deeply nested map value, like map-get but for deeply nested maps
 @param {Map} $map
 @param {String} $keys variable number of key args
 @return {*} The value
*/
/**
 font($name) (i.e. - typestyles)
 Mixin for applying the font (sets font-family, font-size, font-weight, & line-height)
 @param {String} $name Name of the font
*/
@font-face {
  font-family: "micro-icons";
  src: url("/etc/designs/microsites/fonts/micro-icons.eot?b8z80k");
  src: url("/etc/designs/microsites/fonts/micro-icons.eot?b8z80k#iefix") format("embedded-opentype"), url("/etc/designs/microsites/fonts/micro-icons.ttf?b8z80k") format("truetype"), url("/etc/designs/microsites/fonts/micro-icons.woff?b8z80k") format("woff"), url("/etc/designs/microsites/fonts/micro-icons.svg?b8z80k#micro-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "micro-icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-warning:before {
  content: "\e932";
}

.icon-checkmark-disc:before {
  content: "\e94d";
}

.icon-cross:before {
  content: "\e94b";
}

.icon-pencil:before {
  content: "\e94a";
}

.icon-logout:before {
  content: "\e949";
}

.icon-movie:before {
  content: "\e948";
}

.icon-pdf:before {
  content: "\e947";
}

.icon-web-link:before {
  content: "\e946";
}

.icon-barcode:before {
  content: "\e940";
}

.icon-call:before {
  content: "\e941";
}

.icon-chat:before {
  content: "\e942";
}

.icon-list-menu:before {
  content: "\e943";
}

.icon-envelope:before {
  content: "\e944";
}

.icon-profile:before {
  content: "\e945";
}

.icon-list-alt:before {
  content: "\e935";
}

.icon-caret-down-small:before {
  content: "\e93d";
}

.icon-dark-printer {
  color: black;
}
.icon-dark-printer:before {
  content: "\e93e";
}

.icon-rate-star-button:before {
  content: "\e93f";
}

.icon-sort-descending:before {
  content: "\e93a";
}

.icon-sort-down:before {
  content: "\e93b";
}

.icon-descendant:before {
  content: "\e93c";
}

.icon-chained-links:before {
  content: "\e938";
}

.icon-world:before {
  content: "\e939";
}

.icon-forward-right-arrow-button:before {
  content: "\e937";
}

.icon-paperclip:before {
  content: "\e936";
}

.icon-check:before {
  content: "\e934";
}

.icon-warning:before {
  content: "\e932";
}

.icon-mb-dealer-location:before {
  content: "\e92d";
}

.icon-mb-collision-center:before {
  content: "\e92f";
}

.icon-AMG_logo-performance-center:before {
  content: "\e930";
}

.icon-AMG_logo-performance-center-elite:before {
  content: "\e931";
}

.icon-search:before {
  content: "\e92c";
}

.icon-car-rear:before {
  content: "\e92e";
}

.icon-caret-right-amg:before {
  content: "\e92b";
}

.icon-acceleration:before {
  content: "\e900";
}

.icon-arrow-down-square:before, .view-more-cta .icon-mb:before {
  content: "\e913";
}

.icon-arrow-left-square:before {
  content: "\e915";
}

.icon-arrow-right-square:before {
  content: "\e91a";
}

.icon-arrow-up-square:before, .view-more-cta--less .icon-mb:before {
  content: "\e91b";
}

.icon-calculator:before {
  content: "\e91c";
}

.icon-download-arrow:before {
  content: "\e91d";
}

.icon-exit:before {
  content: "\e91e";
}

.icon-gears:before {
  content: "\e91f";
}

.icon-horsepower:before {
  content: "\e920";
}

.icon-hybrid:before {
  content: "\e921";
}

.icon-minus:before {
  content: "\e922";
}

.icon-plus:before {
  content: "\e923";
}

.icon-rain:before {
  content: "\e924";
}

.icon-request_a_test_drive:before {
  content: "\e925";
}

.icon-solid-circle:before {
  content: "\e926";
}

.icon-steering-wheel:before {
  content: "\e927";
}

.icon-sun:before {
  content: "\e928";
}

.icon-torque:before {
  content: "\e929";
}

.icon-towing:before {
  content: "\e92a";
}

.icon-compare:before {
  content: "\e916";
}

.icon-download:before {
  content: "\e917";
}

.icon-special-offers:before {
  content: "\e918";
}

.icon-view-inventory:before {
  content: "\e919";
}

.icon-trade_in_value:before {
  content: "\e901";
}

.icon-apply_for_credit:before {
  content: "\e912";
}

.icon-model-chooser:before {
  content: "\e914";
}

.icon-caret-down-thin:before {
  content: "\e902";
}

.icon-caret-down:before {
  content: "\e903";
}

.icon-caret-left-thin:before {
  content: "\e904";
}

.icon-caret-right-thin:before {
  content: "\e905";
}

.icon-caret-right:before {
  content: "\e906";
}

.icon-caret-up-thin:before {
  content: "\e907";
}

.icon-caret-up:before {
  content: "\e908";
}

.icon-mbusa-logo:before {
  content: "\e909";
}

.icon-pin:before {
  content: "\e90a";
}

.icon-social-facebook:before {
  content: "\e90b";
}

.icon-social-googleplus:before {
  content: "\e90c";
}

.icon-social-instagram:before {
  content: "\e90d";
}

.icon-social-twitter:before {
  content: "\e90e";
}

.icon-social-youtube:before {
  content: "\e90f";
}

.icon-AMG_logo:before {
  content: "\e910";
}

.icon-smart:before {
  content: "\e911";
}

.icon-preferred-dealer:before {
  content: "\e933";
}

.icon-calendar:before {
  content: "\e94c";
}

.offscreen, .more-info-bar.active .more-info-bar__toggle-copy, .skip-navigation {
  position: absolute;
  clip: rect(1px 1px 1px 1px); /* for Internet Explorer */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
@media only print {
  .offscreen, .more-info-bar.active .more-info-bar__toggle-copy, .skip-navigation {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .skip-navigation:focus {
    clip: auto;
    color: #fff;
    height: auto;
    left: 60px;
    top: 20px;
    width: auto;
  }
}
@media screen and (min-width: 980px) {
  .skip-navigation:focus {
    left: 80px;
  }
}

.footer {
  text-align: center;
  border-top: thin solid #666;
  height: 60px;
  padding-top: 20px;
  display: block;
  position: absolute;
  width: 100%;
  bottom: 0;
  font-size: 14px;
}
.footer__link {
  margin-left: 10px;
  color: #428bca;
}
.footer__link:hover {
  color: #00A6C9;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  position: relative;
  padding-bottom: 60px;
}

body[data-submenu-state=open] .top-nav[data-submenu-model=true] .top-nav__sub-nav {
  background: #333;
  background-image: radial-gradient(farthest-corner circle at right top, #797A7D 15%, #5C5C60 33%, #323232 62%);
  border: 1px solid #fff;
  border-radius: 5px;
  display: none;
  max-height: none;
  border-top: none;
}
@media screen and (max-width: 767px) {
  body[data-submenu-state=open] .top-nav[data-submenu-model=true] .top-nav__sub-nav {
    background: none;
    background-image: none;
    border: none;
    border-radius: none;
  }
}
body[data-submenu-state=open] .top-nav[data-submenu-model=true] .top-nav__sub-nav .top-nav__link {
  border-top: #2F3033 solid thin;
  color: #fff;
  font-size: 12px;
  padding: 3px 20px;
}
@media screen and (max-width: 767px) {
  body[data-submenu-state=open] .top-nav[data-submenu-model=true] .top-nav__sub-nav .top-nav__link {
    font-size: 14px;
    padding: 10px 25px;
    text-transform: capitalize;
  }
}
body[data-submenu-state=open] .top-nav[data-submenu-model=true] .top-nav__sub-nav .top-nav__link:hover {
  background: #333;
  color: #00AEDF;
}
body[data-submenu-state=open] .top-nav[data-submenu-model=true] .top-nav__sub-nav ul {
  background: transparent;
  display: block;
}
body[data-submenu-state=open] .top-nav[data-submenu-model=true] .top-nav__drop-down--single .top-nav__link {
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  body[data-submenu-state=open] .top-nav[data-submenu-model=true] .top-nav__drop-down--single .top-nav__link {
    text-transform: capitalize;
  }
}
body[data-submenu-state=open] .top-nav[data-submenu-model=true] .top-nav__drop-down--single .top-nav__item {
  border-top: none;
}
body[data-submenu-state=open] .top-nav[data-submenu-model=true] .top-nav__drop-down--single .top-nav__sub-nav .top-nav__item:first-child .top-nav__link {
  border-top: none;
}
body[data-submenu-state=open] .top-nav [data-open=true] + .top-nav__sub-nav {
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 220px;
  z-index: 501;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  body[data-submenu-state=open] .top-nav [data-open=true] + .top-nav__sub-nav {
    min-width: 200px;
    position: static;
    z-index: 0;
  }
}
body[data-submenu-state=open] .top-nav [data-open=true] + .top-nav__sub-nav .top-nav__item {
  display: block;
}

.top-nav__link {
  display: block;
  padding: 10px 5px;
}

.top-nav__item .top-nav__link_parent:after {
  display: table-cell;
  vertical-align: bottom;
}

.top-nav__item {
  display: inline-block;
  vertical-align: top;
}
@media screen and (max-width: 767px) {
  .top-nav__item--company-title {
    width: 100%;
    text-align: center;
  }
}
.top-nav__item--company-title .top-nav__link {
  display: block;
}
.top-nav__item--company-title .top-nav__logo_container {
  display: block;
  margin-right: 10px;
  padding: 0;
}
@media screen and (max-width: 767px) {
  .top-nav__item--company-title .top-nav__logo_container {
    text-align: center;
    display: inline-block;
  }
}
.top-nav__item--category {
  border-top: #2F3033 solid thin;
}
.top-nav__item--menu, .top-nav__item--category {
  position: relative;
  display: block;
}
@media screen and (min-width: 768px) {
  .top-nav__item--menu, .top-nav__item--category {
    display: inline-block;
    border-top: none;
  }
}
.top-nav__item--menu:before, .top-nav__item--menu:after, .top-nav__item--category:before, .top-nav__item--category:after {
  background: #444444;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 1px;
}
@media screen and (max-width: 767px) {
  .top-nav__item--menu:before, .top-nav__item--menu:after, .top-nav__item--category:before, .top-nav__item--category:after {
    content: none;
  }
}
.top-nav__item--menu:after, .top-nav__item--category:after {
  left: 100%;
}
.top-nav__item--menu > .top-nav__link_parent, .top-nav__item--category > .top-nav__link_parent {
  display: inline-block;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .top-nav__item--menu > .top-nav__link_parent, .top-nav__item--category > .top-nav__link_parent {
    text-transform: capitalize;
  }
}
.top-nav__item--hidden {
  display: none;
}
.top-nav__item .top-nav__link {
  color: #000000;
}
.top-nav__item .top-nav__link_parent {
  color: #fff;
  position: relative;
}
.top-nav__item .top-nav__link_parent:after {
  content: "\e903";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  font-size: 8px;
  line-height: 0;
  position: absolute;
  bottom: 20px;
  right: 15px;
  transition: all 0.5s ease 0s;
}
@media screen and (min-width: 768px) {
  .top-nav__item .top-nav__link_parent:after {
    bottom: 25px;
  }
}
.top-nav__item .top-nav__link_parent[data-open=true]:after {
  transform: rotate(180deg);
  transform-origin: center center;
}
.top-nav__item .top-nav__link_parent[data-open=false]:after {
  transform: rotate(0deg);
}
.top-nav__item .logo__container .sprinter__logo {
  height: 42px;
  width: 163px;
}
.top-nav__print {
  display: none;
}
.top-nav__link {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.top-nav__link_menu {
  padding: 10px 30px 10px 15px;
}
@media screen and (min-width: 768px) {
  .top-nav__link_menu {
    padding: 25px 30px 15px 15px;
  }
}
.top-nav__link_back-btn {
  display: none;
}
.top-nav__sub-nav {
  max-height: 0;
}
.top-nav__sub-nav ul {
  background: #000000;
  display: none;
}
.top-nav__hamburger {
  position: absolute;
  margin: 15px;
  display: block;
  z-index: 501;
  top: 10px;
}
@media screen and (min-width: 768px) {
  .top-nav__hamburger {
    display: none;
  }
}
.top-nav__hamburger-line {
  width: 20px;
  height: 2px;
  background-color: #ecf0f1;
  display: block;
}
.top-nav__hamburger-line:nth-of-type(2) {
  margin: 3px auto;
}
.top-nav__drop-down {
  display: none;
  position: absolute;
  vertical-align: bottom;
  width: auto;
  min-width: 250px;
  z-index: 501;
  background: #333;
  background-image: radial-gradient(farthest-corner circle at right top, #797A7D 15%, #5C5C60 33%, #323232 62%);
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 0;
}
.top-nav__drop-down--open {
  display: block;
}
.top-nav__drop-down--single .top-nav__link_parent {
  display: none;
}
@media screen and (min-width: 768px) {
  .top-nav__drop-down--single .top-nav__link_parent {
    display: inline-block;
  }
}
@media screen and (min-width: 768px) {
  .top-nav__drop-down {
    display: inline-block;
    position: static;
    width: auto;
    z-index: 0;
    background: none;
    background-image: none;
    border: none;
    min-width: inherit;
    border-radius: none;
  }
}

.sub-nav__level--left {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .sub-nav__level--left {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 57.4468085106%;
  }
  .sub-nav__level--left:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 980px) {
  .sub-nav__level--left {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 40.4255319149%;
  }
  .sub-nav__level--left:last-child {
    margin-right: 0;
  }
}
.sub-nav__level--right {
  float: none;
  text-align: center;
  width: 100%;
  padding: 5px 0px;
}
@media screen and (min-width: 768px) {
  .sub-nav__level--right {
    float: right;
    text-align: right;
    padding: 0px;
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 40.4255319149%;
  }
  .sub-nav__level--right:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 980px) {
  .sub-nav__level--right {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 57.4468085106%;
  }
  .sub-nav__level--right:last-child {
    margin-right: 0;
  }
}
.sub-nav__level--right .top-nav__item {
  color: #fff;
}
@media screen and (max-width: 767px) {
  .sub-nav__level--right .top-nav__item {
    text-align: center;
    width: 100%;
  }
}
.sub-nav__level--right .top-nav__item--heading {
  font-size: 24px;
  font-family: "corporateacondpro", sans-serif;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .sub-nav__level--right .top-nav__item--heading {
    margin: 10px 0 10px 10px;
  }
}
.sub-nav__level--right li:last-child a {
  padding-right: 0;
}
.sub-nav__level--right .logo__container {
  float: none;
  margin-top: 0px;
}
@media screen and (min-width: 768px) {
  .sub-nav__level--right .logo__container {
    float: right;
    margin-top: 15px;
  }
}

.sub-nav__title-block {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .sub-nav__title-block {
    float: right;
    width: auto;
  }
}
.sub-nav__search {
  cursor: pointer;
  width: 0%;
  min-width: 40px;
  float: right;
  overflow: hidden;
  position: relative;
  margin-top: 15px;
  margin-left: 10px;
  transition: width 0.5s ease 0s;
  display: none;
}
@media screen and (min-width: 768px) {
  .sub-nav__search {
    display: block;
  }
}
.sub-nav__search input:-webkit-autofill,
.sub-nav__search input:-webkit-autofill:hover,
.sub-nav__search input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px white inset;
  -webkit-text-fill-color: black;
}
.sub-nav__search textarea:-webkit-autofill, .sub-nav__search select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  -webkit-text-fill-color: black;
  background-color: white;
  color: black;
}
.sub-nav__icon-search {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  position: absolute;
  top: 5px;
  right: 8px;
}
.sub-nav__form-input {
  padding: 10px 35px 10px 5px;
  width: 100%;
  border: none;
  background-color: #000000;
  color: #fff;
}
.sub-nav__search--open {
  width: 50%;
}
.sub-nav__search--open .sub-nav__icon-search {
  color: #000000;
  font-weight: 700;
}
.sub-nav__search--open .sub-nav__form-input {
  color: #000000;
  background-color: #fff;
  border-radius: 5px;
}
.sub-nav__form-btn {
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  opacity: 0;
  top: 0;
  cursor: pointer;
}

.breadcrumb {
  padding: 5px 0;
  background-color: #fff;
  width: 100%;
}
.breadcrumb__list-container {
  margin-bottom: 15px;
}
.breadcrumb__list {
  color: #a3a3a3;
  display: inline-block;
}
.breadcrumb__list + .breadcrumb__list:before {
  content: "/ ";
  padding: 0 5px;
  color: #ccc;
}
.breadcrumb__link {
  color: #a3a3a3;
  display: inline-block;
}
.breadcrumb__link:hover {
  color: #00AEDF;
}

.hero-image {
  background: #000000;
}
.hero-image__container {
  position: relative;
  min-height: 120px;
  overflow-y: auto;
}
@media screen and (min-width: 768px) {
  .hero-image__container {
    min-height: auto;
  }
}
.hero-image__title {
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font: normal 400 28px/1.2142857143 "corporateacondpro";
  z-index: 1;
  margin-left: 15px;
  max-height: 100px;
}
@media screen and (min-width: 980px) {
  .hero-image__title {
    font: normal 400 30px/1.2666666667 "corporateacondpro";
  }
}
@media screen and (min-width: 768px) {
  .hero-image__title {
    font-weight: 500;
    max-width: 55%;
    margin-left: 50px;
    max-height: 120px;
  }
}
.hero-image__image {
  height: 100%;
  position: absolute;
  right: -110px;
}
@media screen and (min-width: 768px) {
  .hero-image__image {
    width: 100%;
    height: auto;
    position: inherit;
    right: auto;
  }
}

body[data-submenu-state=open] .custom-top-nav__link_parent[data-open=true] + .custom-top-nav__sub-menu-list {
  display: block;
}
body[data-submenu-state=open] .custom-top-nav__link_parent[data-open=false] + .custom-top-nav__sub-menu-list {
  display: none;
}

.custom-websites[data-menu-state=open] {
  overflow: hidden;
  position: fixed;
  width: 100%;
}
.custom-websites[data-menu-state=open] .page-content {
  left: 250px;
  z-index: 0;
}
.custom-websites[data-menu-state=open] .header {
  left: 250px;
}
.custom-websites[data-menu-state=open] footer {
  left: 250px;
  z-index: -1;
}
.custom-websites[data-menu-state=open] .header-click-blocker {
  visibility: visible;
  left: 250px;
}

.custom-top-nav__item {
  display: block;
}
@media screen and (min-width: 980px) {
  .custom-top-nav__item {
    margin: 0px 10px;
    display: table-cell;
    vertical-align: bottom;
  }
}
.custom-top-nav__item--company-title {
  display: none;
}
@media screen and (min-width: 980px) {
  .custom-top-nav__item--company-title {
    display: table-cell;
    width: 168px;
    margin: 0px 10px;
  }
}
.custom-top-nav__item--company-title .custom-top-nav__link {
  display: block;
  margin: 10px 10px 10px 0px;
  padding: 0;
}
@media screen and (max-width: 767px) {
  .custom-top-nav__item--company-title .custom-top-nav__link {
    text-align: center;
  }
}
.custom-top-nav__item--heading {
  font-size: 26px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .custom-top-nav__item--menu {
    position: inherit;
    display: block;
  }
}
@media screen and (min-width: 980px) {
  .custom-top-nav__item--menu {
    display: inline-block;
    margin: 0;
    text-align: center;
    position: relative;
  }
}
.custom-top-nav__item--menu:before, .custom-top-nav__item--menu:after {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 1px;
}
@media screen and (max-width: 767px) {
  .custom-top-nav__item--menu:before, .custom-top-nav__item--menu:after {
    content: none;
  }
}
.custom-top-nav__item--menu:after {
  left: 101%;
}
.custom-top-nav__item .custom-top-nav__link {
  color: #777777;
  padding: 20px 25px 0px 0px;
}
.custom-top-nav__item .custom-top-nav__link:hover {
  color: #A1A1A1;
}
@media screen and (min-width: 980px) {
  .custom-top-nav__item .custom-top-nav__link {
    color: #fff;
    padding: 0px;
  }
  .custom-top-nav__item .custom-top-nav__link--disabled:hover {
    cursor: not-allowed;
    color: #cccccc;
  }
}
.custom-top-nav__item .custom-top-nav__link--disabled {
  color: #cccccc;
}
.custom-top-nav__item .custom-top-nav__link--disabled:focus {
  color: #cccccc;
}
.custom-top-nav__item .custom-top-nav__link--disabled:hover {
  color: #cccccc;
}
.custom-top-nav__item .custom-top-nav__link_parent {
  color: #777777;
  position: relative;
  padding-right: 15px;
}
@media screen and (min-width: 980px) {
  .custom-top-nav__item .custom-top-nav__link_parent {
    color: #fff;
  }
}
.custom-top-nav__item .custom-top-nav__link_parent:after {
  content: "\e903";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  font-size: 8px;
  transition: all 0.5s ease 0s;
  position: relative;
  margin-left: 5px;
  position: absolute;
  bottom: 4px;
}
@media screen and (min-width: 980px) {
  .custom-top-nav__item .custom-top-nav__link_parent:after {
    line-height: 0;
    bottom: 10px;
    bottom: 8px;
    right: 0;
  }
}
@media screen and (min-width: 1200px) {
  .custom-top-nav__item .custom-top-nav__link_parent:after {
    bottom: 10px;
    right: 0;
  }
}
.custom-top-nav__item .custom-top-nav__link_parent[data-open=true]:after {
  transform: rotate(180deg);
  transform-origin: center center;
}
.custom-top-nav__item .custom-top-nav__link_parent[data-open=false]:after {
  transform: rotate(0deg);
}
.custom-top-nav__item .logo__container .sprinter__logo {
  width: 240px;
}
.custom-top-nav__menu-list {
  display: inline-block;
  width: 100%;
}
@media screen and (min-width: 980px) {
  .custom-top-nav__menu-list {
    display: flex;
    justify-content: space-around;
  }
}
.custom-top-nav__menu-list__container {
  display: table-cell;
  vertical-align: bottom;
}
.custom-top-nav__sub-menu-list {
  display: none;
}
@media screen and (min-width: 980px) {
  .custom-top-nav__sub-menu-list {
    top: 100%;
    white-space: nowrap;
    display: none;
    min-width: 190px;
    background: #000000;
    border-radius: 5px;
    text-align: left;
    max-height: none;
    z-index: 1000;
    left: -10px;
    position: absolute;
  }
}
@media screen and (min-width: 1200px) {
  .custom-top-nav__sub-menu-list {
    top: 100%;
  }
}
.custom-top-nav__sub-menu-list__item {
  margin-top: 20px;
  margin-left: 15px;
}
@media screen and (min-width: 980px) {
  .custom-top-nav__sub-menu-list__item {
    display: block;
    margin-top: 0px;
    margin-left: 0px;
  }
}
.custom-top-nav__sub-menu-list__link {
  color: #777777;
  font-size: 14px;
}
@media screen and (min-width: 980px) {
  .custom-top-nav__sub-menu-list__link {
    color: #fff;
    display: block;
    padding: 3px 10px;
    font-size: 11px;
  }
  .custom-top-nav__sub-menu-list__link:first-child {
    padding-top: 6px;
  }
}
@media screen and (min-width: 1200px) {
  .custom-top-nav__sub-menu-list__link {
    font-size: 12px;
  }
}
.custom-top-nav__sub-menu-list__link:hover {
  color: #00AEDF;
}
.custom-top-nav__link {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  display: block;
}
@media screen and (min-width: 980px) {
  .custom-top-nav__link {
    font-size: 12px;
    display: inline-block;
  }
}
@media screen and (min-width: 1200px) {
  .custom-top-nav__link {
    font-size: 14px;
  }
}
.custom-top-nav__link_back-btn {
  display: none;
}
.custom-top-nav__level {
  position: relative;
}
@media screen and (min-width: 980px) {
  .custom-top-nav__level {
    display: block;
  }
}
.custom-top-nav__container {
  width: 250px;
  position: fixed;
  overflow: hidden;
  height: 100vh;
  background-color: #f8f8f8;
  top: 0;
  transition: 0.5s;
  left: -250px;
  overflow-y: scroll;
}
body[data-menu-state=open] .custom-top-nav__container {
  left: 0;
}
@media screen and (min-width: 980px) {
  .custom-top-nav__container {
    background: transparent;
    height: auto;
    position: static;
    width: auto;
    overflow: inherit;
  }
}
.custom-top-nav__sub-nav {
  max-height: 0;
}
.custom-top-nav__sub-nav ul {
  background: #000000;
  display: none;
}
.custom-top-nav .wrapper {
  max-width: 1170px;
}
.custom-top-nav .error-field--hidden {
  visibility: hidden;
}
.custom-top-nav .error-field--zip-code {
  color: #FF6B6B;
}
.custom-top-nav .hide-container {
  display: none;
}

.custom-sub-nav__level {
  width: 100%;
  margin-top: 10px;
}
@media screen and (min-width: 768px) {
  .custom-sub-nav__level {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 100%;
    margin-right: 0px;
  }
  .custom-sub-nav__level:last-child {
    margin-right: 0;
  }
}
.custom-sub-nav__level--left {
  display: inline-block;
  margin: 65px 0px 0px 0px;
  bottom: 10px;
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.custom-sub-nav__level--left:last-child {
  margin-right: 0;
}
@media screen and (min-width: 980px) {
  .custom-sub-nav__level--left {
    margin: 15px 0px;
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 74.4680851064%;
    display: table;
  }
  .custom-sub-nav__level--left:last-child {
    margin-right: 0;
  }
}
.custom-sub-nav__level--right {
  position: absolute;
  top: 0%;
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
  margin-top: 5px;
}
.custom-sub-nav__level--right:last-child {
  margin-right: 0;
}
@media screen and (min-width: 980px) {
  .custom-sub-nav__level--right {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 23.4042553191%;
    position: inherit;
    margin-top: 7px;
  }
  .custom-sub-nav__level--right:last-child {
    margin-right: 0;
  }
}

.custom-sub-nav__search {
  text-align: left;
  display: block;
  width: 100%;
  margin-right: 7px;
}
@media screen and (min-width: 980px) {
  .custom-sub-nav__search {
    width: 75%;
    display: inline-block;
  }
}
@media screen and (min-width: 1200px) {
  .custom-sub-nav__search {
    display: inline-block;
    margin-right: 10px;
  }
}
.custom-sub-nav__title-block {
  margin: 10px 5px 5px 0px;
  font-family: "corporateacondpro", sans-serif;
  font-size: 26px;
}
.custom-sub-nav__form-title {
  color: #000000;
}
@media screen and (min-width: 980px) {
  .custom-sub-nav__form-title {
    color: #fff;
    font-weight: 700;
    font-size: 11px;
  }
}
@media screen and (min-width: 1200px) {
  .custom-sub-nav__form-title {
    font-size: 12px;
  }
}
.custom-sub-nav__form {
  margin-bottom: 10px;
  display: inline-block;
}
@media screen and (min-width: 980px) {
  .custom-sub-nav__form {
    margin-bottom: 0px;
  }
}
.custom-sub-nav__form-input {
  width: 100%;
  height: 28px;
  font-size: 12px;
  padding-left: 3px;
}
@media screen and (min-width: 980px) {
  .custom-sub-nav__form-input {
    height: 25px;
    display: inline-block;
  }
}
.custom-sub-nav__form-input__container {
  width: 60%;
  display: inline-block;
  float: left;
}
@media screen and (min-width: 980px) {
  .custom-sub-nav__form-input__container {
    width: 75%;
  }
}
.custom-sub-nav__form-btn {
  min-width: 65px;
  height: 28px;
  margin-left: 10px;
  background-color: #176db7;
  color: #fff;
  border: none;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}
@media screen and (min-width: 980px) {
  .custom-sub-nav__form-btn {
    display: inline-block;
    padding: 2px 0px;
    width: 25%;
    height: auto;
    min-width: 0px;
    margin: 0px;
  }
}
.custom-sub-nav__form-btn:hover {
  background-color: #1E8DF4;
}
.custom-sub-nav__form__title-block {
  color: #fff;
}
.custom-sub-nav__logout {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  font-weight: 700;
  color: #000000;
  font-size: 13px;
  margin-top: 20px;
}
@media screen and (min-width: 980px) {
  .custom-sub-nav__logout {
    color: #fff;
    margin-top: 0px;
  }
}
@media screen and (min-width: 1200px) {
  .custom-sub-nav__logout {
    font-size: 14px;
  }
}
@media screen and (min-width: 980px) {
  .custom-sub-nav__logout--small-screen {
    display: none;
  }
}
.custom-sub-nav__logout--large-screen {
  display: none;
}
@media screen and (min-width: 980px) {
  .custom-sub-nav__logout--large-screen {
    display: inline-block;
  }
}
.custom-sub-nav__logout--icon {
  display: none;
}
.custom-sub-nav__logout--icon:before {
  font-family: "micro-icons";
  content: "\e949";
}
@media screen and (min-width: 980px) {
  .custom-sub-nav__logout--icon {
    display: block;
  }
}
.custom-sub-nav__logout_custom {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  font-weight: 700;
  color: #000000;
  font-size: 13px;
  margin-top: 20px;
}
@media screen and (min-width: 980px) {
  .custom-sub-nav__logout_custom {
    color: #fff;
    margin-top: 0px;
  }
}
@media screen and (min-width: 1200px) {
  .custom-sub-nav__logout_custom {
    font-size: 14px;
  }
}
@media screen and (min-width: 980px) {
  .custom-sub-nav__logout_custom--small-screen {
    display: none;
  }
}
.custom-sub-nav__logout_custom--large-screen {
  display: none;
}
@media screen and (min-width: 980px) {
  .custom-sub-nav__logout_custom--large-screen {
    display: inline-block;
  }
}
.custom-sub-nav__logout_custom--icon {
  display: none;
}
.custom-sub-nav__logout_custom--icon:before {
  font-family: "micro-icons";
  content: "\e949";
}
@media screen and (min-width: 980px) {
  .custom-sub-nav__logout_custom--icon {
    display: block;
  }
}

.custom-top-nav__level--active {
  overflow: scroll;
  padding-bottom: 50%;
}
.custom-top-nav__level--open {
  transform: none;
  visibility: visible;
  z-index: 0;
}
.custom-top-nav__level--full-width .custom-sub-nav__level--left {
  margin: 5px 0px 0px 0px;
  width: 100%;
}
@media screen and (min-width: 980px) {
  .custom-top-nav__level--full-width .custom-sub-nav__level--left {
    margin: 18px 0px;
  }
}
.custom-top-nav__level--search-only .custom-sub-nav__level--left {
  margin: 70px 0px 0px 0px;
}
@media screen and (min-width: 980px) {
  .custom-top-nav__level--search-only .custom-sub-nav__level--left {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 82.9787234043%;
    margin: 15px 0px;
    display: table;
  }
  .custom-top-nav__level--search-only .custom-sub-nav__level--left:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 980px) {
  .custom-top-nav__level--search-only .custom-sub-nav__level--right {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 14.8936170213%;
  }
  .custom-top-nav__level--search-only .custom-sub-nav__level--right:last-child {
    margin-right: 0;
  }
}
.custom-top-nav__level--search-only .custom-sub-nav__search {
  width: 100%;
}
.custom-top-nav__level--logout-only .custom-sub-nav__level--left {
  margin: 25px 0px 0px 0px;
}
@media screen and (min-width: 980px) {
  .custom-top-nav__level--logout-only .custom-sub-nav__level--left {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 91.4893617021%;
    margin: 15px 0px;
    display: table;
  }
  .custom-top-nav__level--logout-only .custom-sub-nav__level--left:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 980px) {
  .custom-top-nav__level--logout-only .custom-sub-nav__level--right {
    margin-top: 7px;
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 6.3829787234%;
    text-align: right;
  }
  .custom-top-nav__level--logout-only .custom-sub-nav__level--right:last-child {
    margin-right: 0;
  }
}

.custom-websites .header-click-blocker {
  visibility: hidden;
  width: 100%;
  position: fixed;
  top: 40px;
  left: 0;
  background-color: #000000;
  height: 100vh;
  opacity: 0.4;
  transition: 0.5s;
}
.custom-websites .page-content {
  left: 0;
  z-index: 500;
  position: relative;
  transition: 0.5s;
}
.custom-websites .page-content.un-transform {
  transform: none;
}
.custom-websites footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 0;
  left: 0;
  transition: 0.5s;
}
.custom-websites .header {
  position: relative;
  z-index: 501;
  left: 0;
  transition: 0.5s;
}
.custom-websites .header--position {
  z-index: 500;
}
.custom-websites .breadcrumb, .custom-websites .hero-image {
  display: none;
}
@media screen and (min-width: 980px) {
  .custom-websites .breadcrumb, .custom-websites .hero-image {
    display: block;
  }
}

.header {
  background: #000000;
}

#content {
  z-index: 500;
  position: relative;
}

/**
 * Footer specific styling
 * This file defines medium & large sized behavior of the accordion WITHIN the context of footer
 * Because the the layout of footer in medium breakpoint, column-link-list items could not be wrapped in a container
 * Each of the items in footer (4 column link lists + stay connected + other sites) are a flex items, and it's width (for medium & large) is controlled using calc()
 */
.custom-footer {
  background-color: #F0F0F0;
  display: block;
}
.custom-footer--variant {
  padding-top: 0;
}
.custom-footer__container {
  padding-top: 20px;
  margin-top: 15px;
}
@media screen and (min-width: 768px) {
  .custom-footer__container {
    padding-top: 30px;
  }
}
@media screen and (min-width: 768px) {
  .custom-footer--variant {
    padding-top: 0;
  }
}
@media only print {
  .custom-footer {
    padding-top: 0;
    border-top: #c7c7c7 1px solid;
    background-color: unset;
  }
}
@media only print {
  .custom-footer [data-accordion-name=footer-accordion] {
    display: none;
  }
}
@media only print {
  .custom-footer .legal {
    display: none;
  }
}
.custom-footer__accordion-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
@media screen and (max-width: 767px) {
  .custom-footer__accordion-container.wrapper {
    width: 100%;
  }
}
.custom-footer__accordion-item {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .custom-footer__accordion-item {
    margin-right: 20px;
    width: calc(33.3333333333% - 13.3333333333px);
  }
  .custom-footer__accordion-item:nth-of-type(3) {
    margin-right: 0;
  }
}
@media screen and (min-width: 980px) {
  .custom-footer__accordion-item {
    width: calc(25% - 15px);
  }
  .custom-footer__accordion-item:nth-of-type(3) {
    margin-right: 20px;
  }
  .custom-footer__accordion-item:nth-of-type(4) {
    margin-right: 0;
  }
}
.custom-footer__accordion-item.stay-connected__stay-in-touch {
  margin-right: 0;
  width: 100%;
}
.custom-footer__accordion-item.stay-connected__other-sites {
  margin-right: 0;
  width: 100%;
}
.custom-footer__accordion-item-heading {
  font: normal 400 24px/1.1666666667 "corporateacondpro";
  border-bottom: 1px dashed #999;
}
@media screen and (min-width: 768px) {
  .custom-footer__accordion-item-heading {
    border-top: none;
    margin-bottom: 15px;
  }
  .custom-footer__accordion-item-heading button {
    font: inherit;
  }
}
@media screen and (min-width: 768px) {
  .custom-footer__accordion-item-content {
    margin-bottom: 15px;
    border-top: none;
    padding: 0;
  }
}
@media screen and (min-width: 768px) {
  .custom-footer__accordion-item-cta {
    padding: 0;
  }
}
.custom-footer .wrapper {
  max-width: 1170px;
}

.footer__accordion-container--editorial {
  display: block !important;
}
.footer__accordion-item--column-link-list-editorial {
  display: flex;
  flex-direction: row;
}
.footer__accordion-item--stay-connected-editorial {
  flex-direction: column;
}

.column-link-list {
  margin-bottom: 15px;
}
.column-link-list__column {
  display: block;
}
.column-link-list__column-heading {
  color: #333;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-size: 26px;
}
.column-link-list__column-heading--blank {
  padding: 20px;
}
.column-link-list__image {
  width: 30px;
}
.column-link-list__link-container {
  color: #000000;
  display: flow-root;
  margin-left: 40px;
  text-align: left;
}
@media screen and (min-width: 768px) {
  .column-link-list__link-container {
    margin-left: 0px;
  }
}
.column-link-list__link-container li {
  font: normal 700 12px/1.8333333333 "Arial", Helvetica, Arial, sans-serif;
}
.column-link-list__link-container a {
  display: block;
  padding-bottom: 10px;
  color: #222;
}
.column-link-list__link-container a:hover, .column-link-list__link-container a:focus {
  text-decoration: underline;
  color: #222;
}
.column-link-list__link-color {
  display: block;
  padding-bottom: 10px;
  color: #fff !important;
}
.column-link-list__link-color:hover, .column-link-list__link-color:focus {
  text-decoration: underline;
  color: #fff !important;
}

.legal {
  background-color: #fff;
  padding: 20px 0px;
  color: #666;
}
.legal .footer__link {
  margin-left: 0px;
  display: block;
  color: #666;
}
@media screen and (min-width: 768px) {
  .legal .footer__link {
    margin-left: 70px;
    display: inline-block;
  }
}
@media screen and (min-width: 980px) {
  .legal .footer__link:hover, .legal .footer__link:focus {
    animation: none;
    color: #3276B1;
    text-decoration: underline;
  }
}
.legal .pull--right {
  float: right;
}
.legal .link {
  margin: 0 10px;
}
.legal .link:hover {
  color: #0090ff;
}
.legal .link:last-of-type {
  margin-right: 0;
}

body {
  padding-bottom: 0px;
}

.stay-connected {
  margin-bottom: 15px;
}
.stay-connected__stay-in-touch {
  color: #333;
}
@media screen and (max-width: 767px) {
  .stay-connected__other-sites {
    border-bottom: 1px solid #c7c7c7;
  }
}
.stay-connected__title {
  font-size: 26px;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.stay-connected__sub-title {
  font-style: italic;
  font-size: 14px;
}
.stay-connected__form {
  display: inline-block;
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.stay-connected__form:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .stay-connected__form {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 65.9574468085%;
  }
  .stay-connected__form:last-child {
    margin-right: 0;
  }
}
.stay-connected__form-group {
  display: inline-block;
  width: 60%;
  margin-bottom: 10px;
}
.stay-connected__form-input {
  width: 80%;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 12px;
}
.stay-connected__form-input--error {
  margin-top: 5px;
  color: red;
  font-weight: 700;
}
.stay-connected__link-container::after {
  clear: both;
  content: "";
  display: block;
}
.stay-connected__social-links-container {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.stay-connected__social-links-container:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .stay-connected__social-links-container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 31.914893617%;
  }
  .stay-connected__social-links-container:last-child {
    margin-right: 0;
  }
}
.stay-connected__social-links-list {
  display: inline-block;
  padding-left: 20px;
}
.stay-connected__social-links-list:first-child {
  padding-left: 0;
}
.stay-connected__social-link {
  font-size: 40px;
  color: #000000;
  display: inline-block;
}
.stay-connected__social-link:hover, .stay-connected__social-link:focus {
  animation: none;
  color: #0090ff;
}
@media screen and (max-width: 767px) {
  .stay-connected__other-sites-container {
    padding-top: 10px;
  }
}
@media screen and (min-width: 768px) {
  .stay-connected__other-sites-container {
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (max-width: 767px) {
  .stay-connected__other-links-list:first-child .stay-connected__other-link {
    padding-top: 5px;
  }
  .stay-connected__other-links-list:last-child .stay-connected__other-link {
    padding-top: 0;
  }
}
.stay-connected__other-links-list .icon-mb.icon-mbvans span {
  font: normal 400 18px/1 "corporateacondpro";
}
.stay-connected__other-links-list .icon-mb.icon-mbfs span {
  font: italic 400 18px/1 "corporateacondpro";
}
.stay-connected__other-links-list .icon-mb.icon-mbvans span,
.stay-connected__other-links-list .icon-mb.icon-mbfs span {
  position: static;
  overflow: visible;
}
.stay-connected__other-links-list .icon-smart, .stay-connected__other-links-list .icon-AMG_logo {
  line-height: 10px;
}
.stay-connected__other-link {
  color: #000000;
}
@media screen and (max-width: 767px) {
  .stay-connected__other-link {
    display: block;
    width: 100%;
    display: inline-block;
    height: 30px;
    overflow: hidden;
    padding-top: 10px;
  }
}
.stay-connected__other-link:hover, .stay-connected__other-link:focus {
  animation: none;
  color: #0090ff;
}
.stay-connected--icon-mbvans {
  font-size: 15px;
}
.stay-connected--icon-smart {
  font-size: 80px;
}
.stay-connected--icon-AMG_logo {
  font-size: 80px;
}
.stay-connected--icon-mbfs {
  font-size: 15px;
}
.stay-connected__subscribe-cta {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  background: linear-gradient(to bottom, #424242 8%, #000000 100%);
  min-width: 110px;
  padding: 4px 10px;
  border: none;
  border-radius: 2px;
}
@media screen and (min-width: 980px) {
  .stay-connected__subscribe-cta {
    margin-bottom: 0;
  }
}
.stay-connected .hidden {
  display: none;
}

.column-display--2 .stay-connected__form, .column-display--3 .stay-connected__form, .column-display--4 .stay-connected__form {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
  margin-bottom: 10px;
}
.column-display--2 .stay-connected__form:last-child, .column-display--3 .stay-connected__form:last-child, .column-display--4 .stay-connected__form:last-child {
  margin-right: 0;
}
.column-display--2 .stay-connected__social-links-container, .column-display--3 .stay-connected__social-links-container, .column-display--4 .stay-connected__social-links-container {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.column-display--2 .stay-connected__social-links-container:last-child, .column-display--3 .stay-connected__social-links-container:last-child, .column-display--4 .stay-connected__social-links-container:last-child {
  margin-right: 0;
}

.partnerships {
  margin-bottom: 15px;
}
.partnerships__heading {
  font: normal 400 24px/1.1666666667 "corporateacondpro";
  color: #333;
  padding-bottom: 10px;
  margin-bottom: 15px;
  font-size: 26px;
  border-bottom: 1px dashed #999;
}
.partnerships__img {
  width: 100%;
  height: auto;
}
.partnerships__link-list__link {
  display: block;
}
.partnerships__link-list__link-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.partnerships__link-list__item {
  max-width: 125px;
  text-align: center;
  vertical-align: middle;
  padding: 0 15px;
  margin-bottom: 15px;
}

.g-Class-StickyNav-SideBar__fixed-section {
  right: 0%;
  bottom: 33%;
  z-index: 99999;
  position: fixed;
}
.g-Class-StickyNav-SideBar__container {
  display: flex;
}
.g-Class-StickyNav-SideBar__link-container {
  background-color: rgb(102, 102, 102);
  margin-left: auto;
  position: relative;
  padding: 0 10 0 10;
  opacity: 0;
  visibility: hidden;
}
.g-Class-StickyNav-SideBar__link {
  position: relative;
  top: 8;
  color: white;
}
.g-Class-StickyNav-SideBar__image {
  margin: 0;
  width: 35% !important;
  position: relative;
  margin-left: auto;
  padding: 10;
  background-color: rgb(102, 102, 102);
  border-bottom-width: 1px;
  border-bottom-color: white;
  border-style: solid;
}
@media screen and (max-width: 767px) {
  .g-Class-StickyNav-SideBar__mobile {
    display: none !important;
  }
}

.g-Class-StickyNav-SideBar__container:hover .g-Class-StickyNav-SideBar__link-container {
  opacity: 1;
  visibility: visible;
}

.g-class-media-richtext__list {
  display: flex;
  border-bottom-width: 1px;
  border-style: solid;
  padding-bottom: 3%;
  padding-top: 3%;
}
.g-class-media-richtext__image {
  width: 12%;
  padding: 0px 20px 0px 0px;
}

.button-video__popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
}
.button-video__close {
  position: absolute;
  top: 15%;
  right: 24%;
  font-size: 20px;
}

.col-3 {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.col-3:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .col-3 {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 23.4042553191%;
  }
  .col-3:last-child {
    margin-right: 0;
  }
}
.col-6 {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.col-6:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .col-6 {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
  }
  .col-6:last-child {
    margin-right: 0;
  }
}
.col-9 {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.col-9:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .col-9 {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 74.4680851064%;
  }
  .col-9:last-child {
    margin-right: 0;
  }
}
.col-12 {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.col-12:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .col-12 {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 100%;
  }
  .col-12:last-child {
    margin-right: 0;
  }
}

.custom-header__favorite {
  color: white;
  position: relative;
  right: 60%;
  top: 28%;
  width: fit-content;
  font-size: 115%;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .custom-header__favorite {
    text-align: center;
    width: 100%;
  }
}
.custom-header__popuptext {
  color: black;
  text-align: justify;
}
.custom-header__popuparrow {
  display: none;
  content: "";
  position: absolute;
  bottom: -13%;
  left: 40%;
  margin-left: -5px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid white;
}
.custom-header__show {
  display: none;
  left: -645%;
  top: 110%;
  width: 800%;
  background: white;
  height: 1200%;
  position: absolute;
  z-index: 11111;
  border: 1px solid #000000;
  overflow: auto;
  padding: 20% 25%;
  border-radius: 6px;
  border-style: solid;
  border-color: grey;
}
@media only screen and (max-width: 1128px) and (min-width: 360px) {
  .custom-header__show {
    width: 500%;
    left: -344%;
  }
}
.custom-header__button {
  color: white;
  position: absolute;
  margin-left: 35%;
  margin-right: 42%;
  background: steelblue;
  padding: 2px;
  width: 22%;
  border-radius: 6px;
  display: block;
}
.custom-header__flag {
  fill: white;
  position: absolute;
  top: 16%;
  right: 100%;
}

.accordion {
  margin: 0px 0px 20px 20px;
}
.accordion__list, .accordion__link-list-v2 {
  list-style: disc;
}
.accordion__title {
  cursor: pointer;
  font-size: 16px;
  color: #0051C2;
  font-weight: 500;
}
.accordion__title:hover {
  color: #666;
}
.accordion__panel {
  display: none;
  font-size: 14px;
  padding: 10px;
}
.accordion__panel ol {
  list-style: decimal;
}
.accordion__panel ul {
  list-style: disc;
}
.accordion__active .accordion__panel {
  display: block;
}

.mygrid__row {
  margin-top: 40px;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.mygrid__nopadding {
  padding: 0;
}
.mygrid__col-md-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
}
.mygrid__col-md-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
}
.mygrid__text-left {
  text-align: left !important;
}
.mygrid__img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.cmp-grid-column {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.cmp-grid-column .column {
  padding-right: 3%;
}

.cmp-grid-column img {
  height: 130px;
  width: 100%;
  margin-left: 0;
  margin-top: 10%;
}

.cmp-grid-column p {
  font-size: 14px;
  word-break: break-word;
}

.cmp-grid-column h4 {
  font: normal 700 18px/1.5 "Arial", Helvetica, Arial, sans-serif;
  padding-top: 10px;
  word-break: break-word;
}

.textImage__container {
  position: relative;
  text-align: center;
  color: white;
  width: 125%;
  margin-left: -13%;
}
.textImage__top-left {
  text-align: left;
  font-family: sans-serif;
  overflow-y: auto;
  background-size: contain;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}
.textImage__top-title {
  text-align: left;
  margin-right: 10%;
  font-family: sans-serif;
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  color: white;
}
pre__div {
  width: 100%;
  height: 100%;
  border: 1px solid #4CAF50;
}

.vin__table1 {
  display: none;
}
.vin__custom-padding {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.vin__btn1 {
  background-color: #008CBA; /* Green */
  border: none;
  color: white;
  padding: 6px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}
.vin__body-text {
  font-family: "Times New Roman", Times, serif;
}
.vin__padding-table-columns td {
  padding: 0 40px 0 0; /* Only right padding*/
}
.vin__custom-padding {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.vin__row-custom-border {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding-top: 15px;
  padding-bottom: 15px;
}
.vin__row-custom-padding {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #E8E8E8;
}
.vin__text-bold {
  font-weight: bold;
}
.vin__table-column-padding {
  padding-right: 200px;
}
.vin__column-spacing {
  cellspacing: 30px;
}
.vin__custom-padding-table-columns td {
  padding: 0 100px 0 0;
}
.vin__custom-padding-bill-columns td {
  padding: 0 700px 0 0; /* Only right padding*/
}
.vin__container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.vin__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.vin__col-md-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.vin__col-md-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.vin__col-md-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.vin__form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 1rem;
}
.vin__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.vin__error-field--hidden {
  display: none;
}
.vin__form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.vin__form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.vin__form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.vin__form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.vin__form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.vin__form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.vin__form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.vin__form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.vin__form-control:disabled, .vin__form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.viewmore__row {
  margin-top: 40px;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.viewmore__my-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  display: none;
}
.viewmore__nopadding {
  padding: 0;
}
.viewmore__col-md-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
}
.viewmore__col-md-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
}
.viewmore__text-left {
  text-align: left !important;
}
.viewmore__img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.viewmore__btn {
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
}
.viewmore__myButton1 {
  border: none;
  background-color: transparent;
  color: #366fe3;
  font-weight: bolder;
  font-family: inherit;
  cursor: pointer;
  font-size: 20px;
}
.viewmore__icon {
  font-size: 20px !important;
  color: #366fe3;
  cursor: pointer;
}

.cmp-viewmore-column {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.cmp-viewmore-column .column {
  padding-right: 3%;
}

.cmp-viewmore-column img {
  height: 130px;
  width: 100%;
  margin-left: 0;
  margin-top: 10%;
}

.cmp-viewmore-column p {
  font-size: 14px;
  word-break: break-word;
}

.cmp-viewmore-column h4 {
  font: normal 700 18px/1.5 "Arial", Helvetica, Arial, sans-serif;
  padding-top: 10px;
  word-break: break-word;
}

.map {
  height: 480px !important;
  width: 120%;
  margin-left: -10%;
}
.map__calender {
  position: absolute;
  width: 16%;
  padding: 1%;
  top: -3%;
  margin-left: 4%;
  height: 50px;
  background-color: white;
}
.map__calender::before {
  content: "";
  position: absolute;
  background: #939393;
  height: 24%;
  width: 95%;
  margin-top: -6%;
  margin-left: -42%;
  transform: skewX(23deg);
  transform-origin: top;
  z-index: -1;
}

@media (max-width: 767px) {
  .map__calender {
    top: 39px;
    margin-left: 2%;
    width: 13%;
    padding-top: 3%;
    position: relative;
  }
}
@media (max-width: 767px) {
  .map__calender::before {
    content: "";
    margin-top: -22%;
    margin-left: -28%;
    width: 80%;
    height: 22%;
    position: absolute;
  }
}
.address {
  display: none;
}

.adminview__btn1 {
  background-color: #008CBA; /* Green */
  border: none;
  color: white;
  padding: 6px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}
.adminview__btn2 {
  background-color: #808080; /* Green */
  border: none;
  color: white;
  padding: 6px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}
.adminview__custom-padding {
  width: 100%;
  padding-right: 20%;
  padding-left: 20%;
  margin-right: auto;
  margin-left: auto;
}

.list .text-desc {
  margin-bottom: 20px;
}
.list .text-desc__title {
  font: normal 400 28px/1.2142857143 "corporateacondpro";
  font-size: 24px;
  font-family: "corporateacondpro", sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
}
@media screen and (min-width: 980px) {
  .list .text-desc__title {
    font: normal 400 30px/1.2666666667 "corporateacondpro";
  }
}
.list .text-desc__subtitle {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
}
.list .text-desc__panel-content {
  margin-bottom: 10px;
}
.list .text-desc__link {
  font-size: 14px;
}

.rich-text-desc {
  display: inline-block;
  font-size: 14px;
  margin-bottom: 20px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .rich-text-desc__container {
    margin: 0% !important;
  }
}
.rich-text-desc__description-container {
  overflow: visible;
  font-family: "Helvetica", sans-serif;
}
@media screen and (min-width: 980px) {
  .rich-text-desc__description-container {
    overflow: visible;
  }
}
.rich-text-desc__description-container ul {
  list-style: disc;
  padding-left: 20px;
  margin: 15px 0;
}
.rich-text-desc__description-container li {
  line-height: 20px;
  list-style-position: outside;
  margin-bottom: 10px;
}
.rich-text-desc__description-container li ul {
  list-style-type: circle;
}
.rich-text-desc__description-container ol {
  list-style: decimal;
  padding-left: 20px;
  margin: 14px 0;
}
.rich-text-desc__description-container ol ol {
  list-style-type: lower-alpha;
}
.rich-text-desc__description-container ol ol ol {
  list-style-type: lower-roman;
}
.rich-text-desc__description-container p {
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 20px;
}
.rich-text-desc__description-container a {
  color: #428bca;
}
.rich-text-desc__description-container a:hover {
  color: #00A6C9;
}
.rich-text-desc__description-container h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.rich-text-desc__description-container h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.rich-text-desc__description-container h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.rich-text-desc__description-container h4 {
  display: block;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.rich-text-desc__description-container h5 {
  display: block;
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.rich-text-desc__description-container h6 {
  display: block;
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.rich-text-desc__description-container table {
  width: 100%;
}
.rich-text-desc__description-container table tr:first-child {
  background-color: #C0C0C0;
  color: black;
}
.rich-text-desc__description-container table td {
  border-collapse: collapse;
  padding: 5px;
}
.rich-text-desc__description-container table[border="0"] td {
  border: none;
}
.rich-text-desc__title {
  font: normal 400 18px/1 "corporateacondpro";
  font-size: 24px;
  font-family: "corporateacondpro", sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
}
.rich-text-desc__subTitle {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.rich-text-desc__para {
  display: block;
}

.heading-blue-background-rich-text-desc .rich-text-desc {
  border: 1px solid black;
}
.heading-blue-background-rich-text-desc .rich-text-desc__title {
  margin-bottom: 0px;
  padding: 10px 0px;
  background-color: #0090ff;
}
.heading-blue-background-rich-text-desc .rich-text-desc__subTitle {
  background-color: #C0C0C0;
}
.heading-blue-background-rich-text-desc .rich-text-desc table {
  border-bottom: none;
}
.heading-blue-background-rich-text-desc .rich-text-desc table tr td:first-child {
  border-left: none;
}
.heading-blue-background-rich-text-desc .rich-text-desc table tr td:last-child {
  border-right: none;
}
.heading-blue-background-rich-text-desc .rich-text-desc table tr:last-child td {
  border-bottom: none;
}
.heading-blue-background-rich-text-desc .rich-text-desc p {
  margin-bottom: 0px;
}

.bottom-bordered-rich-text-desc .rich-text-desc {
  margin-bottom: 0px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddddd;
}

.complete-bordered-rich-text-desc .rich-text-desc {
  border: 1px solid #dddddd;
  border-radius: 3px;
}
.complete-bordered-rich-text-desc .rich-text-desc__title-container {
  border-bottom: 1px solid #dddddd;
  padding: 10px 20px;
}
.complete-bordered-rich-text-desc .rich-text-desc__subTitle {
  margin-left: 20px;
}
.complete-bordered-rich-text-desc .rich-text-desc__title {
  margin-bottom: 0px;
}
.complete-bordered-rich-text-desc .rich-text-desc__description-container {
  padding: 10px 20px;
}

.content-bordered-rich-text-desc .rich-text-desc__description-container {
  border: 1px solid #dddddd;
  margin-top: 20px;
  padding: 20px 10px;
  border-radius: 3px;
}
.content-bordered-rich-text-desc .rich-text-desc__title-container {
  border-bottom: 1px solid #dddddd;
}

.table-no-border-rich-text-desc .rich-text-desc table {
  width: 100%;
}
.table-no-border-rich-text-desc .rich-text-desc table td {
  border: 1px solid #fff;
  border-collapse: collapse;
  padding: 5px;
}

.table-top-row-blue-rich-text-desc .rich-text-desc tr:first-child {
  background-color: #0431B4;
  color: #fff;
}

.table-top-row-white-rich-text-desc .rich-text-desc tr:first-child {
  background-color: #fff;
}

.link-list {
  margin-bottom: 20px;
}
.link-list__heading {
  color: #5a5a5a;
  font-size: 24px;
  font-family: "corporateacondpro", sans-serif;
}
.link-list__content-description {
  margin-bottom: 10px;
  font-size: 14px;
}
.link-list__container {
  display: block;
}
.link-list--white-text {
  color: #fff;
}
.link-list--black-text {
  color: #000000;
}
.link-list__item {
  border-top: 1px solid #dddddd;
}
.link-list__item:last-child {
  border-bottom: 1px solid #dddddd;
}
.link-list__item-link {
  color: #555555;
  display: block;
  padding: 10px 25px;
  font-size: 14px;
  position: relative;
  width: 100%;
}
.link-list__item-link:hover {
  background-color: #428bca;
  color: #fff;
}
.link-list__item-link-description {
  font-size: 12px;
}
.link-list__item-link-white-text {
  color: #fff;
}
.link-list__item-link-black-text {
  color: #000000;
}
.link-list__item-text {
  display: block;
  padding: 0 5px;
}
.link-list__icon {
  position: absolute;
  top: 13px;
  left: 10px;
}
.link-list__icon--xl {
  font-size: x-large;
  cursor: pointer;
}
.link-list__icon-gray-color {
  color: darkgray;
}
.link-list__icon-list {
  color: #428bca;
}
.link-list__icon-nav {
  left: auto;
  right: 5px;
}
.link-list__main-icon {
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding: 5px;
}
.link-list__noSeparator .link-list__item {
  border-top: none;
}
.link-list__noSeparator .link-list__item:last-child {
  border-bottom: none;
}
.link-list__loader {
  display: inline-block;
  width: 64px;
  height: 64px;
  text-align: center;
}
.link-list__loader:after {
  content: " ";
  display: block;
  text-align: center;
  width: 25px;
  height: 25px;
  margin: 1px;
  border-radius: 50%;
  border: 3px solid #176db7;
  border-color: #176db7 transparent #176db7 transparent;
  animation: dual-ring 1.2s linear infinite;
}
@keyframes dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.link-list .hidden {
  display: none;
}

.link-list-accordion {
  border-bottom: 1px solid #dddddd;
}
.link-list-accordion__title {
  font-size: 24px;
  font-family: "corporateacondpro", sans-serif;
  font-weight: 400;
  color: #5a5a5a;
  padding-top: 5px 15px 0px 15px;
}
.link-list-accordion__title:hover {
  color: #00ADEF;
  cursor: pointer;
}
.link-list-accordion--white-text {
  color: #fff;
}
.link-list-accordion--black-text {
  color: #000000;
}
.link-list-accordion .link-list__item:first-child {
  border-top: none;
}
.link-list-accordion .link-list__item:last-child {
  border-bottom: none;
}

.favorite-item__link-list-v2.custom-loader {
  width: 25px;
  height: 25px;
}

.complete-bordered-link-list .link-list {
  border: 1px solid #dddddd;
  border-radius: 3px;
}
.complete-bordered-link-list .link-list__heading {
  font-family: "corporateacondpro";
  font-size: 20px;
  font-weight: 500;
  color: #333;
  padding: 10px 15px;
}
.complete-bordered-link-list .link-list__content-description {
  margin-bottom: 0px;
  margin-left: 15px;
}
.complete-bordered-link-list .link-list__item-text {
  font-weight: 700;
}
.complete-bordered-link-list .link-list__icon-list {
  color: #c7c7c7;
}
.complete-bordered-link-list .link-list__icon-nav {
  color: #00A6C9;
}

.tabular-image-text__table {
  border-collapse: collapse;
}
.tabular-image-text__cell {
  border: 1px solid #000000;
  max-width: 100%;
  padding: 20px;
  vertical-align: middle;
  font-size: 14px;
  line-height: 20px;
}
.tabular-image-text__cell ol {
  list-style: decimal;
}
.tabular-image-text__cell ul {
  list-style: disc;
}
@media screen and (max-width: 767px) {
  .tabular-image-text__cell {
    width: 100%;
  }
}
.tabular-image-text__heading {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}
.tabular-image-text__para {
  display: block;
  margin-bottom: 10px;
  padding: 10px;
}
.tabular-image-text a {
  color: #428bca;
}
.tabular-image-text a:hover {
  color: #00A6C9;
}
.tabular-image-text__media {
  width: 100%;
}

.with-background-color-tabular-image-text .tabular-image-text {
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding: 5px;
  background-color: #F7F7F9;
}
.with-background-color-tabular-image-text .tabular-image-text__cell {
  border: none;
  vertical-align: top;
}
.with-background-color-tabular-image-text .tabular-image-text__media {
  border: 1px solid #dddddd;
  padding: 5px;
  border-radius: 3px;
}
.with-background-color-tabular-image-text .tabular-image-text__heading {
  font-family: "corporateacondpro", sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 10px;
}
.with-background-color-tabular-image-text .tabular-image-text p {
  font-family: "Helvetica", sans-serif;
  font-size: 12px;
  line-height: 20px;
}
.with-background-color-tabular-image-text .tabular-image-text a {
  font-weight: 700;
  font-size: 14px;
}

.image-text__block {
  background: #F7F7F9;
  border: 1px solid #e1e1e8;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px 15px;
}
.image-text__left {
  float: left;
  margin-right: 10px;
}
.image-text__left--img {
  background: #fff;
  border: 1px solid #dddddd;
  padding: 4px;
}
.image-text__right {
  overflow: hidden;
}
.image-text__heading {
  font: normal 400 18px/1 "corporateacondpro";
  margin-bottom: 5px;
}
.image-text__para {
  margin-bottom: 10px;
}
.image-text__link {
  font-weight: bold;
  font-size: 14px;
  display: block;
}

.featured-link-list {
  margin-bottom: 20px;
}
.featured-link-list__block-container {
  display: block;
  font-size: 0;
}
.featured-link-list__block {
  border: 1px solid #e1e1e8;
  border-radius: 5px;
  display: inline-block;
  font-size: 12px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: top;
  width: 165px;
}
.featured-link-list__img-block {
  text-align: center;
}
.featured-link-list__img {
  display: block;
  width: 100%;
}
.featured-link-list__content-block {
  display: block;
}
.featured-link-list__block-list-container {
  display: block;
}
.featured-link-list__block-list {
  display: block;
}
.featured-link-list__block-link {
  color: #428bca;
  padding: 0 5px;
  display: block;
}
.featured-link-list__block-link:before {
  color: #5a5a5a;
  content: "»";
}
.featured-link-list a {
  color: #428bca;
}
.featured-link-list a:hover {
  color: #00A6C9;
}

.featured-list {
  display: block;
  margin-bottom: 20px;
}
.featured-list__container {
  border: 1px solid #dddddd;
  border-radius: 0 0 3px 2px;
}
.featured-list__heading {
  background: #000000;
  color: #fff;
  font-size: 18px;
  padding: 5px 10px;
  font-family: "corporateacondpro", sans-serif;
}
.featured-list__sub-heading {
  display: block;
  padding: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.featured-list__list-block {
  display: block;
}
.featured-list__desc {
  padding: 10px;
}
.featured-list__list-container {
  display: block;
}
.featured-list__list {
  border-bottom: 1px solid #dddddd;
  padding: 10px;
}
.featured-list__list:last-child {
  border-bottom: none;
}
.featured-list__list-head {
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.featured-list__list-desc {
  display: block;
  font-size: 12px;
  line-height: 18px;
  padding: 10px;
}
.featured-list__list-desc ul {
  list-style: disc;
}
.featured-list__list-desc ol {
  list-style: decimal;
}
.featured-list__list-block {
  display: block;
}

.contact-us {
  margin-bottom: 20px;
}
.contact-us-highlights {
  border: 1px solid #dddddd;
  border-radius: 0 0 3px 2px;
  margin-top: 20px;
}
.contact-us__heading-container {
  display: block;
}
.contact-us__gheading {
  background: #000000;
  color: #fff;
  font-size: 18px;
  padding: 5px 10px;
  font-family: "corporateacondpro", sans-serif;
}
.contact-us__list-heading {
  font-size: 14px;
  font-weight: bold;
}
.contact-us__list {
  padding: 15px;
}
.contact-us__list ol {
  list-style: decimal;
}
.contact-us__list ul {
  list-style: disc;
}
.contact-us__list-para {
  margin-bottom: 10px;
}

.filtered-list {
  display: block;
}
.filtered-list__container {
  display: block;
}
.filtered-list__list {
  border-top: 1px solid #999;
}
.filtered-list__list-desc {
  color: #666;
  display: block;
  font-size: 14px;
  padding: 5px 15px 5px 20px;
  position: relative;
}
.filtered-list__list-desc:hover {
  color: #0071EF;
}
.filtered-list__list-desc .icon-mc {
  display: none;
  font-size: 10px;
}
.filtered-list__list--active > .filtered-list__list-block .filtered-list__list-desc {
  color: #0071EF;
}
.filtered-list__list--open > .filtered-list__sublist-container {
  display: block;
}
.filtered-list__list-block {
  position: relative;
}
.filtered-list__list-block > .icon-mc {
  right: 0;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  cursor: pointer;
}
.filtered-list__sublist-block > .filtered-list__list-block .icon-mc:before {
  content: "\e923";
}
.filtered-list__sublist-block.filtered-list__list--open > .filtered-list__list-block .icon-mc:before {
  content: "\e922";
}
.filtered-list__sublist-container {
  display: none;
  padding-left: 20px;
}
.filtered-list--without-icons .icon-mc {
  display: none;
}
.filtered-list--without-icons .filtered-list__list {
  border-top: none;
}
.filtered-list--without-icons .filtered-list__list-desc {
  color: #000000;
  font-size: 12px;
  font-weight: 700;
}
.filtered-list--without-icons .filtered-list__list-desc:hover {
  color: #0071EF;
}

.title {
  color: #5a5a5a;
}
.title__white {
  color: #5a5a5a;
}
.title__black {
  color: #f1f1f1;
}
.title__content {
  font: normal 400 18px/1 "corporateacondpro";
  font-size: 24px;
}
.title__container {
  text-align: center;
}
.title__spacing--top {
  padding-top: 20px;
}
.title__spacing--bottom {
  padding-bottom: 20px;
}
.title__spacing--both {
  padding: 20px 0px;
}
.title__spacing--none {
  padding: 0;
}
.title--add-bottom-border {
  padding-bottom: 20px;
  border-bottom: 1px solid #c7c7c7;
}
.title__alignment--left {
  text-align: left;
}
.title__alignment--right {
  text-align: right;
}
.title__alignment--center {
  text-align: center;
}
.title__separator--top {
  border-top: 1px solid #c7c7c7;
  padding-top: 20px;
}
.title__separator--bottom {
  border-bottom: 1px solid #c7c7c7;
  padding-bottom: 20px;
}

.button-component {
  width: 100%;
}
.button-component__container {
  margin-bottom: 5px;
}
.button-component__btn {
  border-radius: 5px;
  display: inline-block;
  font-family: "Arial", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  padding: 5px 15px;
  cursor: pointer;
}
.button-component__btn--primary {
  background: linear-gradient(to bottom, #428bca 0px, #2D6CA2 100%) repeat-x;
  border-color: #285E8E;
  color: #fff;
}
.button-component__btn--primary:hover, .button-component__btn--primary:focus {
  background: #3276B1;
  border-color: #285E8E;
  color: #fff;
}
.button-component__btn--wide {
  padding: 5px 80px;
}
.button-component__heading {
  font-family: "corporateacondpro";
  font-size: 24px;
  font-weight: 500;
}
.button-component__pointer {
  cursor: pointer;
}

.video__title {
  font-family: "CorpA Reg", sans-serif;
  font-size: 24px;
  border-bottom: 1px solid #eeeeee;
  margin: 40px 0 20px;
  padding-bottom: 10px;
}
.video__container {
  border-radius: 5px;
  margin-bottom: 15px;
  position: relative;
}
.video__container iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.video__description {
  font-size: 14px;
}
.video__img, .video__video-block {
  width: 100%;
}
.video__img {
  opacity: 0;
}

.alternate-link-list__mainHeading {
  font-family: "corporateacondpro";
  font-size: 20px;
  font-weight: 500;
  color: #5a5a5a;
  margin: 0px 0px 20px 10px;
}
.alternate-link-list .link-list {
  border: 1px solid #dddddd;
  border-radius: 3px;
}
.alternate-link-list .link-list__heading {
  font-family: "corporateacondpro";
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  background-color: #000000;
  padding: 5px 0px 0px 10px;
  padding-bottom: 5px;
  border-radius: 3px 3px 0px 0px;
}
.alternate-link-list .link-list__content-description {
  margin-bottom: 0px;
}
.alternate-link-list .link-list__item-text {
  font-weight: 700;
}
.alternate-link-list .link-list__item-link {
  border-bottom: 1px solid #dddddd;
}
.alternate-link-list .link-list__item-link:last-child {
  border-bottom: none;
}
.alternate-link-list .link-list__item-link:hover {
  background-color: #fff;
  color: #00A6C9;
}
.alternate-link-list .link-list__icon-list {
  color: #c7c7c7;
}
.alternate-link-list .link-list__icon-nav {
  color: #00A6C9;
}

.home-office-support-request-form {
  color: #333;
}
.home-office-support-request-form__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.home-office-support-request-form__group {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.home-office-support-request-form__group_input {
  padding: 10px 0px;
}
.home-office-support-request-form__group_btn {
  padding: 10px 0px;
  text-align: center;
}
.home-office-support-request-form__asterisk_field {
  color: red;
}
.home-office-support-request-form__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.home-office-support-request-form__control {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.home-office-support-request-form__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.home-office-support-request-form__control--error, .home-office-support-request-form__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.home-office-support-request-form__control--success, .home-office-support-request-form__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.home-office-support-request-form__control_textarea {
  height: auto;
}
.home-office-support-request-form__control_document {
  background: #fff;
  padding: 6px 10px;
  width: 100%;
  font-family: "Arial";
}
.home-office-support-request-form__control_table {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.home-office-support-request-form__control_table:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.home-office-support-request-form__control_table--error, .home-office-support-request-form__control_table--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.home-office-support-request-form__control_table--success, .home-office-support-request-form__control_table--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.home-office-support-request-form__title {
  font-size: 20px;
  width: 100%;
}
.home-office-support-request-form__text {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.home-office-support-request-form__hide {
  visibility: hidden;
}
.home-office-support-request-form__table_column {
  width: 35%;
  vertical-align: middle;
}
.home-office-support-request-form__table_column_checkbox {
  width: 35%;
}
.home-office-support-request-form__table_column_padding {
  padding-left: 15px;
}
.home-office-support-request-form__table {
  width: 80%;
}
.home-office-support-request-form__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.home-office-support-request-form__error-field--hidden {
  display: none;
}
.home-office-support-request-form__btn_column {
  text-align: center;
}
.home-office-support-request-form__reset_btn {
  background-color: #D3D3D3;
  color: #333;
  border-color: #ccc;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
}
.home-office-support-request-form__section {
  display: block;
}
.home-office-support-request-form__section .pop-up {
  padding-top: 150px;
}
.home-office-support-request-form__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.home-office-support-request-form__section .pop-up__content-body {
  background: #fff;
}
.home-office-support-request-form__control_textarea {
  height: auto;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 6px 12px;
  width: 100%;
}
.home-office-support-request-form__input-symbol-dollar {
  position: relative;
  left: 3%;
}
.home-office-support-request-form__input-symbol-dollar:after {
  content: "$";
  font-size: 104% !important;
  font-weight: 400;
  left: 3%;
  position: absolute;
  top: -31%;
  padding-right: 3% !important;
}
.home-office-support-request-form__abc-input {
  padding-left: 5% !important;
}

.home-office-support-request-report {
  color: #333;
}
.home-office-support-request-report__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.home-office-support-request-report__group {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.home-office-support-request-report__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.home-office-support-request-report__index {
  display: inline-block;
  margin: 20px 0 10px 0;
}
.home-office-support-request-report__index--anchor {
  color: #428bca;
  float: left;
  padding: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #dddddd;
  width: 55px;
  height: 35px;
  text-align: center;
  margin-bottom: 10px;
}
.home-office-support-request-report__index--anchor:hover {
  color: #285E8E;
  background-color: #eeeeee;
  border-color: #dddddd;
  cursor: pointer;
}
.home-office-support-request-report__index--anchor:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.home-office-support-request-report__index--anchor:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.home-office-support-request-report__index--anchor-hidden {
  display: none;
}
.home-office-support-request-report__index--active {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.home-office-support-request-report__index--active:hover {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.home-office-support-request-report__index--disabled {
  pointer-events: none;
  color: grey;
  cursor: default;
}
.home-office-support-request-report__index--disabled:hover, .home-office-support-request-report__index--disabled:focus {
  color: grey;
  cursor: default;
  border-color: none;
  background-color: #fff;
}
.home-office-support-request-report__table {
  width: 160%;
  border-collapse: collapse;
  table-layout: fixed;
}
.home-office-support-request-report__table .fixed {
  table-layout: fixed;
}
.home-office-support-request-report__table .fixed td {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
.home-office-support-request-report__table .fixed th {
  cursor: pointer;
}
.home-office-support-request-report__table th[data-filter-value] {
  cursor: pointer;
}
.home-office-support-request-report__sortable {
  position: relative;
}
.home-office-support-request-report__child {
  font: initial;
}
.home-office-support-request-report__half_width {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.home-office-support-request-report__btn_label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #5cb85c;
}
.home-office-support-request-report__control {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.home-office-support-request-report__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.home-office-support-request-report__control--error, .home-office-support-request-report__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.home-office-support-request-report__control--success, .home-office-support-request-report__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.home-office-support-request-report__table_column_padding_left {
  padding-left: 15px;
}
.home-office-support-request-report__table_column_padding_right {
  padding-right: 15px;
}
.home-office-support-request-report__error-field {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 30px 10px 10px;
}
.home-office-support-request-report__loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.home-office-support-request-report__listing_loader {
  position: fixed;
  left: 54%;
  top: 70%;
  z-index: 1;
  width: 60px;
  height: 60px;
  margin: -75px 0 0 -75px;
  border: 10px solid #DCDCDC;
  border-radius: 50%;
  border-top: 10px solid #000;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.home-office-support-request-report__button {
  color: #fff;
  background-color: #176db7;
  padding: 5px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
  border-radius: 0.6em;
}
.home-office-support-request-report__button:hover {
  cursor: pointer;
  background-color: #0090ff;
}
.home-office-support-request-report__border_rule {
  border-bottom: 1px solid #DCDCDC;
}
.home-office-support-request-report__td_padding {
  margin: 10px;
  padding: 10px 3px;
  text-align: center;
}
.home-office-support-request-report__th_padding {
  margin: 10px;
  padding: 10px 0px;
  text-align: center;
}
.home-office-support-request-report__pagin_padding {
  display: flex;
  justify-content: center;
  padding-top: 25px;
  font-size: initial;
}
.home-office-support-request-report__arrow_icon {
  color: #add8e6;
}

.fleet-info-can-search {
  position: sticky;
  top: 0;
  background-color: #ebeef1;
  padding: 0px 0px 0px 130px;
  font-size: 20px;
  z-index: 5;
}
.fleet-info-can-search__contained {
  font-size: 12px;
  height: 48px;
  max-width: 1000px;
  position: relative;
  display: inline-flex;
  width: 100%;
  border-radius: 4px;
  top: 0%;
  margin-bottom: 10px;
  margin-left: 62.5%;
}
.fleet-info-can-search__input-group {
  display: inline-flex;
  width: 50%;
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.fleet-info-can-search__input-group:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .fleet-info-can-search__input-group {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 40.4255319149%;
  }
  .fleet-info-can-search__input-group:last-child {
    margin-right: 0;
  }
}
.fleet-info-can-search__input {
  width: auto;
  padding: 6px 0px 8px 15px;
  float: left;
  color: #000000;
}
.fleet-info-can-search__input:focus {
  border-color: #66AFE9;
}
.fleet-info-can-search__button {
  color: #fff;
  background-color: #5bc0de;
  padding: 4px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
}
.fleet-info-can-search__button:hover {
  cursor: pointer;
  background-color: #39B3D7;
}
.fleet-info-can-search__nextbutton {
  color: #fff;
  background-color: #5bc0de;
  padding: 5px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
}
.fleet-info-can-search__nextbutton:hover {
  cursor: pointer;
  background-color: #39B3D7;
}
.fleet-info-can-search__heading {
  color: #1c1c1f;
  margin: -30px;
  margin-left: -100px;
  font-size: 15px;
}

.faq {
  padding: 20px 0;
}
.faq__title {
  font-size: 24px;
  font-family: "corporateacondpro", sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
}
.faq__subtitle {
  font-size: 20px;
  font-family: "corporateacondpro", sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
}
.faq__question {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
  cursor: default;
}
.faq__link {
  font-size: 14px;
}
.faq__list {
  margin-bottom: 15px;
}
.faq__spacing--top {
  padding-top: 20px;
}
.faq__spacing--bottom {
  padding-bottom: 20px;
}
.faq__spacing--both {
  padding-top: 20px;
  padding-bottom: 20px;
}
.faq__panel ul {
  margin: 5px 0;
}

.faq-accordion .faq {
  margin-bottom: 20px;
}
.faq-accordion .faq__list {
  list-style: none;
  margin: 0;
}
.faq-accordion .faq__title {
  font: normal 400 28px/1.2142857143 "corporateacondpro";
  margin-bottom: 10px;
}
@media screen and (min-width: 980px) {
  .faq-accordion .faq__title {
    font: normal 400 30px/1.2666666667 "corporateacondpro";
  }
}
.faq-accordion .faq__question {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
  background-color: #eeeeee;
  color: #444;
  cursor: pointer;
  border: none;
  padding: 20px;
  outline: none;
}
.faq-accordion .faq__question:hover {
  background-color: #cccccc;
}
.faq-accordion .faq__question:before {
  font-family: "micro-icons";
  content: "\e902";
  font-size: 75%;
  padding-top: 2px;
  -webkit-transition: all 0.3s ease-in 0s;
  -moz-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
  float: right;
}
.faq-accordion .faq__icon--down-arrow-key:before {
  content: "\e902";
  padding-top: 0.7%;
}
.faq-accordion .faq__icon--plus:before {
  content: "\e923";
}
.faq-accordion .faq__panel {
  padding: 0 30px;
  background-color: #fff;
  overflow: hidden;
  margin-bottom: 10px;
}
.faq-accordion .faq__panel-content {
  padding: 20px 0px;
  margin-bottom: 10px;
}
.faq-accordion .accordion__active .faq__question:before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.faq-accordion .accordion__active .faq__icon--plus:before {
  transform: none;
  content: "\e922";
}
.faq-accordion .faq-accordion-display--without-background-color .faq__subtitle {
  font: normal 400 13px/1.5384615385 "Arial", Helvetica, Arial, sans-serif;
}
.faq-accordion .faq-accordion-display--without-background-color .faq__question {
  background-color: #fff;
  padding: 0px;
}
.faq-accordion .faq-accordion-display--without-background-color .faq__question:hover {
  background-color: #fff;
}
.faq-accordion .faq-accordion-display--without-background-color .faq__question:before {
  float: left;
  margin-right: 10px;
}
.faq-accordion .faq-accordion-display--without-background-color .faq__panel-content {
  padding: 0;
  font-size: 13px;
}

.back-to-top {
  height: 70px;
  position: relative;
}
.back-to-top__cta {
  background-color: #fff;
  border-radius: 50%;
  bottom: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  display: block;
  height: 50px;
  right: 0;
  opacity: 0;
  pointer-events: auto;
  position: fixed;
  transform: translateX(-50%);
  transition: background-color 0.5s;
  width: 50px;
  z-index: 900;
  color: #222;
  display: none;
  left: 50%;
}
.back-to-top__cta:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}
.back-to-top__cta:hover, .back-to-top__cta:focus {
  animation: none;
  background-color: #e3e3e3;
}
.back-to-top--enabled .back-to-top__cta {
  opacity: 0.75;
  display: block;
}
.back-to-top--enabled .back-to-top__cta:hover, .back-to-top--enabled .back-to-top__cta:focus {
  animation: none;
  background-color: #e3e3e3;
}
.back-to-top--sticky .back-to-top__cta {
  position: absolute;
  z-index: 499;
  display: block;
}

.button-link-list {
  margin-bottom: 20px;
}
.button-link-list__item {
  float: none;
  border-bottom: 1px solid #dddddd;
}
@media screen and (min-width: 768px) {
  .button-link-list__item {
    border-bottom: none;
    float: left;
    border-right: 1px solid #dddddd;
  }
}
.button-link-list__item-container {
  border: 1px solid #dddddd;
  border-radius: 3px;
}
@media screen and (min-width: 768px) {
  .button-link-list__item-container {
    display: inline-block;
  }
}
.button-link-list__item__anchor {
  display: block;
  color: #333;
  text-align: center;
  padding: 5px 10px;
}
.button-link-list__item__anchor:hover {
  background-color: #428bca;
  color: #fff;
}
.button-link-list__item__anchor-disabled {
  color: #999;
}
.button-link-list__item__anchor-disabled:hover {
  background-color: #fff;
  color: #999;
}
.button-link-list__item:last-child {
  border-bottom: none;
}
@media screen and (min-width: 768px) {
  .button-link-list__item:last-child {
    border-right: none;
  }
}
.button-link-list__active .button-link-list__item__anchor {
  color: white;
  background-color: #428bca;
}

.button-matrix__container {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .button-matrix__container {
    align-items: center;
  }
}
.button-matrix__item {
  border: 1px solid #000000;
  margin-bottom: 20px;
  padding: 5px;
  text-align: center;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .button-matrix__item {
    text-align: left;
    margin-left: -1px;
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 31.914893617%;
    margin-right: 0;
  }
  .button-matrix__item:last-child {
    margin-right: 0;
  }
}
.button-matrix__anchor {
  display: block;
  padding: 5px 10px;
}
@media screen and (min-width: 768px) {
  .button-matrix__anchor {
    display: inline-block;
  }
}
.button-matrix__anchor:hover {
  background-color: #428bca;
  color: #fff;
}
.button-matrix__anchor:focus {
  background-color: #428bca;
  color: #fff;
}
.button-matrix__hide {
  display: none;
}

.modal__section {
  font-size: 14px;
  border: 1px solid #dddddd;
  border-radius: 4px;
}
.modal__underline {
  border-bottom: 2px solid #00adef;
  width: 8%;
}
.modal__title {
  padding: 3px 10px;
  font-size: 24px;
  border-bottom: 1px solid #dddddd;
  font-family: "corporateacondpro", sans-serif;
}
.modal__button {
  color: #fff;
  background-color: #3276B1;
  border-color: #285E8E;
  padding: 10px 15px;
  margin: 20px 15px;
  border-style: none;
  cursor: pointer;
  border-radius: 4px;
}

.pop-up {
  display: none;
  font-size: 14px;
  position: fixed;
  padding-top: 80px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
}
.pop-up--open {
  display: block;
}
.pop-up__control {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  width: 100%;
  padding-left: 5px;
}
.pop-up__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.pop-up__control--error, .pop-up__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.pop-up__control--success, .pop-up__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.pop-up__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.pop-up__error-field--hidden {
  display: none;
}
.pop-up__content {
  position: relative;
  background-color: #fff;
  margin: auto;
  padding: 0;
  border-radius: 6px;
  width: 60%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
.pop-up__content-header {
  padding: 5px 20px;
  font-size: 24px;
  font-family: "corporateacondpro", sans-serif;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #3276B1 !important;
  color: #fff;
}
.pop-up__content-close-icon {
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  font-family: "micro-icons";
  position: absolute;
  right: 20px;
  top: 17px;
}
@media screen and (min-width: 768px) {
  .pop-up__content-close-icon {
    top: 15px;
  }
}
.pop-up__content-close-icon:before {
  content: "\e91e";
}
.pop-up__content-close-icon:hover, .pop-up__content-close-icon:focus {
  text-decoration: none;
  cursor: pointer;
  color: #999;
}
.pop-up__content-close-button {
  font-size: 14px;
  white-space: nowrap;
  color: #333;
  background-color: #fff;
  border-style: none;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}
.pop-up__content-close-button:focus {
  border-color: #a3a3a3;
  outline: 0;
}
.pop-up__content-body {
  padding: 10px;
}
@media screen and (min-width: 768px) {
  .pop-up__content-body {
    padding: 20px;
  }
}
.pop-up__content-body img {
  border: 1px solid #666;
  padding: 2px;
  width: 100%;
}
.pop-up__content-body .video {
  position: relative;
  width: 100%;
}
.pop-up__content-body .video__img {
  width: 100%;
}
.pop-up__content-body .video__img {
  opacity: 0;
}
.pop-up__content-body .video__frame {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.pop-up__content-footer {
  border-top: 1px solid #e3e3e3;
  margin-top: 10px;
  padding: 10px;
  text-align: right;
}
@media screen and (min-width: 768px) {
  .pop-up__content-footer {
    margin-top: 15px;
    padding: 20px;
  }
}

.carousel .pop-up__content-header {
  background-color: #fff;
}
.carousel .pop-up__content-close-icon {
  color: #000000;
}

.multi-link-list .link-list {
  margin-bottom: 0px;
}
.multi-link-list .link-list__item:last-child {
  border-bottom: none;
}
.multi-link-list--reverse .link-list__container {
  display: flex;
  flex-direction: column-reverse;
}
.multi-link-list--reverse .link-list__item {
  flex: 0 0 auto;
}

.sitemap__item-container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px 10px 20px;
}
.sitemap__listItems {
  width: 100%;
}
.sitemap__itemLink {
  color: #555555;
  display: block;
  font-size: 12px;
  position: relative;
}
.sitemap__icon {
  font-size: 12px;
  left: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.sitemap__icon-list {
  display: none;
}
.sitemap__icon-nav {
  font-size: 6px;
  left: -10px;
  top: 6px;
}
.sitemap__container {
  margin-left: 1px;
}
.sitemap__container:after {
  clear: both;
  display: table;
  content: " ";
}
.sitemap__container > .sitemap__item-container {
  padding: 0;
}
.sitemap__container > .sitemap__item-container > .sitemap__listItems {
  margin-left: -1px;
  margin-top: -1px;
  width: calc(33.33% + 1px);
}
.sitemap__container > .sitemap__item-container > .sitemap__listItems > .sitemap__itemLink {
  color: #333;
  font: normal 400 18px/1 "corporateacondpro";
  padding: 10px;
}
.sitemap__container > .sitemap__item-container > .sitemap__listItems > .sitemap__itemLink .sitemap__icon-nav {
  display: none;
}
.sitemap__container > .sitemap__item-container > .sitemap__listItems > .sitemap__item-container > .sitemap__listItems > .sitemap__itemLink {
  color: #176db7;
  font-size: 14px;
  font-weight: bold;
}
.sitemap__container > .sitemap__item-container > .sitemap__listItems > .sitemap__item-container > .sitemap__listItems > .sitemap__itemLink .sitemap__icon-nav {
  display: none;
}
.sitemap__container > .sitemap__item-container > .sitemap__listItems > .sitemap__item-container > .sitemap__listItems > .sitemap__item-container > .sitemap__listItems > .sitemap__itemLink {
  font-size: 14px;
}
.sitemap__container > .sitemap__item-container > .sitemap__listItems > .sitemap__item-container > .sitemap__listItems > .sitemap__item-container > .sitemap__listItems > .sitemap__itemLink .sitemap__icon-nav {
  top: 8px;
}
.sitemap-col-1 > .sitemap__item-container > .sitemap__listItems {
  width: 100%;
}
.sitemap-col-2 > .sitemap__item-container > .sitemap__listItems {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .sitemap-col-2 > .sitemap__item-container > .sitemap__listItems {
    width: calc(50% + 1px);
  }
}
.sitemap-col-2.sitemap-separator > .sitemap__item-container > .sitemap__listItems {
  width: 100%;
}
.sitemap-col-2.sitemap-separator > .sitemap__item-container > .sitemap__listItems:nth-child(2n) {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .sitemap-col-2.sitemap-separator > .sitemap__item-container > .sitemap__listItems {
    margin-right: 2%;
    width: 49%;
  }
}
.sitemap-col-2.sitemap-border > .sitemap__item-container > .sitemap__listItems {
  width: 100%;
}
.sitemap-col-2.sitemap-border > .sitemap__item-container > .sitemap__listItems:nth-child(2n) {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .sitemap-col-2.sitemap-border > .sitemap__item-container > .sitemap__listItems {
    margin-right: 0%;
    width: calc(50% + 1px);
  }
}
.sitemap-col-3 > .sitemap__item-container > .sitemap__listItems {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .sitemap-col-3 > .sitemap__item-container > .sitemap__listItems {
    width: calc(33.33% + 1px);
  }
}
.sitemap-col-3.sitemap-separator > .sitemap__item-container > .sitemap__listItems {
  width: 100%;
}
.sitemap-col-3.sitemap-separator > .sitemap__item-container > .sitemap__listItems:nth-child(3n) {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .sitemap-col-3.sitemap-separator > .sitemap__item-container > .sitemap__listItems {
    margin-right: 2%;
    width: 32%;
  }
}
.sitemap-col-3.sitemap-border > .sitemap__item-container > .sitemap__listItems {
  width: 100%;
}
.sitemap-col-3.sitemap-border > .sitemap__item-container > .sitemap__listItems:nth-child(2n) {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .sitemap-col-3.sitemap-border > .sitemap__item-container > .sitemap__listItems {
    margin-right: 0%;
    width: calc(33.33% + 1px);
  }
}
.sitemap-border > .sitemap__item-container > .sitemap__listItems {
  border: 1px solid #000000;
}
.sitemap-border > .sitemap__item-container > .sitemap__listItems > .sitemap__itemLink {
  position: relative;
}
.sitemap-border > .sitemap__item-container > .sitemap__listItems > .sitemap__itemLink:after {
  background: #000000;
  bottom: -1px;
  content: " ";
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}
.sitemap-separator .sitemap__item-container {
  padding: 0 0 0 25px;
}
.sitemap-separator .sitemap__listItems {
  border-top: 1px solid #dddddd;
  width: 100%;
}
.sitemap-separator .sitemap__itemLink {
  color: #555555;
  font: normal 400 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
  padding: 10px 20px 10px 0;
}
.sitemap-separator .sitemap__icon-nav {
  display: block;
  left: auto;
  right: 5px;
  top: 15px;
  font-size: 12px;
}
.sitemap-separator > .sitemap__item-container {
  padding: 0;
}
.sitemap-separator > .sitemap__item-container > .sitemap__listItems > .sitemap__itemLink {
  font: normal 400 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
  padding: 10px 20px 10px 10px;
}
.sitemap-separator > .sitemap__item-container > .sitemap__listItems > .sitemap__itemLink .sitemap__icon-nav {
  display: block;
}
.sitemap-separator > .sitemap__item-container > .sitemap__listItems > .sitemap__item-container > .sitemap__listItems > .sitemap__itemLink {
  color: #555555;
  font: normal 400 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.sitemap-separator > .sitemap__item-container > .sitemap__listItems > .sitemap__item-container > .sitemap__listItems > .sitemap__itemLink .sitemap__icon-nav {
  display: block;
  left: auto;
  right: 5px;
  top: 15px;
  font-size: 12px;
}
.sitemap-separator > .sitemap__item-container > .sitemap__listItems > .sitemap__item-container > .sitemap__listItems > .sitemap__item-container > .sitemap__listItems > .sitemap__itemLink {
  font: normal 400 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.sitemap-separator > .sitemap__item-container > .sitemap__listItems > .sitemap__item-container > .sitemap__listItems > .sitemap__item-container > .sitemap__listItems > .sitemap__itemLink .sitemap__icon-nav {
  top: 15px;
}
.sitemap-separator.sitemap-border > .sitemap__item-container > .sitemap__listItems {
  border: 1px solid #000000;
  margin-right: 0;
}
.sitemap-icon.sitemap-border > .sitemap__item-container > .sitemap__listItems > .sitemap__itemLink {
  padding-left: 30px;
}
.sitemap-icon.sitemap-border > .sitemap__item-container > .sitemap__listItems > .sitemap__itemLink .sitemap__icon-list {
  display: block;
  left: 5px;
}
.sitemap-icon.sitemap-separator .sitemap__icon-list {
  display: block;
}
.sitemap-icon.sitemap-separator .sitemap__itemLink {
  padding-left: 30px;
}
.sitemap-icon.sitemap-separator > .sitemap__item-container > .sitemap__listItems > .sitemap__itemLink {
  padding-left: 30px;
}
.sitemap-icon.sitemap-separator > .sitemap__item-container > .sitemap__listItems > .sitemap__itemLink .sitemap__icon-list {
  left: 10px;
}
.sitemap-icon > .sitemap__item-container > .sitemap__listItems > .sitemap__itemLink {
  padding-left: 30px;
}
.sitemap-icon > .sitemap__item-container > .sitemap__listItems > .sitemap__itemLink .sitemap__icon-list {
  display: block;
  left: 5px;
}

.carousel {
  position: relative;
}
.carousel__slides {
  position: relative;
  overflow: hidden;
  line-height: 0;
}
.carousel__slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  overflow: hidden;
  list-style: none;
  transition: opacity ease 0.975s;
  z-index: 498;
}
.carousel__slide--active {
  height: auto;
  opacity: 1;
  position: relative;
  z-index: 499;
}
@media screen and (min-width: 768px) and (-ms-high-contrast: active), screen and (min-width: 768px) and (-ms-high-contrast: none) {
  .carousel {
    overflow-x: hidden;
  }
}
@media screen and (min-width: 768px) {
  @supports (-ms-ime-align: auto) {
    .carousel {
      overflow-x: hidden;
    }
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .carousel:hover .carousel-nav--sliding .carousel-nav__buttons,
  .carousel:hover .carousel-nav--overlay .carousel-nav__buttons {
    opacity: 1;
  }
}

/**
  Styling for a CarouselNavigation template
 */
.carousel-nav--inactive {
  display: none;
}
.carousel-nav--bottom {
  margin-top: 15px;
}
.carousel-nav--bottom-left {
  text-align: left;
}
.carousel-nav--bottom-right {
  text-align: right;
}
.carousel-nav__buttons {
  line-height: 1;
}
.carousel-nav--bottom .carousel-nav__buttons {
  display: inline-block;
}
.carousel-nav--overlay .carousel-nav__buttons, .carousel-nav--sliding .carousel-nav__buttons {
  display: none;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 0;
  width: 98%;
  z-index: 503;
}
@media screen and (min-width: 768px) {
  .carousel-nav--overlay .carousel-nav__buttons, .carousel-nav--sliding .carousel-nav__buttons {
    display: flex;
  }
}
.carousel-nav--show-small .carousel-nav__buttons {
  display: flex;
}
@media screen and (min-width: 768px) {
  .carousel-nav--sliding .carousel-nav__buttons {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
}
@media screen and (min-width: 768px) {
  .carousel-nav--overlay .carousel-nav__buttons {
    opacity: 1;
  }
}
.carousel-nav__button {
  color: #176db7;
  cursor: pointer;
  font-size: 20px;
  line-height: 1;
  margin-right: 5px;
}
.carousel-nav--overlay .carousel-nav__button, .carousel-nav--sliding .carousel-nav__button {
  color: #fff;
  height: 30px;
  font-size: 30px;
  text-shadow: 1px 1px 5px #666;
}
.carousel-nav__button:last-child {
  margin-right: 0;
}
.carousel-nav__button--inactive {
  color: #c7c7c7;
  cursor: auto;
}
.carousel-nav--overlay .carousel-nav__button--inactive, .carousel-nav--sliding .carousel-nav__button--inactive {
  visibility: hidden;
}
.carousel-nav__button--next:before {
  content: "\e91a";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
}
.carousel-nav__button--prev:before {
  content: "\e915";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
}
.carousel-nav__pages {
  font: normal 700 13px/1 "Arial", Helvetica, Arial, sans-serif;
  color: #666;
  display: inline-block;
  margin: 0 10px 0 0;
}
.carousel-nav--overlay .carousel-nav__pages, .carousel-nav--sliding .carousel-nav__pages {
  display: none;
}
.carousel-nav__indicator {
  position: absolute;
  width: 100%;
  top: auto;
  bottom: 0;
  z-index: 503;
}
.carousel-nav--overlay-bottom .carousel-nav__indicator {
  position: absolute;
  width: 100%;
  bottom: -10px;
  z-index: 503;
}
.carousel-nav__indicator-list {
  text-align: center;
  padding-bottom: 10px;
}
.carousel-nav__indicator-list > li {
  display: inline-block;
}
.carousel-nav__indicator-dot {
  display: inline-block;
  cursor: pointer;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background: #fff;
  border-radius: 50%;
  outline: 0;
  box-shadow: 1px 1px 1px #999;
}
.carousel-nav__indicator-dot--selected {
  background: #0090ff !important;
}
.carousel-nav__indicator-dot-grey {
  display: inline-block;
  cursor: pointer;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background: grey;
  border-radius: 50%;
  outline: 0;
  box-shadow: 1px 1px 1px #999;
}
.carousel-nav__indicator-dot-grey--selected {
  background: #0090ff !important;
}

.grid-content__loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 10;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.contact-form__section {
  display: block;
}
.contact-form__section .pop-up {
  padding-top: 150px;
}
.contact-form__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.contact-form__section .pop-up__content-body {
  background: #fff;
}
.contact-form__block {
  background-color: #F5F5F5;
  border: 1px solid #e3e3e3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 20px;
}
.contact-form__title {
  border-bottom: 1px solid #dddddd;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}
.contact-form__group {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
  margin-bottom: 15px;
}
.contact-form__group:last-child {
  margin-right: 0;
}
.contact-form__label {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 31.914893617%;
  padding-left: 5px;
  padding-top: 7px;
  text-align: right;
  margin-right: 5px;
  font-size: 14px;
  font-weight: bold;
}
.contact-form__label:last-child {
  margin-right: 0;
}
.contact-form__input-group-container {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 48.9361702128%;
  padding: 5px 5px 0 5px;
}
.contact-form__input-group-container:last-child {
  margin-right: 0;
}
.contact-form__input-group {
  display: table;
  font-size: 14px;
}
.contact-form__icon {
  background: #eeeeee;
  border: 1px solid #cccccc;
  border-right: 0;
  border-radius: 5px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  display: table-cell;
  padding: 5px 10px;
  vertical-align: middle;
  width: 1%;
}
.contact-form__control {
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  display: table-cell;
  padding: 6px 10px;
  float: left;
  width: 100%;
  font-family: "Arial";
}
.contact-form__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.contact-form__control--error, .contact-form__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.contact-form__control--success, .contact-form__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.contact-form__control-container {
  display: inline-block;
}
.contact-form__asterisk {
  color: red;
}
.contact-form__has-error .contact-form__label {
  color: #e23a39;
}
.contact-form__has-error .contact-form__control {
  border-color: #e23a39;
}
.contact-form__has-error .contact-form__icon {
  background-color: rgba(226, 58, 57, 0.1);
  border-color: #e23a39;
  color: #e23a39;
}
.contact-form__help-block {
  color: #e23a39;
}
.contact-form__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.contact-form__error-field--hidden {
  display: none;
}
.contact-form__section {
  display: block;
}
.contact-form__section .pop-up {
  padding-top: 150px;
}
.contact-form__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.contact-form__section .pop-up__content-body {
  background: #fff;
}

.training-course {
  text-align: left;
}
.training-course__table {
  border: 1px solid #000000;
  border-collapse: collapse;
  font-size: 14px;
  width: 100%;
}
.training-course__table-title {
  font: normal 400 24px/1.1666666667 "corporateacondpro";
  margin-bottom: 10px;
}
.training-course__table-row {
  text-align: left;
}
.training-course__table-col {
  border: 1px solid #000000;
  padding: 10px;
  vertical-align: middle;
}
.training-course__table-col:nth-child(1) {
  width: 25%;
}
.training-course__table-col:nth-child(2) {
  width: 50%;
}
.training-course__table-col:nth-child(3) {
  width: 25%;
}
.training-course__table-col-title {
  font-weight: bold;
}
.training-course__table-title {
  text-align: left;
}
.training-course__table-content {
  text-align: left;
}

.text-desc-link-list {
  margin-bottom: 20px;
}
.text-desc-link-list__title {
  font-size: 24px;
  font-family: "corporateacondpro", sans-serif;
  font-weight: 500;
  padding-top: 20px;
  padding-bottom: 10px;
}
.text-desc-link-list__content-container {
  padding: 0px 10px;
}
.text-desc-link-list__description {
  font-size: 14px;
  margin-bottom: 10px;
  padding: 10px;
}
.text-desc-link-list__description ol {
  list-style: decimal;
}
.text-desc-link-list__description ul {
  list-style: disc;
}
.text-desc-link-list__link {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 10px;
}
.text-desc-link-list__link-anchor {
  color: #5a5a5a;
}
.text-desc-link-list__link-anchor:hover {
  color: #fff;
}
.text-desc-link-list__link:hover {
  background-color: #428bca;
}

.alert-box {
  font-size: 14px;
  font-family: "Arial", Helvetica, Arial, sans-serif;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.alert-box__container {
  padding: 10px;
}
.alert-box__title {
  font-weight: 700;
}
.alert-box__close-icon {
  float: right;
  font-family: "micro-icons";
  cursor: pointer;
  margin-top: 10px;
  margin-right: 10px;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
}
.alert-box__close-icon:hover {
  color: #000000;
  opacity: 0.5;
}
.alert-box__close-icon:before {
  content: "\e91e";
}
.alert-box--success {
  background-color: #dff0d8;
  border-color: #d0e9c6;
}
.alert-box--success .alert-box__title {
  color: #3c763d;
}
.alert-box--info {
  background-color: #d9edf7;
  border-color: #bcdff1;
}
.alert-box--info .alert-box__title {
  color: #31708f;
}
.alert-box--warning {
  background-color: #fcf8e3;
  border-color: #faf2cc;
}
.alert-box--warning .alert-box__title {
  color: #8a6d3b;
}
.alert-box--danger {
  background-color: #f2dede;
  border-color: #ebcccc;
}
.alert-box--danger .alert-box__title {
  color: #a94442;
}
.alert-box--close {
  display: none;
}

.favorites-list {
  font-size: 14px;
}
.favorites-list__container {
  margin-top: 10px;
}
.favorites-list__item-anchor {
  color: #428bca;
  cursor: pointer;
}
.favorites-list__item-anchor:hover {
  color: #00A6C9;
}

.alphabetical-search {
  font-size: 13px;
  margin-bottom: 20px;
}
.alphabetical-search__heading {
  font: normal 700 13px/1.5384615385 "Arial", Helvetica, Arial, sans-serif;
  text-decoration: underline;
  margin-bottom: 10px;
}
.alphabetical-search__anchor {
  color: #428bca;
}
.alphabetical-search__anchor:hover {
  color: #00A6C9;
}
.alphabetical-search__column {
  border: 1px solid #000000;
  text-align: center;
  vertical-align: middle;
}
.alphabetical-search__column:first-child {
  width: 20%;
  padding: 15px;
}
.alphabetical-search__column:first-child:nth-last-child(3), .alphabetical-search__column:first-child:nth-last-child(3) ~ .alphabetical-search__column {
  width: 33.3333%;
}
.alphabetical-search__heading-column {
  font-weight: 700;
  border: 1px solid #000000;
  padding: 15px;
  text-align: center;
}
.alphabetical-search__index-anchor {
  color: #428bca;
  text-decoration: underline;
}
.alphabetical-search__index-anchor:hover {
  color: #00A6C9;
}
.alphabetical-search__table-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
@media screen and (min-width: 980px) {
  .alphabetical-search__table-container {
    overflow: visible;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .alphabetical-search__table-container {
    overflow: auto;
  }
}
.alphabetical-search__index {
  margin-bottom: 20px;
}
.alphabetical-search__index__column {
  border: 1px solid #000000;
  padding: 10px;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  min-width: 34px;
}
.alphabetical-search__index--disabled {
  pointer-events: none;
  text-decoration: none;
  color: #5a5a5a;
}
.alphabetical-search__column-new {
  border: 1px solid #000000;
  text-align: center;
  vertical-align: middle;
}
.alphabetical-search__column-new:first-child {
  width: 10%;
}
.alphabetical-search__column-new:nth-last-child(2) {
  width: 50%;
}
.alphabetical-search__column-new:nth-last-child(3) {
  width: 30%;
}
.alphabetical-search__column-new:nth-last-child(3):nth-last-child(4), .alphabetical-search__column-new:nth-last-child(3):nth-last-child(4) ~ .alphabetical-search__column-new {
  width: 25%;
}
.alphabetical-search__column-new-last {
  border: 1px solid #000000;
  text-align: center;
  vertical-align: middle;
}
.alphabetical-search__column-new-last:first-child {
  width: 20%;
}
.alphabetical-search__column-new-last:nth-last-child(2) {
  width: 12%;
}
.alphabetical-search__column-new-last:nth-last-child(3) {
  width: 30%;
}
.alphabetical-search__column-new-last:nth-last-child(4) {
  width: 30%;
}
.alphabetical-search__column-new-last:nth-last-child(5) {
  width: 10%;
}
.alphabetical-search__column-new-last:nth-last-child(5):nth-last-child(6), .alphabetical-search__column-new-last:nth-last-child(5):nth-last-child(6) ~ .alphabetical-search__column-new-last {
  width: 25%;
}
.alphabetical-search__star-icon {
  visibility: hidden;
  cursor: pointer;
  font-size: 30px;
  content: "☆";
  height: 50px;
  width: 50px;
  text-align: center;
  font-size: 30px;
  padding: 0 1rem;
}
.alphabetical-search__star-icon:before {
  content: "☆";
  visibility: visible;
  cursor: pointer;
}
.alphabetical-search__star-red {
  visibility: hidden;
  cursor: pointer;
  font-size: 30px;
  content: "☆";
  height: 50px;
  width: 50px;
  text-align: center;
  font-size: 30px;
  padding: 0 1rem;
}
.alphabetical-search__star-red:after {
  content: "★";
  color: red;
  visibility: visible;
  cursor: pointer;
}
.alphabetical-search__remove-icon {
  cursor: pointer;
  visibility: hidden;
  content: "✖";
  color: red;
  font-size: 24px;
  height: 24.5px;
}
.alphabetical-search__remove-icon:before {
  cursor: pointer;
  content: "✖";
  visibility: visible;
}

.dropdown {
  margin: 10px 0px;
  width: 100%;
}
.dropdown:after {
  content: "";
  clear: both;
  display: table;
}
.dropdown-container {
  position: relative;
  display: inline-block;
  float: right;
}
.dropdown__button {
  color: #333;
  font-size: 14px;
  background-color: #fff;
  padding: 9px 10px;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
}
.dropdown__button:hover {
  outline: 0;
  background-color: #eeeeee;
  border: 1px solid #a3a3a3;
}
.dropdown-active {
  background-color: #eeeeee;
  border: 1px solid #a3a3a3;
}
.dropdown__content {
  display: none;
  position: absolute;
  min-width: 160px;
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.175);
  z-index: 1;
  background: #fff;
  right: 0px;
  white-space: nowrap;
}
.dropdown__content-anchor {
  font-size: 14px;
  color: #333;
  padding: 5px 15px;
  text-decoration: none;
  display: block;
}
.dropdown__content-anchor:hover {
  background-color: #F5F5F5;
  color: #333;
}
.dropdown-show {
  display: block;
}
.dropdown__feedback-icon {
  font-family: "micro-icons";
  font-size: 15px;
}
.dropdown__feedback-icon:before {
  content: "\e94a";
}
.dropdown__caret-icon {
  font-family: "micro-icons";
  font-size: 10px;
  margin-left: 5px;
}
.dropdown__caret-icon:before {
  content: "\e93d";
}
.dropdown__star-icon {
  font-family: "micro-icons";
  font-size: 14px;
}
.dropdown__star-icon:before {
  content: "\e93f";
}
.dropdown__print-icon {
  font-family: "micro-icons";
}
.dropdown__print-icon:before {
  content: "\e93e";
}
.dropdown__margin-left {
  margin-left: 1px;
}

.print-dropdown .dropdown__button:hover {
  border: 1px solid #428bca;
  border-radius: 4px;
  background-color: #428bca;
  color: #fff;
}
.print-dropdown .dropdown-active {
  border: 1px solid #428bca;
  background-color: #428bca;
  color: #fff;
}

@media print {
  .col-3, .dropdown, .hero-image, .warranty-categories, .sub-nav__level--left, .sub-nav__level--right {
    display: none;
  }
  body, .top-nav__item--heading, .breadcrumb__link, .favorites-list__item-anchor, .active {
    color: #000000;
  }
  a[href]:after {
    content: " (" attr(href) ")";
    color: #000000;
  }
  .top-nav__link[href]:after {
    content: none;
  }
  .sub-nav__level--right {
    float: right;
    width: auto;
  }
  .top-nav__print {
    display: block;
  }
  .top-nav__print__title__container {
    font-size: 24px;
    font-family: "corporateacondpro", sans-serif;
    color: #000000;
    display: inline-block;
    float: right;
  }
  .top-nav__print__logo__container {
    display: inline-block;
  }
  .button-link-list {
    display: none;
  }
}
.search-nav__container {
  box-shadow: 0px 0px 10px #000000;
  font-size: 12px;
  height: 48px;
  max-width: 1000px;
  position: relative;
  padding: 8px 10px;
  width: 100%;
  border-radius: 4px;
  top: 0%;
  background: #000000;
}
.search-nav__input-group {
  display: flex;
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.search-nav__input-group:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .search-nav__input-group {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 40.4255319149%;
  }
  .search-nav__input-group:last-child {
    margin-right: 0;
  }
}
.search-nav__input {
  width: 75%;
  padding: 8px 0px 8px 15px;
  float: left;
  color: #000000;
}
@media screen and (min-width: 980px) {
  .search-nav__input {
    width: 80%;
  }
}
.search-nav__input:focus {
  border-color: #66AFE9;
}
.search-nav__button {
  color: #fff;
  background-color: #5bc0de;
  padding: 5px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
}
.search-nav__button:hover {
  cursor: pointer;
  background-color: #39B3D7;
}
.search-nav__button-disabled {
  color: #fff;
  background-color: #5bc0de;
  padding: 5px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
}
.search-nav__loader {
  position: relative;
  z-index: 1;
  width: 50px;
  height: 50px;
  margin: auto;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid black;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.search-nav__loader-text {
  text-shadow: 0 0 black;
  text-align: center;
}

.search-nav-brc__container {
  font-size: 12px;
  height: 48px;
  max-width: 1000px;
  position: relative;
  padding: 8px 10px;
  border-radius: 4px;
  top: 0%;
  display: inline-flex;
  margin-bottom: 10px;
  place-content: flex-end;
  margin-left: -10px;
}
.search-nav-brc__containers {
  font-size: 12px;
  height: 48px;
  max-width: 1000px;
  position: relative;
  display: inline-flex;
  padding: 8px 10px;
  border-radius: 4px;
  top: 0%;
  margin-bottom: 10px;
  margin-left: 65%;
}
@media screen and (max-width: 767px) {
  .search-nav-brc__containers {
    margin-left: 0;
  }
}
.search-nav-brc__contained {
  font-size: 12px;
  height: 48px;
  max-width: 1000px;
  position: relative;
  display: inline-flex;
  padding: 8px 10px;
  width: 100%;
  border-radius: 4px;
  top: 0%;
  margin-bottom: 10px;
  margin-left: 70%;
}
.search-nav-brc__input-group {
  width: 100%;
  justify-content: flex-end;
  display: inline-flex;
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.search-nav-brc__input-group:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .search-nav-brc__input-group {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 40.4255319149%;
    width: 100%;
  }
  .search-nav-brc__input-group:last-child {
    margin-right: 0;
  }
}
.search-nav-brc__input {
  width: auto;
  padding: 8px 0px 8px 15px;
  float: left;
  color: #000000;
}
.search-nav-brc__input:focus {
  border-color: #66AFE9;
}
.search-nav-brc__button {
  color: #fff;
  background-color: #5bc0de;
  padding: 5px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
}
.search-nav-brc__button:hover {
  cursor: pointer;
  background-color: #39B3D7;
}

.fleet-info-can-search {
  position: sticky;
  top: 0;
  background-color: #ebeef1;
  padding: 0px 0px 0px 130px;
  font-size: 20px;
  z-index: 5;
}
.fleet-info-can-search__contained {
  font-size: 12px;
  height: 48px;
  max-width: 1000px;
  position: relative;
  display: inline-flex;
  width: 100%;
  border-radius: 4px;
  top: 0%;
  margin-bottom: 10px;
  margin-left: 62.5%;
}
.fleet-info-can-search__input-group {
  display: inline-flex;
  width: 50%;
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.fleet-info-can-search__input-group:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .fleet-info-can-search__input-group {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 40.4255319149%;
  }
  .fleet-info-can-search__input-group:last-child {
    margin-right: 0;
  }
}
.fleet-info-can-search__input {
  width: auto;
  padding: 6px 0px 8px 15px;
  float: left;
  color: #000000;
}
.fleet-info-can-search__input:focus {
  border-color: #66AFE9;
}
.fleet-info-can-search__button {
  color: #fff;
  background-color: #5bc0de;
  padding: 4px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
}
.fleet-info-can-search__button:hover {
  cursor: pointer;
  background-color: #39B3D7;
}
.fleet-info-can-search__nextbutton {
  color: #fff;
  background-color: #5bc0de;
  padding: 5px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
}
.fleet-info-can-search__nextbutton:hover {
  cursor: pointer;
  background-color: #39B3D7;
}
.fleet-info-can-search__heading {
  color: #1c1c1f;
  margin: -30px;
  margin-left: -100px;
  font-size: 15px;
}

.pagination {
  font-size: 14px;
}
.pagination__index {
  display: inline-block;
  margin: 20px 0 10px 0;
}
.pagination__index--anchor {
  color: #428bca;
  float: left;
  padding: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #dddddd;
  width: 40px;
  text-align: center;
  margin-bottom: 10px;
}
.pagination__index--anchor:hover {
  color: #285E8E;
  background-color: #eeeeee;
  border-color: #dddddd;
  cursor: pointer;
}
.pagination__index--anchor:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.pagination__index--anchor:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.pagination__index--anchor-hidden {
  display: none;
}
.pagination__index--active {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.pagination__index--active:hover {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.pagination__index--disabled {
  pointer-events: none;
  color: grey;
  cursor: default;
}
.pagination__index--disabled:hover, .pagination__index--disabled:focus {
  color: grey;
  cursor: default;
  border-color: none;
  background-color: #fff;
}
.pagination__content {
  display: block;
}
.pagination__content--anchor {
  color: #555555;
}
.pagination__content--anchor:hover {
  color: white;
}
.pagination__content--anchor:hover .pagination__content-container {
  background-color: #428bca;
}
.pagination__content__title {
  font-size: 18px;
  font-weight: 600;
  font-family: "corporateacondpro";
}
.pagination__content--hidden {
  display: none;
}
.pagination__content--not-found {
  font-size: 18px;
  font-weight: 700;
}
.pagination__content-container {
  padding: 10px;
  border: 1px solid #dddddd;
}

.article-module {
  color: #333;
}
.article-module__section-header {
  font: normal 400 24px/1.1666666667 "corporateacondpro";
  text-align: center;
  padding: 10px 0px;
}
@media screen and (min-width: 768px) {
  .article-module__section-header {
    font-size: 34px;
  }
}
.article-module__content {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  padding: 10px 10px 10px 25px;
  box-shadow: 3px 3px 5px 0 #c7c7c7;
  min-height: 250px;
}
.article-module__content:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .article-module__content {
    width: 25%;
    margin: 0px;
    margin-bottom: 15px;
  }
}
.article-module__content-icon {
  font-family: "micro-icons";
  position: absolute;
  right: 0;
}
.article-module__content-icon:before {
  content: "\e947";
}
.article-module__content-heading {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  font-size: 16px;
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 82.9787234043%;
  margin: 10px 0px;
}
.article-module__content-heading:last-child {
  margin-right: 0;
}
.article-module__content-link {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 6.3829787234%;
  float: right;
  font-size: 22px;
  display: inline-block;
  position: relative;
}
.article-module__content-link:last-child {
  margin-right: 0;
}
.article-module__content-icon {
  color: #D0011B;
}
.article-module__content-desc {
  display: inline-block;
  margin-right: 20px;
  font: normal 400 12px/1.8333333333 "Arial", Helvetica, Arial, sans-serif;
}
@media screen and (min-width: 980px) {
  .article-module__content-desc {
    font-size: 14px;
  }
}
.article-module--border-bottom {
  border-bottom: 1px solid #999;
}

.tab__list--active {
  color: #176db7;
}
.tab__content {
  display: none;
}
.tab__content--active {
  display: block;
}

.tabbed-module__container {
  padding: 20px 0;
}
.tabbed-module__container.separator--top {
  border-top: 1px solid #c7c7c7;
}
.tabbed-module__container.separator--bottom {
  border-bottom: 1px solid #c7c7c7;
}
.tabbed-module__container.separator--both {
  border-top: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
}
.tabbed-module__container .video {
  position: relative;
  width: 100%;
}
.tabbed-module__container .video__img {
  width: 100%;
}
.tabbed-module__container .video__img {
  opacity: 0;
}
.tabbed-module__container .video__frame {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.tabbed-module__heading {
  font: normal 400 32px/1 "corporateacondpro";
  font-family: "corporateacondpro", sans-serif;
}
.tabbed-module__sub-heading {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}
.tabbed-module__description {
  font-size: 14px;
}
.tabbed-module__tab {
  margin: 10px;
  display: block;
}
.tabbed-module__tab-container {
  display: block;
  clear: both;
  flex: 0 0 auto;
  -ms-flex: 0 0 auto;
}
.tabbed-module__tab-links {
  text-align: left;
}
.tabbed-module__tab-links:hover {
  cursor: pointer;
  color: #176db7;
}
.tabbed-module__tab-list {
  display: block;
  padding: 5px 15px;
}
@media screen and (min-width: 768px) {
  .tabbed-module__tab-list {
    display: inline-block;
  }
}
.tabbed-module__tab-tab-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.tabbed-module__tab--right {
  text-align: right;
}
.tabbed-module__tab--bottom {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
}
.tabbed-module__tab--bottom .tabbed-module__tab-content {
  margin-top: 10px;
}
.tabbed-module__content-container {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  line-height: 20px;
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
  padding: 20px;
}
.tabbed-module__content-container:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .tabbed-module__content-container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 65.9574468085%;
  }
  .tabbed-module__content-container:last-child {
    margin-right: 0;
  }
}
.tabbed-module__content-container a {
  font-size: 12px;
  color: #176db7;
}
.tabbed-module__content-container a:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #23527C;
}
.tabbed-module__content-container h1 {
  font: normal 400 24px/1.1666666667 "corporateacondpro";
  margin: 10px 0px;
}
.tabbed-module__content-container p {
  margin-bottom: 10px;
}
.tabbed-module__content-container img {
  width: 100%;
}
.tabbed-module__content-container td {
  vertical-align: top;
}
.tabbed-module__content-container ol {
  list-style: decimal;
}
.tabbed-module__content-container ul {
  list-style: disc;
}
.tabbed-module__content-heading {
  font-size: 22px;
  font-family: "corporateacondpro", sans-serif;
  margin-bottom: 15px;
}
.tabbed-module__content-description {
  margin-top: 10px;
}
.tabbed-module__media-container {
  flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
  margin-bottom: 20px;
}
.tabbed-module__media-container:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .tabbed-module__media-container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 31.914893617%;
    margin-left: 0%;
    margin-bottom: 0px;
  }
  .tabbed-module__media-container:last-child {
    margin-right: 0;
  }
}
.tabbed-module__media-heading {
  font: normal 400 24px/1.1666666667 "corporateacondpro";
  font-size: 22px;
  margin-bottom: 15px;
}
.tabbed-module__media_item {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.tabbed-module__media_item:nth-child(2n) {
  margin-right: 2.1276595745%;
}
.tabbed-module__media_item:nth-child(2n+1) {
  clear: none;
}
.tabbed-module__media_item:last-child {
  margin-right: 0;
}
.tabbed-module__media_title {
  font: normal 400 18px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.tabbed-module__media_description {
  margin: 5px 0 15px;
  font-size: 14px;
}
.tabbed-module__media--top .tab__content--active {
  display: flex;
  flex-direction: column;
}
.tabbed-module__media--bottom .tab__content--active {
  display: flex;
  flex-direction: column-reverse;
}
@media screen and (min-width: 768px) {
  .tabbed-module__media--top .tabbed-module__media-container, .tabbed-module__media--bottom .tabbed-module__media-container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 65.9574468085%;
    margin-left: 17.0212765957%;
    margin-bottom: 15px;
  }
  .tabbed-module__media--top .tabbed-module__media-container:last-child, .tabbed-module__media--bottom .tabbed-module__media-container:last-child {
    margin-right: 0;
  }
  .tabbed-module__media--top .tabbed-module__content-container, .tabbed-module__media--top .tabbed-module__tab, .tabbed-module__media--bottom .tabbed-module__content-container, .tabbed-module__media--bottom .tabbed-module__tab {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 65.9574468085%;
    margin-left: 17.0212765957%;
  }
  .tabbed-module__media--top .tabbed-module__content-container:last-child, .tabbed-module__media--top .tabbed-module__tab:last-child, .tabbed-module__media--bottom .tabbed-module__content-container:last-child, .tabbed-module__media--bottom .tabbed-module__tab:last-child {
    margin-right: 0;
  }
  .tabbed-module__media--top .tabbed-module--only-content .tabbed-module__content-container, .tabbed-module__media--bottom .tabbed-module--only-content .tabbed-module__content-container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 65.9574468085%;
  }
  .tabbed-module__media--top .tabbed-module--only-content .tabbed-module__content-container:last-child, .tabbed-module__media--bottom .tabbed-module--only-content .tabbed-module__content-container:last-child {
    margin-right: 0;
  }
}
.tabbed-module__media--right .tab__content--active {
  display: flex;
  flex-direction: column-reverse;
}
@media screen and (min-width: 768px) {
  .tabbed-module__media--right .tab__content--active {
    flex-direction: row-reverse;
  }
  .tabbed-module__media--right .tabbed-module--only-media {
    flex-direction: row;
  }
  .tabbed-module__media--right .tabbed-module__media-container {
    margin: 0px;
  }
  .tabbed-module__media--right .tabbed-module__content-container {
    margin-right: auto;
  }
}
@media screen and (min-width: 768px) {
  .tabbed-module--only-content .tabbed-module__content-container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 100%;
  }
  .tabbed-module--only-content .tabbed-module__content-container:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .tabbed-module--only-content .tabbed-module__tab {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 100%;
  }
  .tabbed-module--only-content .tabbed-module__tab:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .tabbed-module--only-media .tabbed-module__media-container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 65.9574468085%;
    margin-left: 17.0212765957%;
  }
  .tabbed-module--only-media .tabbed-module__media-container:last-child {
    margin-right: 0;
  }
}
.tabbed-module--border-bottom {
  border-bottom: 1px solid #999;
}

@media screen and (min-width: 768px) {
  .tabbed-module-with-multiple-media .tabbed-module__media--top .tabbed-module--only-media .tabbed-module__media_item, .tabbed-module-with-multiple-media .tabbed-module__media--bottom .tabbed-module--only-media .tabbed-module__media_item {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 65.9574468085%;
    margin-left: 17.0212765957%;
  }
  .tabbed-module-with-multiple-media .tabbed-module__media--top .tabbed-module--only-media .tabbed-module__media_item:nth-child(2n), .tabbed-module-with-multiple-media .tabbed-module__media--bottom .tabbed-module--only-media .tabbed-module__media_item:nth-child(2n) {
    margin-right: 2.1276595745%;
  }
  .tabbed-module-with-multiple-media .tabbed-module__media--top .tabbed-module--only-media .tabbed-module__media_item:nth-child(2n+1), .tabbed-module-with-multiple-media .tabbed-module__media--bottom .tabbed-module--only-media .tabbed-module__media_item:nth-child(2n+1) {
    clear: none;
  }
  .tabbed-module-with-multiple-media .tabbed-module__media--top .tabbed-module--only-media .tabbed-module__media_item:last-child, .tabbed-module-with-multiple-media .tabbed-module__media--bottom .tabbed-module--only-media .tabbed-module__media_item:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .tabbed-module-with-multiple-media .tabbed-module__media--top .tabbed-module__media-heading, .tabbed-module-with-multiple-media .tabbed-module__media--bottom .tabbed-module__media-heading {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 65.9574468085%;
    margin-left: 17.0212765957%;
  }
  .tabbed-module-with-multiple-media .tabbed-module__media--top .tabbed-module__media-heading:last-child, .tabbed-module-with-multiple-media .tabbed-module__media--bottom .tabbed-module__media-heading:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .tabbed-module-with-multiple-media .tabbed-module--only-media .tabbed-module__media-container {
    margin-left: 0%;
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 100%;
  }
  .tabbed-module-with-multiple-media .tabbed-module--only-media .tabbed-module__media-container:last-child {
    margin-right: 0;
  }
  .tabbed-module-with-multiple-media .tabbed-module--only-media .tabbed-module__media_item {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
  }
  .tabbed-module-with-multiple-media .tabbed-module--only-media .tabbed-module__media_item:last-child {
    margin-right: 0;
  }
  .tabbed-module-with-multiple-media .tabbed-module--only-media .tabbed-module__media_item:nth-child(2n) {
    margin-right: 0;
  }
  .tabbed-module-with-multiple-media .tabbed-module--only-media .tabbed-module__media_item:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .tabbed-module-with-multiple-media .tabbed-module--only-media .tabbed-module__tab {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 100%;
  }
  .tabbed-module-with-multiple-media .tabbed-module--only-media .tabbed-module__tab:last-child {
    margin-right: 0;
  }
}

.form {
  display: block;
}
.form__block {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
  float: none;
  border: 1px solid #dddddd;
  box-shadow: 3px 3px 5px 0px #c7c7c7;
}
.form__block:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .form__block {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 69.3617021277%;
    margin-left: 15.3191489362%;
    float: none;
  }
  .form__block:last-child {
    margin-right: 0;
  }
}
.form__title {
  display: block;
  font: normal 400 24px/1.1666666667 "corporateacondpro";
  padding: 10px 15px;
  border-bottom: 1px solid #dddddd;
}
.form__group {
  padding: 10px 15px;
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.form__group:last-child {
  text-align: right;
}
.form__group .button {
  font-size: 14px;
  min-width: 110px;
  border-radius: 0;
}
.form__group .button:hover {
  cursor: pointer;
}
.form__input-group {
  margin-top: 10px;
  display: block;
}
.form__control {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  border: 1px solid #dddddd;
}
.form__control:focus {
  border: 1px solid #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.form__link {
  color: #3276B1;
}
.form__link:hover {
  text-decoration: underline;
  color: #23527C;
}
.form__link-text {
  display: inline-block;
}
.form__link-container {
  margin-bottom: 10px;
}
.form__error-block {
  display: inline-block;
}
.form__error-block-container {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 91.4893617021%;
  margin-left: 4.2553191489%;
  border: 3px solid #cc0000;
  color: #cc0000;
  background-color: #ffEEEE;
  padding: 10px 15px;
  margin-bottom: 10px;
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  float: none;
}
.form__error-block-container:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .form__error-block-container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 57.4468085106%;
    margin-left: 21.2765957447%;
    float: none;
  }
  .form__error-block-container:last-child {
    margin-right: 0;
  }
}
.form__error-block--hidden {
  display: none;
}

.contact-form-dropdown__label {
  display: inline-block;
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  color: #333;
}

.contact-form-data {
  color: #333;
}
.contact-form-data__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.contact-form-data__group {
  padding: 10px 0px;
}
.contact-form-data__group:nth-last-child(2) {
  padding-bottom: 20px;
  border-bottom: 1px solid #999;
}
.contact-form-data__group:last-child {
  padding: 20px 0px;
}
.contact-form-data__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.contact-form-data__control {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.contact-form-data__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.contact-form-data__control--error, .contact-form-data__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.contact-form-data__control--success, .contact-form-data__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.contact-form-data textarea {
  resize: none;
  height: auto;
}
.contact-form-data .button {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  border-radius: 0px;
  padding: 5px 20px;
}
.contact-form-data .button:hover {
  cursor: pointer;
}
.contact-form-data__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.contact-form-data__error-field--hidden {
  display: none;
}

.forgot-password-form .form__block {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
  margin-left: 0%;
  float: none;
}
.forgot-password-form .form__block:last-child {
  margin-right: 0;
}
.forgot-password-form .form__error-block-container {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
  margin: 10px 0px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #F2DEDE;
  color: #A94442;
}
.forgot-password-form .form__error-block-container:last-child {
  margin-right: 0;
}

.accordion-table__container {
  color: #333;
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.accordion-table__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.accordion-table__cta-link {
  font-size: 14px;
  border-radius: 0px;
  margin-right: 30px;
  margin-bottom: 20px;
}
.accordion-table__download-link {
  display: inline-block;
}
.accordion-table__download-link--text {
  color: #0051C2;
}
.accordion-table__download-link--text:hover {
  color: #23527C;
  text-decoration: underline;
}
.accordion-table__download-link--icon {
  color: #cc0000;
}
.accordion-table__list__item {
  list-style: none;
  padding: 10px 0px 5px 0px;
}
.accordion-table__list__item-text {
  font: normal 400 24px/1.1666666667 "corporateacondpro";
  cursor: pointer;
  color: #333;
}
.accordion-table__list__item-text:hover {
  color: #333;
}
.accordion-table__list__item-text:before {
  font-family: "micro-icons";
  font-size: 17px;
  content: "\e91a";
  float: right;
}
.accordion-table__table {
  width: 100%;
}
.accordion-table__table__container {
  overflow: auto;
}
.accordion-table__table__heading {
  padding: 20px 8px 10px 8px;
  font-weight: 700;
  text-align: left;
}
.accordion-table__table__body {
  font: normal 400 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.accordion-table__table__row {
  border-top: 1px solid #c7c7c7;
}
.accordion-table__table__row--full-content {
  border-top: 2px solid #c7c7c7;
}
.accordion-table__table__column {
  padding: 8px;
}
.accordion-table__table__column:nth-child(2) {
  width: 25%;
}
.accordion-table__table__column--bold {
  font-weight: 700;
}
.accordion-table__table__custom_width {
  width: 20%;
}
.accordion-table .accordion__active .accordion-table__list__item-text:before {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.parts-pro-locator {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.parts-pro-locator__heading {
  font: normal 700 18px/1.3333333333 "corpos";
  margin: 10px 0px;
}
.parts-pro-locator__description {
  margin-bottom: 10px;
}
.parts-pro-locator__drop-down {
  background-color: #176db7;
  color: #fff;
  font-size: 14px;
  padding: 8px;
  border: none;
}
.parts-pro-locator__dealer__list {
  margin-top: 20px;
}
.parts-pro-locator__dealer__list-item {
  border-top: 1px solid #c7c7c7;
  padding: 15px 0px;
}
@media screen and (min-width: 980px) {
  .parts-pro-locator__dealer__list-item {
    display: flex;
  }
}
.parts-pro-locator__dealer__details {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
  float: none;
  margin-bottom: 10px;
}
.parts-pro-locator__dealer__details:last-child {
  margin-right: 0;
}
@media screen and (min-width: 980px) {
  .parts-pro-locator__dealer__details {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 74.4680851064%;
    margin-bottom: 0px;
  }
  .parts-pro-locator__dealer__details:last-child {
    margin-right: 0;
  }
}
.parts-pro-locator__dealer__link {
  display: block;
  max-width: 50%;
  padding: 6px 12px;
  background: #176db7;
  color: #fff;
  margin-bottom: 10px;
  border-radius: 2px;
  text-align: center;
}
@media screen and (min-width: 480px) {
  .parts-pro-locator__dealer__link {
    display: inline-block;
    max-width: none;
    min-width: 110px;
  }
}
.parts-pro-locator__dealer__link__container {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
  float: none;
}
.parts-pro-locator__dealer__link__container:last-child {
  margin-right: 0;
}
@media screen and (min-width: 980px) {
  .parts-pro-locator__dealer__link__container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 23.4042553191%;
  }
  .parts-pro-locator__dealer__link__container:last-child {
    margin-right: 0;
  }
}

.rich-text-with-multi-link__container {
  font-size: 0;
}
.rich-text-with-multi-link__title {
  font: normal 400 32px/1 "corporateacondpro";
  font-size: 34px;
  margin-bottom: 10px;
}
.rich-text-with-multi-link__para {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
  padding: 20px;
}
.rich-text-with-multi-link__para ol {
  list-style: decimal;
}
.rich-text-with-multi-link__para ul {
  list-style: disc;
}
.rich-text-with-multi-link__rich-text-container {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.rich-text-with-multi-link__rich-text-container:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .rich-text-with-multi-link__rich-text-container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
    display: inline-block;
    float: none;
    vertical-align: top;
  }
  .rich-text-with-multi-link__rich-text-container:last-child {
    margin-right: 0;
  }
}
.rich-text-with-multi-link__multi-link-container {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.rich-text-with-multi-link__multi-link-container:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .rich-text-with-multi-link__multi-link-container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
    display: inline-block;
    float: none;
    vertical-align: top;
  }
  .rich-text-with-multi-link__multi-link-container:last-child {
    margin-right: 0;
  }
}
.rich-text-with-multi-link__multi-link-items {
  margin-bottom: 20px;
}
.rich-text-with-multi-link__button {
  min-width: 100px;
  text-align: center;
}
.rich-text-with-multi-link__download-link {
  margin-left: 5px;
  font-size: 14px;
  display: inline-block;
}
.rich-text-with-multi-link__download-link .icon-pdf {
  color: #cc0000;
}
.rich-text-with-multi-link__download-text {
  color: #176db7;
}
.rich-text-with-multi-link__download-text:hover {
  color: #23527C;
  text-decoration: underline;
}
.rich-text-with-multi-link--full-width {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.rich-text-with-multi-link--full-width:last-child {
  margin-right: 0;
}

.printAll-container {
  display: none;
}
.printAll-container header, .printAll-container footer, .printAll-container .col-3 {
  display: none;
}
.printAll-container .col-9 {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.printAll-container .col-9:last-child {
  margin-right: 0;
}
.printAll-container .page-content {
  display: block;
}
.printAll-container .page-content > .wrapper {
  display: block;
}
.printAll-container .page-content > .wrapper > .col-9 {
  display: block;
}
.printAll-container .page-content > .wrapper > .col-9 .dropdown, .printAll-container .page-content > .wrapper > .col-9 .newpar, .printAll-container .page-content > .wrapper > .col-9 .par {
  display: none;
}
.printAll-container .page-content > .wrapper > .col-9 b {
  display: inline-block;
}
.printAll-container .page-content > .wrapper > .col-9 .pop-up {
  display: none;
}
.printAll-container .page-content > .wrapper > .col-9 .button-link-list {
  display: none;
}

.menu-toggle {
  background-color: #000000;
  height: 40px;
  padding-top: 10px;
  position: relative;
  display: block;
}
@media screen and (min-width: 980px) {
  .menu-toggle {
    display: none;
  }
}
@media only print {
  .menu-toggle {
    display: none;
  }
  .menu-toggle * {
    display: none;
  }
}
.menu-toggle__cta {
  color: #f6f6f6;
}
.menu-toggle__hamburger {
  display: inline-block;
  margin-right: 10px;
}
.menu-toggle__hamburger-line {
  width: 17px;
  height: 2px;
  background-color: #ecf0f1;
  display: block;
  transition: all 0.3s ease-in-out;
}
.menu-toggle__hamburger-line:nth-of-type(2) {
  margin: 3px auto;
}
body[data-menu-state=open] .menu-toggle__hamburger-line:nth-child(2) {
  opacity: 0;
}
body[data-menu-state=open] .menu-toggle__hamburger-line:nth-child(1) {
  transform: translateY(5px) rotate(45deg);
  -webkit-transform: translateY(5px) rotate(45deg);
}
body[data-menu-state=open] .menu-toggle__hamburger-line:nth-child(3) {
  transform: translateY(-5px) rotate(-45deg);
  -webkit-transform: translateY(-5px) rotate(-45deg);
}
.menu-toggle__text {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.menu-toggle__logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: auto;
  height: 25px;
  max-width: 150px;
}
.menu-toggle__logo-img {
  width: auto;
  height: 25px;
  max-width: 100%;
}
body[data-menu-state=open] .menu-toggle__text {
  visibility: hidden;
}

.column-display {
  vertical-align: top;
  display: inline-block;
}
.column-display > div,
.column-display .column-display__container {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.column-display > div:last-child,
.column-display .column-display__container:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .column-display--2 > div, .column-display--2 > .column-display__container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
  }
  .column-display--2 > div:last-child, .column-display--2 > .column-display__container:last-child {
    margin-right: 0;
  }
  .column-display--2 > div:nth-child(2n), .column-display--2 > .column-display__container:nth-child(2n) {
    margin-right: 0;
  }
  .column-display--2 > div:nth-child(2n+1), .column-display--2 > .column-display__container:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 768px) {
  .column-display--3 > div, .column-display--3 > .column-display__container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
  }
  .column-display--3 > div:last-child, .column-display--3 > .column-display__container:last-child {
    margin-right: 0;
  }
  .column-display--3 > div:nth-child(2n), .column-display--3 > .column-display__container:nth-child(2n) {
    margin-right: 0;
  }
  .column-display--3 > div:nth-child(2n+1), .column-display--3 > .column-display__container:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 980px) {
  .column-display--3 > div, .column-display--3 > .column-display__container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 31.914893617%;
  }
  .column-display--3 > div:nth-child(2n), .column-display--3 > .column-display__container:nth-child(2n) {
    margin-right: 2.1276595745%;
  }
  .column-display--3 > div:nth-child(2n+1), .column-display--3 > .column-display__container:nth-child(2n+1) {
    clear: none;
  }
  .column-display--3 > div:last-child, .column-display--3 > .column-display__container:last-child {
    margin-right: 0;
  }
  .column-display--3 > div:nth-child(3n), .column-display--3 > .column-display__container:nth-child(3n) {
    margin-right: 0;
  }
  .column-display--3 > div:nth-child(3n+1), .column-display--3 > .column-display__container:nth-child(3n+1) {
    clear: left;
  }
}
@media screen and (min-width: 768px) {
  .column-display--4 > div, .column-display--4 > .column-display__container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
  }
  .column-display--4 > div:last-child, .column-display--4 > .column-display__container:last-child {
    margin-right: 0;
  }
  .column-display--4 > div:nth-child(2n), .column-display--4 > .column-display__container:nth-child(2n) {
    margin-right: 0;
  }
  .column-display--4 > div:nth-child(2n+1), .column-display--4 > .column-display__container:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 980px) {
  .column-display--4 > div, .column-display--4 > .column-display__container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 23.4042553191%;
  }
  .column-display--4 > div:nth-child(3n), .column-display--4 > .column-display__container:nth-child(3n) {
    margin-right: 2.1276595745%;
  }
  .column-display--4 > div:nth-child(3n+1), .column-display--4 > .column-display__container:nth-child(3n+1) {
    clear: none;
  }
  .column-display--4 > div:nth-child(2n), .column-display--4 > .column-display__container:nth-child(2n) {
    margin-right: 2.1276595745%;
  }
  .column-display--4 > div:nth-child(2n+1), .column-display--4 > .column-display__container:nth-child(2n+1) {
    clear: none;
  }
  .column-display--4 > div:last-child, .column-display--4 > .column-display__container:last-child {
    margin-right: 0;
  }
  .column-display--4 > div:nth-child(4n), .column-display--4 > .column-display__container:nth-child(4n) {
    margin-right: 0;
  }
  .column-display--4 > div:nth-child(4n+1), .column-display--4 > .column-display__container:nth-child(4n+1) {
    clear: left;
  }
}
@media screen and (min-width: 768px) {
  .column-display--5 > div, .column-display--5 > .column-display__container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
  }
  .column-display--5 > div:last-child, .column-display--5 > .column-display__container:last-child {
    margin-right: 0;
  }
  .column-display--5 > div:nth-child(2n), .column-display--5 > .column-display__container:nth-child(2n) {
    margin-right: 0;
  }
  .column-display--5 > div:nth-child(2n+1), .column-display--5 > .column-display__container:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 980px) {
  .column-display--5 > div, .column-display--5 > .column-display__container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 16.5957446809%;
  }
  .column-display--5 > div:nth-child(4n), .column-display--5 > .column-display__container:nth-child(4n) {
    margin-right: 2.1276595745%;
  }
  .column-display--5 > div:nth-child(4n+1), .column-display--5 > .column-display__container:nth-child(4n+1) {
    clear: none;
  }
  .column-display--5 > div:nth-child(3n), .column-display--5 > .column-display__container:nth-child(3n) {
    margin-right: 2.1276595745%;
  }
  .column-display--5 > div:nth-child(3n+1), .column-display--5 > .column-display__container:nth-child(3n+1) {
    clear: none;
  }
  .column-display--5 > div:nth-child(2n), .column-display--5 > .column-display__container:nth-child(2n) {
    margin-right: 2.1276595745%;
  }
  .column-display--5 > div:nth-child(2n+1), .column-display--5 > .column-display__container:nth-child(2n+1) {
    clear: none;
  }
  .column-display--5 > div:last-child, .column-display--5 > .column-display__container:last-child {
    margin-right: 0;
  }
  .column-display--5 > div:nth-child(5n), .column-display--5 > .column-display__container:nth-child(5n) {
    margin-right: 0;
  }
  .column-display--5 > div:nth-child(5n+1), .column-display--5 > .column-display__container:nth-child(5n+1) {
    clear: left;
  }
}
@media screen and (min-width: 768px) {
  .column-display--6 > div, .column-display--6 > .column-display__container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
  }
  .column-display--6 > div:last-child, .column-display--6 > .column-display__container:last-child {
    margin-right: 0;
  }
  .column-display--6 > div:nth-child(2n), .column-display--6 > .column-display__container:nth-child(2n) {
    margin-right: 0;
  }
  .column-display--6 > div:nth-child(2n+1), .column-display--6 > .column-display__container:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 980px) {
  .column-display--6 > div, .column-display--6 > .column-display__container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 14.8936170213%;
  }
  .column-display--6 > div:nth-child(5n), .column-display--6 > .column-display__container:nth-child(5n) {
    margin-right: 2.1276595745%;
  }
  .column-display--6 > div:nth-child(5n+1), .column-display--6 > .column-display__container:nth-child(5n+1) {
    clear: none;
  }
  .column-display--6 > div:nth-child(4n), .column-display--6 > .column-display__container:nth-child(4n) {
    margin-right: 2.1276595745%;
  }
  .column-display--6 > div:nth-child(4n+1), .column-display--6 > .column-display__container:nth-child(4n+1) {
    clear: none;
  }
  .column-display--6 > div:nth-child(3n), .column-display--6 > .column-display__container:nth-child(3n) {
    margin-right: 2.1276595745%;
  }
  .column-display--6 > div:nth-child(3n+1), .column-display--6 > .column-display__container:nth-child(3n+1) {
    clear: none;
  }
  .column-display--6 > div:nth-child(2n), .column-display--6 > .column-display__container:nth-child(2n) {
    margin-right: 2.1276595745%;
  }
  .column-display--6 > div:nth-child(2n+1), .column-display--6 > .column-display__container:nth-child(2n+1) {
    clear: none;
  }
  .column-display--6 > div:last-child, .column-display--6 > .column-display__container:last-child {
    margin-right: 0;
  }
  .column-display--6 > div:nth-child(6n), .column-display--6 > .column-display__container:nth-child(6n) {
    margin-right: 0;
  }
  .column-display--6 > div:nth-child(6n+1), .column-display--6 > .column-display__container:nth-child(6n+1) {
    clear: left;
  }
}

.accordion-banner {
  background-color: #000000;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.accordion-banner__interior {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 650px;
  padding-top: 5px;
  padding-bottom: 5px;
}
@media screen and (min-width: 768px) {
  .accordion-banner__interior {
    background-size: cover;
  }
}
@media screen and (min-width: 980px) {
  .accordion-banner__interior {
    background-size: auto;
    padding-bottom: 5px;
    padding-top: 5px;
    width: 100%;
  }
}
.accordion-banner__interior-wrapper {
  max-width: 1170px;
}
.accordion-banner__copy-container {
  overflow: hidden;
  padding: 20px 30px 20px 10px;
  text-align: left;
}
.accordion-banner__copy-container.right {
  margin-left: 51.0638297872%;
}
.accordion-banner__copy-container.dark {
  color: #000000;
}
.accordion-banner__copy-container.light {
  color: white;
}
@media screen and (min-width: 768px) {
  .accordion-banner__copy-container {
    text-align: left;
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 57.4468085106%;
  }
  .accordion-banner__copy-container:last-child {
    margin-right: 0;
  }
  .accordion-banner__copy-container--wide {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 40.4255319149%;
  }
  .accordion-banner__copy-container--wide:last-child {
    margin-right: 0;
  }
}
.accordion-banner__eyebrow {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}
.accordion-banner__heading {
  font: normal 400 30px/1 "corporateacondpro";
  margin-bottom: 5px;
}
@media screen and (min-width: 980px) {
  .accordion-banner__heading {
    font: normal 400 40px/1 "corporateacondpro";
  }
}
@media screen and (min-width: 768px) {
  .accordion-banner__heading {
    margin-bottom: 20px;
  }
}
.accordion-banner__sub-heading {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 15px;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .accordion-banner__sub-heading {
    display: initial;
    margin-bottom: 20px;
  }
}
.accordion-banner .button {
  font-size: 18px;
  border-radius: 5px;
  padding: 3px 15px;
}
.accordion-banner__container {
  padding: 20px 30px 20px 10px;
}
.accordion-banner__container.dark {
  color: #000000;
}
.accordion-banner__container.light {
  color: white;
}
@media screen and (min-width: 768px) {
  .accordion-banner__container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 57.4468085106%;
  }
  .accordion-banner__container:last-child {
    margin-right: 0;
  }
}
.accordion-banner__list {
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}
.accordion-banner__list:before {
  position: absolute;
  left: 0;
  top: 8px;
  font-family: "micro-icons";
  content: "\e902";
  -webkit-transition: all 0.3s ease-in 0s;
  -moz-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
  line-height: normal;
}
.accordion-banner__list--active .accordion-banner__description {
  display: block;
}
.accordion-banner__list--active:before {
  content: "\e907";
}
.accordion-banner__title {
  font: normal 400 24px/1.1666666667 "corporateacondpro";
  cursor: pointer;
  margin-bottom: 10px;
}
.accordion-banner__description {
  font: normal 400 13px/1.5384615385 "Arial", Helvetica, Arial, sans-serif;
  display: none;
  padding-left: 10px;
}

@media screen and (min-width: 768px) {
  .multimedia-asset {
    display: flex;
    justify-content: center;
  }
}
.multimedia-asset__image {
  width: 100%;
}
.multimedia-asset__image--override-video + .multimedia-asset__video {
  display: none;
}
@media screen and (min-width: 768px) {
  .multimedia-asset__image--override-video {
    display: none;
  }
  .multimedia-asset__image--override-video + .multimedia-asset__video {
    display: inline-block;
  }
}
.multimedia-asset__youtube {
  position: relative;
}
.multimedia-asset__video {
  width: 100%;
  position: relative;
}
.multimedia-asset__video-preview-container {
  position: relative;
  overflow: hidden;
}
.multimedia-asset__icon-play {
  border-radius: 50%;
  border: 2px solid #fff;
  height: 75px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 75px;
}
.multimedia-asset__circle {
  opacity: 0.5;
  fill: #000000;
}
.multimedia-asset__triangle {
  fill: #fff;
}
.multimedia-asset__youtube-preview-image {
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.multimedia-asset__youtube-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 9;
  transition: opacity 0.8s ease-in-out;
  background-color: #fff;
}
.playing .multimedia-asset__youtube-container {
  opacity: 1;
}
.multimedia-asset__youtube-container iframe {
  width: 100%;
  height: 100%;
}

.owl-carousel {
  margin: 2rem 0;
}
.owl-carousel .item {
  padding: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
.owl-carousel .item h4 {
  color: #fff;
  font-weight: 400;
  margin-top: 0rem;
}
.owl-carousel .item-video {
  height: 300px;
}
.owl-carousel .owl-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 67%;
  margin: 0 !important;
}
@media screen and (min-width: 768px) {
  .owl-carousel .owl-nav {
    height: 47%;
  }
}
.owl-carousel .owl-nav span {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAzCAMAAABYHiNGAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAS1BMVEUODg4ODg4ODg4ODg4ODg4ODg4TExMRERGbm5uQkJA4ODjFxcVDQ0N/f38+Pj5gYGDLy8tycnKMjIxNTU0ODg4ODg4ODg7MzMwAAACGT2UqAAAAF3RSTlMmUitgzGzNze/s1v3Y6Nff/uTr2jp6QXkbj44AAAABYktHRBibaYUeAAAACXBIWXMAAAsSAAALEgHS3X78AAAApElEQVQ4y9XSRxLDIBAAQWxhSc7Z/P+nvrFp2Lu49hQFLKXssrUvU83WYSs+L6nP63HJ/HRuGPT9LxzI+ThQ58dA348Cc38I7PvEwL1fCPz7+iC8vwvifGwA8zMBzVcHOH8V8P+QYPB/ejD6X9fWbonfH+2Z7N+ZXRhdMblmcMPRLQd37N2z88DWIxsH1k6sHFmcufuAu7+YZf93zb1u1T/fbP3+Xvsg/vxhuJcAAAAASUVORK5CYII=") no-repeat scroll 0 0 transparent;
  color: transparent;
  font-size: 56px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  height: 50px;
  width: 30px;
}
.owl-carousel .owl-nav .owl-next span {
  right: -10%;
}
@media screen and (min-width: 768px) {
  .owl-carousel .owl-nav .owl-next span {
    right: -8%;
  }
}
.owl-carousel .owl-nav .owl-prev span {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAzCAMAAABYHiNGAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAS1BMVEUODg4ODg4ODg4ODg4ODg4ODg4RERETExOQkJCbm5vFxcU4ODhDQ0M+Pj5/f39ycnLLy8tgYGCMjIxNTU0ODg4ODg4ODg7MzMwAAABDgZnxAAAAF3RSTlMmUitgzGzNzezv/dbY1+jk/t/r2jp6Qfd5JX8AAAABYktHRBibaYUeAAAACXBIWXMAAAsSAAALEgHS3X78AAAArUlEQVQ4y9XUyxKCMAyF4aqxoKjgBfL+b8q4gOZykj3dfv8wQ5u2lFO2zuVC2boe1GuXeu1vXeK15/sQ+58f8fcFI5cMXLF3zc4NW7ds3LF2z8oBS0csHHJzzLsHvHnEmz+ZX5Q4jTy9M4+C9n84EPsDA7m/KFDnAwJ9vj4w8+ECO182cPNpAj/fOgD3QwXofskA3k8R4PvdguB92IPofRn5kzp9KXc6iv/mbC0rllcgzU/Z+5UAAAAASUVORK5CYII=") no-repeat scroll 0 0 transparent;
  left: -10%;
}
@media screen and (min-width: 768px) {
  .owl-carousel .owl-nav .owl-prev span {
    left: -8%;
  }
}
.owl-carousel .owl-stage-outer {
  z-index: 1;
}

.thumbnail-carousel__container {
  margin-left: 8%;
  width: 84%;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .thumbnail-carousel__container--full {
    margin-left: 0;
    width: 100%;
  }
}
.thumbnail-carousel__image-border, .thumbnail-carousel__item-border {
  border: 3px solid #fff;
  box-shadow: 0 2px 3px 2px #c7c7c7;
}
.thumbnail-carousel__copy-container {
  color: #000000;
  font-size: 12px;
  padding: 0 5px 0 0;
}
.thumbnail-carousel__link {
  display: block;
  text-align: left;
}
.thumbnail-carousel__title {
  font: normal 700 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
}
.thumbnail-carousel__description {
  font: normal 400 12px/1.8333333333 "Arial", Helvetica, Arial, sans-serif;
  line-height: normal;
}
.thumbnail-carousel__list-container {
  list-style: initial;
  list-style-position: outside;
}
.thumbnail-carousel__left {
  margin: 1rem 0;
}
@media screen and (min-width: 768px) {
  .thumbnail-carousel__left {
    margin: 2rem 0;
  }
}
.thumbnail-carousel__left .owl-nav {
  height: 100%;
}
.thumbnail-carousel__left .item {
  background: #dddddd;
  font-size: 0;
  height: 260px;
  padding: 1% 0;
}
.thumbnail-carousel__left .thumbnail-carousel__image-container {
  display: inline-block;
  margin: 0 4% 0 2%;
  vertical-align: top;
  text-align: center;
  width: 47%;
}
.thumbnail-carousel__left .thumbnail-carousel__copy-container {
  display: inline-block;
  font-size: 12px;
  vertical-align: top;
  width: 47%;
}
.thumbnail-carousel__DD {
  text-align: center;
}
.thumbnail-carousel__DD-label {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
  display: block;
}
@media screen and (min-width: 768px) {
  .thumbnail-carousel__DD-label {
    display: inline-block;
    margin-bottom: 0;
    font-size: 24px;
  }
}
.thumbnail-carousel__DD-container {
  background: #176db7;
  color: #fff;
  min-width: 140px;
  padding: 5px;
  margin-left: 10px;
}
.thumbnail-carousel__article-block {
  display: none;
  font-size: 0;
}
.thumbnail-carousel__article-block-container {
  background: #dddddd;
  display: none;
  padding: 20px 40px;
  position: relative;
}
.thumbnail-carousel__article-block-container .icon-exit {
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  padding: 15px;
  top: 10px;
  right: 10px;
}
.thumbnail-carousel__article-list {
  display: inline-block;
  font-size: 14px;
  padding: 0 10px;
  vertical-align: top;
  width: 50%;
}
.thumbnail-carousel__article-link {
  color: #176db7;
}
.thumbnail-carousel__article-link:hover {
  text-decoration: underline;
}
.thumbnail-carousel__article-link-block .icon-pdf {
  color: #cc0000;
}
.thumbnail-carousel__article-description {
  border-bottom: 1px solid #666;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.article-data .item {
  cursor: pointer;
}
.article-data .item:hover .thumbnail-carousel__copy-container {
  text-decoration: underline;
}

.parts-pro-locator {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.parts-pro-locator__heading {
  font: normal 700 18px/1.3333333333 "corpos";
  margin: 10px 0px;
}
.parts-pro-locator__description {
  margin-bottom: 10px;
}
.parts-pro-locator__drop-down {
  background-color: #176db7;
  color: #fff;
  font-size: 14px;
  padding: 8px;
  border: none;
}
.parts-pro-locator__dealer__list {
  margin-top: 20px;
}
.parts-pro-locator__dealer__list-item {
  border-top: 1px solid #c7c7c7;
  padding: 15px 0px;
}
@media screen and (min-width: 980px) {
  .parts-pro-locator__dealer__list-item {
    display: flex;
  }
}
.parts-pro-locator__dealer__details {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
  float: none;
  margin-bottom: 10px;
}
.parts-pro-locator__dealer__details:last-child {
  margin-right: 0;
}
@media screen and (min-width: 980px) {
  .parts-pro-locator__dealer__details {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 74.4680851064%;
    margin-bottom: 0px;
  }
  .parts-pro-locator__dealer__details:last-child {
    margin-right: 0;
  }
}
.parts-pro-locator__dealer__link {
  display: block;
  max-width: 50%;
  padding: 6px 12px;
  background: #176db7;
  color: #fff;
  margin-bottom: 10px;
  border-radius: 2px;
  text-align: center;
}
@media screen and (min-width: 480px) {
  .parts-pro-locator__dealer__link {
    display: inline-block;
    max-width: none;
    min-width: 110px;
  }
}
.parts-pro-locator__dealer__link__container {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
  float: none;
}
.parts-pro-locator__dealer__link__container:last-child {
  margin-right: 0;
}
@media screen and (min-width: 980px) {
  .parts-pro-locator__dealer__link__container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 23.4042553191%;
  }
  .parts-pro-locator__dealer__link__container:last-child {
    margin-right: 0;
  }
}

.startuned-article {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  padding: 20px 0;
}
@media screen and (min-width: 768px) {
  .startuned-article {
    flex-direction: row;
  }
}
.startuned-article__media-container {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.startuned-article__media-container:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .startuned-article__media-container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
    text-align: center;
  }
  .startuned-article__media-container:last-child {
    margin-right: 0;
  }
}
.startuned-article__media .button {
  margin-top: 10px;
}
.startuned-article__title {
  font: normal 400 32px/1 "corporateacondpro";
  margin-top: 15px;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .startuned-article__title {
    margin-top: 0;
  }
}
.startuned-article__sub-title {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 15px;
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .startuned-article__sub-title {
    margin-top: 0;
    margin-bottom: 10px;
  }
}
.startuned-article__description {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.startuned-article__description a {
  color: #3276B1;
}
.startuned-article__description a:hover {
  text-decoration: underline;
  color: #23527C;
}
.startuned-article__description ul {
  list-style: initial;
  padding: 0 0 0 40px;
  list-style-position: outside !important;
}
.startuned-article__description p {
  margin-bottom: 5px;
}
.startuned-article__copy-container {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.startuned-article__copy-container:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .startuned-article__copy-container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
  }
  .startuned-article__copy-container:last-child {
    margin-right: 0;
  }
}
.startuned-article__image-container picture {
  display: block;
  margin-bottom: 20px;
  text-align: center;
}
.startuned-article__image {
  max-width: 100%;
  border: 3px solid #fff;
  box-shadow: 0px 2px 3px 2px #a3a3a3;
}
.startuned-article__image-thumbnail {
  width: auto;
  max-width: 100%;
}
.startuned-article__video-preview-container {
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
}
.startuned-article__video-preview-image {
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.startuned-article__video {
  width: 100%;
}
.startuned-article__video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.startuned-article__icon-play {
  border-radius: 50%;
  border: 2px solid #fff;
  height: 75px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 75px;
}
.startuned-article__circle {
  opacity: 0.5;
  fill: #000000;
}
.startuned-article__triangle {
  fill: #fff;
}
.startuned-article__search {
  margin-top: 10px;
}
@media screen and (min-width: 980px) {
  .startuned-article__search {
    display: flex;
    align-items: center;
  }
}
.startuned-article__search-container {
  margin-top: 20px;
}
@media screen and (min-width: 980px) {
  .startuned-article__search-container {
    margin-top: 0;
    min-height: 75px;
    margin-left: 40px;
    padding-left: 40px;
    border-left: 1px solid #999;
  }
}
.startuned-article__form-container {
  position: relative;
  display: inline-block;
}
.startuned-article__form-label {
  font: normal 700 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
  color: #333;
  display: block;
  margin-bottom: 4px;
}
.startuned-article__form-input {
  width: calc(100% - 45px);
  float: left;
  padding: 7px 50px 7px 10px;
  border: 1px solid #c7c7c7;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
@media screen and (min-width: 768px) {
  .startuned-article__form-input {
    width: auto;
  }
}
.startuned-article__form-button {
  position: absolute;
  right: 0;
  top: 0;
}
.startuned-article__download-link {
  display: block;
  padding-left: 20px;
}
.startuned-article__download-link:before {
  content: none;
}
.startuned-article__download-link [class^=icon-] {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 14px;
}
.startuned-article__download-link [class^=icon-] > span {
  position: absolute;
}
.startuned-article__download-link [class^=icon-] > span:before {
  margin-left: 0;
}
.startuned-article__download-text {
  font-size: 16px;
  line-height: normal;
}
.startuned-article.separator-top {
  border-top: 1px solid #c7c7c7;
}
.startuned-article.separator-bottom {
  border-bottom: 1px solid #c7c7c7;
}
.startuned-article.separator-both {
  border-top: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
}
.startuned-article .icon-pdf {
  color: #D0011B;
}
.startuned-article--left {
  flex-direction: column-reverse;
}
@media screen and (min-width: 768px) {
  .startuned-article--left {
    flex-direction: row-reverse;
  }
  .startuned-article--left .startuned-article__media-container {
    margin-right: 0;
  }
  .startuned-article--left .startuned-article__copy-container {
    margin-right: 2.1%;
  }
}
.startuned-article--top {
  flex-direction: column-reverse;
}
.startuned-article--bottom {
  flex-direction: column;
}
.startuned-article--top, .startuned-article--bottom {
  display: flex;
}
@media screen and (min-width: 768px) {
  .startuned-article--top .startuned-article__media-container, .startuned-article--bottom .startuned-article__media-container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 100%;
    margin-right: 0px;
  }
  .startuned-article--top .startuned-article__media-container:last-child, .startuned-article--bottom .startuned-article__media-container:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .startuned-article--top .startuned-article__image-container picture, .startuned-article--bottom .startuned-article__image-container picture {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
  }
  .startuned-article--top .startuned-article__image-container picture:last-child, .startuned-article--bottom .startuned-article__image-container picture:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .startuned-article--top .startuned-article__copy-container, .startuned-article--bottom .startuned-article__copy-container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 100%;
  }
  .startuned-article--top .startuned-article__copy-container:last-child, .startuned-article--bottom .startuned-article__copy-container:last-child {
    margin-right: 0;
  }
}
.startuned-article__cta-button {
  min-width: 100px;
  text-align: center;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .startuned-article--full-width {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 100%;
  }
  .startuned-article--full-width:last-child {
    margin-right: 0;
  }
}
.startuned-article .error-field--hidden {
  display: none;
}
.startuned-article .error-field--zip-code {
  font-size: 14px;
  text-align: left;
  color: #cc0000;
}
.startuned-article__media {
  position: relative;
}
.startuned-article__badge-block {
  background-color: #176db7;
  position: absolute;
  top: -5px;
  left: -10px;
  padding: 2px 10px;
  border-radius: 10px;
  color: #fff;
  text-transform: uppercase;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
@media screen and (min-width: 768px) {
  .startuned-article__badge-block {
    left: -20px;
  }
}
@media screen and (min-width: 768px) {
  .startuned-article__vertical {
    flex-direction: row;
  }
  .startuned-article__vertical--middle {
    align-items: center;
  }
  .startuned-article__vertical--top {
    align-items: flex-start;
  }
  .startuned-article__vertical--bottom {
    align-items: flex-end;
  }
}

.sticky-nav {
  position: relative;
}
.sticky-nav *:focus {
  animation: none;
  outline: none;
}
@media screen and (min-width: 768px) {
  .sticky-nav {
    margin-bottom: 30px;
  }
}
.sticky-nav__container {
  background-color: transparent;
  transition: background-color 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  width: 100%;
  z-index: 901;
}
@media screen and (min-width: 768px) {
  .sticky-nav__container {
    background-color: #fff;
  }
}
@media screen and (min-width: 768px) {
  .sticky-nav__container:after {
    width: 91.1458333333%;
    margin: 0 auto;
    content: "";
    height: 1px;
    background-color: #c7c7c7;
    display: block;
    margin: auto;
    transition: width 1s cubic-bezier(0.25, 0.04, 0.22, 0.97);
  }
}
@media screen and (min-width: 980px) {
  .sticky-nav__container:after {
    width: 940px;
  }
}
.sticky-nav__container.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .sticky-nav__container.sticky:after {
    width: 100%;
    transition: width 1s cubic-bezier(0.25, 0.04, 0.22, 0.97);
  }
}
.sticky-nav__container.absolute {
  position: absolute;
  top: initial;
  bottom: 200px;
}
.sticky-nav__container.borderless {
  border: none;
}
.sticky-nav__container.borderless:after {
  display: none;
}
.sticky-nav__container[data-page-level-sticky-nav-state=collapsed] {
  border-bottom: 1px solid #c7c7c7;
  border-top: 1px solid #c7c7c7;
}
.sticky-nav__container[data-page-level-sticky-nav-state=collapsed].sticky {
  border-top-color: #fff;
  position: fixed;
}
@media screen and (min-width: 768px) {
  .sticky-nav__container[data-page-level-sticky-nav-state=collapsed] {
    border: none;
  }
}
.sticky-nav__container[data-page-level-sticky-nav-state=expanded] {
  background-color: #fff;
  height: 100vh;
  overflow: scroll;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 901;
}
@media screen and (min-width: 768px) {
  .sticky-nav__container[data-page-level-sticky-nav-state=expanded] {
    height: auto;
    max-height: 100vh;
    overflow: auto;
    position: absolute;
  }
  .sticky-nav__container[data-page-level-sticky-nav-state=expanded].sticky {
    position: fixed;
  }
  .sticky-nav__container[data-page-level-sticky-nav-state=expanded]:after {
    width: 100%;
    transition: width 1s cubic-bezier(0.25, 0.04, 0.22, 0.97);
  }
}
.sticky-nav__wrapper {
  overflow: visible;
  max-width: 100%;
  width: 100%;
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .sticky-nav__wrapper {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .sticky-nav__wrapper {
    overflow: hidden;
  }
}
.sticky-nav__container {
  position: relative;
}
.sticky-nav__container:after {
  width: 100%;
}
.sticky-nav__container.sticky {
  position: fixed;
}
.sticky-nav__items-list {
  font: normal 400 13px/1.5384615385 "Arial", Helvetica, Arial, sans-serif;
  display: flex;
  justify-content: space-between;
}
@media screen and (min-width: 768px) {
  .sticky-nav__items-list {
    font: normal 400 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
    flex-direction: row;
    align-items: stretch;
  }
}
[data-page-level-sticky-nav-state=collapsed] .sticky-nav__items-list {
  display: block;
}
[data-page-level-sticky-nav-state=expanded] .sticky-nav__items-list {
  display: block;
}
.sticky-nav__item {
  width: 93.4375%;
  margin: 0;
  width: 100%;
  text-align: center;
  transition: color 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95), max-height 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95), border 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .sticky-nav__item {
    align-items: center;
    color: #222;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    transition: color 0.15s cubic-bezier(0.445, 0.05, 0.55, 0.95), height 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95), border 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    position: relative;
  }
}
[data-page-level-sticky-nav-state=collapsed] .sticky-nav__item {
  max-height: 0;
  border-bottom: 0 solid transparent;
  display: none;
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=collapsed] .sticky-nav__item {
    height: 0;
    max-height: none;
  }
}
@media screen and (max-width: 767px) {
  [data-page-level-sticky-nav-state=expanded] .sticky-nav__item {
    max-height: 60px;
    border-bottom: 1px solid #f1f1f1;
  }
  [data-page-level-sticky-nav-state=expanded] .sticky-nav__item:first-child {
    border-bottom-color: #c7c7c7;
    display: flex !important;
  }
  [data-page-level-sticky-nav-state=expanded] .sticky-nav__item:last-child:after {
    border-bottom: 1px solid #c7c7c7;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .sticky-nav__item {
    border-bottom: 1px solid #c7c7c7;
    height: 45px;
  }
}
[data-page-level-sticky-nav-state=expanded] .sticky-nav__item:last-of-type {
  border-bottom: none;
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .sticky-nav__item:last-of-type:before {
    border-bottom: none;
  }
}
@media screen and (min-width: 768px) {
  .sticky-nav__item:before {
    content: "";
    border-bottom: 3px solid transparent;
    bottom: -1px;
    left: 0;
    right: auto;
    top: auto;
    transform: none;
    transition: border-color 0.15s cubic-bezier(0.25, 0.04, 0.22, 0.97);
    position: absolute;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .sticky-nav__item:before {
    content: none;
  }
}
@media screen and (min-width: 768px) {
  .sticky-nav__item:after {
    border-right: 1px solid #c7c7c7;
    content: "";
    height: 28px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=collapsed] .sticky-nav__item:after {
    border-right: none;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .sticky-nav__item:after {
    border-right: none;
  }
}
.sticky-nav__item:first-child {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .sticky-nav__item:first-child {
    display: none !important;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=collapsed] .sticky-nav__item:first-child {
    display: flex;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .sticky-nav__item:first-child {
    display: flex;
    width: 93.4375%;
  }
}
@media screen and (min-width: 768px) {
  .sticky-nav__item:last-of-type:after {
    content: none;
  }
}
@media screen and (min-width: 768px) {
  .sticky-nav__item--last-visible:after {
    content: none;
  }
}
.sticky-nav__item--active {
  color: #222;
  display: block;
  max-height: none;
}
@media screen and (min-width: 768px) {
  .sticky-nav__item--active {
    display: flex;
    height: auto;
    max-height: none;
  }
}
[data-page-level-sticky-nav-state=collapsed] .sticky-nav__item--active {
  display: block;
  max-height: none;
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=collapsed] .sticky-nav__item--active {
    display: flex;
    height: auto;
  }
}
[data-page-level-sticky-nav-state=expanded] .sticky-nav__item--active {
  color: #0090ff;
}
@media screen and (min-width: 768px) {
  .sticky .sticky-nav__item--active {
    color: #0090ff;
  }
}
@media screen and (min-width: 768px) {
  .sticky .sticky-nav__item--active:before {
    border-bottom-color: #0090ff;
  }
}
[data-page-level-sticky-nav-state=collapsed].sticky .sticky-nav__item--active {
  color: #222;
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=collapsed].sticky .sticky-nav__item--active:before {
    border-bottom-color: transparent;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded].sticky .sticky-nav__item--active:before {
    border-bottom-color: #c7c7c7;
  }
}
.sticky-nav__item-link {
  color: currentColor;
  display: inline-block;
  line-height: 15px;
  padding: 12px 0;
  position: relative;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .sticky-nav__item-link {
    display: block;
    padding: 20px 10px;
    transition: color 0.15s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=collapsed] .sticky-nav__item-link {
    padding: 15px 30px;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .sticky-nav__item-link {
    padding: 15px 30px;
  }
}
.sticky-nav__item-link:hover, .sticky-nav__item-link:focus {
  animation: none;
  color: #0090ff;
}
.sticky-nav__item-link-label {
  display: inline-block;
  padding: 0 20px;
  position: relative;
}
@media screen and (min-width: 768px) {
  .sticky-nav__item-link-label {
    padding: 0;
    display: block;
    word-break: break-word;
  }
}
[data-page-level-sticky-nav-state=collapsed] .sticky-nav__item--active .sticky-nav__item-link-label:after {
  content: "\e902";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  color: #176db7;
  display: inline-block;
  margin-left: 10px;
}
[data-page-level-sticky-nav-state=expanded] .sticky-nav__item--active .sticky-nav__item-link-label:after {
  content: none;
}
.sticky-nav__item-skip-to {
  color: #0090ff;
  display: inline-block;
  font-size: 100%;
  line-height: 15px;
  padding: 12px 20px;
  position: relative;
}
.sticky-nav__item-skip-to:after {
  content: "\e907";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  color: #0090ff;
  display: inline-block;
  margin-left: 10px;
}
@media screen and (min-width: 768px) {
  .sticky-nav__item-skip-to {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=collapsed] .sticky-nav__item-skip-to {
    display: inline-block;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .sticky-nav__item-skip-to {
    display: inline-block;
    padding: 15px 30px;
  }
}
.sticky-nav__nav-toggle {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .sticky-nav__nav-toggle {
    cursor: pointer;
  }
}
[data-page-level-sticky-nav-state=collapsed] .sticky-nav__nav-toggle {
  height: 100%;
}
[data-page-level-sticky-nav-state=expanded] .sticky-nav__nav-toggle {
  height: 40px;
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .sticky-nav__nav-toggle {
    height: 45px;
  }
}
.sticky-nav__dropdown {
  position: relative;
}
.sticky-nav__dropdown-menu {
  background: #fff;
  border: 1px solid #c7c7c7;
  border-top: none;
  display: none;
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
}
.sticky-nav__dropdown-menu .sticky-nav__item {
  border-top: 1px solid #c7c7c7;
}
.sticky-nav__dropdown-menu .sticky-nav__item:after {
  border-right: none;
}
.sticky-nav__dropdown-menu .sticky-nav__item-link {
  padding: 10px 5px;
}
.sticky-nav__dropdown > .sticky-nav__item-link:after {
  content: "\e913";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  margin-left: 5px;
  vertical-align: middle;
}
.sticky-nav__dropdown.sticky-nav__item--open .sticky-nav__dropdown-menu {
  display: block;
}
.sticky-nav__section-container {
  padding-top: 20px;
  width: 100%;
}
.sticky-nav__dropdown-menu {
  border: none;
  position: relative;
  left: auto;
  top: auto;
}
.sticky-nav__dropdown-menu .sticky-nav__item {
  border-top: none;
}
.sticky-nav__item {
  overflow: visible;
}
.sticky-nav__item:first-child {
  display: flex;
}
.sticky-nav__item--active {
  color: #0090ff;
}
.sticky-nav__item--active:before {
  border-bottom-color: #0090ff;
}
.sticky-nav__item-switch {
  cursor: pointer;
}
.sticky-nav__item-switch-label {
  opacity: 0;
  position: absolute;
}
.sticky-nav__item-switch-label:checked + .sticky-nav__item-switch .sticky-nav__item-slider:before {
  background-color: black;
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.sticky-nav__item-slider {
  position: relative;
  cursor: pointer;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
  display: inline-block;
  width: 40px;
  height: 10px;
  vertical-align: middle;
  border-radius: 34px;
}
.sticky-nav__item-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 0;
  bottom: -3px;
  background-color: red;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.sticky-nav__button {
  border-bottom: 1px solid #c7c7c7;
  border-top: 1px solid #c7c7c7;
  color: #222;
  cursor: pointer;
  line-height: 15px;
  padding: 15px 10px;
  width: 100%;
}
.sticky-nav__button:after {
  content: "\e902";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  color: #176db7;
  display: inline-block;
  margin-left: 10px;
}
@media screen and (min-width: 980px) {
  .sticky-nav__button {
    display: none;
  }
}
.sticky-nav__button.active {
  border-color: #0090ff;
  border-top: none;
  color: #0090ff;
}
.sticky-nav__button.active:after {
  content: "\e907";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
}

.page-sticky-nav {
  position: relative;
  margin-bottom: 0;
}
.page-sticky-nav *:focus {
  animation: none;
  outline: none;
}
@media screen and (min-width: 768px) {
  .page-sticky-nav {
    margin-bottom: 30px;
  }
}
.page-sticky-nav__container {
  background-color: transparent;
  transition: background-color 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  width: 100%;
  z-index: 901;
}
@media screen and (min-width: 768px) {
  .page-sticky-nav__container {
    background-color: #fff;
  }
}
@media screen and (min-width: 768px) {
  .page-sticky-nav__container:after {
    width: 91.1458333333%;
    margin: 0 auto;
    content: "";
    height: 1px;
    background-color: #c7c7c7;
    display: block;
    margin: auto;
    transition: width 1s cubic-bezier(0.25, 0.04, 0.22, 0.97);
  }
}
@media screen and (min-width: 980px) {
  .page-sticky-nav__container:after {
    width: 940px;
  }
}
.page-sticky-nav__container.page-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .page-sticky-nav__container.page-sticky:after {
    width: 100%;
    transition: width 1s cubic-bezier(0.25, 0.04, 0.22, 0.97);
  }
}
.page-sticky-nav__container.absolute {
  position: absolute;
  top: initial;
  bottom: 200px;
}
.page-sticky-nav__container.borderless {
  border: none;
}
.page-sticky-nav__container.borderless:after {
  display: none;
}
.page-sticky-nav__container[data-page-level-sticky-nav-state=collapsed] {
  border-bottom: 1px solid #c7c7c7;
  border-top: 1px solid #c7c7c7;
}
.page-sticky-nav__container[data-page-level-sticky-nav-state=collapsed].page-sticky {
  border-top-color: #fff;
  position: fixed;
}
@media screen and (min-width: 768px) {
  .page-sticky-nav__container[data-page-level-sticky-nav-state=collapsed] {
    border: none;
  }
}
.page-sticky-nav__container[data-page-level-sticky-nav-state=expanded] {
  background-color: #fff;
  height: 100vh;
  overflow: scroll;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 901;
}
@media screen and (min-width: 768px) {
  .page-sticky-nav__container[data-page-level-sticky-nav-state=expanded] {
    height: auto;
    max-height: 100vh;
    overflow: auto;
    position: absolute;
  }
  .page-sticky-nav__container[data-page-level-sticky-nav-state=expanded].page-sticky {
    position: fixed;
  }
  .page-sticky-nav__container[data-page-level-sticky-nav-state=expanded]:after {
    width: 100%;
    transition: width 1s cubic-bezier(0.25, 0.04, 0.22, 0.97);
  }
}
.page-sticky-nav__wrapper {
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .page-sticky-nav__wrapper {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .page-sticky-nav__wrapper {
    overflow: hidden;
  }
}
.page-sticky-nav__styling--drop-down {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
  background-color: #eeeeee;
  color: #444;
  cursor: pointer;
  border: none;
  padding: 20px;
}
.page-sticky-nav__styling--icon-drop-down:after {
  font-family: "micro-icons";
  content: "\e913";
  padding-right: 5%;
  float: right;
}
.page-sticky-nav__styling--icon-drop-down_upArrow:after {
  font-family: "micro-icons";
  content: "\e91b";
  padding-right: 5%;
  float: right;
}
.page-sticky-nav__styling--display-none {
  display: none;
}
.page-sticky-nav__styling--display-block {
  display: block;
}
.page-sticky-nav__items-list {
  font: normal 400 13px/1.5384615385 "Arial", Helvetica, Arial, sans-serif;
  display: flex;
  justify-content: space-between;
}
@media screen and (min-width: 768px) {
  .page-sticky-nav__items-list {
    font: normal 400 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
    flex-direction: row;
    align-items: stretch;
  }
}
[data-page-level-sticky-nav-state=collapsed] .page-sticky-nav__items-list {
  display: block;
}
[data-page-level-sticky-nav-state=expanded] .page-sticky-nav__items-list {
  display: block;
}
.page-sticky-nav__item {
  width: 93.4375%;
  margin: 0 auto;
  text-align: center;
  transition: color 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95), max-height 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95), border 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .page-sticky-nav__item {
    align-items: center;
    color: #222;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    transition: color 0.15s cubic-bezier(0.445, 0.05, 0.55, 0.95), height 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95), border 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    position: relative;
  }
}
[data-page-level-sticky-nav-state=collapsed] .page-sticky-nav__item {
  max-height: 0;
  border-bottom: 0 solid transparent;
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=collapsed] .page-sticky-nav__item {
    height: 0;
    max-height: none;
  }
}
@media screen and (max-width: 767px) {
  [data-page-level-sticky-nav-state=expanded] .page-sticky-nav__item {
    max-height: 60px;
    border-bottom: 1px solid #f1f1f1;
  }
  [data-page-level-sticky-nav-state=expanded] .page-sticky-nav__item:first-child {
    border-bottom-color: #c7c7c7;
  }
  [data-page-level-sticky-nav-state=expanded] .page-sticky-nav__item:last-child:after {
    border-bottom: 1px solid #c7c7c7;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .page-sticky-nav__item {
    border-bottom: 1px solid #c7c7c7;
    height: 45px;
  }
}
[data-page-level-sticky-nav-state=expanded] .page-sticky-nav__item:last-of-type {
  border-bottom: none;
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .page-sticky-nav__item:last-of-type:before {
    border-bottom: none;
  }
}
@media screen and (min-width: 768px) {
  .page-sticky-nav__item:before {
    content: "";
    border-bottom: 3px solid transparent;
    bottom: -1px;
    left: 0;
    right: auto;
    top: auto;
    transform: none;
    transition: border-color 0.15s cubic-bezier(0.25, 0.04, 0.22, 0.97);
    position: absolute;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .page-sticky-nav__item:before {
    content: none;
  }
}
@media screen and (min-width: 768px) {
  .page-sticky-nav__item:after {
    border-right: 1px solid #c7c7c7;
    content: "";
    height: 28px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=collapsed] .page-sticky-nav__item:after {
    border-right: none;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .page-sticky-nav__item:after {
    border-right: none;
  }
}
@media screen and (min-width: 768px) {
  .page-sticky-nav__item:last-of-type:after {
    content: none;
  }
}
@media screen and (min-width: 768px) {
  .page-sticky-nav__item--last-visible:after {
    content: none;
  }
}
.page-sticky-nav__item--active {
  color: #222;
  display: block;
  max-height: none;
}
@media screen and (min-width: 768px) {
  .page-sticky-nav__item--active {
    display: flex;
    height: auto;
    max-height: none;
  }
}
[data-page-level-sticky-nav-state=collapsed] .page-sticky-nav__item--active {
  display: block;
  max-height: none;
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=collapsed] .page-sticky-nav__item--active {
    display: flex;
    height: auto;
  }
}
[data-page-level-sticky-nav-state=expanded] .page-sticky-nav__item--active {
  color: #0090ff;
}
@media screen and (min-width: 768px) {
  .page-sticky .page-sticky-nav__item--active {
    color: #0090ff;
  }
}
@media screen and (min-width: 768px) {
  .page-sticky .page-sticky-nav__item--active:before {
    border-bottom-color: #0090ff;
  }
}
[data-page-level-sticky-nav-state=collapsed].sticky .page-sticky-nav__item--active {
  color: #222;
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=collapsed].sticky .page-sticky-nav__item--active:before {
    border-bottom-color: transparent;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded].sticky .page-sticky-nav__item--active:before {
    border-bottom-color: #c7c7c7;
  }
}
.page-sticky-nav__item-link {
  color: currentColor;
  display: inline-block;
  line-height: 15px;
  padding: 12px 0;
  position: relative;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .page-sticky-nav__item-link {
    display: block;
    padding: 20px 10px;
    transition: color 0.15s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=collapsed] .page-sticky-nav__item-link {
    padding: 15px 30px;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .page-sticky-nav__item-link {
    padding: 15px 30px;
  }
}
.page-sticky-nav__item-link:hover, .page-sticky-nav__item-link:focus {
  animation: none;
  color: #0090ff;
}
.page-sticky-nav__item-link-label {
  display: inline-block;
  padding: 0 20px;
  position: relative;
}
@media screen and (min-width: 768px) {
  .page-sticky-nav__item-link-label {
    padding: 0;
    display: block;
    word-break: break-word;
  }
}
[data-page-level-sticky-nav-state=collapsed] .page-sticky-nav__item--active .page-sticky-nav__item-link-label:after {
  content: "\e902";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  color: #176db7;
  display: inline-block;
  margin-left: 10px;
}
[data-page-level-sticky-nav-state=expanded] .page-sticky-nav__item--active .page-sticky-nav__item-link-label:after {
  content: none;
}
.page-sticky-nav__item-skip-to {
  color: #0090ff;
  display: inline-block;
  font-size: 100%;
  line-height: 15px;
  padding: 12px 20px;
  position: relative;
}
.page-sticky-nav__item-skip-to:after {
  content: "\e907";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  color: #0090ff;
  display: inline-block;
  margin-left: 10px;
}
@media screen and (min-width: 768px) {
  .page-sticky-nav__item-skip-to {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=collapsed] .page-sticky-nav__item-skip-to {
    display: inline-block;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .page-sticky-nav__item-skip-to {
    display: inline-block;
    padding: 15px 30px;
  }
}
.page-sticky-nav__nav-toggle {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .page-sticky-nav__nav-toggle {
    cursor: pointer;
  }
}
[data-page-level-sticky-nav-state=collapsed] .page-sticky-nav__nav-toggle {
  height: 100%;
}
[data-page-level-sticky-nav-state=expanded] .page-sticky-nav__nav-toggle {
  height: 40px;
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .page-sticky-nav__nav-toggle {
    height: 45px;
  }
}
.page-sticky-nav__wrapper {
  overflow: visible;
  max-width: 100%;
  width: 100%;
}
.page-sticky-nav__container {
  position: relative;
}
.page-sticky-nav__container:after {
  width: 100%;
}
.page-sticky-nav__container.sticky {
  position: fixed;
}
.page-sticky-nav__item {
  overflow: visible;
}
.page-sticky-nav__item--active {
  color: #0090ff;
}
.page-sticky-nav__item--active:before {
  border-bottom-color: #0090ff;
}
.page-sticky-nav__item-switch {
  cursor: pointer;
}
.page-sticky-nav__item-switch-label {
  opacity: 0;
  position: absolute;
}
.page-sticky-nav__item-switch-label:checked + .page-sticky-nav__item-switch .page-sticky-nav__item-slider:before {
  background-color: black;
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.page-sticky-nav__item-slider {
  position: relative;
  cursor: pointer;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
  display: inline-block;
  width: 40px;
  height: 10px;
  vertical-align: middle;
  border-radius: 34px;
}
.page-sticky-nav__item-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 0;
  bottom: -3px;
  background-color: red;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.page-sticky-nav__dropdown {
  position: relative;
}
.page-sticky-nav__dropdown-menu {
  background: #fff;
  border: 1px solid #c7c7c7;
  border-top: none;
  display: none;
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
}
.page-sticky-nav__dropdown-menu .page-sticky-nav__item {
  border-top: 1px solid #c7c7c7;
}
.page-sticky-nav__dropdown-menu .page-sticky-nav__item:after {
  border-right: none;
}
.page-sticky-nav__dropdown-menu .page-sticky-nav__item-link {
  padding: 10px 5px;
}
.page-sticky-nav__dropdown > .page-sticky-nav__item-link:after {
  content: "\e913";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  margin-left: 5px;
  vertical-align: middle;
}
.page-sticky-nav__dropdown.page-sticky-nav__item--open .page-sticky-nav__dropdown-menu {
  display: block;
}
.page-sticky-nav__section-container {
  padding-top: 5%;
  width: 100%;
  display: flow-root;
}
.page-sticky-nav__title-container {
  text-align: center;
}
.page-sticky-nav__spacing--top {
  padding-top: 20px;
}
.page-sticky-nav__spacing--bottom {
  padding-bottom: 20px;
}
.page-sticky-nav__spacing--both {
  padding: 20px 0px;
}
.page-sticky-nav__spacing--none {
  padding: 0;
}
.page-sticky-nav__items-list {
  display: none;
}
@media screen and (min-width: 980px) {
  .page-sticky-nav__items-list {
    display: flex;
  }
}
.page-sticky-nav__button {
  border-bottom: 1px solid #c7c7c7;
  border-top: 1px solid #c7c7c7;
  color: #222;
  cursor: pointer;
  line-height: 15px;
  padding: 15px 10px;
  width: 100%;
}
.page-sticky-nav__button:after {
  content: "\e902";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  color: #176db7;
  display: inline-block;
  margin-left: 10px;
}
@media screen and (min-width: 980px) {
  .page-sticky-nav__button {
    display: none;
  }
}
.page-sticky-nav__button.active {
  border-color: #0090ff;
  border-top: none;
  color: #0090ff;
}
.page-sticky-nav__button.active:after {
  content: "\e907";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
}
.page-sticky-nav__button.active + .page-sticky-nav__items-list {
  display: block;
}
.page-sticky-nav__button.active + .page-sticky-nav__items-list .page-sticky-nav__items-list {
  display: block;
}
.page-sticky-nav__button.active + .page-sticky-nav__items-list .page-sticky-nav__item:not(:last-of-type) {
  border-bottom: 1px solid #f1f1f1;
}
.page-sticky-nav__button.active + .page-sticky-nav__items-list .page-sticky-nav__item:after {
  display: none;
}
.page-sticky-nav__button.active + .page-sticky-nav__items-list .page-sticky-nav__dropdown-menu {
  border: none;
  position: relative;
  left: auto;
  top: auto;
}
.page-sticky-nav__button.active + .page-sticky-nav__items-list .page-sticky-nav__dropdown-menu .page-sticky-nav__item {
  border-top: none;
}

.subscribe-form__container {
  border-bottom: 1px solid #9b9b9b;
  color: #333;
}
.subscribe-form__container::after {
  clear: both;
  content: "";
  display: block;
}
.subscribe-form__container:last-of-type {
  border-bottom: none;
}
@media screen and (min-width: 768px) {
  .subscribe-form__row {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 768px) {
  .subscribe-form__subscription-block {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
  }
  .subscribe-form__subscription-block:last-child {
    margin-right: 0;
  }
}
.subscribe-form__brand-block {
  position: relative;
  padding-left: 20px;
}
@media screen and (min-width: 768px) and (max-width: 979px) {
  .subscribe-form__brand-block {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 23.4042553191%;
  }
  .subscribe-form__brand-block:last-child {
    margin-right: 0;
  }
  .subscribe-form__brand-block:nth-child(4n) {
    margin-right: 0;
  }
  .subscribe-form__brand-block:nth-child(4n+1) {
    clear: left;
  }
}
@media screen and (min-width: 980px) {
  .subscribe-form__brand-block {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 14.8936170213%;
  }
  .subscribe-form__brand-block:last-child {
    margin-right: 0;
  }
  .subscribe-form__brand-block:nth-child(6n) {
    margin-right: 0;
  }
  .subscribe-form__brand-block:nth-child(6n+1) {
    clear: left;
  }
}
.subscribe-form__brand-block .subscribe-form__form-radio {
  left: 0;
  position: absolute;
  top: 0;
}
@media screen and (min-width: 768px) {
  .subscribe-form__ext-block {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 23.4042553191%;
  }
  .subscribe-form__ext-block:last-child {
    margin-right: 0;
  }
}
.subscribe-form__block-container {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.subscribe-form__block-container:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .subscribe-form__phone-block {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 74.4680851064%;
  }
  .subscribe-form__phone-block:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .subscribe-form__rank-block {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 31.914893617%;
  }
  .subscribe-form__rank-block:last-child {
    margin-right: 0;
  }
  .subscribe-form__rank-block:nth-child(3n) {
    margin-right: 0;
  }
  .subscribe-form__rank-block:nth-child(3n+1) {
    clear: left;
  }
}
.subscribe-form__title {
  font: normal 400 32px/1 "corporateacondpro";
  margin-top: 20px;
  margin-bottom: 10px;
}
.subscribe-form__subtitle {
  font: normal 400 24px/1.1666666667 "corporateacondpro";
  margin-top: 20px;
  margin-bottom: 10px;
}
.subscribe-form__text {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 20px;
}
.subscribe-form__text-bold {
  font-weight: 700;
}
.subscribe-form__text-left {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .subscribe-form__text-left {
    text-align: left;
  }
}
.subscribe-form__text-right {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .subscribe-form__text-right {
    text-align: right;
  }
}
.subscribe-form__text-center {
  text-align: center;
}
.subscribe-form__label {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  display: inline-block;
  margin-bottom: 5px;
  vertical-align: middle;
}
.subscribe-form__label-bold {
  font-weight: 700;
}
.subscribe-form__select-container {
  position: relative;
  max-width: 140px;
}
.subscribe-form__form-group {
  margin-top: 15px;
  margin-bottom: 15px;
}
.subscribe-form__form-options {
  margin-bottom: 10px;
}
.subscribe-form__form-radio {
  margin-left: 0;
}
.subscribe-form__form-control {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  width: 100%;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.subscribe-form__form-control:focus {
  border-color: #0090ff;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.subscribe-form__form-control:not(.subscribe-form__form-control-textarea) {
  height: 34px;
}
.subscribe-form__form-control-select {
  background: #176db7;
  border-radius: 0;
  border-color: #176db7;
  color: #fff;
  height: auto;
  padding: 5px 25px 5px 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.subscribe-form__form-control-select-icon {
  color: #fff;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.subscribe-form__form-error-field {
  color: #cc0000;
  display: block;
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.subscribe-form__form-error-field.d-none {
  display: none;
}
.subscribe-form__form-slider {
  appearance: none;
  -webkit-appearance: none;
  background: linear-gradient(180deg, #e3e3e3 0, #f1f1f1);
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  height: 10px;
  margin: 10px 0;
  outline: none;
  width: 210px;
}
.subscribe-form__form-slider::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  background: #176db7;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.subscribe-form__form-slider::-moz-range-thumb {
  background: #176db7;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.subscribe-form__form-slider-item {
  display: inline-block;
  margin-bottom: 15px;
  padding: 0 20px;
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.subscribe-form__form-slider-item:first-of-type {
  padding-left: 0;
}
.subscribe-form__form-slider-item:last-of-type {
  padding-right: 0;
}
.subscribe-form__btn {
  border-radius: 0;
  border: none;
  cursor: pointer;
  min-width: 110px;
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.subscribe-form__btn-reset {
  color: #fff;
  background: #000000;
}
.subscribe-form__btn-reset:hover {
  background: #222;
}
.subscribe-form__margin-b-0 {
  margin-bottom: 0;
}

.category--filters::after {
  clear: both;
  content: "";
  display: block;
}
.category--filters-list {
  display: inline-block;
  padding: 5px 5px 20px;
}
@media screen and (max-width: 767px) {
  .category--filters-list {
    width: 33.33%;
    float: left;
  }
}
@media screen and (min-width: 768px) {
  .category--filters-list {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
  }
}
@media screen and (min-width: 980px) {
  .category--filters-list {
    padding-left: 35px;
    padding-right: 35px;
  }
}
@media screen and (min-width: 768px) {
  .category--filters-list:first-of-type {
    padding-left: 0;
  }
}
.category--filters-list-item {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  color: #fff;
  transition: all 0.5s;
}
.category--filters-list-item:hover {
  opacity: 0.5;
}
.category--filters-list-item--active {
  color: #000000;
}
.category--filters-list-item--active:hover {
  opacity: 1;
}

.latest-news__section {
  padding: 20px 0;
}
.latest-news__section-container {
  background: #999;
  padding: 10px 15px;
}
.latest-news__section-container .category--filters {
  display: flex;
  flex-wrap: wrap;
}
.latest-news__section-container .category--filters-list#services {
  order: 3;
  -webkit-order: 3;
}
.latest-news__section-container .category--filters-list#after-sales {
  order: 4;
  -webkit-order: 4;
}
.latest-news__section-container .category--filters-list#parts {
  order: 5;
  -webkit-order: 5;
}
.latest-news__section-container .category--filters-list#learning-and-performing {
  order: 6;
  -webkit-order: 6;
}
.latest-news--title {
  text-align: center;
  position: relative;
  margin-bottom: 20px;
}
.latest-news--title-text {
  font: normal 400 28px/1.2142857143 "corporateacondpro";
  background: #999;
  color: #fff;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  z-index: 2;
}
@media screen and (min-width: 980px) {
  .latest-news--title-text {
    font: normal 400 30px/1.2666666667 "corporateacondpro";
  }
}
.latest-news--title:after {
  background: #fff;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 100%;
  z-index: 1;
}
.latest-news--section {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  text-align: center;
}
.latest-news--section-block {
  margin-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .latest-news--section-block {
    width: 25%;
  }
}
.latest-news--section-block.hidden {
  display: none;
}
.latest-news--section-date {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  color: #fff;
  margin-top: 15px;
}
.latest-news--section-feed {
  font: normal 400 18px/1 "corporateacondpro";
  color: #fff;
  margin-top: 10px;
}
.latest-news--section-image {
  max-width: 100%;
}

.vehicle-model {
  padding: 20px 0;
}
.vehicle-model__section-title {
  font: normal 400 18px/1 "corporateacondpro";
}
.vehicle-model__title {
  font: normal 400 32px/1 "corporateacondpro";
}
.vehicle-model__section {
  display: flex;
  flex-wrap: wrap;
}
.vehicle-model__section-block {
  margin-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .vehicle-model__section-block {
    width: 25%;
  }
}
.vehicle-model__section-block.hidden {
  display: none;
}
.vehicle-model__section-block hr {
  margin-bottom: 15px;
  border-style: dashed;
  border-bottom: 0;
}
.vehicle-model__image {
  width: 85%;
  margin: 20px auto;
}
.vehicle-model__image_section {
  min-height: 135px;
  text-align: center;
}
.vehicle-model__dropdown__title {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.vehicle-model__dropdown__title:hover {
  color: #4990E2;
  cursor: pointer;
}
.vehicle-model__dropdown__menu {
  min-width: 220px;
  border: 1px solid #c7c7c7;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  position: absolute;
  background: #fff;
  display: none;
}
@media screen and (min-width: 768px) {
  .vehicle-model__dropdown__menu {
    min-width: 190px;
  }
}
@media screen and (min-width: 980px) {
  .vehicle-model__dropdown__menu {
    min-width: 220px;
  }
}
.vehicle-model__dropdown__menu-items:first-child .vehicle-model__dropdown__link {
  padding-top: 8px;
}
.vehicle-model__dropdown__menu-items:last-child .vehicle-model__dropdown__link {
  padding-bottom: 8px;
}
.vehicle-model__dropdown--open {
  display: block;
}
.vehicle-model__dropdown__link {
  padding: 3px 20px;
  display: block;
  color: #5a5a5a;
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.vehicle-model__dropdown__link:hover {
  background-color: #F5F5F5;
}
.vehicle-model__dropdown__link--code {
  float: right;
  margin-left: 5px;
}
.vehicle-model .icon-caret-down-small {
  font-size: 8px;
  color: #4990E2;
  margin-right: 5px;
}
.vehicle-model .category--filters-list-item {
  color: #5a5a5a;
}
.vehicle-model .category--filters-list-item:hover {
  color: #4990E2;
  opacity: 1;
}
.vehicle-model .category--filters-list-item--active {
  color: #4990E2;
}

.separator-top {
  border-top: 1px solid #c7c7c7;
}
.separator-bottom {
  border-bottom: 1px solid #c7c7c7;
}
.separator-both {
  border-top: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
}

.training-courses-container {
  padding: 20px 0px;
}
.training-courses-wrapper.hidden {
  display: none;
}
.training-courses-wrapper .clearfix::after {
  clear: both;
  content: "";
  display: block;
}
.training-courses-title {
  font: normal 400 24px/1.1666666667 "corporateacondpro";
  margin: 20px 0 15px;
}
.training-courses-mb-collision-center {
  padding-bottom: 20px;
}
.training-courses__tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.training-courses__tabs-title {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 20px;
}
.training-courses__tabs-container .tab__item {
  padding: 10px 0;
}
@media screen and (max-width: 767px) {
  .training-courses__tabs-container .tab__item {
    width: 50%;
  }
}
.training-courses__tabs-container .tab-link {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  color: #5a5a5a;
}
.training-courses__tabs-container .tab-link:hover {
  color: #4990E2;
}
.training-courses__tabs-container .tab-link--active {
  color: #4990E2;
}

.tab-title {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 20px;
}
.tab-wrapper {
  padding-top: 20px;
}
.tab-wrapper .tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.tab-wrapper .tabs-container .tab__item {
  padding: 10px 0;
}
@media screen and (max-width: 767px) {
  .tab-wrapper .tabs-container .tab__item {
    width: 50%;
  }
}
.tab-wrapper .tabs-container .tab-link {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  color: #5a5a5a;
}
.tab-wrapper .tabs-container .tab-link:hover {
  color: #4990E2;
}
.tab-wrapper .tabs-container .tab-link--active {
  color: #4990E2;
}

.accordion-rich-text__list {
  padding: 7px 0;
}
.accordion-rich-text__title {
  font: normal 400 18px/1 "corporateacondpro";
  color: #5a5a5a;
  font-size: 20px;
}
.accordion-rich-text__title:hover {
  color: #0051C2;
}
.accordion-rich-text__title:before {
  font-family: "micro-icons";
  font-size: 15px;
  content: "\e91a";
  float: right;
}
.accordion-rich-text__label {
  font: normal 400 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.accordion-rich-text__separator {
  margin: 20px 0;
}
.accordion-rich-text__panel {
  display: block;
  padding: 10px 15px;
}
.accordion-rich-text__panel::after {
  clear: both;
  content: "";
  display: block;
}
.accordion-rich-text__column-1 {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.accordion-rich-text__column-1:last-child {
  margin-right: 0;
}
@media screen and (min-width: 980px) {
  .accordion-rich-text__column-1 {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 65.9574468085%;
  }
  .accordion-rich-text__column-1:last-child {
    margin-right: 0;
  }
}
.accordion-rich-text__column-2 {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
  margin-top: 10px;
}
.accordion-rich-text__column-2:nth-child(2n) {
  margin-right: 2.1276595745%;
}
.accordion-rich-text__column-2:nth-child(2n+1) {
  clear: none;
}
.accordion-rich-text__column-2:last-child {
  margin-right: 0;
}
@media screen and (min-width: 980px) {
  .accordion-rich-text__column-2 {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 31.914893617%;
    float: right;
    margin-top: 0;
  }
  .accordion-rich-text__column-2:last-child {
    margin-right: 0;
  }
  .accordion-rich-text__column-2:nth-child(2n) {
    margin-right: 0;
  }
  .accordion-rich-text__column-2:nth-child(2n+1) {
    clear: left;
  }
}
.accordion-rich-text__column-2-btn-container::after {
  clear: both;
  content: "";
  display: block;
}
@media screen and (max-width: 767px) {
  .accordion-rich-text__column-2-btn-container {
    text-align: center;
  }
}
@media screen and (min-width: 768px) {
  .accordion-rich-text__column-2-btn:last-of-type {
    float: right;
  }
}
.accordion-rich-text__column-2-btn:first-of-type {
  color: #176db7;
}
@media screen and (min-width: 768px) {
  .accordion-rich-text__column-2-btn:first-of-type {
    float: left;
  }
}
@media screen and (max-width: 767px) {
  .accordion-rich-text__column-2-btn:first-of-type {
    display: block;
    margin-bottom: 10px;
  }
}
.accordion-rich-text .accordion__active .accordion-rich-text__title:before {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.gallery--dark {
  background-color: #000000;
}
.gallery__heading {
  font: normal 400 30px/1 "corporateacondpro";
  margin-bottom: 15px;
  text-align: center;
}
@media screen and (min-width: 980px) {
  .gallery__heading {
    font: normal 400 36px/1 "corporateacondpro";
  }
}
@media screen and (min-width: 768px) {
  .gallery__heading {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .gallery__heading--no-filters {
    margin-bottom: 30px;
  }
}
.gallery__container {
  display: flex;
  overflow-x: auto;
}
@media screen and (min-width: 768px) {
  .gallery__container {
    display: block;
  }
}
.gallery__section {
  overflow: hidden;
  margin-bottom: 20px;
  height: inherit;
  opacity: 0;
  animation: fade-in 0.975s forwards;
}
.gallery__section::after {
  clear: both;
  content: "";
  display: block;
}
.gallery__section--disable .gallery__item {
  pointer-events: none;
}
.gallery__items {
  overflow: hidden;
}
.gallery__items::after {
  clear: both;
  content: "";
  display: block;
}
.gallery__item {
  float: left;
}
.gallery__section--gallery-tile-single .gallery__item {
  width: 100%;
}
.gallery__section--gallery-tile-double .gallery__item {
  width: calc(50% - 10px);
}
.gallery__section--gallery-tile-double .gallery__item:nth-child(2) {
  float: right;
}
.gallery__section--gallery-tile-1-2 .gallery__item {
  width: calc(50% - 10px);
}
.gallery__section--gallery-tile-1-2 .gallery__item:nth-child(2) {
  margin-bottom: 15px;
}
.gallery__section--gallery-tile-1-2 .gallery__item:nth-child(n+2) {
  float: right;
}
.gallery__section--gallery-tile-2-1 .gallery__item {
  width: calc(50% - 10px);
}
.gallery__section--gallery-tile-2-1 .gallery__item:nth-child(1) {
  margin-bottom: 15px;
}
.gallery__section--gallery-tile-2-1 .gallery__item:nth-child(2) {
  margin-right: 50%;
}
.gallery__section--gallery-tile-2-1 .gallery__item:nth-child(3) {
  float: right;
  transform: translateY(-100%);
  margin-bottom: -100%;
}
.gallery__item--carousel {
  float: none;
}
.gallery__item--thumbnail {
  flex: 0 0 82px;
  margin-right: 10px;
}
.gallery__item--focus {
  position: relative;
  height: 100%;
}
.gallery__item--focus:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000;
  opacity: 0.7;
}
.gallery__picture, .gallery__youtube-container {
  display: block;
  width: 100%;
}
.gallery__youtube-container {
  height: 58.6579861111vw;
}
@media screen and (min-width: 768px) {
  .gallery__youtube-container {
    height: 51.3260933806vw;
    max-height: 394.1843971631px;
  }
}
@media screen and (min-width: 980px) {
  .gallery__youtube-container {
    height: 54.0136054422vw;
    max-height: 529.3333333333px;
  }
}
@media screen and (min-width: 980px) {
  .modal--full-overlay .gallery__youtube-container {
    height: 54.0136054422vw;
    max-height: 43.2108843537vw;
  }
}
.gallery__image {
  display: inherit;
  line-height: 1;
  max-width: 100%;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .gallery__image {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  .gallery__image:hover, .gallery__image:focus {
    animation: none;
    opacity: 0.5;
  }
}
.gallery__thumbnail-nav {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}
.gallery__thumbnail-nav::-webkit-scrollbar {
  display: none;
}
.gallery__preview-container {
  margin-bottom: 10px;
}
.modal .gallery__preview-container {
  margin-bottom: 0;
}
@keyframes fade-in {
  to {
    opacity: 1;
  }
}

.menu-selector {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 20px;
  position: relative;
}
@media screen and (min-width: 768px) {
  .menu-selector {
    font: normal 700 12px/1.8333333333 "Arial", Helvetica, Arial, sans-serif;
  }
}
@media screen and (min-width: 980px) {
  .menu-selector {
    font: normal 700 13px/1.5384615385 "Arial", Helvetica, Arial, sans-serif;
  }
}
.menu-selector__selected-item {
  color: #0090ff;
  border-bottom: 4px solid #0090ff;
  line-height: 1.2857142857;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
}
.menu-selector__selected-item:after {
  content: "\e913";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  padding-left: 10px;
  vertical-align: middle;
}
@media screen and (min-width: 768px) {
  .menu-selector__selected-item {
    display: none;
  }
}
.menu-selector--open .menu-selector__selected-item:after {
  content: "\e91b";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
}
.menu-selector__item-list {
  border: 1px solid #c7c7c7;
  display: none;
  position: absolute;
  width: 100%;
  z-index: 503;
  top: calc(100% + 5px);
}
@media screen and (min-width: 768px) {
  .menu-selector__item-list {
    border: none;
    border-bottom: 1px solid #c7c7c7;
    display: flex;
    justify-content: center;
    position: relative;
    padding: 0 10%;
  }
}
.menu-selector--open .menu-selector__item-list {
  background-color: #fff;
  display: block;
}
.menu-selector__item {
  font: normal 400 12px/1.8333333333 "Arial", Helvetica, Arial, sans-serif;
  color: #222;
  cursor: pointer;
  display: block;
  line-height: 1;
  padding: 20px 20px 10px;
}
@media screen and (min-width: 768px) {
  .menu-selector__item {
    font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
    color: #999;
    display: inline-block;
    text-align: center;
    width: 100%;
  }
}
.menu-selector__item--selected {
  background-color: #f6f6f6;
  color: #0090ff;
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .menu-selector__item--selected {
    background-color: transparent;
    border-bottom: 3px solid #0090ff;
    font-weight: normal;
  }
}
.menu-selector__item-link {
  color: inherit;
  font: inherit;
}
.menu-selector__item-link:hover {
  color: #0090ff;
  cursor: pointer;
}

.more-info-bar {
  height: 100%;
  position: absolute;
  top: calc(100% - 35px);
  transition: top 0.525s;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .more-info-bar {
    max-height: 35px;
    height: auto;
    transition: max-height 0.525s;
  }
}
.more-info-bar.active {
  top: 0;
}
@media screen and (min-width: 768px) {
  .more-info-bar.active {
    bottom: 0;
    max-height: 40%;
    top: auto;
  }
}
.more-info-bar.active .more-info-bar__toggle {
  text-align: left;
  width: 100%;
  display: block;
}
@media screen and (min-width: 768px) {
  .more-info-bar.active .more-info-bar__toggle {
    display: flex;
    padding: 10px;
    padding-left: 5px;
    width: auto;
  }
}
.more-info-bar.active .more-info-bar__toggle:after {
  content: "\e91e";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
}
.more-info-bar__toggle {
  font: normal 400 12px/1.8333333333 "Arial", Helvetica, Arial, sans-serif;
  background-color: rgba(0, 0, 0, 0.7);
  color: #a3a3a3;
  cursor: pointer;
  display: flex;
  justify-content: center;
  line-height: 1.3;
  max-height: 35px;
  padding: 10px 10px;
}
.more-info-bar__toggle:after {
  content: "\e91b";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  padding-left: 5px;
}
.more-info-bar__toggle:hover {
  color: #fff;
}
.more-info-bar__content {
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  height: calc(100% - 35px);
  line-height: 1;
  padding: 15px;
  position: relative;
}
@media screen and (min-width: 768px) {
  .more-info-bar__content {
    padding: 10px;
    min-height: 70px;
  }
}
@media screen and (min-width: 768px) {
  .more-info-bar__copy-container {
    width: 60%;
  }
}
.more-info-bar__caption-description {
  color: #a3a3a3;
  line-height: 1;
  padding-top: 5px;
}
.more-info-bar__model {
  color: #fff;
  text-decoration: underline;
}
.more-info-bar__cta-container {
  bottom: 0;
  margin: 10px 0;
  position: absolute;
  right: 20px;
}
@media screen and (min-width: 768px) {
  .more-info-bar__cta-container {
    bottom: 50%;
    margin: 0;
    right: 0;
    transform: translateY(50%);
  }
}
.more-info-bar__cta-list-item {
  border-left: 1px solid #222;
  display: inline-block;
  padding: 0 10px;
}
@media screen and (min-width: 768px) {
  .more-info-bar__cta-list-item {
    width: 85px;
    padding: 10px;
    text-align: center;
    vertical-align: top;
  }
}
.more-info-bar__cta {
  color: #a3a3a3;
  display: block;
}
.more-info-bar__cta:hover {
  color: #fff;
}
.more-info-bar__cta.download:after {
  content: "\e903";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  border-bottom: 1px solid #a3a3a3;
  font-size: 70%;
  margin-left: 10px;
}
@media screen and (min-width: 768px) {
  .more-info-bar__cta.download:after {
    display: block;
    width: 9px;
    margin: 0 auto;
    padding-left: 0;
  }
}
.more-info-bar__cta.download:hover:after {
  border-bottom: 1px solid #fff;
}
@media screen and (min-width: 768px) {
  .more-info-bar__cta {
    line-height: 1.2;
  }
}

.view-more-cta {
  display: none;
  font: normal 700 13px/1 "Arial", Helvetica, Arial, sans-serif;
  width: 100%;
  border: #c7c7c7 solid 1px;
  cursor: pointer;
  color: #176db7;
  font-weight: bold;
  padding: 10px 0;
  text-align: center;
  transition: color 0.3s linear 0s;
}
.view-more-cta:hover {
  color: #0090ff;
}
.view-more-cta .icon-mb {
  margin-left: 5px;
  vertical-align: text-bottom;
}
.view-more-cta--visible {
  display: block;
}
.modal {
  background: #fff;
  border: 0;
  height: auto;
  max-height: 75vh;
  max-width: 940px;
  padding: 0;
  position: fixed;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  width: 91.1458333333%;
  z-index: 1000;
}
@media screen and (min-width: 768px) {
  .modal {
    max-height: 90vh;
  }
}
@media screen and (min-width: 980px) {
  .modal {
    width: 95.9183673469%;
  }
}
@media screen and (min-width: 768px) {
  .modal--transitions {
    opacity: 0;
    transition: opacity 0.3s linear;
  }
}
@media screen and (min-width: 768px) {
  .modal--playin {
    opacity: 1;
  }
}
@media screen and (min-width: 768px) {
  .modal--inset {
    max-width: 768px;
  }
}
@media screen and (min-width: 980px) {
  .modal--inset {
    max-width: 720px;
  }
}
@media screen and (min-width: 980px) {
  .modal--full-overlay {
    min-width: 940px;
    max-width: 75%;
  }
}
body[data-variant=largeformat] .modal--full-overlay {
  max-width: 90%;
}
.modal--full-screen {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
@media screen and (min-width: 768px) {
  .modal--dialog {
    width: 44%;
    max-width: 460px;
  }
}
.modal__overlay {
  background: #000000;
  bottom: 0;
  cursor: pointer;
  left: 0;
  opacity: 0.8;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}
.modal__overlay--light {
  background: #fff;
  opacity: 0.5;
}
@media screen and (min-width: 768px) {
  .modal__overlay--transitions {
    opacity: 0;
    transition: opacity 0.3s linear;
  }
}
@media screen and (min-width: 768px) {
  .modal__overlay--playin {
    opacity: 1;
  }
}
.modal__close {
  background-color: #c7c7c7;
  border-radius: 50%;
  color: #222;
  cursor: pointer;
  height: 32px;
  left: 100%;
  position: absolute;
  top: -16px;
  transform: translateX(-50%);
  width: 32px;
  z-index: 503;
}
.modal__close:hover {
  animation: none;
  background-color: #0090ff;
  color: #fff;
}
.modal--full-screen .modal__close {
  background-color: #000000;
  border-radius: 0;
  color: #fff;
  font-size: 14px;
  height: 45px;
  left: 0;
  padding-right: 15px;
  position: fixed;
  text-align: right;
  top: 0;
  transform: none;
  width: 100%;
}
.modal--full-screen .modal__close:hover {
  background-color: #000000;
}
.modal__image {
  display: block;
  width: 100%;
}
.modal__container {
  display: block;
  overflow-y: auto;
  width: 100%;
}
.modal__content {
  overflow: auto;
  overflow: overlay;
  position: static;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .modal__content {
    max-height: 90vh;
  }
}
.modal--disable-overflow .modal__content {
  overflow: visible;
}
.modal--light .modal__content {
  border: 1px solid #e3e3e3;
}
.modal--full-screen .modal__content {
  border: none;
  padding-top: 45px;
}

.modal .carousel {
  position: static;
}

.modal-carousel__image {
  margin-bottom: 20px;
  font-size: 0;
}
.modal-carousel__details {
  padding: 0 20px 20px;
}
.modal-carousel__standard {
  font: normal 700 12px/1.8333333333 "Arial", Helvetica, Arial, sans-serif;
  color: #999;
  padding-bottom: 5px;
}
.modal-carousel__title {
  font: normal 700 12px/1.8333333333 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 20px;
}
.modal-carousel__price {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.modal-carousel__desc {
  font: normal 400 12px/1.8333333333 "Arial", Helvetica, Arial, sans-serif;
  padding-bottom: 20px;
}
.modal-carousel__link, .modal-carousel__button {
  padding-bottom: 20px;
}
.modal-carousel__package {
  font: normal 400 12px/1.8333333333 "Arial", Helvetica, Arial, sans-serif;
  padding-bottom: 20px;
  margin-top: -10px;
}

.eq-form-data {
  color: #333;
}
.eq-form-data__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.eq-form-data__group {
  padding: 10px 0px;
}
.eq-form-data__group_btn {
  padding: 10px 0px;
  text-align: center;
}
.eq-form-data__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.eq-form-data__control {
  width: 80%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.eq-form-data__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.eq-form-data__control--error, .eq-form-data__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.eq-form-data__control--success, .eq-form-data__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.eq-form-data__control_document {
  background: #fff;
  padding: 6px 10px;
  font-family: "Arial";
}
.eq-form-data__control_table {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.eq-form-data__control_table:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.eq-form-data__control_table--error, .eq-form-data__control_table--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.eq-form-data__control_table--success, .eq-form-data__control_table--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.eq-form-data__title {
  font-size: 20px;
  width: 100%;
}
.eq-form-data__asterisk_field {
  color: red;
}
.eq-form-data__text {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.eq-form-data__btn {
  background-color: #000000;
  border: none;
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 4px;
}
.eq-form-data__hide {
  visibility: hidden;
}
.eq-form-data__table_column {
  padding: 15px;
}
.eq-form-data__table {
  width: 90%;
}
.eq-form-data__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.eq-form-data__error-field--hidden {
  display: none;
}
.eq-form-data__section {
  display: block;
}
.eq-form-data__section .pop-up {
  padding-top: 150px;
}
.eq-form-data__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.eq-form-data__section .pop-up__content-body {
  background: #fff;
}

.mb-form-data {
  color: #333;
}
.mb-form-data__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.mb-form-data__group {
  padding: 10px 0px;
}
.mb-form-data__group_btn {
  padding: 10px 0px;
  text-align: center;
}
.mb-form-data__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.mb-form-data__table {
  width: 90%;
}
.mb-form-data__table_column {
  padding: 15px;
}
.mb-form-data__control {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.mb-form-data__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.mb-form-data__control--error, .mb-form-data__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.mb-form-data__control--success, .mb-form-data__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.mb-form-data__title {
  font-size: 20px;
  width: 100%;
}
.mb-form-data__text {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.mb-form-data__btn {
  background-color: #000000;
  border: none;
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 4px;
}
.mb-form-data__hide {
  visibility: hidden;
}
.mb-form-data__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.mb-form-data__error-field--hidden {
  display: none;
}

.digital-shopping {
  color: #333;
}
.digital-shopping__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.digital-shopping__group {
  padding: 10px 0px;
}
.digital-shopping__group_btn {
  padding: 10px 0px;
  text-align: center;
}
.digital-shopping__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.digital-shopping__control {
  width: 40%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.digital-shopping__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.digital-shopping__control--error, .digital-shopping__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.digital-shopping__control--success, .digital-shopping__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.digital-shopping__control_document {
  background: #fff;
  padding: 6px 10px;
  font-family: "Arial";
}
.digital-shopping__control_table {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.digital-shopping__control_table:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.digital-shopping__control_table--error, .digital-shopping__control_table--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.digital-shopping__control_table--success, .digital-shopping__control_table--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.digital-shopping__title {
  font-size: 20px;
  width: 100%;
}
.digital-shopping__asterisk_field {
  color: red;
}
.digital-shopping__text {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.digital-shopping__btn {
  background-color: #000000;
  border: none;
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 4px;
}
.digital-shopping__hide {
  visibility: hidden;
}
.digital-shopping__table_column {
  padding: 15px;
}
.digital-shopping__table {
  width: 90%;
}
.digital-shopping__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.digital-shopping__error-field--hidden {
  display: none;
}
.digital-shopping__section {
  display: block;
}
.digital-shopping__section .pop-up {
  padding-top: 150px;
}
.digital-shopping__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.digital-shopping__section .pop-up__content-body {
  background: #fff;
}

.digital-shopping {
  color: #333;
}
.digital-shopping__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.digital-shopping__group {
  padding: 10px 0px;
}
.digital-shopping__group__information {
  font-weight: bold;
}
.digital-shopping__group__information a {
  text-decoration: revert;
  text-decoration-color: #000000;
  color: rgb(0, 0, 238);
}
.digital-shopping__group_btn {
  padding: 10px 0px;
  text-align: center;
}
.digital-shopping__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.digital-shopping__control {
  width: 40%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.digital-shopping__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.digital-shopping__control--error, .digital-shopping__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.digital-shopping__control--success, .digital-shopping__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.digital-shopping__control_document {
  background: #fff;
  padding: 6px 10px;
  font-family: "Arial";
}
.digital-shopping__control_table {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.digital-shopping__control_table:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.digital-shopping__control_table--error, .digital-shopping__control_table--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.digital-shopping__control_table--success, .digital-shopping__control_table--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.digital-shopping__title {
  font-size: 20px;
  width: 100%;
}
.digital-shopping__asterisk_field {
  color: red;
}
.digital-shopping__text {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.digital-shopping__btn {
  background-color: #000000;
  border: none;
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 4px;
}
.digital-shopping__hide {
  visibility: hidden;
}
.digital-shopping__table_column {
  padding: 15px;
}
.digital-shopping__table {
  width: 90%;
}
.digital-shopping__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.digital-shopping__error-field--hidden {
  display: none;
}
.digital-shopping__section {
  display: block;
}
.digital-shopping__section .pop-up {
  padding-top: 150px;
}
.digital-shopping__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.digital-shopping__section .pop-up__content-body {
  background: #fff;
}
.digital-shopping__no-underline-link {
  text-decoration: none;
}

.category-list .model__specification:last-child .model__specification-field, .order-guides .model__specification:last-child .model__specification-field {
  padding-bottom: 15px;
}
.category-list .model__specification-field, .order-guides .model__specification-field {
  display: block;
  font-size: 18px;
  padding: 0;
}
.category-list .model__specification-field:first-child:after, .order-guides .model__specification-field:first-child:after {
  display: none;
}
.category-list .model__specification:first-child .model__specification-field, .category-list .model__specification:last-child .model__specification-field, .order-guides .model__specification:first-child .model__specification-field, .order-guides .model__specification:last-child .model__specification-field {
  padding: 0;
}
.category-list .model__specification-title, .order-guides .model__specification-title {
  font: italic 400 24px/1.1666666667 "corporateacondpro";
  background: #999;
  color: #fff;
  padding: 5px 10px;
}
.category-list .model__specification-subcategory, .order-guides .model__specification-subcategory {
  width: 100%;
  margin-bottom: 5px;
}
@media screen and (min-width: 768px) {
  .category-list .model__specification-subcategory, .order-guides .model__specification-subcategory {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
  }
  .category-list .model__specification-subcategory:last-child, .order-guides .model__specification-subcategory:last-child {
    margin-right: 0;
  }
  .category-list .model__specification-subcategory:nth-child(2n), .order-guides .model__specification-subcategory:nth-child(2n) {
    margin-right: 0;
  }
}
.category-list .model__specification-subcategory-container, .order-guides .model__specification-subcategory-container {
  background: #dddddd;
  list-style: disc;
  padding: 5px 30px 0;
}
.category-list .model__specification-subcategory-container::after, .order-guides .model__specification-subcategory-container::after {
  clear: both;
  content: "";
  display: block;
}
.category-list .model__specification-subcategory-list, .order-guides .model__specification-subcategory-list {
  border: 1px solid #dddddd;
  list-style: none;
  padding: 0;
}
.category-list .model__specification-subcategory-list li, .order-guides .model__specification-subcategory-list li {
  padding: 5px 10px 0;
  clear: both;
  float: left;
  width: 100%;
}
.category-list .model__specification-subcategory-list li:nth-of-type(2n), .order-guides .model__specification-subcategory-list li:nth-of-type(2n) {
  background: #fff;
}
.category-list .model__specification-subcategory-list li .model__specification-subcategory:nth-of-type(2n), .order-guides .model__specification-subcategory-list li .model__specification-subcategory:nth-of-type(2n) {
  font-weight: 400;
}
.category-list .model__specification-subcategory-list li .model__specification-subcategory span, .order-guides .model__specification-subcategory-list li .model__specification-subcategory span {
  display: block;
}
.category-list .model__specification-subcategory-image, .order-guides .model__specification-subcategory-image {
  background: none;
  list-style: none;
}
.category-list .model__specification-subcategory-image .model__specification-subcategory, .order-guides .model__specification-subcategory-image .model__specification-subcategory {
  width: 100%;
  float: none;
}
.category-list .model__specification-subcategory-image img, .order-guides .model__specification-subcategory-image img {
  display: table;
  margin: 0 auto;
  max-width: 100%;
}

.wepay-signup {
  text-align: center;
}
.wepay-signup a.wepay-widget-button {
  padding: 10px 25px 10px;
}
.wepay-signup a.wepay-widget-button.wepay-green {
  background-color: #176db7;
  background-image: none;
  border: 1px solid #176db7;
  text-shadow: 0 -1px 0 #176db7;
  border: 1px solid #176db7;
  box-shadow: none;
}
.wepay-signup a.wepay-widget-button.wepay-green:hover {
  background-color: #0090ff;
  background-image: none;
}
.wepay-info {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  color: #cc0000;
  font-style: italic;
  padding: 10px 0;
}
.wepay-info--bold {
  font-weight: 700;
}

.concur-enrollment {
  color: #333;
}
.concur-enrollment__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.concur-enrollment__heading_group {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee;
}
.concur-enrollment__form_container {
  border-color: #ddd;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 4px;
}
.concur-enrollment__group {
  padding: 15px;
}
.concur-enrollment__group_btn {
  padding: 10px 0px;
  text-align: center;
}
.concur-enrollment__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.concur-enrollment__group_input {
  padding: 10px 0px;
}
.concur-enrollment__control_textarea {
  height: auto;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 6px 12px;
  width: 70%;
}
.concur-enrollment__control {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
  width: 70%;
}
.concur-enrollment__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.concur-enrollment__control--error, .concur-enrollment__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.concur-enrollment__control--success, .concur-enrollment__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.concur-enrollment__title {
  font-size: 20px;
  width: 100%;
}
.concur-enrollment__table {
  width: 100%;
}
.concur-enrollment__table_column_padding {
  padding-left: 15px;
  width: 60%;
}
.concur-enrollment__table_column_label {
  padding-left: 10%;
  width: 40%;
}
.concur-enrollment__table_column_label_textarea {
  padding-left: 10%;
  width: 40%;
  vertical-align: middle;
}
.concur-enrollment__text {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.concur-enrollment__reset_btn {
  background-color: #EA6441;
  border: none;
  color: white;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.4;
  border-radius: 2px;
}
.concur-enrollment__btn_column {
  text-align: center;
}
.concur-enrollment__hide {
  visibility: hidden;
}
.concur-enrollment__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.concur-enrollment__error-field--hidden {
  display: none;
}
.concur-enrollment__section {
  display: block;
}
.concur-enrollment__section .pop-up {
  padding-top: 150px;
}
.concur-enrollment__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.concur-enrollment__section .pop-up__content-body {
  background: #fff;
}

.vin-recall {
  color: #333;
  background-color: #CCCCCC;
  justify-content: center;
  margin: 0 auto;
}
.vin-recall__title {
  text-align: center;
  padding-top: 5px;
  font-family: "corporateacondpro";
  font-size: 32px;
}
.vin-recall__sub-title {
  font-family: Arial, sans-serif;
  padding-bottom: 10px;
}
.vin-recall__section {
  width: 50%;
  margin: 0 auto;
}
.vin-recall__group {
  padding: 5px 0px;
}
.vin-recall__group_btn {
  padding: 10px 0px;
}
.vin-recall__table {
  width: 100%;
}
.vin-recall__table_column {
  padding: 0px 0px 0px 15px;
  text-align: left;
}
.vin-recall__control {
  width: 100%;
  border: 1px solid #A1A1A1;
  border-radius: 2px;
  height: 32px;
  padding: 7px;
}
.vin-recall__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.vin-recall__control--error, .vin-recall__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.vin-recall__control--success, .vin-recall__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.vin-recall__text {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.vin-recall__btn {
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 2px;
  background-color: #444;
}
.vin-recall__hide {
  visibility: hidden;
}
.vin-recall__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.vin-recall__error-field--hidden {
  display: none;
}

.vin-result {
  background-color: white;
  margin-top: 20px;
}
.vin-result__recall_loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}
.vin-result__result_table_toggle_text {
  cursor: pointer;
  font-family: Arial, sans-serif;
  font-size: 15px;
}
.vin-result__result_selection_container {
  padding-top: 20px;
  padding-bottom: 20px;
}
.vin-result__result_table_selection {
  width: 100%;
}
.vin-result__result_table_selection_column {
  text-align: center;
}
.vin-result__result_table_selection_column_left {
  text-align: left;
  padding-left: 10px;
}
.vin-result__result_table_selection_column_right {
  text-align: right;
  padding-left: 10px;
}
.vin-result__recall_model_title {
  font-family: "corporateacondpro";
  text-align: left;
  padding-top: 20px;
  font-size: 30px;
}
.vin-result__recall-result .vin-result__open-recall.active, .vin-result__recall-result .vin-result__closed-recall.active, .vin-result__recall-result .vin-result__open-other.active, .vin-result__recall-result .vin-result__closed-other.active {
  display: block;
}
.vin-result__recall-result .vin-result__open-recall, .vin-result__recall-result .vin-result__closed-recall, .vin-result__recall-result .vin-result__open-other, .vin-result__recall-result .vin-result__closed-other {
  display: none;
}
.vin-result__recall-result #recall-details-table {
  font-family: Arial, sans-serif;
  font-weight: normal;
  font-size: 12px;
  width: 100%;
  border-width: 1px;
  border-spacing: 0px;
  border-style: solid;
  border-color: #c7c7c7;
  border-collapse: collapse;
}
.vin-result__tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
.vin-result__tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.vin-result__recall-result #recall-details-table .heading-row {
  font-weight: bold;
  height: 40px;
  background-color: #f6f6f6;
}
.vin-result__recall-result #recall-details-table .col1, .vin-result__recall-result #recall-details-table .vin-result__col2, .vin-result__recall-result #recall-details-table .vin-result__col3 {
  width: 11%;
}
.vin-result__recall-result #recall-details-table th, .vin-result__recall-result #recall-details-table td {
  border-width: 1px;
  padding: 5px 10px;
  border-style: solid;
  border-color: #c7c7c7;
  vertical-align: top;
}
.vin-result__recall-result #recall-details-table .vin-result__col4, .vin-result__recall-result #recall-details-table .vin-result__col5 {
  width: 10%;
}
.vin-result__recall-result #recall-details-table .vin-result__col6 {
  width: 30%;
}

.airbag-availability {
  color: #333;
  justify-content: center;
}
.airbag-availability__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.airbag-availability__title {
  text-align: center;
  padding-top: 5px;
  font-family: "corporateacondpro";
  font-size: 36px;
}
.airbag-availability__sub-title {
  font-family: Arial, sans-serif;
}
.airbag-availability__section {
  width: 50%;
  margin: 0 auto;
}
.airbag-availability__group {
  padding: 5px 0px;
  margin-left: 5%;
  width: 85%;
}
.airbag-availability__input_feild {
  width: 70%;
}
.airbag-availability__result_display {
  margin: 2% 10% 2% 10%;
  width: 80%;
  text-align: center;
  border: 2px solid black;
}
.airbag-availability__group_btn {
  padding: 10px 0px;
}
.airbag-availability__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.airbag-availability__table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
}
.airbag-availability__result_table {
  width: 50%;
  margin: 2% 15% 2% 25%;
  border-spacing: 0 5px;
}
.airbag-availability__result_column {
  border: 1px solid black;
  background-color: #176db7;
  padding: 10px 5px;
}
.airbag-availability__result_array_column {
  border: 1px solid black;
  padding: 10px 5px;
}
.airbag-availability__result_column_text {
  color: white;
  text-align: center;
  font-weight: bold;
}
.airbag-availability__table_column {
  text-align: right;
}
.airbag-availability__table_text {
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 13px;
  color: black;
  text-align: center;
}
.airbag-availability__control {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.airbag-availability__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.airbag-availability__control--error, .airbag-availability__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.airbag-availability__control--success, .airbag-availability__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.airbag-availability__text {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.airbag-availability__btn {
  background-color: #000000;
  border: none;
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 4px;
}
.airbag-availability__hide {
  visibility: hidden;
}
.airbag-availability__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.airbag-availability__error-field--hidden {
  display: none;
}
.airbag-availability .media-text {
  padding-top: 10px;
}

.takata-learn-more__learn-more {
  font-family: Arial, sans-serif;
  border: 1px solid #c7c7c7;
  padding: 55px 138px 78px;
  font-size: 13px;
  margin-top: 10px;
  color: black;
}
.takata-learn-more__takata-letter p {
  font-size: 13px;
  padding-bottom: 20px;
}
.takata-learn-more__takata_text {
  font-family: Arial, sans-serif;
  font-size: 13px;
  margin-top: 10px;
  color: black;
  padding-top: 10px;
  text-align: center;
}
.takata-learn-more__takata_text p {
  padding-bottom: 10px;
}
.takata-learn-more__signature {
  padding-top: 25px;
  font-size: 14px;
}
.takata-learn-more__signature span {
  display: block;
  padding: 2px 0;
  line-height: 15px;
}
.takata-learn-more__learn-more-recall {
  color: #176db7;
  font-family: Arial, sans-serif;
  font-size: 14px;
  text-align: center;
  padding: 20px 0 0;
}
.takata-learn-more__link {
  cursor: pointer;
  font-weight: 700;
}
.takata-learn-more__link:hover {
  color: #0090ff;
}
.takata-learn-more__link:after {
  font-family: global_svg;
  font-weight: normal;
  font-size: 16px;
  font-size: 1.6rem;
  vertical-align: middle;
  padding: 5px;
}
.takata-learn-more__active:after {
  content: "^";
}
.takata-learn-more__global-svg.icon-mbusa-logo-font:before {
  content: "\e617";
}
.takata-learn-more__global-svg .icon-mbusa-logo-font:before {
  content: "\e617";
}
.takata-learn-more__mbusa-logo {
  padding-bottom: 20px;
  font-size: 15px;
  padding-left: 20px;
}

.takata-learn-more__global-svg [class^=icon-], .takata-learn-more__global-svg [class*=" icon-"], .takata-learn-more__global-svg[class^=icon-], .takata-learn-more__global-svg[class*=" icon-"] {
  font-family: global_svg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.email-ok {
  color: #333;
}
.email-ok__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.email-ok__group {
  padding: 0px 0px 15px 0px;
  text-align: left;
}
.email-ok__group_center {
  padding: 0px 0px 15px 0px;
  text-align: center;
}
.email-ok__group_btn {
  padding: 10px 0px;
  text-align: center;
}
.email-ok__label {
  display: inline-block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.email-ok__control {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: auto;
  padding: 6px 12px;
  width: 70%;
}
.email-ok__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.email-ok__control--error, .email-ok__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.email-ok__control--success, .email-ok__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.email-ok__control_table {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.email-ok__control_table:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.email-ok__control_table--error, .email-ok__control_table--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.email-ok__control_table--success, .email-ok__control_table--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.email-ok__table {
  width: 100%;
}
.email-ok__table_label {
  width: 40%;
  text-align: right;
}
.email-ok__table_input {
  width: 60%;
  padding-left: 15px;
}
.email-ok__title {
  font-size: 20px;
  width: 100%;
}
.email-ok__text {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.email-ok__btn {
  background-color: #000000;
  border: none;
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 4px;
}
.email-ok__hide {
  visibility: hidden;
}
.email-ok__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.email-ok__error-field--hidden {
  display: none;
}
.email-ok__section {
  display: block;
}
.email-ok__section .pop-up {
  padding-top: 150px;
}
.email-ok__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.email-ok__section .pop-up__content-body {
  background: #fff;
}
.email-ok__panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-color: #ddd;
}
.email-ok__panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  color: #333;
  background-color: #f5f5f5;
  text-align: center;
  border-color: #ddd;
}
.email-ok__panel-body {
  padding: 15px;
}

.meetings-events-form {
  color: #333;
}
.meetings-events-form__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.meetings-events-form__group {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.meetings-events-form__group_input {
  padding: 10px 0px;
}
.meetings-events-form__group_btn {
  padding: 10px 0px;
  text-align: center;
}
.meetings-events-form__asterisk_field {
  color: red;
}
.meetings-events-form__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.meetings-events-form__control {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.meetings-events-form__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.meetings-events-form__control--error, .meetings-events-form__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.meetings-events-form__control--success, .meetings-events-form__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.meetings-events-form__control_textarea {
  height: auto;
}
.meetings-events-form__control_document {
  background: #fff;
  padding: 6px 10px;
  width: 100%;
  font-family: "Arial";
}
.meetings-events-form__control_table {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.meetings-events-form__control_table:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.meetings-events-form__control_table--error, .meetings-events-form__control_table--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.meetings-events-form__control_table--success, .meetings-events-form__control_table--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.meetings-events-form__title {
  font-size: 20px;
  width: 100%;
}
.meetings-events-form__text {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.meetings-events-form__hide {
  visibility: hidden;
}
.meetings-events-form__table_column {
  width: 35%;
  vertical-align: middle;
}
.meetings-events-form__table_column_checkbox {
  width: 35%;
}
.meetings-events-form__table_column_padding {
  padding-left: 15px;
}
.meetings-events-form__table {
  width: 80%;
}
.meetings-events-form__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.meetings-events-form__error-field--hidden {
  display: none;
}
.meetings-events-form__btn_column {
  text-align: center;
}
.meetings-events-form__reset_btn {
  background-color: #D3D3D3;
  color: #333;
  border-color: #ccc;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
}
.meetings-events-form__section {
  display: block;
}
.meetings-events-form__section .pop-up {
  padding-top: 150px;
}
.meetings-events-form__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.meetings-events-form__section .pop-up__content-body {
  background: #fff;
}

.dealer-lookup {
  color: #333;
  width: 100%;
  padding: 1%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .dealer-lookup {
    width: 100%;
    padding: 1%;
  }
}
.dealer-lookup__mbusatitle {
  color: #333333;
  font-family: corporateacondpro;
  font-size: 26px;
  padding: 3px 5px 2px 0px;
  margin-bottom: 10px;
}
.dealer-lookup__group {
  padding: 10px 0px;
}
.dealer-lookup__control {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 32px;
  padding: 6px 12px;
}
.dealer-lookup__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.dealer-lookup__control--error, .dealer-lookup__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.dealer-lookup__control--success, .dealer-lookup__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.dealer-lookup__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.dealer-lookup__error-field--hidden {
  display: none;
}
.dealer-lookup__result-table {
  border-collapse: collapse;
  inline-size: -webkit-fill-available;
  text-align: center;
  width: 100%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .dealer-lookup__result-table {
    width: 100%;
  }
}
.dealer-lookup__result-table-padding {
  padding: 1.2%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .dealer-lookup__result-table-padding {
    padding: 1.2%;
  }
}
.dealer-lookup__line_separator {
  border-top: 1.5px solid grey;
}
.dealer-lookup__hr_margin {
  margin-top: 1%;
}
.dealer-lookup__downloadPdf {
  font-size: 35px;
}
.dealer-lookup__download {
  position: absolute;
  right: 130px;
  top: 96px;
}
.dealer-lookup__loader {
  position: fixed;
  left: 54%;
  top: 80%;
  z-index: 1;
  width: 60px;
  height: 60px;
  margin: -75px 0 0 -75px;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.dealer-lookup__button {
  color: #fff;
  background-color: #176db7;
  padding: 5px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
  border-radius: 0.6em;
  position: absolute;
  right: 190px;
  top: 96px;
}
.dealer-lookup__button:hover {
  cursor: pointer;
  background-color: #0090ff;
}

td {
  font: initial;
  font-size: 100%;
  font-family: inherit;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  td {
    font-size: 100%;
    font-family: inherit;
    font-variant: normal;
    font-weight: normal;
    line-height: normal;
    font-style: normal;
  }
}

.cac-suggestion {
  color: #333;
}
.cac-suggestion__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.cac-suggestion__group {
  padding: 0px 0px 15px 0px;
  text-align: left;
}
.cac-suggestion__group_center {
  padding: 0px 0px 15px 0px;
  text-align: center;
}
.cac-suggestion__group_btn {
  padding: 10px 0px;
  text-align: center;
}
.cac-suggestion__label {
  display: inline-block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.cac-suggestion__textarea_border {
  width: 100%;
  border: 1px solid black;
}
.cac-suggestion__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.cac-suggestion__control--error, .cac-suggestion__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.cac-suggestion__control--success, .cac-suggestion__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.cac-suggestion__data-control {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  width: 25%;
  padding-left: 5px;
}
.cac-suggestion__data-control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.cac-suggestion__data-control--error, .cac-suggestion__data-control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.cac-suggestion__data-control--success, .cac-suggestion__data-control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.cac-suggestion__sub-title {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 20px;
  padding-bottom: 5px;
}
.cac-suggestion__asterisk_field {
  color: red;
}
.cac-suggestion__control_table {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.cac-suggestion__control_table:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.cac-suggestion__control_table--error, .cac-suggestion__control_table--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.cac-suggestion__control_table--success, .cac-suggestion__control_table--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.cac-suggestion__table {
  width: 100%;
}
.cac-suggestion__table_label {
  width: 40%;
  text-align: right;
}
.cac-suggestion__table_input {
  width: 60%;
  padding-left: 15px;
}
.cac-suggestion__title {
  font-size: 20px;
  width: 100%;
}
.cac-suggestion__text {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.cac-suggestion__btn {
  background-color: #000000;
  border: none;
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 4px;
}
.cac-suggestion__hide {
  visibility: hidden;
}
.cac-suggestion__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.cac-suggestion__error-field--hidden {
  display: none;
}
.cac-suggestion__section {
  display: block;
}
.cac-suggestion__section .pop-up {
  padding-top: 150px;
}
.cac-suggestion__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.cac-suggestion__section .pop-up__content-body {
  background: #fff;
}
.cac-suggestion__panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-color: #080808;
  width: 100%;
}
.cac-suggestion__panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  color: #333;
  background-color: #f5f5f5;
  text-align: center;
  border-color: #ddd;
  text-align: left;
}
.cac-suggestion__panel-body {
  padding: 15px;
}
.cac-suggestion__button {
  border-radius: 2px;
  color: currentColor;
  display: inline-block;
  font-family: "Arial", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.83333;
  padding: 5px 10px;
  text-decoration: none;
  height: 30px;
  width: 850px;
  margin-right: 85;
  background-color: #3371FF;
}
.cac-suggestion__btn_reset {
  border-radius: 2px;
  color: currentColor;
  display: inline-block;
  font-family: "Arial", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.83333;
  padding: 5px 10px;
  text-decoration: none;
  height: 30px;
  width: 850px;
  margin-right: 85;
  background-color: #3371FF;
  margin-top: 10px;
}

.faq-v2 {
  margin-bottom: 20px;
}
.faq-v2__color-light {
  color: #f1f1f1;
}
.faq-v2__color-dark {
  color: #202020;
}
.faq-v2__list {
  list-style: none;
  margin: 0;
}
.faq-v2__title {
  font-size: 24px;
  font-family: "corporateacondpro", sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
}
.faq-v2__subtitle {
  font-size: 20px;
  font-family: "corporateacondpro", sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
}
.faq-v2__componentBorder {
  border-bottom: 1px solid;
  border-top: 1px solid;
}
.faq-v2__question {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
  cursor: pointer;
  border: none;
  padding: 20px;
  outline: none;
  padding-bottom: 10px;
}
.faq-v2__question:hover {
  background-color: #cccccc;
}
.faq-v2__question:before {
  font-family: "micro-icons";
  content: "\e902";
  padding-top: 2px;
  -webkit-transition: all 0.3s ease-in 0s;
  -moz-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
  float: right;
}
.faq-v2__question_faq_v3 {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
  color: #444;
  cursor: pointer;
  border: none;
  padding: 20px;
  outline: none;
}
.faq-v2__question_faq_v3:before {
  font-family: "micro-icons";
  content: "\e902";
  font-size: 75%;
  padding-top: 2px;
  -webkit-transition: all 0.3s ease-in 0s;
  -moz-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
  float: right;
}
.faq-v2__icon--down-arrow-key:before {
  content: "\e902";
  padding-top: 0.7%;
}
.faq-v2__icon--right-arrow-key:before {
  content: "\e905";
  padding-top: 0.7%;
}
.faq-v2__icon--plus:before {
  content: "\e923";
}
.faq-v2__panel {
  padding: 0 30px;
  overflow: hidden;
  margin-bottom: 10px;
}
.faq-v2__panel-content {
  padding: 20px 0px;
  margin-bottom: 10px;
}
.faq-v2__accordion_active_icon--down-arrow-key:before {
  content: "\e902";
  padding-top: 0.7%;
  -webkit-transform: rotate(180deg);
  moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.faq-v2__accordion_active_icon--right-arrow-key:before {
  content: "\e905";
  padding-top: 0.7%;
  -webkit-transform: rotate(90deg);
  moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.faq-v2__accordion_active_icon--plus:before {
  transform: none;
  content: "\e922";
}
.faq-v2__spacing--top {
  padding-top: 20px;
}
.faq-v2__spacing--bottom {
  padding-bottom: 20px;
}
.faq-v2__spacing--both {
  padding-top: 20px;
  padding-bottom: 20px;
}
.faq-v2__without--background-color__question {
  background-color: #fff;
  padding: 0px;
  font-size: 16px;
  font-weight: bold;
}
.faq-v2__without--background-color__question:hover {
  background-color: #fff;
}
.faq-v2__without--background-color__question:before {
  float: left;
  margin-right: 10px;
  font-family: "micro-icons";
  padding-top: 2px;
  -webkit-transition: all 0.3s ease-in 0s;
  -moz-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}
.faq-v2__hover-black:hover {
  background: none;
}

.faq-v4 {
  margin-bottom: 20px;
}
.faq-v4__list {
  list-style: none;
  margin: 0;
}
.faq-v4__title {
  font-size: 24px;
  font-family: "corporateacondpro", sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
}
.faq-v4__subtitle {
  font-size: 20px;
  font-family: "corporateacondpro", sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
}
.faq-v4__question {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
  background-color: #eeeeee;
  color: #444;
  cursor: pointer;
  border: none;
  padding: 20px;
  outline: none;
}
.faq-v4__question:hover {
  background-color: #cccccc;
}
.faq-v4__icon--down-arrow-key:after {
  font-family: "micro-icons";
  content: "\e91b";
  padding-right: 5%;
  float: right;
}
.faq-v4__icon--plus:after {
  font-family: "micro-icons";
  content: "\e923";
  padding-right: 5%;
  float: right;
}
.faq-v4__icon--minus:after {
  font-family: "micro-icons";
  content: "\e922";
  padding-right: 5%;
  float: right;
}
.faq-v4__icon--left-arrow:after {
  font-family: "micro-icons";
  content: "\e92b";
  padding-right: 5%;
  float: right;
}
.faq-v4__panel {
  padding: 0 30px;
  background-color: #fff;
  overflow: hidden;
  margin-bottom: 10px;
}
.faq-v4__panel-content {
  padding: 20px 0px;
  margin-bottom: 10px;
}
.faq-v4__accordion_active_icon--down-arrow-key:before {
  content: "\e902";
  padding-top: 0.7%;
  -webkit-transform: rotate(180deg);
  moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.faq-v4__accordion_active_icon--plus:before {
  transform: none;
  content: "\e922";
}
.faq-v4__spacing--top {
  padding-top: 20px;
}
.faq-v4__spacing--bottom {
  padding-bottom: 20px;
}
.faq-v4__spacing--both {
  padding-top: 20px;
  padding-bottom: 20px;
}
.faq-v4__display-none {
  display: none;
}
.faq-v4__display-block {
  display: block;
}
.faq-v4__without--background-color__question {
  background-color: #fff;
  padding: 0px;
  font-size: 16px;
  font-weight: bold;
}
.faq-v4__without--background-color__question:hover {
  background-color: #fff;
}
.faq-v4__without--background-color__question:before {
  float: left;
  margin-right: 10px;
  font-family: "micro-icons";
  padding-top: 2px;
  -webkit-transition: all 0.3s ease-in 0s;
  -moz-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.star-parts-dynamic__icon--right-arrow-key:before {
  content: "\e905";
}
.star-parts-dynamic__accordion_active_icon--right-arrow-key:before {
  content: "\e905";
  -webkit-transform: rotate(90deg);
  moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.faq2 {
  padding: 20px 0;
}

.fleet-tab .tab__list {
  background: #c7c7c7;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  flex-basis: 0;
  flex-grow: 1;
  margin-right: 10px;
  padding: 10px;
  text-align: center;
}
.fleet-tab .tab__list--active {
  background: #F5F5F5;
}
.fleet-tab .tab__list:last-child {
  margin-right: 0;
}
.fleet-tab .tab__list-container {
  border: 1px solid #a3a3a3;
  border-bottom: none;
  display: flex;
}
.fleet-tab .tab__content-container {
  background-color: #F5F5F5;
  border: 1px solid #a3a3a3;
  border-top: none;
  display: flex;
  margin-bottom: 20px;
}
.fleet-tab .tab__content {
  margin-top: 20px;
  width: 100%;
}
.fleet-tab .tab__links {
  cursor: pointer;
}
.fleet-tab .contact-form__input-group {
  display: block;
}

.fleet-employee-program__container {
  margin: 0 auto;
  width: 80%;
}

.fleet-customers {
  float: left;
  margin-bottom: 20px;
  width: 40%;
}
.fleet-customers .contact-form__input-group-container {
  display: block;
  padding-left: 0;
  width: auto;
}
.fleet-customers .contact-form__input-group-container .button {
  cursor: pointer;
}

.fleet-members {
  float: left;
  margin-left: 20%;
  margin-bottom: 20px;
  position: relative;
  width: 40%;
}
.fleet-members .contact-form__input-group-container {
  display: block;
  float: right;
  padding-right: 0;
  width: auto;
}
.fleet-members .contact-form__input-group-container .button {
  cursor: pointer;
}

.label {
  padding-top: 2px;
}

.contact-form__control-container {
  padding-top: 2px;
}
.contact-form__control-container input, .contact-form__control-container input + label {
  cursor: pointer;
}

.error_message {
  color: #cc0000;
  display: none;
}
.error_message.show {
  display: block;
}

.fleet-program-status-maintenance .form__error-container {
  background-color: #F2DEDE;
  border: 1px solid #A94442;
  color: #A94442;
  font-weight: 700;
  margin-bottom: 10px;
  padding: 10px 0;
  text-align: center;
}

.can-applications__container .can_form {
  width: 60%;
}
.can-applications__container .can-block {
  font-size: 0;
  margin-bottom: 10px;
}
.can-applications__container .can-block label {
  display: inline-block;
  font-size: 14px;
  margin-left: 10%;
  width: 40%;
}
.can-applications__container .can-block input {
  display: inline-block;
  font-size: 14px;
  padding: 4px 2px;
  width: 50%;
}
.can-applications__container .can_results {
  max-height: 300px;
  margin: 0 20px;
  overflow: auto;
}
.can-applications__container .can_results table {
  margin-bottom: 20px;
  text-align: left;
  width: 100%;
}
.can-applications__container .can_results table th {
  background: #c7c7c7;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #e3e3e3;
  padding: 10px;
}
.can-applications__container .can_results table tbody tr td {
  font-weight: normal;
  border: 1px solid #e3e3e3;
  padding: 15px;
}
.can-applications__container .can_results table tbody tr td .icon-dark-printer {
  color: initial;
  cursor: pointer;
  font-size: 14px;
}
.can-applications__container .can_results table tbody tr:nth-child(even) {
  background: #fff;
}

.btn_container {
  margin-left: 50%;
  margin-bottom: 20px;
  width: 50%;
}
.btn_container .button {
  cursor: pointer;
}

.canApplication__wrapper {
  font-size: 0;
}
.canApplication__wrapper--hidden {
  display: none;
}
.canApplication__left {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.canApplication__right {
  display: inline-block;
  margin-left: 5%;
  vertical-align: top;
  width: 70%;
}
.canApplication__accordion-list {
  margin-bottom: 10px;
}
.canApplication__accordion-list--active .canApplication__accordion-panel {
  display: block;
}
.canApplication__accordion-title {
  display: inline-block;
  vertical-align: middle;
}
.canApplication__accordion-title-block {
  background: #c7c7c7;
  cursor: pointer;
  display: block;
  font-size: 14px;
  padding: 5px;
}
.canApplication__accordion-panel {
  display: none;
  font-size: 0;
}
.canApplication__accordion-confirmation {
  display: inline-block;
  vertical-align: middle;
}
.canApplication__accordion-confirmation-block {
  display: inline-block;
  margin-right: 10px;
}
.canApplication__accordion-confirmation-block input {
  cursor: pointer;
  display: inline-block;
}
.canApplication__accordion-confirmation-block label {
  cursor: pointer;
  display: inline-block;
}
.canApplication__block {
  display: inline-block;
  font-size: 14px;
  margin-bottom: 10px;
  vertical-align: top;
  width: 33.33%;
}
.canApplication__block--full {
  display: block;
  margin: 10px 20px 0 20px;
  width: auto;
}
.canApplication__block--full .canApplication__label {
  display: inline-block;
}
.canApplication__block--full .canApplication__input {
  display: inline-block;
  width: 58%;
}
.canApplication__label {
  display: block;
}
.canApplication__input, .canApplication__select, .canApplication__textarea {
  display: block;
  width: 75%;
}
.canApplication__input--error {
  border: 1px solid #cc0000;
}
.canApplication__footer {
  text-align: right;
}
.canApplication__footer button {
  cursor: pointer;
  margin-left: 40px;
}
.canApplication__addInfo {
  font-size: 10px;
  display: block;
}
.canApplication__instructions {
  color: initial;
  margin-top: 20px;
}
.canApplication__instructions-block {
  font-size: 12px;
}
.canApplication__instructions-confirmation {
  display: block;
}
.canApplication__instructions-confirmation-block {
  font-size: 10px;
}
.canApplication__instructions-confirmation input {
  display: inline-block;
  vertical-align: top;
}
.canApplication__instructions-confirmation label {
  display: inline-block;
  vertical-align: top;
}
.canApplication__accNumber, .canApplication__comments, .canApplication__updatedBy, .canApplication__typeOfVehicle, .canApplication__fleetSize {
  display: none;
}
.canApplication__form--short .canApplication__accNumber, .canApplication__form--short .canApplication__comments, .canApplication__form--short .canApplication__updatedBy, .canApplication__form--short .canApplication__typeOfVehicle, .canApplication__form--short .canApplication__fleetSize {
  display: inline-block;
}
.canApplication__form--short .short {
  display: none;
}
.canApplication__form--short #printForm, .canApplication__form--short #approve, .canApplication__form--short #reject {
  display: none;
}
.canApplication__update-error {
  padding: 10px 0;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  margin-top: 5px;
}

.fleet-program-status-maintenance .pop-up {
  padding-top: 10px;
}
.fleet-program-status-maintenance .pop-up__content {
  width: 85%;
}
.fleet-program-status-maintenance .pop-up__content-body {
  max-height: 450px;
  overflow: auto;
}
.fleet-program-status-maintenance .pop-up__content-body .canApplication__left {
  width: 25%;
}

.can-search-and-update .pop-up__content-body .canApplication__left {
  width: 25%;
}

.corporate-sales-report-form .button:hover, .can-search-and-update .button:hover {
  cursor: pointer;
}
.corporate-sales-report-form .button__container, .can-search-and-update .button__container {
  margin: 15px 0;
}
.corporate-sales-report-form .button__container button, .can-search-and-update .button__container button {
  margin: 5px 0;
}
@media screen and (min-width: 768px) {
  .corporate-sales-report-form .button__container button, .can-search-and-update .button__container button {
    margin: 0;
  }
}
.corporate-sales-report-form .form__container, .can-search-and-update .form__container {
  text-align: center;
  padding: 20px;
}
.corporate-sales-report-form .form-submit__container, .can-search-and-update .form-submit__container {
  width: 100%;
  padding: 10px 0;
}
.corporate-sales-report-form .form-submit__container button[disabled], .can-search-and-update .form-submit__container button[disabled] {
  cursor: not-allowed;
  background: #dddddd;
}
.corporate-sales-report-form .form-submit__container button[disabled]:hover, .can-search-and-update .form-submit__container button[disabled]:hover {
  background: #dddddd;
}
.corporate-sales-report-form .form-submit__container button[disabled]:focus, .can-search-and-update .form-submit__container button[disabled]:focus {
  background: #dddddd;
}
.corporate-sales-report-form .form-submit__container input, .can-search-and-update .form-submit__container input {
  border: none;
}
.corporate-sales-report-form .form-title, .can-search-and-update .form-title {
  font: normal 700 18px/1.3333333333 "corpos";
  margin: 10px 0;
}
.corporate-sales-report-form .form-input, .can-search-and-update .form-input {
  width: 60%;
  display: inline-block;
  margin: 5px 0;
  padding: 5px;
}
.corporate-sales-report-form .form-input:focus, .can-search-and-update .form-input:focus {
  border: 2px solid #66AFE9;
}
.corporate-sales-report-form .form-input__container, .can-search-and-update .form-input__container {
  display: block;
}
@media screen and (min-width: 980px) {
  .corporate-sales-report-form .form-input__container, .can-search-and-update .form-input__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.corporate-sales-report-form .form-input-group, .can-search-and-update .form-input-group {
  display: block;
  width: 100%;
  font-size: 0;
}
@media screen and (min-width: 980px) {
  .corporate-sales-report-form .form-input-group, .can-search-and-update .form-input-group {
    width: 40%;
  }
  .corporate-sales-report-form .form-input-group:first-of-type, .can-search-and-update .form-input-group:first-of-type {
    width: 50%;
  }
}
.corporate-sales-report-form .form-input-group label, .can-search-and-update .form-input-group label {
  width: 40%;
  display: inline-block;
  font-size: 12px;
  text-align: left;
}
.corporate-sales-report-form .form-input-group--separator, .can-search-and-update .form-input-group--separator {
  position: relative;
  display: block;
  width: 100%;
  margin: 20px 0;
  min-height: 1px;
}
@media screen and (min-width: 980px) {
  .corporate-sales-report-form .form-input-group--separator, .can-search-and-update .form-input-group--separator {
    width: 10%;
    min-height: 180px;
  }
}
.corporate-sales-report-form .form-input-group--separator .line, .can-search-and-update .form-input-group--separator .line {
  position: absolute;
  left: 0%;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #c7c7c7;
  z-index: 1;
}
@media screen and (min-width: 980px) {
  .corporate-sales-report-form .form-input-group--separator .line, .can-search-and-update .form-input-group--separator .line {
    left: 49%;
    width: 1px;
  }
}
.corporate-sales-report-form .form-input-group--separator .wordwrapper, .can-search-and-update .form-input-group--separator .wordwrapper {
  text-align: center;
  height: 20px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -12px;
  z-index: 2;
}
.corporate-sales-report-form .form-input-group--separator .word, .can-search-and-update .form-input-group--separator .word {
  color: #c7c7c7;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 3px;
  font: normal 700 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
  background: #fff;
  display: inline-block;
}
.corporate-sales-report-form #canSearch .form-submit__container, .can-search-and-update #canSearch .form-submit__container {
  text-align: left;
}
@media screen and (min-width: 980px) {
  .corporate-sales-report-form #canSearch #canListInputFields label, .can-search-and-update #canSearch #canListInputFields label {
    width: 25%;
  }
  .corporate-sales-report-form #canSearch #canListInputFields input, .can-search-and-update #canSearch #canListInputFields input {
    width: 75%;
  }
}
.corporate-sales-report-form .hidden, .can-search-and-update .hidden {
  display: none;
}
.corporate-sales-report-form button:focus, .can-search-and-update button:focus {
  background: #176db7;
}
.corporate-sales-report-form .tab__items-list, .can-search-and-update .tab__items-list {
  display: block;
  text-align: center;
  border-bottom: 1px solid #c7c7c7;
}
@media screen and (min-width: 768px) {
  .corporate-sales-report-form .tab__items-list, .can-search-and-update .tab__items-list {
    font: normal 400 13px/1.5384615385 "Arial", Helvetica, Arial, sans-serif;
    display: flex;
    justify-content: space-between;
  }
}
.corporate-sales-report-form .tab__item, .can-search-and-update .tab__item {
  width: 50%;
  position: relative;
}
.corporate-sales-report-form .tab__item:hover .button, .can-search-and-update .tab__item:hover .button {
  color: #0090ff;
}
.corporate-sales-report-form .tab__item:first-of-type:after, .can-search-and-update .tab__item:first-of-type:after {
  border-right: 1px solid #c7c7c7;
  content: "";
  height: 28px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.corporate-sales-report-form .tab__item-link, .can-search-and-update .tab__item-link {
  color: #5a5a5a;
  display: block;
  width: 100%;
  padding: 15px;
}
.corporate-sales-report-form .tab__item-link:hover, .can-search-and-update .tab__item-link:hover {
  cursor: pointer;
  color: #0090ff;
}
.corporate-sales-report-form .tab__content, .can-search-and-update .tab__content {
  padding: 20px 0;
}
.corporate-sales-report-form .download-button-container, .can-search-and-update .download-button-container {
  padding: 20px 0;
  text-align: center;
}

.corporate-sales-report-form .sort-table__container .search, .can-search-and-update .sort-table__container .search, .fep-eab-filter .sort-table__container .search, .fleet-program-status-maintenance .sort-table__container .search {
  width: 75%;
  padding: 5px;
  display: inline-block;
  margin-left: 4%;
}
.corporate-sales-report-form .sort-table__container .search-container, .can-search-and-update .sort-table__container .search-container, .fep-eab-filter .sort-table__container .search-container, .fleet-program-status-maintenance .sort-table__container .search-container {
  text-align: right;
  font-size: 0;
}
.corporate-sales-report-form .sort-table__container .search-container label, .can-search-and-update .sort-table__container .search-container label, .fep-eab-filter .sort-table__container .search-container label, .fleet-program-status-maintenance .sort-table__container .search-container label {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  display: inline-block;
  width: 21%;
}
.corporate-sales-report-form .sort-table__container table, .can-search-and-update .sort-table__container table, .fep-eab-filter .sort-table__container table, .fleet-program-status-maintenance .sort-table__container table {
  min-width: auto;
  overflow: visible;
  width: 100%;
  margin: 20px 0;
  text-align: center;
}
.corporate-sales-report-form .sort-table__container table tr:nth-child(odd), .can-search-and-update .sort-table__container table tr:nth-child(odd), .fep-eab-filter .sort-table__container table tr:nth-child(odd), .fleet-program-status-maintenance .sort-table__container table tr:nth-child(odd) {
  background-color: #fff;
}
.corporate-sales-report-form .sort-table__container table tr:nth-child(even), .can-search-and-update .sort-table__container table tr:nth-child(even), .fep-eab-filter .sort-table__container table tr:nth-child(even), .fleet-program-status-maintenance .sort-table__container table tr:nth-child(even) {
  background-color: #f1f1f1;
}
.corporate-sales-report-form .sort-table__container table td:first-child, .can-search-and-update .sort-table__container table td:first-child, .fep-eab-filter .sort-table__container table td:first-child, .fleet-program-status-maintenance .sort-table__container table td:first-child {
  font: normal 700 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.corporate-sales-report-form .sort-table__container table td, .can-search-and-update .sort-table__container table td, .fep-eab-filter .sort-table__container table td, .fleet-program-status-maintenance .sort-table__container table td {
  padding: 15px;
  font: normal 400 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.corporate-sales-report-form .sort-table__container table td.print, .can-search-and-update .sort-table__container table td.print, .fep-eab-filter .sort-table__container table td.print, .fleet-program-status-maintenance .sort-table__container table td.print {
  color: #176db7;
}
.corporate-sales-report-form .sort-table__container table td.print:hover, .can-search-and-update .sort-table__container table td.print:hover, .fep-eab-filter .sort-table__container table td.print:hover, .fleet-program-status-maintenance .sort-table__container table td.print:hover {
  color: #0090ff;
  cursor: pointer;
}
.corporate-sales-report-form .sort-table__container table td, .corporate-sales-report-form .sort-table__container table th, .can-search-and-update .sort-table__container table td, .can-search-and-update .sort-table__container table th, .fep-eab-filter .sort-table__container table td, .fep-eab-filter .sort-table__container table th, .fleet-program-status-maintenance .sort-table__container table td, .fleet-program-status-maintenance .sort-table__container table th {
  border: 1px solid #e3e3e3;
  word-wrap: break-word;
}
.corporate-sales-report-form .sort-table__container table th, .can-search-and-update .sort-table__container table th, .fep-eab-filter .sort-table__container table th, .fleet-program-status-maintenance .sort-table__container table th {
  text-transform: uppercase;
  background-color: #c7c7c7;
  font: normal 700 13px/1.5384615385 "Arial", Helvetica, Arial, sans-serif;
  padding: 10px;
  cursor: pointer;
}
.corporate-sales-report-form .sort-table__container table th .icon, .can-search-and-update .sort-table__container table th .icon, .fep-eab-filter .sort-table__container table th .icon, .fleet-program-status-maintenance .sort-table__container table th .icon {
  margin: 0 5px;
}
.corporate-sales-report-form .sort-table__container table th .icon:before, .can-search-and-update .sort-table__container table th .icon:before, .fep-eab-filter .sort-table__container table th .icon:before, .fleet-program-status-maintenance .sort-table__container table th .icon:before {
  content: "\e93c";
}
.corporate-sales-report-form .sort-table__container table .asc .icon:before, .can-search-and-update .sort-table__container table .asc .icon:before, .fep-eab-filter .sort-table__container table .asc .icon:before, .fleet-program-status-maintenance .sort-table__container table .asc .icon:before {
  content: "\e93a";
}
.corporate-sales-report-form .sort-table__container table .desc .icon:before, .can-search-and-update .sort-table__container table .desc .icon:before, .fep-eab-filter .sort-table__container table .desc .icon:before, .fleet-program-status-maintenance .sort-table__container table .desc .icon:before {
  content: "\e93b";
}
.corporate-sales-report-form .sort-table__container .pagination-container, .can-search-and-update .sort-table__container .pagination-container, .fep-eab-filter .sort-table__container .pagination-container, .fleet-program-status-maintenance .sort-table__container .pagination-container {
  display: inline-block;
  width: 100%;
}
.corporate-sales-report-form .sort-table__container .pagination-info, .can-search-and-update .sort-table__container .pagination-info, .fep-eab-filter .sort-table__container .pagination-info, .fleet-program-status-maintenance .sort-table__container .pagination-info {
  display: inline-block;
}
.corporate-sales-report-form .sort-table__container .pagination-wrap, .can-search-and-update .sort-table__container .pagination-wrap, .fep-eab-filter .sort-table__container .pagination-wrap, .fleet-program-status-maintenance .sort-table__container .pagination-wrap {
  user-select: none;
  display: flex;
  float: right;
  align-items: stretch;
  border: 1px solid #e3e3e3;
}
.corporate-sales-report-form .sort-table__container .pagination-wrap a, .can-search-and-update .sort-table__container .pagination-wrap a, .fep-eab-filter .sort-table__container .pagination-wrap a, .fleet-program-status-maintenance .sort-table__container .pagination-wrap a {
  text-decoration: none;
}
.corporate-sales-report-form .sort-table__container .pagination-layout, .can-search-and-update .sort-table__container .pagination-layout, .fep-eab-filter .sort-table__container .pagination-layout, .fleet-program-status-maintenance .sort-table__container .pagination-layout {
  display: flex;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.corporate-sales-report-form .sort-table__container .pagination-layout li a.page, .can-search-and-update .sort-table__container .pagination-layout li a.page, .fep-eab-filter .sort-table__container .pagination-layout li a.page, .fleet-program-status-maintenance .sort-table__container .pagination-layout li a.page {
  display: block;
  border: none;
  line-height: 24px;
  padding: 0;
  text-align: center;
  font-weight: 700;
  color: #176db7;
  background-color: transparent;
  padding: 5px 10px;
  border-right: 1px solid #e3e3e3;
  min-width: 35px;
}
.corporate-sales-report-form .sort-table__container .pagination-layout li a.page:first-of-type, .can-search-and-update .sort-table__container .pagination-layout li a.page:first-of-type, .fep-eab-filter .sort-table__container .pagination-layout li a.page:first-of-type, .fleet-program-status-maintenance .sort-table__container .pagination-layout li a.page:first-of-type {
  border-left: 1px solid #e3e3e3;
}
.corporate-sales-report-form .sort-table__container .pagination-layout li a.page:hover, .can-search-and-update .sort-table__container .pagination-layout li a.page:hover, .fep-eab-filter .sort-table__container .pagination-layout li a.page:hover, .fleet-program-status-maintenance .sort-table__container .pagination-layout li a.page:hover {
  background-color: #dddddd;
}
.corporate-sales-report-form .sort-table__container .pagination-layout li.active a.page, .can-search-and-update .sort-table__container .pagination-layout li.active a.page, .fep-eab-filter .sort-table__container .pagination-layout li.active a.page, .fleet-program-status-maintenance .sort-table__container .pagination-layout li.active a.page {
  background-color: #176db7;
  color: #fff;
  border-right: 1px solid #176db7;
}
.corporate-sales-report-form .sort-table__container .pagination-next, .corporate-sales-report-form .sort-table__container .pagination-prev, .can-search-and-update .sort-table__container .pagination-next, .can-search-and-update .sort-table__container .pagination-prev, .fep-eab-filter .sort-table__container .pagination-next, .fep-eab-filter .sort-table__container .pagination-prev, .fleet-program-status-maintenance .sort-table__container .pagination-next, .fleet-program-status-maintenance .sort-table__container .pagination-prev {
  color: #176db7;
  padding: 5px 10px;
  display: flex;
  align-items: center;
}
.corporate-sales-report-form .sort-table__container .pagination-next:hover, .corporate-sales-report-form .sort-table__container .pagination-prev:hover, .can-search-and-update .sort-table__container .pagination-next:hover, .can-search-and-update .sort-table__container .pagination-prev:hover, .fep-eab-filter .sort-table__container .pagination-next:hover, .fep-eab-filter .sort-table__container .pagination-prev:hover, .fleet-program-status-maintenance .sort-table__container .pagination-next:hover, .fleet-program-status-maintenance .sort-table__container .pagination-prev:hover {
  background: #dddddd;
}
.corporate-sales-report-form .sort-table__container .pagination-next.disabled, .corporate-sales-report-form .sort-table__container .pagination-prev.disabled, .can-search-and-update .sort-table__container .pagination-next.disabled, .can-search-and-update .sort-table__container .pagination-prev.disabled, .fep-eab-filter .sort-table__container .pagination-next.disabled, .fep-eab-filter .sort-table__container .pagination-prev.disabled, .fleet-program-status-maintenance .sort-table__container .pagination-next.disabled, .fleet-program-status-maintenance .sort-table__container .pagination-prev.disabled {
  color: #c7c7c7;
  cursor: default;
}
.corporate-sales-report-form .sort-table__container .pagination-next.disabled:hover, .corporate-sales-report-form .sort-table__container .pagination-prev.disabled:hover, .can-search-and-update .sort-table__container .pagination-next.disabled:hover, .can-search-and-update .sort-table__container .pagination-prev.disabled:hover, .fep-eab-filter .sort-table__container .pagination-next.disabled:hover, .fep-eab-filter .sort-table__container .pagination-prev.disabled:hover, .fleet-program-status-maintenance .sort-table__container .pagination-next.disabled:hover, .fleet-program-status-maintenance .sort-table__container .pagination-prev.disabled:hover {
  color: #c7c7c7;
  background: transparent;
}
.corporate-sales-report-form .sort-table__container .hidden, .can-search-and-update .sort-table__container .hidden, .fep-eab-filter .sort-table__container .hidden, .fleet-program-status-maintenance .sort-table__container .hidden {
  display: none;
}
.corporate-sales-report-form .sort-table-tools, .can-search-and-update .sort-table-tools, .fep-eab-filter .sort-table-tools, .fleet-program-status-maintenance .sort-table-tools {
  display: flex;
  justify-content: space-between;
}
.corporate-sales-report-form .sort-table-tools .entries-amount, .can-search-and-update .sort-table-tools .entries-amount, .fep-eab-filter .sort-table-tools .entries-amount, .fleet-program-status-maintenance .sort-table-tools .entries-amount {
  display: inline-block;
}
.corporate-sales-report-form .sort-table-tools .entries-amount select, .can-search-and-update .sort-table-tools .entries-amount select, .fep-eab-filter .sort-table-tools .entries-amount select, .fleet-program-status-maintenance .sort-table-tools .entries-amount select {
  padding: 4px;
}
.corporate-sales-report-form .sort-table-tools .entries-amount span, .can-search-and-update .sort-table-tools .entries-amount span, .fep-eab-filter .sort-table-tools .entries-amount span, .fleet-program-status-maintenance .sort-table-tools .entries-amount span {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.corporate-sales-report-form .sort-table__table-section, .can-search-and-update .sort-table__table-section, .fep-eab-filter .sort-table__table-section, .fleet-program-status-maintenance .sort-table__table-section {
  overflow-x: auto;
}

.can-search-and-update .sort-table__table-section {
  max-height: 400px;
}
.can-search-and-update .sort-table__table-section th {
  cursor: default;
}
.can-search-and-update .form__container {
  padding: 0;
}
.can-search-and-update .pop-up__content-body {
  overflow: auto;
  max-height: 250px;
}
.can-search-and-update .pop-up table {
  width: 100%;
  text-align: center;
  border: 1px solid #c7c7c7;
}
.can-search-and-update .pop-up table caption {
  margin: 5px 0;
  font: normal 700 18px/1.3333333333 "corpos";
}
.can-search-and-update .pop-up table th {
  text-transform: uppercase;
  font: normal 700 13px/1.5384615385 "Arial", Helvetica, Arial, sans-serif;
  padding: 10px;
  background: #dddddd;
}
.can-search-and-update .pop-up table tr {
  border-top: 1px solid #c7c7c7;
}
.can-search-and-update .pop-up table td, .can-search-and-update .pop-up table th {
  border-right: 1px solid #c7c7c7;
}
.can-search-and-update .pop-up table td:last-of-type, .can-search-and-update .pop-up table th:last-of-type {
  border-right: none;
}
.can-search-and-update .pop-up[type=update] {
  padding-top: 10px;
}
.can-search-and-update .pop-up[type=update] .pop-up__content {
  width: 85%;
}
.can-search-and-update .pop-up[type=update] .pop-up__content-body {
  max-height: 450px;
  overflow: auto;
}
.can-search-and-update .button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.fleet-program-status-maintenance #table-container {
  padding: 15px;
}

.fep-eab-form .form-title {
  font: normal 700 26px/1.2307692308 "corpos";
  padding: 10px 0;
}
.fep-eab-form .form__container form {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
}
.fep-eab-form .form__container select {
  background: white;
}
.fep-eab-form .form-input__group {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .fep-eab-form .form-input__group {
    display: inline-block;
    width: 50%;
    padding: 10px;
  }
}
.fep-eab-form .form-input__group:first-of-type {
  width: 100%;
}
.fep-eab-form .form-submit__container {
  padding: 10px;
  width: 100%;
}

.fep-eab-filter {
  margin: 20px 0;
}
.fep-eab-filter fieldset {
  border: 1px solid #c7c7c7;
  padding: 6px 12px;
}
.fep-eab-filter fieldset legend {
  padding: 2px;
}
.fep-eab-filter fieldset .form-input {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.fep-eab-filter fieldset .form-left {
  display: inline-block;
  font-size: 0;
  padding-top: 5px;
  vertical-align: top;
  width: 66%;
}
.fep-eab-filter fieldset .form-left .form-input__group {
  display: inline-block;
  font-size: 12px;
  margin-bottom: 15px;
  margin-right: 5%;
  vertical-align: top;
  width: 45%;
}
.fep-eab-filter fieldset .form-right {
  border: 1px solid #c7c7c7;
  display: inline-block;
  padding: 10px 20px;
  width: 33%;
}
.fep-eab-filter fieldset .form-right label {
  display: inline-block;
  min-width: 35px;
}
.fep-eab-filter fieldset .form-right .form-input__group {
  margin-top: 10px;
}
.fep-eab-filter fieldset .form-right .form-input {
  width: 160px;
}
.fep-eab-filter fieldset .form-right .icon-calendar {
  font-size: 22px;
}

#activate-deactivate label {
  display: block;
}
#activate-deactivate .activate-deactivate__input-field {
  display: block;
  width: 100%;
  padding: 5px;
}

.form__message-container {
  padding: 10px 10px;
  text-align: center;
  font-weight: 700;
  position: relative;
  margin-top: 5px;
}
.form__message-container .form__message {
  padding-right: 20px;
}
.form__message-container .icon-cross {
  position: absolute;
  right: 0;
  margin-right: 10px;
  opacity: 0.5;
}
.form__message-container .icon-cross:hover {
  opacity: 1;
  cursor: pointer;
}
.form__message--error {
  color: #A94442;
  background-color: #F2DEDE;
  border: 1px solid #A94442;
}
.form__message--success {
  color: #2B542C;
  background-color: #dff0d8;
  border: 1px solid #2B542C;
}

.print-form .fleet-tab, .print-form .form__message-container, .print-form .header, .print-form .footer, .print-form .canApplication__left, .print-form .pop-up__content-header, .print-form .pop-up__content-footer {
  display: none;
}
.print-form .pop-up {
  padding: 0;
  position: initial;
  height: auto;
}
.print-form .pop-up__content {
  border-radius: 0;
  margin: 0;
  width: auto;
}
.print-form .pop-up__content-body {
  max-height: none;
}
.print-form .canApplication__right {
  margin: 0;
  width: auto;
}
.print-form .canApplication__accordion-panel {
  display: block;
}
.print-form .canApplication__footer {
  display: none;
}
.print-form .title {
  margin-top: 20px;
}

.quiz-container__form {
  font-size: 0;
}
.quiz-container__btn {
  padding: 5px 17px;
  display: inline-block;
  border: 1px solid #b1b1b2;
  border-radius: 7px;
  width: 100%;
  margin-top: 10px;
  margin-right: 4px;
  margin-bottom: 4px;
  transition: all ease 0s;
}
.quiz-container__btnTest {
  box-shadow: inset 934px 0 0 0 blue;
}
.quiz-container__parentdiv {
  display: flex;
  justify-content: center;
}
.quiz-container__block {
  display: inline-block;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .quiz-container__block {
    margin-right: 4%;
    width: 48%;
  }
}
.quiz-container__block:nth-child(even) {
  margin-right: 0;
}
.quiz-container__block:nth-last-child(2) {
  border-bottom: none;
}
.quiz-container__block:last-child {
  text-align: center;
}
.quiz-container__recaptcha-container {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .quiz-container__recaptcha-container {
    margin-bottom: 0;
  }
}
.quiz-container__recaptcha-container--hidden {
  display: none;
}
.quiz-container__recaptcha-container #form-resubmit-recaptcha {
  display: inline-block;
}
.quiz-container__assessment-details {
  text-align: center;
}

.question__block {
  box-shadow: inset 0px 0px 5px 2px #c7c7c7;
  margin-bottom: 10px;
  padding: 20px 20px 15px 0;
}
.question__para {
  margin-bottom: 20px;
}
.question__label {
  font-size: 14px;
  display: block;
  margin-left: 20px;
}
.question__options {
  margin-left: 20px;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .question__options {
    margin-left: 100px;
  }
}
.question__textarea_border {
  width: 100%;
  border: 2px solid black;
  margin-left: -60px;
}
.question__option-block {
  display: block;
  margin-right: 5px;
  position: relative;
}
.question__option-block input {
  position: absolute;
}
@media screen and (min-width: 980px) {
  .question__option-block input {
    cursor: pointer;
    display: inline-block;
  }
}
.question__option-block label {
  display: block;
  padding-left: 25px;
}
@media screen and (min-width: 980px) {
  .question__option-block label {
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
  }
}
.question__button-container {
  text-align: right;
}
.question__button-container button {
  cursor: pointer;
}
.question__error-container {
  color: #cc0000;
  margin-left: 100px;
}
.question__submit-button-container {
  display: block;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .question__submit-button-container {
    justify-content: space-around;
    align-items: center;
    display: flex;
    float: right;
    width: 50%;
  }
}
.question__range {
  display: block;
}
@media screen and (min-width: 768px) {
  .question__range {
    display: flex;
    align-items: center;
    padding-top: 30px;
  }
}
.question__range-text {
  font-size: 14px;
  text-align: center;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .question__range-text {
    width: 30%;
  }
}
.question__range-input {
  width: 100%;
  padding: 25px 0;
}
@media screen and (min-width: 768px) {
  .question__range-input {
    width: 40%;
    padding: 0;
  }
}
.question__range-input input {
  width: 100%;
}
.question__range input[type=range] {
  -webkit-appearance: none;
  margin: 5px 0;
  width: 100%;
}
.question__range input[type=range]:focus {
  outline: none;
}
.question__range input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: #176db7;
  border-radius: 25px;
}
.question__range input[type=range]::-webkit-slider-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 4px 0 rgb(0, 0, 0);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}
.question__range input[type=range]::-ms-thumb {
  margin: -5px 1px 0 1px;
}
.question__range input[type=range]:focus::-webkit-slider-runnable-track {
  background: #176db7;
}
.question__range input[type=range]::-ms-track {
  height: 4px;
  background: transparent;
  border-color: transparent;
  border-width: 6px 0;
  color: transparent;
}
.question__range input[type=range]::-ms-fill-upper {
  background: #176db7;
  border-radius: 6px;
}
.question__range input[type=range]::-ms-fill-lower {
  background: #176db7;
  border-radius: 6px;
}
.question__range .range-wrap {
  position: relative;
  padding: 0 5px;
  padding-top: 20px;
}
.question__range .range-value {
  position: absolute;
  margin-left: 2px;
  bottom: 100%;
  padding-bottom: 20px;
}
.question__range .range-value span {
  width: 30px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: #176db7;
  color: #fff;
  font-size: 12px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 6px;
}
.question__range .range-value span:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid #176db7;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
}
.question__range .range__datalist {
  display: flex;
  justify-content: space-between;
  padding: 5px 4px;
}
.question__message-container {
  padding: 20px 0;
}
.question__message-container .success__message-container {
  background-color: #f6f6f6;
  box-shadow: inset 0px 0px 5px 2px #c7c7c7;
  margin-bottom: 10px;
  padding: 20px;
}
.question__message-container .success__message-container .success__button-container {
  text-align: right;
}
.question__message-container .success__message-container .success__button-container .start_assessmment1 {
  margin-bottom: 10px;
}
.question__message-container .failure__message-container {
  background-color: #f6f6f6;
  box-shadow: inset 0px 0px 5px 2px #c7c7c7;
  margin-bottom: 10px;
  padding: 20px;
  text-align: center;
}
.question__message-container p {
  font-size: 14px;
  margin-bottom: 15px;
  line-height: normal;
}

.fmla-request-form {
  color: #333;
}
.fmla-request-form__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.fmla-request-form__group {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.fmla-request-form__group_input {
  padding: 10px 0px;
}
.fmla-request-form__group_btn {
  padding: 10px 0px;
  text-align: center;
}
.fmla-request-form__asterisk_field {
  color: red;
}
.fmla-request-form__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.fmla-request-form__control {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.fmla-request-form__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.fmla-request-form__control--error, .fmla-request-form__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.fmla-request-form__control--success, .fmla-request-form__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.fmla-request-form__control_textarea {
  height: auto;
}
.fmla-request-form__control_document {
  background: #fff;
  padding: 6px 10px;
  width: 100%;
  font-family: "Arial";
}
.fmla-request-form__control_table {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.fmla-request-form__control_table:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.fmla-request-form__control_table--error, .fmla-request-form__control_table--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.fmla-request-form__control_table--success, .fmla-request-form__control_table--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.fmla-request-form__title {
  font-size: 20px;
  width: 100%;
}
.fmla-request-form__text {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.fmla-request-form__hide {
  visibility: hidden;
}
.fmla-request-form__table_column {
  width: 35%;
  vertical-align: middle;
}
.fmla-request-form__table_column_checkbox {
  width: 35%;
}
.fmla-request-form__table_column_padding {
  padding-left: 15px;
}
.fmla-request-form__table {
  width: 80%;
}
.fmla-request-form__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.fmla-request-form__error-field--hidden {
  display: none;
}
.fmla-request-form__btn_column {
  text-align: center;
}
.fmla-request-form__reset_btn {
  background-color: #D3D3D3;
  color: #333;
  border-color: #ccc;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
}
.fmla-request-form__section {
  display: block;
}
.fmla-request-form__section .pop-up {
  padding-top: 150px;
}
.fmla-request-form__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.fmla-request-form__section .pop-up__content-body {
  background: #fff;
}

.ppl-request-form {
  color: #333;
}
.ppl-request-form__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.ppl-request-form__group {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.ppl-request-form__group_input {
  padding: 10px 0px;
}
.ppl-request-form__group_btn {
  padding: 10px 0px;
  text-align: center;
}
.ppl-request-form__asterisk_field {
  color: red;
}
.ppl-request-form__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.ppl-request-form__control_date {
  width: 50%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.ppl-request-form__control {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.ppl-request-form__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.ppl-request-form__control--error, .ppl-request-form__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.ppl-request-form__control--success, .ppl-request-form__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.ppl-request-form__control_textarea {
  height: auto;
}
.ppl-request-form__control_document {
  background: #fff;
  padding: 6px 10px;
  width: 100%;
  font-family: "Arial";
}
.ppl-request-form__control_table {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.ppl-request-form__control_table:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.ppl-request-form__control_table--error, .ppl-request-form__control_table--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.ppl-request-form__control_table--success, .ppl-request-form__control_table--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.ppl-request-form__title {
  font-size: 20px;
  width: 100%;
}
.ppl-request-form__text {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.ppl-request-form__hide {
  visibility: hidden;
}
.ppl-request-form__table_column {
  width: 35%;
  vertical-align: middle;
}
.ppl-request-form__table_column_checkbox {
  width: 35%;
}
.ppl-request-form__table_column_padding {
  padding-left: 15px;
}
.ppl-request-form__table {
  width: 80%;
}
.ppl-request-form__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.ppl-request-form__error-field--hidden {
  display: none;
}
.ppl-request-form__btn_column {
  text-align: center;
}
.ppl-request-form__reset_btn {
  background-color: #D3D3D3;
  color: #333;
  border-color: #ccc;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
}
.ppl-request-form__section {
  display: block;
}
.ppl-request-form__section .pop-up {
  padding-top: 150px;
}
.ppl-request-form__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.ppl-request-form__section .pop-up__content-body {
  background: #fff;
}

.emp-auth-form {
  color: #333;
}
.emp-auth-form__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.emp-auth-form__group {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.emp-auth-form__group_input {
  padding: 10px 0px;
}
.emp-auth-form__group_btn {
  padding: 10px 0px;
  text-align: center;
}
.emp-auth-form__asterisk_field {
  color: red;
}
.emp-auth-form__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.emp-auth-form__control_date {
  width: 50%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.emp-auth-form__control {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.emp-auth-form__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.emp-auth-form__control--error, .emp-auth-form__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.emp-auth-form__control--success, .emp-auth-form__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.emp-auth-form__control_textarea {
  height: auto;
}
.emp-auth-form__control_document {
  background: #fff;
  padding: 6px 10px;
  width: 100%;
  font-family: "Arial";
}
.emp-auth-form__control_table {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.emp-auth-form__control_table:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.emp-auth-form__control_table--error, .emp-auth-form__control_table--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.emp-auth-form__control_table--success, .emp-auth-form__control_table--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.emp-auth-form__title {
  font-size: 20px;
  width: 100%;
}
.emp-auth-form__text {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.emp-auth-form__hide {
  visibility: hidden;
}
.emp-auth-form__table_column {
  width: 35%;
  vertical-align: middle;
}
.emp-auth-form__table_column_checkbox {
  width: 35%;
}
.emp-auth-form__table_column_padding {
  padding-left: 15px;
}
.emp-auth-form__table {
  width: 80%;
}
.emp-auth-form__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.emp-auth-form__error-field--hidden {
  display: none;
}
.emp-auth-form__btn_column {
  text-align: center;
}
.emp-auth-form__reset_btn {
  background-color: #D3D3D3;
  color: #333;
  border-color: #ccc;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
}
.emp-auth-form__section {
  display: block;
}
.emp-auth-form__section .pop-up {
  padding-top: 150px;
}
.emp-auth-form__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.emp-auth-form__section .pop-up__content-body {
  background: #fff;
}

.pension-form {
  color: #333;
}
.pension-form__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.pension-form__group {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.pension-form__group_input {
  padding: 10px 0px;
}
.pension-form__group_btn {
  padding: 10px 0px;
  text-align: center;
}
.pension-form__asterisk_field {
  color: red;
}
.pension-form__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.pension-form__control {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.pension-form__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.pension-form__control--error, .pension-form__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.pension-form__control--success, .pension-form__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.pension-form__control_textarea {
  height: auto;
}
.pension-form__control_document {
  background: #fff;
  padding: 6px 10px;
  width: 100%;
  font-family: "Arial";
}
.pension-form__control_table {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.pension-form__control_table:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.pension-form__control_table--error, .pension-form__control_table--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.pension-form__control_table--success, .pension-form__control_table--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.pension-form__title {
  font-size: 20px;
  width: 100%;
}
.pension-form__text {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.pension-form__hide {
  visibility: hidden;
}
.pension-form__table_column {
  width: 35%;
  vertical-align: middle;
}
.pension-form__table_column_checkbox {
  width: 35%;
}
.pension-form__table_column_padding {
  padding-left: 15px;
}
.pension-form__table {
  width: 80%;
}
.pension-form__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.pension-form__error-field--hidden {
  display: none;
}
.pension-form__btn_column {
  text-align: center;
}
.pension-form__reset_btn {
  background-color: #D3D3D3;
  color: #333;
  border-color: #ccc;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
}
.pension-form__section {
  display: block;
}
.pension-form__section .pop-up {
  padding-top: 150px;
}
.pension-form__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.pension-form__section .pop-up__content-body {
  background: #fff;
}
.pension-form__control_textarea {
  height: auto;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 6px 12px;
  width: 100%;
}

.mbm-lease-return-form {
  color: #333;
}
.mbm-lease-return-form__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.mbm-lease-return-form__group {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.mbm-lease-return-form__group_input {
  padding: 10px 0px;
}
.mbm-lease-return-form__group_btn {
  padding: 10px 0px;
  text-align: center;
}
.mbm-lease-return-form__asterisk_field {
  color: red;
}
.mbm-lease-return-form__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.mbm-lease-return-form__control {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.mbm-lease-return-form__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.mbm-lease-return-form__control--error, .mbm-lease-return-form__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.mbm-lease-return-form__control--success, .mbm-lease-return-form__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.mbm-lease-return-form__control_textarea {
  height: auto;
}
.mbm-lease-return-form__control_document {
  background: #fff;
  padding: 6px 10px;
  width: 100%;
  font-family: "Arial";
}
.mbm-lease-return-form__control_table {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.mbm-lease-return-form__control_table:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.mbm-lease-return-form__control_table--error, .mbm-lease-return-form__control_table--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.mbm-lease-return-form__control_table--success, .mbm-lease-return-form__control_table--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.mbm-lease-return-form__title {
  font-size: 20px;
  width: 100%;
}
.mbm-lease-return-form__text {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.mbm-lease-return-form__hide {
  visibility: hidden;
}
.mbm-lease-return-form__table_column {
  width: 35%;
  vertical-align: middle;
}
.mbm-lease-return-form__table_column_checkbox {
  width: 35%;
}
.mbm-lease-return-form__table_column_padding {
  padding-left: 15px;
}
.mbm-lease-return-form__table {
  width: 80%;
}
.mbm-lease-return-form__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.mbm-lease-return-form__error-field--hidden {
  display: none;
}
.mbm-lease-return-form__btn_column {
  text-align: center;
}
.mbm-lease-return-form__reset_btn {
  background-color: #D3D3D3;
  color: #333;
  border-color: #ccc;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
}
.mbm-lease-return-form__section {
  display: block;
}
.mbm-lease-return-form__section .pop-up {
  padding-top: 150px;
}
.mbm-lease-return-form__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.mbm-lease-return-form__section .pop-up__content-body {
  background: #fff;
}
.mbm-lease-return-form__control_textarea {
  height: auto;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 6px 12px;
  width: 100%;
}
.mbm-lease-return-form__input-symbol-dollar {
  position: relative;
  left: 3%;
}
.mbm-lease-return-form__input-symbol-dollar:after {
  content: "$";
  font-size: 104% !important;
  font-weight: 400;
  left: 3%;
  position: absolute;
  top: -31%;
  padding-right: 3% !important;
}
.mbm-lease-return-form__abc-input {
  padding-left: 5% !important;
}

.mb-lease-report {
  color: #333;
}
.mb-lease-report__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.mb-lease-report__group {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.mb-lease-report__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.mb-lease-report__index {
  display: inline-block;
  margin: 20px 0 10px 0;
}
.mb-lease-report__index--anchor {
  color: #428bca;
  float: left;
  padding: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #dddddd;
  width: 55px;
  height: 35px;
  text-align: center;
  margin-bottom: 10px;
}
.mb-lease-report__index--anchor:hover {
  color: #285E8E;
  background-color: #eeeeee;
  border-color: #dddddd;
  cursor: pointer;
}
.mb-lease-report__index--anchor:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.mb-lease-report__index--anchor:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.mb-lease-report__index--anchor-hidden {
  display: none;
}
.mb-lease-report__index--active {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.mb-lease-report__index--active:hover {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.mb-lease-report__index--disabled {
  pointer-events: none;
  color: grey;
  cursor: default;
}
.mb-lease-report__index--disabled:hover, .mb-lease-report__index--disabled:focus {
  color: grey;
  cursor: default;
  border-color: none;
  background-color: #fff;
}
.mb-lease-report__table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.mb-lease-report__table .fixed {
  table-layout: fixed;
}
.mb-lease-report__table .fixed td {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
.mb-lease-report__table .fixed th {
  cursor: pointer;
}
.mb-lease-report__table th[data-filter-value] {
  cursor: pointer;
}
.mb-lease-report__sortable {
  position: relative;
}
.mb-lease-report__child {
  font: initial;
}
.mb-lease-report__half_width {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.mb-lease-report__btn_label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #5cb85c;
}
.mb-lease-report__control {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.mb-lease-report__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.mb-lease-report__control--error, .mb-lease-report__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.mb-lease-report__control--success, .mb-lease-report__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.mb-lease-report__table_column_padding_left {
  padding-left: 15px;
}
.mb-lease-report__table_column_padding_right {
  padding-right: 15px;
}
.mb-lease-report__error-field {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 30px 10px 10px;
}
.mb-lease-report__loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.mb-lease-report__listing_loader {
  position: fixed;
  left: 54%;
  top: 70%;
  z-index: 1;
  width: 60px;
  height: 60px;
  margin: -75px 0 0 -75px;
  border: 10px solid #DCDCDC;
  border-radius: 50%;
  border-top: 10px solid #000;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.mb-lease-report__button {
  color: #fff;
  background-color: #176db7;
  padding: 5px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
  border-radius: 0.6em;
}
.mb-lease-report__button:hover {
  cursor: pointer;
  background-color: #0090ff;
}
.mb-lease-report__border_rule {
  border-bottom: 1px solid #DCDCDC;
}
.mb-lease-report__td_padding {
  margin: 10px;
  padding: 10px 3px;
  text-align: center;
}
.mb-lease-report__th_padding {
  margin: 10px;
  padding: 10px 0px;
  text-align: center;
}
.mb-lease-report__pagin_padding {
  display: flex;
  justify-content: center;
  padding-top: 25px;
  font-size: initial;
}
.mb-lease-report__arrow_icon {
  color: #add8e6;
}
.mb-lease-report__downloadPdf {
  font-size: 35px;
}
.mb-lease-report__download {
  position: absolute;
  right: 40px;
}
.mb-lease-report__popup-header {
  height: 40px;
}

.dealer-results__list {
  max-height: 195px;
  overflow-y: auto;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
}
.dealer-results.error .dealer-results__list {
  border: 0;
}
.dealer-results__sub-heading-copy {
  margin-bottom: 20px;
}
.dealer-results__error-message {
  display: none;
  color: #cc0000;
}
.dealer-results.error .dealer-results__error-message {
  display: block;
}
.dealer-results__sub-heading {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
}
.dealer-results__sub-heading-copy {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.dealer-results .load-more {
  color: #176db7;
  cursor: pointer;
  padding: 0 10px;
  display: inline-block;
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.dealer-results .load-more:hover {
  color: #0090ff;
}
.dealer-results .load-more-container {
  padding: 10px;
  display: flex;
  float: right;
}
.dealer-results .load-more-container .loading-spinner:before {
  width: 20px;
  height: 20px;
}

.dealer-result-list-item {
  border-bottom: 1px dotted #e3e3e3;
  padding-bottom: 10px;
  position: relative;
  cursor: pointer;
  min-height: 65px;
}
.dealer-result-list-item__suggested {
  padding-top: 15px;
}
.dealer-result-list-item:last-of-type {
  border-bottom: none;
}
.dealer-result-list-item__address-container {
  padding-top: 10px;
  padding-left: 35px;
}
.dealer-result-list-item__address-container::before {
  content: "\e92d";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 25px;
  position: absolute;
  width: 5px;
  margin-top: -5px;
  right: 0;
  left: 0;
}
.dealer-result-list-item__dealer-name {
  font: normal 700 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.dealer-result-list-item__dealer-distance {
  font: normal 400 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
  float: right;
  margin-right: 0;
}
.dealer-result-list-item__address {
  font: normal 400 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.dealer-result-list-item__suggested-dealer {
  font: normal 400 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
  color: #176db7;
  padding-top: 15px;
}
.dealer-result-list-item__suggested-dealer:before {
  content: "\e94d";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  margin-right: 5px;
}
.dealer-result-list-item__error {
  color: #cc0000;
}

.preferred-dealer {
  position: relative;
  padding-top: 10px;
  padding-bottom: 20px;
}
.preferred-dealer__address-container-dealer {
  padding-top: 10px;
  padding-left: 35px;
  text-align: center;
}
.preferred-dealer__dealer-name {
  font: normal 700 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
  color: #176db7;
}
.preferred-dealer__dealer-name-label {
  font: normal 700 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
  color: #5a5a5a;
}
.preferred-dealer__address {
  font: normal 400 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.preferred-dealer__phone-number {
  font: normal 400 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
  color: #176db7;
}
.preferred-dealer__icon {
  font-size: 6px;
}
.preferred-dealer__link {
  font-weight: bold;
}
.preferred-dealer__map {
  height: 145px;
  margin-top: 5px;
}

.location-search {
  position: relative;
}
.location-search__results {
  position: absolute;
  width: 100%;
  z-index: 501;
}
.location-search--active .location-search__results {
  display: block;
}
.location-search__locations {
  background: #fff;
  border: 1px solid #c7c7c7;
  border-top: none;
  z-index: 100;
}
.location-search__locations::after {
  clear: both;
  content: "";
  display: block;
}
.location-search__locations[aria-hidden=true] {
  display: none;
}
.location-search__location {
  color: #222;
  display: flex;
  align-items: center;
  padding: 5px;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.location-search__location:hover {
  color: #0090ff;
  cursor: pointer;
}
.location-search__location:before {
  content: "\e90a";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 120%;
  color: #999;
  padding-right: 5px;
}
.location-search__location:focus, .location-search__location--active {
  color: #0090ff;
}
.location-search__engine {
  float: right;
  margin: 0 5px 5px 0;
  background: url("/etc/designs/microsites/images/powered_by_google_on_white.png");
  background-size: contain;
  display: inline-block;
  width: 112px;
  height: 14px;
}
.location-search__engine::after {
  clear: both;
  content: "";
  display: block;
}
.location-search .form__input-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (min-width: 768px) and (max-width: 979px) {
  .location-search .form__input-label {
    font-size: 13px;
  }
}

/**
 * Style definitions for form elements
 */
.form__input {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}
.form__input.hidden {
  padding: 0;
}
.form__input--inline {
  padding-bottom: 0;
}
.form__input--inline .form__input-reset {
  bottom: 10px;
}
.form__input-field {
  width: 100%;
  padding: 0 0 5px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #999;
  transition: border-bottom ease 0.15s;
  font: normal 400 16px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.form__input.error .form__input-field {
  border-bottom-color: #cc0000;
}
.form__input-field:focus, .form__input.focused .form__input-field {
  outline: none;
  border-bottom-color: #0090ff;
  animation: unset;
}
.form__input.readonly .form__input-field {
  border-bottom: 0;
}
.form__input-field--can-reset {
  padding-right: 25px;
}
.form__input-field--has-prefix {
  padding-left: 25px;
}
.form__input-field[data-required] + .form__input-label .form__input-label-text:after {
  display: inline;
  content: "*";
}
.form__input-field:-moz-ui-invalid {
  box-shadow: none !important;
  color: inherit !important;
}
.form__input-field::-ms-clear {
  display: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .form__input-field {
    padding-top: 5px;
    padding-bottom: 10px;
  }
}
.form__input-field--date {
  min-height: 24px;
  padding-right: 20px;
}
.form__input-field--has-mask {
  caret-color: #222;
  color: transparent;
  font-family: courier, monospace;
}
.form__input-field--truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.form__input-prefix {
  color: #666;
  visibility: hidden;
  left: 0;
  position: absolute;
  top: 20px;
  font: normal 400 16px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.form__input-field:focus ~ .form__input-prefix, .form__input-field--has-value ~ .form__input-prefix {
  visibility: visible;
}
.form__input-masked-element {
  border: none;
  padding: 0;
  position: absolute;
  top: 20px;
  user-select: none;
  z-index: -1;
}
.form__input-masked-element span {
  display: inline-block;
  text-align: center;
  width: 9.7px;
}
@supports (-ms-ime-align: auto) {
  .form__input-masked-element span {
    width: 8.8px;
  }
}
.form__input-more-info-text {
  font: normal 400 12px/1.8333333333 "Arial", Helvetica, Arial, sans-serif;
  color: #666;
  display: block;
  margin-top: 5px;
}
.form__input-label {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  position: absolute;
  display: flex;
  align-items: center;
  top: 17.5px;
  left: 0;
  color: #666;
  transition: all ease 0.15s;
  width: 100%;
}
.form__input-label:hover {
  cursor: text;
}
.form__input-label.tool-tip-active {
  z-index: 500;
}
.form__input-field:focus + .form__input-label, .form__input-field:required:valid + .form__input-label, .form__input-field--has-value + .form__input-label {
  top: 0;
  transform: scale(0.99, 0.99);
  transform-origin: top left;
}
.form__input-field:focus + .form__input-label, .form__input.focused .form__input-label {
  color: #0090ff;
}
.form__input.error .form__input-field:focus + .form__input-label, .form__input.error .form__input-label .form__input-label-text {
  color: #cc0000;
}
.form__input-label-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 20px);
  display: inline-block;
  margin-right: 5px;
}
.form__input-error {
  display: none;
  color: #cc0000;
}
.form__input.error .form__input-error {
  display: block;
}
.form__input-icon {
  margin-right: 5px;
}
.form__input-icon:before {
  font-size: 120%;
}
.form__input-reset {
  display: none;
  position: absolute;
  right: 5px;
  top: 20px;
  height: 24px;
  cursor: pointer;
}
.form__input-reset:after {
  content: "\e91e";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
}
.form__input-reset:active, .form__input-reset--visible {
  display: block;
}
.form__input-date-trigger {
  position: absolute;
  top: 20px;
  right: 0;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .form__input-date-trigger {
    z-index: -1;
  }
}
@media screen and (min-width: 768px) {
  .form__input-date-trigger {
    width: 100%;
    height: 30px;
  }
}
.form__input-date-trigger .icon-mb {
  color: #176db7;
}
@media screen and (min-width: 768px) {
  .form__input-date-trigger .icon-mb {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.loading-spinner:before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  left: -20px;
  top: -20px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: #a3a3a3;
  animation: spinner 0.6s linear infinite;
}
.loading-spinner--large:before {
  width: 60px;
  height: 60px;
  left: -30px;
  top: -30px;
}
.loading-spinner--inline {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.loading-spinner--inline.loading-spinner--large {
  width: 60px;
  height: 60px;
}
.loading-spinner--inline:before, .loading-spinner--inline.loading-spinner--large:before {
  top: 0;
  left: 0;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

/**
 * Styling for a Dealer Locator DealerSearchBar view
 */
.dealer-search-bar {
  display: flex;
  align-items: baseline;
}
.dealer-search-bar__location-search {
  flex: 1 1 auto;
  margin-right: 10px;
}
.dealer-search-bar__cta {
  flex: 0 0 auto;
}

.dealer-results-list__loading-list {
  text-align: center;
}
.dealer-location-search .dealer-results-list__empty-list-copy {
  padding: 10px;
}

.preferred-dealer-plugin__sub-heading {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
}
.preferred-dealer-plugin__sub-heading-copy {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}

@keyframes marquee {
  0% {
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -ms-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }
}
.notification__marquee {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;
  display: block;
  background-color: wheat;
  margin-left: -90px;
}
.notification__marquee span {
  display: inline-block;
  padding-left: 100%;
  text-indent: 0;
  font-size: initial;
  will-change: transform;
  color: black;
  animation: marquee 28s linear infinite;
}
.notification__search {
  text-align: left;
  display: block;
  width: 100%;
  margin-right: 7px;
}
@media screen and (min-width: 980px) {
  .notification__search {
    display: inline-block;
  }
}
@media screen and (min-width: 1200px) {
  .notification__search {
    display: inline-block;
    margin-right: 10px;
  }
}

.brc-form-report {
  color: #333;
}
.brc-form-report__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.brc-form-report__group {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.brc-form-report__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.brc-form-report__index {
  display: inline-block;
  margin: 20px 0 10px 0;
}
.brc-form-report__index--anchor {
  color: #428bca;
  float: left;
  padding: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #dddddd;
  width: 55px;
  height: 35px;
  text-align: center;
  margin-bottom: 10px;
}
.brc-form-report__index--anchor:hover {
  color: #285E8E;
  background-color: #eeeeee;
  border-color: #dddddd;
  cursor: pointer;
}
.brc-form-report__index--anchor:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.brc-form-report__index--anchor:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.brc-form-report__index--anchor-hidden {
  display: none;
}
.brc-form-report__index--active {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.brc-form-report__index--active:hover {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.brc-form-report__index--disabled {
  pointer-events: none;
  color: grey;
  cursor: default;
}
.brc-form-report__index--disabled:hover, .brc-form-report__index--disabled:focus {
  color: grey;
  cursor: default;
  border-color: none;
  background-color: #fff;
}
.brc-form-report__table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.brc-form-report__table .fixed {
  table-layout: fixed;
}
.brc-form-report__table .fixed td {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
.brc-form-report__table .fixed th {
  cursor: pointer;
}
.brc-form-report__table th[data-filter-value] {
  cursor: pointer;
}
.brc-form-report__sortable {
  position: relative;
}
.brc-form-report__child {
  font: initial;
}
.brc-form-report__half_width {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.brc-form-report__btn_label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #5cb85c;
}
.brc-form-report__control {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.brc-form-report__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.brc-form-report__control--error, .brc-form-report__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.brc-form-report__control--success, .brc-form-report__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.brc-form-report__table_column_padding_left {
  padding-left: 15px;
}
.brc-form-report__table_column_padding_right {
  padding-right: 15px;
}
.brc-form-report__error-field {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 30px 10px 10px;
}
.brc-form-report__loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.brc-form-report__listing_loader {
  position: fixed;
  left: 54%;
  top: 70%;
  z-index: 1;
  width: 60px;
  height: 60px;
  margin: -75px 0 0 -75px;
  border: 10px solid #DCDCDC;
  border-radius: 50%;
  border-top: 10px solid #000;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.brc-form-report__button {
  color: #fff;
  background-color: #176db7;
  padding: 5px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
  border-radius: 0.6em;
}
.brc-form-report__button:hover {
  cursor: pointer;
  background-color: #0090ff;
}
.brc-form-report__border_rule {
  border-bottom: 1px solid #DCDCDC;
}
.brc-form-report__td_padding {
  margin: 10px;
  padding: 10px 3px;
  text-align: center;
}
.brc-form-report__th_padding {
  margin: 10px;
  padding: 10px 0px;
  text-align: center;
}
.brc-form-report__pagin_padding {
  display: flex;
  justify-content: center;
  padding-top: 25px;
  font-size: initial;
}
.brc-form-report__arrow_icon {
  color: #add8e6;
}
.brc-form-report__downloadPdf {
  font-size: 35px;
}
.brc-form-report__download {
  position: absolute;
  right: 40px;
}
.brc-form-report__popup-header {
  height: 40px;
}

.brc-favorite-list {
  color: #333;
}
.brc-favorite-list__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.brc-favorite-list__group {
  min-height: 20px;
  margin: 5%;
}
.brc-favorite-list__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
  margin: 5%;
}
.brc-favorite-list__index {
  display: inline-block;
  margin: 20px 0 10px 0;
}
.brc-favorite-list__index--anchor {
  color: #428bca;
  float: left;
  padding: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #dddddd;
  width: 55px;
  height: 35px;
  text-align: center;
  margin-bottom: 10px;
}
.brc-favorite-list__index--anchor:hover {
  color: #285E8E;
  background-color: #eeeeee;
  border-color: #dddddd;
  cursor: pointer;
}
.brc-favorite-list__index--anchor:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.brc-favorite-list__index--anchor:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.brc-favorite-list__index--anchor-hidden {
  display: none;
}
.brc-favorite-list__index--active {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.brc-favorite-list__index--active:hover {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.brc-favorite-list__index--disabled {
  pointer-events: none;
  color: grey;
  cursor: default;
}
.brc-favorite-list__index--disabled:hover, .brc-favorite-list__index--disabled:focus {
  color: grey;
  cursor: default;
  border-color: none;
  background-color: #fff;
}
.brc-favorite-list__table {
  width: 160%;
  table-layout: fixed;
}
.brc-favorite-list__table .fixed {
  table-layout: fixed;
}
.brc-favorite-list__table .fixed td {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
.brc-favorite-list__table .fixed th {
  cursor: pointer;
}
.brc-favorite-list__table th[data-filter-value] {
  cursor: pointer;
}
.brc-favorite-list__sortable {
  position: relative;
}
.brc-favorite-list__half_width {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
}
.brc-favorite-list__btn_label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #5cb85c;
}
.brc-favorite-list__control {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.brc-favorite-list__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.brc-favorite-list__control--error, .brc-favorite-list__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.brc-favorite-list__control--success, .brc-favorite-list__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.brc-favorite-list__table_column_padding_left {
  padding-left: 15px;
}
.brc-favorite-list__table_column_padding_right {
  padding-right: 15px;
}
.brc-favorite-list__error-field {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 30px 10px 10px;
}
.brc-favorite-list__loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.brc-favorite-list__listing_loader {
  position: fixed;
  left: 54%;
  top: 70%;
  z-index: 1;
  width: 60px;
  height: 60px;
  margin: -75px 0 0 -75px;
  border: 10px solid #DCDCDC;
  border-radius: 50%;
  border-top: 10px solid #000;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.brc-favorite-list__button {
  color: #fff;
  background-color: #176db7;
  padding: 5px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
  border-radius: 0.6em;
}
.brc-favorite-list__button:hover {
  cursor: pointer;
  background-color: #0090ff;
}
.brc-favorite-list__td_padding {
  margin: 10px;
  font-size: 13px;
  text-align: left;
  vertical-align: middle;
  padding-left: 1%;
}
.brc-favorite-list__th_padding {
  margin: 10px;
  padding: 10px 0px;
  text-align: center;
}
.brc-favorite-list__pagin_padding {
  display: flex;
  justify-content: center;
  padding-top: 25px;
  font-size: initial;
}
.brc-favorite-list__error-field-new {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.brc-favorite-list__error-field-new--hidden {
  display: none;
}
.brc-favorite-list__title {
  font: normal 400 32px/1 "corporateacondpro";
  margin-top: 15px;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .brc-favorite-list__title {
    margin-top: 0;
  }
}
.brc-favorite-list__arrow_icon {
  color: #add8e6;
}
.brc-favorite-list__remove-icon {
  cursor: pointer;
  visibility: hidden;
  content: "✖";
  color: red;
  font-size: 24px;
}
.brc-favorite-list__remove-icon:before {
  cursor: pointer;
  content: "✖";
  visibility: visible;
}

.instructor-form {
  color: #333;
}
.instructor-form__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
  text-align: center;
  margin-top: -8%;
}
.instructor-form__center {
  text-align: center;
}
.instructor-form__group {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
}
.instructor-form__group_input {
  padding: 10px 0px;
}
.instructor-form__input_group1 {
  font-size: 14px;
}
.instructor-form__group_input1 {
  padding-right: 15px;
}
.instructor-form__group_btn {
  padding: 10px 0px;
  text-align: center;
}
.instructor-form__asterisk_field {
  color: red;
}
.instructor-form__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.instructor-form__control {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  width: 100%;
  padding-left: 5px;
}
.instructor-form__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.instructor-form__control--error, .instructor-form__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.instructor-form__control--success, .instructor-form__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.instructor-form__control1 {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  width: 100%;
  padding-left: 5px;
}
.instructor-form__control1:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.instructor-form__control1--error, .instructor-form__control1--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.instructor-form__control1--success, .instructor-form__control1--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.instructor-form__control_textarea {
  height: auto;
}
.instructor-form__control_document {
  background: #fff;
  padding: 6px 10px;
  width: 100%;
  font-family: "Arial";
}
.instructor-form__control_table {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.instructor-form__control_table:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.instructor-form__control_table--error, .instructor-form__control_table--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.instructor-form__control_table--success, .instructor-form__control_table--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.instructor-form__title {
  font-size: 20px;
  width: 100%;
}
.instructor-form__text {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.instructor-form__hide {
  visibility: hidden;
}
.instructor-form__table_column {
  padding-right: 25px;
  vertical-align: middle;
}
.instructor-form__table_column1 {
  width: 35%;
  vertical-align: middle;
}
.instructor-form__table_column_checkbox {
  width: 35%;
}
.instructor-form__table_column_padding {
  padding-left: 15px;
}
.instructor-form__table {
  width: 80%;
}
.instructor-form__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.instructor-form__error-field--hidden {
  display: none;
}
.instructor-form__btn_column {
  text-align: center;
}
.instructor-form__section {
  display: block;
}
.instructor-form__section .pop-up {
  padding-top: 150px;
}
.instructor-form__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.instructor-form__section .pop-up__content-body {
  background: #fff;
}
.instructor-form__control_textarea {
  height: auto;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 6px 12px;
  width: 100%;
}
.instructor-form__sub-title {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 20px;
  padding-bottom: 5px;
}

.getstarted-form {
  color: #333;
}
.getstarted-form__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
  text-align: center;
}
.getstarted-form__center {
  text-align: center;
}
.getstarted-form__group {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
}
.getstarted-form__group_input {
  padding: 10px 0px;
}
.getstarted-form__group_input1 {
  padding-right: 15px;
}
.getstarted-form__group_btn {
  padding: 10px 0px;
  text-align: center;
}
.getstarted-form__asterisk_field {
  color: red;
}
.getstarted-form__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.getstarted-form__control {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  width: 100%;
  padding-left: 5px;
}
.getstarted-form__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.getstarted-form__control--error, .getstarted-form__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.getstarted-form__control--success, .getstarted-form__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.getstarted-form__control1 {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  width: 100%;
  padding-left: 5px;
}
.getstarted-form__control1:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.getstarted-form__control1--error, .getstarted-form__control1--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.getstarted-form__control1--success, .getstarted-form__control1--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.getstarted-form__control_textarea {
  height: auto;
}
.getstarted-form__control_document {
  background: #fff;
  padding: 6px 10px;
  width: 100%;
  font-family: "Arial";
}
.getstarted-form__control_table {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.getstarted-form__control_table:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.getstarted-form__control_table--error, .getstarted-form__control_table--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.getstarted-form__control_table--success, .getstarted-form__control_table--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.getstarted-form__title {
  font-size: 20px;
  width: 100%;
}
.getstarted-form__text {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.getstarted-form__hide {
  visibility: hidden;
}
.getstarted-form__table_column {
  padding-right: 25px;
  width: 35%;
  vertical-align: middle;
}
.getstarted-form__table_column1 {
  width: 35%;
}
.getstarted-form__table_column_checkbox {
  width: 35%;
}
.getstarted-form__table_column_padding {
  padding-left: 15px;
}
.getstarted-form__table {
  width: 80%;
}
.getstarted-form__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.getstarted-form__error-field--hidden {
  display: none;
}
.getstarted-form__btn_column {
  text-align: center;
}
.getstarted-form__section {
  display: block;
}
.getstarted-form__section .pop-up {
  padding-top: 150px;
}
.getstarted-form__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.getstarted-form__section .pop-up__content-body {
  background: #fff;
}
.getstarted-form__control_textarea {
  height: auto;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 6px 12px;
  width: 100%;
}
.getstarted-form__sub-title {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 20px;
  padding-bottom: 5px;
}

@media screen and (min-width: 768px) {
  .featured-promo-list {
    padding-bottom: 0;
  }
}
.featured-promo-list__section-header {
  margin-bottom: 20px;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
@media screen and (min-width: 768px) {
  .featured-promo-list__section-header {
    margin-bottom: 15px;
  }
}
.featured-promo-list__tout-container {
  font-size: 0;
  text-align: center;
  display: block;
}
.featured-promo-list__tout-container::after {
  clear: both;
  content: "";
  display: block;
}
@media screen and (min-width: 768px) {
  .featured-promo-list__tout-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.featured-promo-list__tout-container.image-border .responsive-image {
  border: 3px solid #fff;
  box-shadow: 0 2px 3px 2px #a3a3a3;
}
.featured-promo-list__copy-container {
  font: normal 400 18px/1 "corporateacondpro";
}
@media screen and (min-width: 768px) {
  .featured-promo-list__copy-container {
    text-align: center;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    display: table;
  }
}
.featured-promo-list__tout {
  padding-top: 20px;
  width: 250px;
  margin: 0 auto;
  margin-bottom: 20px;
}
.featured-promo-list__tout:first-of-type {
  padding-top: 0;
}
@media screen and (min-width: 768px) {
  .featured-promo-list__tout {
    position: relative;
    padding-top: 0;
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 31.914893617%;
    display: inline-block;
    float: none;
    margin-bottom: 40px;
    vertical-align: top;
    margin-left: 0;
    padding-bottom: 31px;
  }
  .featured-promo-list__tout:nth-child(4n) {
    margin-right: 2.1276595745%;
  }
  .featured-promo-list__tout:nth-child(4n+1) {
    clear: none;
  }
  .featured-promo-list__tout:nth-child(3n) {
    margin-right: 2.1276595745%;
  }
  .featured-promo-list__tout:nth-child(3n+1) {
    clear: none;
  }
  .featured-promo-list__tout:nth-child(2n) {
    margin-right: 2.1276595745%;
  }
  .featured-promo-list__tout:nth-child(2n+1) {
    clear: none;
  }
  .featured-promo-list__tout:last-child {
    margin-right: 0;
  }
  .featured-promo-list__tout:nth-child(3n) {
    margin-right: 0;
  }
  .featured-promo-list__tout:nth-child(3n+1) {
    clear: left;
  }
}
.featured-promo-list__tout .featured-promo-list__image picture:last-of-type {
  display: none;
}
.featured-promo-list__tout .featured-promo-list__image picture:first-of-type {
  display: block;
}
.featured-promo-list__tout:hover .featured-promo-list__image picture:first-of-type {
  display: none;
}
.featured-promo-list__tout:hover .featured-promo-list__image picture:last-of-type {
  display: block;
}
@media screen and (min-width: 768px) {
  .featured-promo-list__link-container {
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    margin-top: -20px;
  }
}
.featured-promo-list__tout-heading {
  font: normal 400 24px/1.1666666667 "corporateacondpro";
  margin: 15px 0 5px;
}
.featured-promo-list__tout-sub-heading {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 10px;
}
.featured-promo-list__tout-watermark {
  color: #cccccc;
}
.featured-promo-list__tout-desc {
  font: normal 400 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .featured-promo-list__tout-desc {
    min-height: 60px;
  }
}
.featured-promo-list__tout-rte {
  display: inline-block;
  font: normal 400 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .featured-promo-list__tout-rte {
    min-height: 60px;
  }
}
.featured-promo-list__tout-rte ul {
  list-style: disc;
}
.featured-promo-list__tout-rte ol {
  list-style: decimal;
}
.featured-promo-list__description {
  margin-top: 10px;
}
.featured-promo-list__image-container {
  margin-bottom: 10px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .featured-promo-list__image-container {
    margin-bottom: 0;
  }
}
.featured-promo-list__image-container img {
  width: 100%;
  max-width: 300px;
}
.featured-promo-list .button_plain-link {
  color: #fff;
  background-color: #176db7;
}
.featured-promo-list .button_plain-link:hover {
  background-color: #0090ff;
}
.featured-promo-list__cta-alignment--left {
  text-align: left;
}
.featured-promo-list__cta-alignment--right {
  text-align: right;
  width: 100%;
}
.featured-promo-list__cta-alignment--center {
  text-align: center;
  width: 100%;
}
.featured-promo-list__subheading-alignment--left {
  text-align: left;
}
.featured-promo-list__subheading-alignment--right {
  text-align: right;
  width: 100%;
}
.featured-promo-list__subheading-alignment--center {
  text-align: center;
  width: 100%;
}
.featured-promo-list_display-column--1 .featured-promo-list__tout-container {
  display: block;
}
@media screen and (min-width: 768px) {
  .featured-promo-list_display-column--1 .featured-promo-list__tout {
    margin-left: 34.0425531915%;
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .featured-promo-list_display-column--2 .featured-promo-list__tout {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
  }
  .featured-promo-list_display-column--2 .featured-promo-list__tout:last-child {
    margin-right: 0;
  }
  .featured-promo-list_display-column--2 .featured-promo-list__tout:nth-child(2n) {
    margin-right: 0;
  }
  .featured-promo-list_display-column--2 .featured-promo-list__tout:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 768px) {
  .featured-promo-list_display-column--4 .featured-promo-list__tout {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 23.4042553191%;
    display: inline-block;
    float: none;
  }
  .featured-promo-list_display-column--4 .featured-promo-list__tout:nth-child(3n) {
    margin-right: 2.1276595745%;
  }
  .featured-promo-list_display-column--4 .featured-promo-list__tout:nth-child(3n+1) {
    clear: none;
  }
  .featured-promo-list_display-column--4 .featured-promo-list__tout:nth-child(2n) {
    margin-right: 2.1276595745%;
  }
  .featured-promo-list_display-column--4 .featured-promo-list__tout:nth-child(2n+1) {
    clear: none;
  }
  .featured-promo-list_display-column--4 .featured-promo-list__tout:last-child {
    margin-right: 0;
  }
  .featured-promo-list_display-column--4 .featured-promo-list__tout:nth-child(4n) {
    margin-right: 0;
  }
  .featured-promo-list_display-column--4 .featured-promo-list__tout:nth-child(4n+1) {
    clear: left;
  }
}
.featured-promo-list_display-column--4 .featured-promo-list__image-container img {
  max-width: 250px;
}
@media screen and (min-width: 768px) {
  .featured-promo-list_display-column--4 .featured-promo-list__copy-container {
    max-width: 250px;
  }
}
.featured-promo-list__separator-top {
  border-top: 1px solid #c7c7c7;
  padding: 10px 0;
}
.featured-promo-list__separator-bottom {
  border-bottom: 1px solid #c7c7c7;
  padding: 10px 0;
}
.featured-promo-list__separator-both {
  border-top: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
  padding: 10px 0;
}

@media screen and (min-width: 768px) {
  .small-image-tile {
    padding-bottom: 0;
  }
}
.small-image-tile__tout-container {
  font-size: 0;
  text-align: center;
}
.small-image-tile__tout-container::after {
  clear: both;
  content: "";
  display: block;
}
@media screen and (min-width: 768px) {
  .small-image-tile__tout-container {
    margin: 0 auto;
    width: 600px;
  }
}
.small-image-tile__copy-container {
  font: normal 400 18px/1 "corporateacondpro";
}
@media screen and (min-width: 768px) {
  .small-image-tile__copy-container {
    text-align: center;
    padding: 0 5px;
  }
}
.small-image-tile__tout {
  padding-top: 20px;
  margin-bottom: 20px;
}
.small-image-tile__tout:first-of-type {
  padding-top: 0;
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .small-image-tile__tout {
    padding-top: 0;
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 31.914893617%;
    display: inline-block;
    float: none;
    margin-bottom: 15px;
    vertical-align: top;
  }
  .small-image-tile__tout:last-child {
    margin-right: 0;
  }
  .small-image-tile__tout:nth-child(3n) {
    margin-right: 0;
  }
  .small-image-tile__tout:nth-child(3n+1) {
    clear: left;
  }
}
@media screen and (min-width: 768px) {
  .small-image-tile__tout {
    padding-top: 0;
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 31.914893617%;
    display: inline-block;
    float: none;
    margin-bottom: 15px;
    vertical-align: top;
  }
  .small-image-tile__tout:last-child {
    margin-right: 0;
  }
  .small-image-tile__tout:nth-child(3n) {
    margin-right: 0;
  }
  .small-image-tile__tout:nth-child(3n+1) {
    clear: left;
  }
}
.small-image-tile__tout-link {
  font: normal 400 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
  color: #0090ff;
  font-weight: bold;
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .small-image-tile__tout-link {
    margin-bottom: 20px;
  }
}
.small-image-tile__image-container {
  margin-bottom: 15px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .small-image-tile__image-container {
    margin-bottom: 10px;
  }
}
.small-image-tile__image-container img {
  width: auto;
}

.fixed-hero {
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .fixed-hero--no-gap {
    line-height: 0;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .fixed-hero__container {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-width: 80%;
  }
}
.fixed-hero__container--disable-video-controls video::-webkit-media-controls-play-button,
.fixed-hero__container--disable-video-controls video::-webkit-media-controls-timeline,
.fixed-hero__container--disable-video-controls video::-webkit-media-controls-current-time-display,
.fixed-hero__container--disable-video-controls video::-webkit-media-controls-time-remaining-display,
.fixed-hero__container--disable-video-controls video::-webkit-media-controls-toggle-closed-captions-button,
.fixed-hero__container--disable-video-controls video::-webkit-media-controls-volume-slider {
  display: none;
}
.fixed-hero__container.video__frame--show .video__frame {
  opacity: 1;
}
.fixed-hero__container.video__frame--show .fixed-hero__play-btn {
  display: none;
}
.fixed-hero__container.video__frame--show .fixed-hero__copy-container {
  display: block;
}
.fixed-hero__image-container {
  text-align: center;
  position: relative;
  width: 768px;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (min-width: 768px) {
  .fixed-hero__image-container {
    width: 100%;
  }
}
.fixed-hero__image-container .video__frame {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.fixed-hero__backgroundImage-container {
  text-align: center;
  position: relative;
  width: 768px;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 100px;
  padding-bottom: 70px;
}
@media screen and (min-width: 768px) {
  .fixed-hero__backgroundImage-container {
    width: 85%;
  }
}
.fixed-hero__backgroundImage-container .video__frame {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
@media screen and (min-width: 768px) {
  .fixed-hero__interior {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
}
.fixed-hero__copy-container {
  overflow: hidden;
  padding: 20px 30px 20px 10px;
  line-height: normal;
}
@media screen and (max-width: 767px) {
  .fixed-hero__copy-container {
    width: 100% !important;
  }
  .fixed-hero__copy-container.dark {
    background-color: white;
    color: black;
  }
  .fixed-hero__copy-container.light {
    background-color: black;
    color: white;
  }
}
@media screen and (min-width: 768px) {
  .fixed-hero__copy-container {
    text-align: left;
    width: 85%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .fixed-hero__copy-container.dark {
    color: #000000;
  }
  .fixed-hero__copy-container.light {
    color: #f1f1f1;
  }
  .fixed-hero__copy-container--wide {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 40.4255319149%;
  }
  .fixed-hero__copy-container--wide:last-child {
    margin-right: 0;
  }
  .fixed-hero__copy-container--wide.top-right {
    margin-left: 59.5744680851%;
  }
  .fixed-hero__copy-container--full {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 57.4468085106%;
  }
  .fixed-hero__copy-container--full:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 980px) {
  .fixed-hero__copy-container {
    width: 62%;
  }
}
.fixed-hero__copy-container--equal_padding_bg-white, .fixed-hero__copy-container --equal_padding_bg-black {
  padding: 25px;
  opacity: 0.9;
}
.fixed-hero__eyebrow {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}
.fixed-hero__play-btn {
  color: #fff;
  cursor: pointer;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  height: 75px;
  width: 75px;
  border: 2px solid #fff;
  border-radius: 50%;
}
@media screen and (min-width: 768px) {
  .fixed-hero__play-btn {
    font-size: 72px;
  }
}
.fixed-hero__play-btn--desktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .fixed-hero__play-btn--desktop {
    display: inline-block;
  }
}
.fixed-hero__play-btn--mobile {
  display: inline-block;
}
@media screen and (min-width: 768px) {
  .fixed-hero__play-btn--mobile {
    display: none;
  }
}
.fixed-hero__heading {
  font: normal 400 24px/1.1666666667 "corporateacondpro";
  margin-bottom: 5px;
}
@media screen and (min-width: 768px) {
  .fixed-hero__heading {
    font: normal 400 32px/1 "corporateacondpro";
  }
}
@media screen and (min-width: 768px) {
  .fixed-hero__heading {
    margin-bottom: 20px;
  }
}
.fixed-hero__sub-heading {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 15px;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .fixed-hero__sub-heading {
    display: block;
    margin-bottom: 20px;
  }
}
.fixed-hero__button-space {
  width: 15%;
}
@media screen and (max-width: 767px) {
  .fixed-hero__button-space {
    width: 20%;
  }
}
.fixed-hero .button {
  font-size: 14px;
  padding: 3px 12px;
}
@media screen and (max-width: 767px) {
  .fixed-hero .button {
    font-size: 10px;
    padding: 1px 8px;
  }
}
.fixed-hero .pop-up__content {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  background: #000000;
}
.fixed-hero .pop-up__heading {
  line-height: normal;
}
.fixed-hero .pop-up__content-body {
  padding: 5px;
}
@media screen and (min-width: 768px) {
  .fixed-hero__background--black {
    background-color: #000000;
  }
  .fixed-hero__background--white {
    background-color: #fff;
  }
}
.fixed-hero__mobile-image .fixed-hero__image-container {
  width: 100%;
}
.fixed-hero__order {
  position: absolute;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 72px;
  border-radius: 50%;
  border: 3px solid #fff;
  height: 100px;
  width: 100px;
  text-align: center;
  vertical-align: middle;
  display: table;
}
.fixed-hero__order--number {
  display: table-cell;
  vertical-align: middle;
}
.fixed-hero__order.dark {
  border-color: #000000;
  color: #000000;
}

.bottom-right,
.bottom-left {
  top: auto;
  bottom: 0;
}
@media screen and (min-width: 768px) {
  .bottom-right,
  .bottom-left {
    padding-bottom: 0px;
  }
}
.bottom-right .fixed-hero__heading,
.bottom-left .fixed-hero__heading {
  margin-bottom: 5px;
}
.bottom-right .fixed-hero__copy-container,
.bottom-left .fixed-hero__copy-container {
  text-align: right;
}
@media screen and (min-width: 768px) {
  .bottom-right .fixed-hero__copy-container,
  .bottom-left .fixed-hero__copy-container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 100%;
    width: auto;
    float: right;
    padding: 15px;
    text-align: left;
  }
  .bottom-right .fixed-hero__copy-container:last-child,
  .bottom-left .fixed-hero__copy-container:last-child {
    margin-right: 0;
  }
  body.pac .bottom-right .fixed-hero__copy-container,
  body.pac .bottom-left .fixed-hero__copy-container {
    background-color: #000000;
  }
  body.pac .bottom-right .fixed-hero__copy-container:last-child,
  body.pac .bottom-left .fixed-hero__copy-container:last-child {
    margin-bottom: 25px;
  }
  body.pac-vans .bottom-right .fixed-hero__copy-container,
  body.pac-vans .bottom-left .fixed-hero__copy-container {
    background-color: #000000;
  }
  body.pac-vans .bottom-right .fixed-hero__copy-container:last-child,
  body.pac-vans .bottom-left .fixed-hero__copy-container:last-child {
    margin-bottom: 25px;
  }
  .bottom-right .fixed-hero__copy-container:last-child,
  .bottom-left .fixed-hero__copy-container:last-child {
    margin-right: 20px;
  }
}
.bottom-right .button,
.bottom-left .button {
  font-size: 14px;
}

.bottom-left .fixed-hero__copy-container {
  text-align: right;
}
@media screen and (min-width: 768px) {
  .bottom-left .fixed-hero__copy-container {
    float: left;
    text-align: left;
  }
  .bottom-left .fixed-hero__copy-container:last-child {
    margin-left: 20px;
  }
}

.top-right .fixed-hero__copy-container {
  text-align: right;
}
@media screen and (min-width: 768px) {
  .top-right .fixed-hero__copy-container {
    float: right;
  }
}

@media screen and (min-width: 768px) {
  .center {
    max-width: 80%;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }
}
.center .fixed-hero__copy-container {
  text-align: left;
}
@media screen and (min-width: 768px) {
  .center .fixed-hero__copy-container {
    text-align: center;
    width: 100%;
  }
}

.fluid-hero__container {
  width: 100%;
  max-width: none;
}
.fluid-hero__image-container {
  width: 100%;
}

.responsive-image {
  width: 100%;
}

.img-container {
  height: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
}

.media-text {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  padding: 20px 0;
}
@media screen and (min-width: 768px) {
  .media-text {
    flex-direction: row;
  }
}
.media-text__media-container {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
  text-align: center;
  min-height: 1px;
}
.media-text__media-container:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .media-text__media-container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
  }
  .media-text__media-container:last-child {
    margin-right: 0;
  }
}
.media-text__media .button {
  margin-top: 10px;
}
.media-text__title {
  font: normal 400 32px/1 "corporateacondpro";
  margin-top: 15px;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .media-text__title {
    margin-top: 0;
  }
}
.media-text__sub-title {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 15px;
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .media-text__sub-title {
    margin-top: 0;
    margin-bottom: 5px;
  }
}
.media-text__description {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.media-text__description a {
  color: #3276B1;
}
.media-text__description a:hover {
  text-decoration: underline;
  color: #23527C;
}
.media-text__description ul {
  list-style: initial;
  padding: 0 0 0 40px;
  list-style-position: outside !important;
}
.media-text__description p {
  margin-bottom: 5px;
}
.media-text__description table tr:first-child {
  background-color: transparent;
}
.media-text__description table th {
  background-color: transparent;
}
.media-text__description-dark-theme li, .media-text__description-dark-theme a {
  color: black;
}
.media-text__description-light-theme li, .media-text__description-light-theme a {
  color: #fff;
}
.media-text__copy-container {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.media-text__copy-container:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .media-text__copy-container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
  }
  .media-text__copy-container:last-child {
    margin-right: 0;
  }
}
.media-text__image-container picture {
  display: block;
  margin-bottom: 20px;
  text-align: center;
}
.media-text__image {
  width: 100%;
}
.media-text__image-thumbnail {
  width: auto;
  max-width: 100%;
}
.media-text__image--border {
  border: 3px solid #fff;
  box-shadow: 0px 2px 3px 2px #a3a3a3;
}
.media-text__parentdiv {
  border-radius: 1px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
  display: flex;
  left: 20px;
  position: absolute;
  text-align: center;
  top: -10px;
  width: 50px;
  z-index: 500;
}
.media-text__parentdiv:before {
  background: #939393;
  content: "";
  height: 10px;
  left: 5px;
  position: absolute;
  top: 0;
  transform: skewX(23deg);
  transform-origin: top;
  width: 47px;
  z-index: -1;
}
.media-text__calender {
  background: #fff;
  color: #333;
  width: 100%;
  padding: 11px 0 6px;
}
.media-text__video-preview-container {
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
}
.media-text__video-preview-image {
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.media-text__video {
  width: 100%;
}
.media-text__video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.media-text__icon-play {
  border-radius: 50%;
  border: 2px solid #fff;
  height: 75px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 75px;
}
.media-text__circle {
  opacity: 0.5;
  fill: #000000;
}
.media-text__triangle {
  fill: #fff;
}
.media-text__search {
  margin-top: 10px;
}
@media screen and (min-width: 980px) {
  .media-text__search {
    display: flex;
    align-items: center;
  }
}
.media-text__search-container {
  margin-top: 20px;
}
@media screen and (min-width: 980px) {
  .media-text__search-container {
    margin-top: 0;
    min-height: 75px;
    margin-left: 40px;
    padding-left: 40px;
    border-left: 1px solid #999;
  }
}
.media-text__form-container {
  position: relative;
  display: inline-block;
}
.media-text__form-label {
  font: normal 700 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
  color: #333;
  display: block;
  margin-bottom: 4px;
}
.media-text__form-input {
  width: calc(100% - 45px);
  float: left;
  padding: 7px 50px 7px 10px;
  border: 1px solid #c7c7c7;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
@media screen and (min-width: 768px) {
  .media-text__form-input {
    width: auto;
  }
}
.media-text__form-button {
  position: absolute;
  right: 0;
  top: 0;
}
.media-text__download-link {
  display: inline-block;
  padding-left: 20px;
  line-height: 18px;
}
.media-text__download-link:before {
  content: none;
}
.media-text__download-link [class^=icon-] {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 14px;
}
.media-text__download-link [class^=icon-] > span {
  position: absolute;
}
.media-text__download-link [class^=icon-] > span:before {
  margin-left: 0;
}
.media-text__download-text {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.media-text .icon-pdf {
  color: #D0011B;
}
@media screen and (min-width: 768px) {
  .media-text--left {
    flex-direction: row-reverse;
  }
  .media-text--left .media-text__media-container {
    margin-right: 0;
  }
  .media-text--left .media-text__copy-container {
    margin-right: 2.1%;
  }
}
.media-text--top {
  flex-direction: column-reverse;
}
.media-text--bottom {
  flex-direction: column;
}
.media-text--top, .media-text--bottom {
  display: flex;
}
@media screen and (min-width: 768px) {
  .media-text--top .media-text__media-container, .media-text--bottom .media-text__media-container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 100%;
    margin-right: 0px;
  }
  .media-text--top .media-text__media-container:last-child, .media-text--bottom .media-text__media-container:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .media-text--top .media-text__image-container picture, .media-text--bottom .media-text__image-container picture {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 100%;
  }
  .media-text--top .media-text__image-container picture:last-child, .media-text--bottom .media-text__image-container picture:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .media-text--top .media-text__copy-container, .media-text--bottom .media-text__copy-container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 100%;
  }
  .media-text--top .media-text__copy-container:last-child, .media-text--bottom .media-text__copy-container:last-child {
    margin-right: 0;
  }
}
.media-text__cta-button {
  min-width: 100px;
  text-align: center;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .media-text--full-width {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 100%;
  }
  .media-text--full-width:last-child {
    margin-right: 0;
  }
}
.media-text__no-bottom-space {
  margin-bottom: 0;
  padding-bottom: 0;
}
.media-text .error-field--hidden {
  display: none;
}
.media-text .error-field--zip-code {
  font-size: 14px;
  text-align: left;
  color: #cc0000;
}
.media-text__media {
  position: relative;
}
.media-text__badge-block {
  background-color: #176db7;
  position: absolute;
  top: -5px;
  left: -10px;
  padding: 2px 10px;
  border-radius: 10px;
  color: #fff;
  text-transform: uppercase;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
@media screen and (min-width: 768px) {
  .media-text__badge-block {
    left: -20px;
  }
}
@media screen and (min-width: 768px) {
  .media-text__vertical {
    flex-direction: row;
  }
  .media-text__vertical--middle {
    align-items: center;
  }
  .media-text__vertical--top {
    align-items: flex-start;
  }
  .media-text__vertical--bottom {
    align-items: flex-end;
  }
}
.media-text__image-sub-title {
  text-align: left;
  font-size: 14px;
}
.media-text__cta-alignment--left {
  text-align: left;
}
.media-text__cta-alignment--right {
  text-align: right;
  width: 100%;
}
.media-text__cta-alignment--center {
  text-align: center;
  width: 100%;
}
.media-text__content-alignment--left {
  text-align: left;
}
.media-text__content-alignment--center {
  text-align: center;
}
.media-text__content-alignment--right {
  text-align: right;
}
.media-text__carousel-slides {
  position: relative;
  overflow: hidden;
  line-height: 0;
}
.media-text__carousel-slide {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  opacity: 1;
  overflow: hidden;
  list-style: none;
  transition: opacity ease 0.975s;
  z-index: 499;
}
.media-text__carousel-slide--active {
  height: auto;
  opacity: 1;
  position: relative;
  z-index: 499;
}
.media-text__carousel-navigation {
  text-align: left;
  display: none;
}
.media-text__carousel-nav-pages {
  font: normal 700 13px/1 "Arial", Helvetica, Arial, sans-serif;
  color: #666;
  display: inline-block;
  margin: 0 10px 0 0;
}
.media-text__carousel-nav-buttons {
  display: inline-block;
}
.media-text__carousel-nav-button {
  color: #176db7;
  cursor: pointer;
  font-size: 20px;
  line-height: 1;
  margin-right: 5px;
}
.media-text__carousel-nav-button--inactive {
  color: #c7c7c7;
  cursor: auto;
}
.media-text__carousel-nav-button--prev:before {
  content: "\e915";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
}
.media-text__carousel-nav-button--next:before {
  content: "\e91a";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
}
.media-text__carousel-js-loaded .media-text__carousel-slide {
  height: 0;
  opacity: 0;
  position: absolute;
  z-index: 498;
}
.media-text__carousel-js-loaded .media-text__carousel-slide--active {
  height: auto;
  opacity: 1;
  position: relative;
  z-index: 499;
}
.media-text__carousel-js-loaded + .media-text__carousel-navigation {
  display: block;
}

.dealer-module {
  display: flex;
  position: relative;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .dealer-module {
    display: flex;
    align-items: center;
    position: inherit;
    flex-direction: row;
  }
}
.dealer-module__media-container {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .dealer-module__media-container {
    top: 100%;
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 57.4468085106%;
    position: relative;
  }
  .dealer-module__media-container:last-child {
    margin-right: 0;
  }
}
.dealer-module__title {
  font: normal 400 32px/1 "corporateacondpro";
  margin-top: 15px;
}
@media screen and (min-width: 768px) {
  .dealer-module__title {
    margin-top: 0;
  }
}
.dealer-module__sub-title {
  font: normal 400 12px/1.8333333333 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 15px;
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .dealer-module__sub-title {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.dealer-module__copy-container {
  position: relative;
}
@media screen and (min-width: 768px) {
  .dealer-module__copy-container {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 40.4255319149%;
  }
  .dealer-module__copy-container:last-child {
    margin-right: 0;
  }
}
.dealer-module__copy-first {
  flex-direction: column-reverse;
}
@media screen and (min-width: 768px) {
  .dealer-module__copy-first {
    flex-direction: row-reverse;
  }
}
.dealer-module__image {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .dealer-module__image {
    height: auto;
  }
}
@media screen and (min-width: 768px) {
  .dealer-module__search {
    top: 25%;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    width: 100%;
  }
}
.dealer-module__search-container {
  text-align: left;
  width: 80%;
  vertical-align: top;
  margin-left: 10%;
}
@media screen and (min-width: 768px) {
  .dealer-module__search-container {
    width: 100%;
    margin-top: 0;
    text-align: right;
    min-height: 75px;
    margin-right: 10%;
  }
}
.dealer-module__search-content .dealer-module__search {
  display: none;
}
.dealer-module__search-content .dealer-module__search-copy {
  display: block;
}
.dealer-module__search-copy {
  position: static;
  display: none;
}
.dealer-module__search-copy .dealer-module__search-container {
  margin: 0;
  padding: 0;
}
.dealer-module__search-copy .dealer-module__form-container {
  text-align: left;
}
.dealer-module__form-label {
  font: normal 700 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
  color: #333;
  display: block;
  margin-bottom: 4px;
}
.dealer-module__form-input {
  width: 100%;
  /* float: left; */
  padding: 8px 10px;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .dealer-module__form-input {
    width: auto;
    margin-right: 10px;
    margin-bottom: 0;
  }
}
.dealer-module__form-input__container {
  display: block;
}
@media screen and (min-width: 768px) {
  .dealer-module__form-input__container {
    display: inline-block;
  }
}
.dealer-module__form-button {
  padding: 5px 40px;
  border-radius: 0px;
  vertical-align: top;
}
.dealer-module .error-field--hidden {
  display: none;
}
.dealer-module .error-field--zip-code {
  font-size: 14px;
  text-align: left;
  color: #cc0000;
}

.brc-sites-report {
  color: #333;
  padding: 1%;
}
.brc-sites-report__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.brc-sites-report__group {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.brc-sites-report__control {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 30px;
  padding: 6px 12px;
}
.brc-sites-report__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.brc-sites-report__control--error, .brc-sites-report__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.brc-sites-report__control--success, .brc-sites-report__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.brc-sites-report__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.brc-sites-report__error-field--hidden {
  display: none;
}
.brc-sites-report__asterisk_field {
  color: red;
}
.brc-sites-report__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.brc-sites-report__index {
  display: inline-block;
  margin: 20px 0 10px 0;
}
.brc-sites-report__index--anchor {
  color: #428bca;
  float: left;
  padding: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #dddddd;
  width: 55px;
  height: 35px;
  text-align: center;
  margin-bottom: 10px;
}
.brc-sites-report__index--anchor:hover {
  color: #285E8E;
  background-color: #eeeeee;
  border-color: #dddddd;
  cursor: pointer;
}
.brc-sites-report__index--anchor:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.brc-sites-report__index--anchor:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.brc-sites-report__index--anchor-hidden {
  display: none;
}
.brc-sites-report__index--active {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.brc-sites-report__index--active:hover {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.brc-sites-report__index--disabled {
  pointer-events: none;
  color: grey;
  cursor: default;
}
.brc-sites-report__index--disabled:hover, .brc-sites-report__index--disabled:focus {
  color: grey;
  cursor: default;
  border-color: none;
  background-color: #fff;
}
.brc-sites-report__table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.brc-sites-report__table .fixed {
  table-layout: fixed;
}
.brc-sites-report__table .fixed td {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
.brc-sites-report__table .fixed th {
  cursor: pointer;
}
.brc-sites-report__table th[data-filter-value] {
  cursor: pointer;
}
.brc-sites-report__sortable {
  cursor: pointer;
}
.brc-sites-report__child {
  font: initial;
}
.brc-sites-report__half_width {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.brc-sites-report__btn_label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #5cb85c;
}
.brc-sites-report__control {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.brc-sites-report__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.brc-sites-report__control--error, .brc-sites-report__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.brc-sites-report__control--success, .brc-sites-report__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.brc-sites-report__table_column_padding_left {
  padding-left: 15px;
}
.brc-sites-report__table_column_padding_right {
  padding-right: 15px;
}
.brc-sites-report__loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.brc-sites-report__listing_loader {
  position: fixed;
  left: 54%;
  top: 70%;
  z-index: 1;
  width: 60px;
  height: 60px;
  margin: -75px 0 0 -75px;
  border: 10px solid #DCDCDC;
  border-radius: 50%;
  border-top: 10px solid #000;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.brc-sites-report__button {
  color: #fff;
  background-color: #176db7;
  padding: 5px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
  border-radius: 0.6em;
}
.brc-sites-report__button:hover {
  cursor: pointer;
  background-color: #0090ff;
}
.brc-sites-report__border_rule {
  border-bottom: 1px solid #DCDCDC;
}
.brc-sites-report__td_padding {
  margin: 10px;
  padding: 10px 3px;
  text-align: center;
}
.brc-sites-report__th_padding {
  margin: 10px;
  padding: 10px 0px;
  text-align: center;
}
.brc-sites-report__pagin_padding {
  display: flex;
  justify-content: center;
  padding-top: 25px;
  font-size: initial;
}
.brc-sites-report__arrow_icon {
  color: #add8e6;
}
.brc-sites-report__downloadPdf {
  font-size: 35px;
}
.brc-sites-report__download {
  position: absolute;
  right: 130px;
  top: 88px;
}
.brc-sites-report__popup-header {
  height: 40px;
}
.brc-sites-report__label-width {
  width: 6%;
}
.brc-sites-report__date-width {
  width: 20%;
  padding-right: 1.5%;
}
.brc-sites-report__arrow_icon {
  color: #add8e6;
}
.brc-sites-report__danger {
  color: red;
}

.table-search {
  position: sticky;
  top: 0;
  padding: 0px 0px 0px 0px;
  font-size: 20px;
  z-index: 5;
}
.table-search__contained {
  font-size: 12px;
  height: 24px;
  max-width: 1000px;
  position: relative;
  display: inline-flex;
  width: 100%;
  border-radius: 4px;
  top: 0%;
  margin-bottom: 10px;
  margin-left: 66%;
}
.table-search__input-group {
  display: inline-flex;
  width: 50%;
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.table-search__input-group:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .table-search__input-group {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 40.4255319149%;
  }
  .table-search__input-group:last-child {
    margin-right: 0;
  }
}
.table-search__input {
  width: auto;
  padding: 6px 0px 8px 15px;
  float: left;
  color: #000000;
}
.table-search__input:focus {
  border-color: #66AFE9;
}
.table-search__button {
  color: #fff;
  background-color: #5bc0de;
  padding: 4px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
}
.table-search__button:hover {
  cursor: pointer;
  background-color: #39B3D7;
}
.table-search__nextbutton {
  color: #fff;
  background-color: #5bc0de;
  padding: 5px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
}
.table-search__nextbutton:hover {
  cursor: pointer;
  background-color: #39B3D7;
}
.table-search__heading {
  color: #1c1c1f;
  margin: -30px;
  margin-left: -100px;
  font-size: 15px;
}

.custom-loader {
  margin: auto;
  position: relative;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid black;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.discovertalent-form__section {
  display: block;
}
.discovertalent-form__section .pop-up {
  padding-top: 150px;
}
.discovertalent-form__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.discovertalent-form__section .pop-up__content-body {
  background: #fff;
}
.discovertalent-form__block {
  background-color: #F5F5F5;
  border: 1px solid #e3e3e3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 20px;
}
.discovertalent-form__title {
  border-bottom: 1px solid #dddddd;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}
.discovertalent-form__group {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
  margin-bottom: 15px;
}
.discovertalent-form__group:last-child {
  margin-right: 0;
}
.discovertalent-form__label {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 31.914893617%;
  padding-left: 5px;
  padding-top: 7px;
  text-align: right;
  margin-right: 5px;
  font-size: 14px;
  font-weight: bold;
}
.discovertalent-form__label:last-child {
  margin-right: 0;
}
.discovertalent-form__input-group-container {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 48.9361702128%;
  padding: 5px 5px 0 5px;
}
.discovertalent-form__input-group-container:last-child {
  margin-right: 0;
}
.discovertalent-form__input-group {
  display: table;
  font-size: 14px;
}
.discovertalent-form__icon {
  background: #eeeeee;
  border: 1px solid #cccccc;
  border-right: 0;
  border-radius: 5px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  display: table-cell;
  padding: 5px 10px;
  vertical-align: middle;
  width: 1%;
}
.discovertalent-form__control {
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  display: table-cell;
  padding: 6px 10px;
  float: left;
  width: 100%;
  font-family: "Arial";
}
.discovertalent-form__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.discovertalent-form__control--error, .discovertalent-form__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.discovertalent-form__control--success, .discovertalent-form__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.discovertalent-form__control-container {
  display: inline-block;
}
.discovertalent-form__asterisk {
  color: red;
}
.discovertalent-form__has-error .contact-form__label {
  color: #e23a39;
}
.discovertalent-form__has-error .contact-form__control {
  border-color: #e23a39;
}
.discovertalent-form__has-error .contact-form__icon {
  background-color: rgba(226, 58, 57, 0.1);
  border-color: #e23a39;
  color: #e23a39;
}
.discovertalent-form__help-block {
  color: #e23a39;
}
.discovertalent-form__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.discovertalent-form__error-field--hidden {
  display: none;
}
.discovertalent-form__section {
  display: block;
}
.discovertalent-form__section .pop-up {
  padding-top: 150px;
}
.discovertalent-form__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.discovertalent-form__section .pop-up__content-body {
  background: #fff;
}
.discovertalent-form__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
  text-align: center;
}
.discovertalent-form__heading_two {
  font-weight: 500;
  margin-bottom: 14px;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
}
.discovertalent-form__heading_three {
  font-weight: 500;
  margin-bottom: 14px;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
  white-space: pre;
  position: relative;
  top: 5px;
}
@media screen and (max-width: 767px) {
  .discovertalent-form__heading_three {
    margin-left: 0px;
    white-space: pre-line;
    top: auto;
  }
}
.discovertalent-form__heading_four {
  font-weight: 500;
  margin-bottom: 14px;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
  white-space: pre;
  position: relative;
  top: 5px;
}
@media screen and (max-width: 767px) {
  .discovertalent-form__heading_four {
    margin-left: 0px;
    white-space: pre-line;
    top: auto;
  }
}
.discovertalent-form__number_circle {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 8px;
  background: #d4ebf2;
  border: 2px solid #666;
  color: #666;
  text-align: center;
  font: 12px Arial, sans-serif;
  margin-left: 24%;
  float: left;
}
@media screen and (max-width: 767px) {
  .discovertalent-form__number_circle {
    margin-left: 0px;
    margin-left: 0px;
  }
}

.discovertalent-form-data {
  color: #333;
}
.discovertalent-form-data__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.discovertalent-form-data__group {
  padding: 10px 0px;
}
.discovertalent-form-data__group:nth-last-child(2) {
  padding-bottom: 20px;
  border-bottom: 1px solid #999;
}
.discovertalent-form-data__group:last-child {
  padding: 20px 0px;
}
.discovertalent-form-data__group1 {
  padding: 10px 0px;
}
.discovertalent-form-data__group1:nth-last-child(2) {
  padding-bottom: 20px;
  border-bottom: 1px solid #999;
}
.discovertalent-form-data__group1:last-child {
  padding: 20px 0px;
}
@media screen and (max-width: 767px) {
  .discovertalent-form-data__group1 {
    text-align-last: center;
  }
}
.discovertalent-form-data__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
  text-align: center;
}
.discovertalent-form-data__heading_two {
  font-weight: 500;
  margin-bottom: 14px;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
}
.discovertalent-form-data__heading_three {
  font-weight: 500;
  margin-bottom: 14px;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
  white-space: pre;
  position: relative;
  top: 5px;
}
@media screen and (max-width: 767px) {
  .discovertalent-form-data__heading_three {
    margin-left: 0px;
    white-space: pre-line;
    top: auto;
  }
}
.discovertalent-form-data__heading_four {
  font-weight: 500;
  margin-bottom: 14px;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
  white-space: pre;
  position: relative;
  top: 5px;
}
@media screen and (max-width: 767px) {
  .discovertalent-form-data__heading_four {
    margin-left: 0px;
    white-space: pre-line;
    top: auto;
  }
}
.discovertalent-form-data__number_circle {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 8px;
  background: #d4ebf2;
  border: 2px solid #666;
  color: #666;
  text-align: center;
  font: 12px Arial, sans-serif;
  margin-left: 24%;
  float: left;
}
@media screen and (max-width: 767px) {
  .discovertalent-form-data__number_circle {
    margin-left: 0px;
    margin-left: 0px;
  }
}
.discovertalent-form-data__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.discovertalent-form-data__control {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.discovertalent-form-data__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.discovertalent-form-data__control--error, .discovertalent-form-data__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.discovertalent-form-data__control--success, .discovertalent-form-data__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.discovertalent-form-data textarea {
  resize: none;
  height: auto;
}
.discovertalent-form-data .button {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  border-radius: 0px;
  padding: 5px 20px;
}
.discovertalent-form-data .button:hover {
  cursor: pointer;
}
.discovertalent-form-data__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.discovertalent-form-data__error-field--hidden {
  display: none;
}

.alternate-link-list-download__mainHeading {
  font-family: "corporateacondpro";
  font-size: 20px;
  font-weight: 500;
  color: #5a5a5a;
  margin: 0px 0px 20px 10px;
}
.alternate-link-list-download .link-list {
  border: 1px solid #dddddd;
  border-radius: 3px;
}
.alternate-link-list-download .link-list__heading {
  font-family: "corporateacondpro";
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  background-color: #000000;
  padding: 5px 0px 0px 10px;
  padding-bottom: 5px;
  border-radius: 3px 3px 0px 0px;
}
.alternate-link-list-download .link-list__content-description {
  margin-bottom: 0px;
}
.alternate-link-list-download .link-list__item-text {
  font-weight: 700;
}
.alternate-link-list-download .link-list__item-link {
  border-bottom: 1px solid #dddddd;
}
.alternate-link-list-download .link-list__item-link:last-child {
  border-bottom: none;
}
.alternate-link-list-download .link-list__item-link:hover {
  background-color: #fff;
  color: #00A6C9;
}
.alternate-link-list-download .link-list__icon-list {
  color: #c7c7c7;
}
.alternate-link-list-download .link-list__icon-nav {
  color: #00A6C9;
}
.alternate-link-list-download .link-list__divider {
  width: 5px;
  height: auto;
  display: inline-block;
}
.alternate-link-list-download .link-list__btn_column {
  text-align: center;
  padding: 2%;
}
.alternate-link-list-download__control {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.alternate-link-list-download__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.alternate-link-list-download__control--error, .alternate-link-list-download__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.alternate-link-list-download__control--success, .alternate-link-list-download__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.alternate-link-list-download__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.alternate-link-list-download__error-field--hidden {
  display: none;
}

.whats-new__table1 {
  display: none;
}
.whats-new__upper {
  text-transform: uppercase;
}
.whats-new__visible {
  display: block;
}
.whats-new__hide {
  display: none;
}
.whats-new__custom-padding {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.whats-new__btn1 {
  background-color: #008CBA; /* Green */
  border: none;
  color: white;
  padding: 6px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}
.whats-new__body-text {
  font-family: "Times New Roman", Times, serif;
}
.whats-new__padding-table-columns td {
  padding: 0 40px 0 0; /* Only right padding*/
}
.whats-new__custom-padding {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.whats-new__row-custom-border {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding-top: 15px;
  padding-bottom: 15px;
}
.whats-new__row-custom-padding {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #E8E8E8;
}
.whats-new__text-bold {
  font-weight: bold;
}
.whats-new__table-column-padding {
  padding-right: 200px;
}
.whats-new__column-spacing {
  cellspacing: 30px;
}
.whats-new__custom-padding-table-columns td {
  padding: 0 100px 0 0;
}
.whats-new__custom-padding-bill-columns td {
  padding: 0 700px 0 0; /* Only right padding*/
}
.whats-new__container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.whats-new__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.whats-new__col-md-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.whats-new__col-md-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.whats-new__col-md-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.whats-new__form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 1rem;
}
.whats-new__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.whats-new__error-field--hidden {
  display: none;
}
.whats-new__print {
  font-size: 35px;
  align: right;
}
.whats-new__download {
  position: absolute;
  right: 130px;
  top: 96px;
}
.whats-new__loader {
  position: fixed;
  left: 54%;
  top: 80%;
  z-index: 1;
  width: 60px;
  height: 60px;
  margin: -75px 0 0 -75px;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.whats-new__form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.whats-new__form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.whats-new__form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.whats-new__form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.whats-new__form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.whats-new__form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.whats-new__form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.whats-new__form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.whats-new__form-control:disabled, .whats-new__form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

@media screen and (min-width: 768px) {
  .data-deletion__section {
    padding: 0 15%;
  }
}
.data-deletion-container {
  width: 60%;
  margin: 0 auto;
}
.data-deletion__heading-container {
  width: 100%;
  padding: 40px 0;
}
@media screen and (min-width: 768px) {
  .data-deletion__heading-container {
    padding: 60px 0;
  }
}
.data-deletion__main-heading {
  font: normal 400 30px/1 "corporateacondpro";
  margin-bottom: 10px;
}
@media screen and (min-width: 980px) {
  .data-deletion__main-heading {
    font: normal 400 40px/1 "corporateacondpro";
  }
}
.data-deletion__sub-heading {
  font-weight: bold;
}
.data-deletion-search-field-container {
  display: block;
  align-items: center;
  margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .data-deletion-search-field-container {
    display: flex;
  }
  .data-deletion-search-field-container > div {
    width: 20%;
  }
  .data-deletion-search-field-container > div:first-of-type {
    width: 80%;
  }
}
.data-deletion-search-field-container .button-control {
  text-align: right;
}
.data-deletion-search-field-container .button {
  padding: 5px 20px;
}
.data-deletion-search-btn {
  text-align: center;
}
.data-deletion__result-table {
  border: 1px solid #c7c7c7;
}
.data-deletion__result-table__header {
  padding: 10px;
  font-weight: bold;
  background-color: #dddddd;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-deletion__result-table__header:first-of-type {
  width: 10%;
}
.data-deletion__result-table__header:nth-of-type(2) {
  width: 40%;
}
.data-deletion__result-table__header:nth-of-type(3) {
  width: 30%;
}
.data-deletion__result-table__header:last-child {
  width: 20%;
}
.data-deletion__result-table__header-container {
  display: flex;
  justify-content: space-between;
  background-color: #dddddd;
}
.data-deletion__result-table__header__container {
  display: flex;
}
.data-deletion__result-table__row {
  display: flex;
  text-align: center;
  border-bottom: 1px solid #c7c7c7;
}
.data-deletion__result-table__row:last-child {
  border-bottom: none;
}
.data-deletion__result-table__column {
  padding: 10px 0;
  border-right: 1px solid #c7c7c7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.data-deletion__result-table__column:first-child {
  width: 10%;
}
.data-deletion__result-table__column:nth-of-type(2) {
  width: 40%;
}
.data-deletion__result-table__column:nth-of-type(3) {
  width: 30%;
}
.data-deletion__result-table__column:last-child {
  width: 20%;
  border-right: none;
  padding: 2px 0;
}
.data-deletion__result-table__column .button {
  padding: 5px 10px;
}
@media screen and (min-width: 768px) {
  .data-deletion__result-table__column .button {
    padding: 5px 30px;
  }
}
.data-deletion__result-table__column .delete-btn--disabled {
  color: #5a5a5a;
  background-color: #999;
}
.data-deletion__modal-content__container {
  text-align: center;
  padding: 40px;
}
.data-deletion__modal-content__text {
  font: normal 400 24px/1.1666666667 "corporateacondpro";
  margin-bottom: 40px;
}
.data-deletion__modal-content__btns {
  display: flex;
  justify-content: space-around;
}
.data-deletion__modal-content__btns .button {
  padding: 5px 40px;
}
.data-deletion__msg-container {
  margin-top: 40px;
  padding: 10px;
  text-align: center;
}
.data-deletion .form__input {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}
.data-deletion .form__input.hidden {
  padding: 0;
}
.data-deletion .form__input--inline {
  padding-bottom: 0;
}
.data-deletion .form__input--inline .form__input-reset {
  bottom: 10px;
}
.data-deletion .form__input.error .form__input-field:focus {
  border-bottom-color: #cc0000;
}
.data-deletion .form__input-field {
  width: 100%;
  padding: 0 0 5px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #999;
  transition: border-bottom ease 0.15s;
}
.form__input.error .data-deletion .form__input-field {
  border-bottom-color: #cc0000;
}
.data-deletion .form__input-field:focus, .form__input.focused .data-deletion .form__input-field {
  outline: none;
  border-bottom-color: #0090ff;
  animation: unset;
}
.data-deletion .form__input-field[data-required] + .form__input-label .form__input-label-text:after {
  display: inline;
  content: "*";
}
.data-deletion .form__input-prefix {
  color: #666;
  visibility: hidden;
  left: 0;
  position: absolute;
  top: 20px;
}
.form__input-field:focus ~ .data-deletion .form__input-prefix, .form__input-field--has-value ~ .data-deletion .form__input-prefix {
  visibility: visible;
}
.data-deletion .form__input-label {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  position: absolute;
  display: flex;
  align-items: center;
  top: 17.5px;
  left: 0;
  color: #666;
  transition: all ease 0.15s;
  width: 100%;
}
.data-deletion .form__input-label:hover {
  cursor: text;
}
.form__input-field:focus + .data-deletion .form__input-label, .form__input-field:required:valid + .data-deletion .form__input-label, .form__input-field--has-value + .data-deletion .form__input-label {
  top: 0;
  transform: scale(0.99, 0.99);
  transform-origin: top left;
}
.form__input-field:focus + .data-deletion .form__input-label, .form__input.focused .data-deletion .form__input-label {
  color: #0090ff;
}
.form__input.error .form__input-field:focus + .data-deletion .form__input-label, .form__input.error .data-deletion .form__input-label .form__input-label-text {
  color: #cc0000;
}
.data-deletion .form__input-label-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 20px);
  display: inline-block;
  margin-right: 5px;
}
.data-deletion .form__input-error {
  display: none;
  color: #cc0000;
}
.form__input.error .data-deletion .form__input-error {
  display: block;
}
.data-deletion .form__input-reset {
  display: none;
  position: absolute;
  right: 5px;
  top: 20px;
  height: 24px;
  cursor: pointer;
}
.data-deletion .form__input-reset:after {
  content: "\e91e";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
}
.data-deletion .form__input-reset:active, .data-deletion .form__input-reset--visible {
  display: block;
}
.data-deletion .tooltip {
  position: relative;
  display: inline-block;
  color: #cc0000;
}
.data-deletion .tooltip .tooltiptext {
  visibility: hidden;
  width: 155px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
.data-deletion .tooltip:hover .tooltiptext {
  visibility: visible;
}
.data-deletion .alert-box--danger {
  border: 1px solid #ebcccc;
}
.data-deletion .alert-box--success {
  border: 1px solid #d0e9c6;
}

.brc-field-cast-report {
  color: #333;
}
.brc-field-cast-report__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.brc-field-cast-report__group {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.brc-field-cast-report__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.brc-field-cast-report__index {
  display: inline-block;
  margin: 20px 0 10px 0;
}
.brc-field-cast-report__index--anchor {
  color: #428bca;
  float: left;
  padding: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #dddddd;
  width: 55px;
  height: 35px;
  text-align: center;
  margin-bottom: 10px;
}
.brc-field-cast-report__index--anchor:hover {
  color: #285E8E;
  background-color: #eeeeee;
  border-color: #dddddd;
  cursor: pointer;
}
.brc-field-cast-report__index--anchor:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.brc-field-cast-report__index--anchor:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.brc-field-cast-report__index--anchor-hidden {
  display: none;
}
.brc-field-cast-report__index--active {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.brc-field-cast-report__index--active:hover {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.brc-field-cast-report__index--disabled {
  pointer-events: none;
  color: grey;
  cursor: default;
}
.brc-field-cast-report__index--disabled:hover, .brc-field-cast-report__index--disabled:focus {
  color: grey;
  cursor: default;
  border-color: none;
  background-color: #fff;
}
.brc-field-cast-report__table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.brc-field-cast-report__table .fixed {
  table-layout: fixed;
}
.brc-field-cast-report__table .fixed td {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
.brc-field-cast-report__table .fixed th {
  cursor: pointer;
}
.brc-field-cast-report__table th[data-filter-value] {
  cursor: pointer;
}
.brc-field-cast-report__sortable {
  position: relative;
}
.brc-field-cast-report__child {
  font: initial;
}
.brc-field-cast-report__half_width {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.brc-field-cast-report__btn_label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #5cb85c;
}
.brc-field-cast-report__control {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.brc-field-cast-report__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.brc-field-cast-report__control--error, .brc-field-cast-report__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.brc-field-cast-report__control--success, .brc-field-cast-report__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.brc-field-cast-report__table_column_padding_left {
  padding-left: 15px;
}
.brc-field-cast-report__table_column_padding_right {
  padding-right: 15px;
}
.brc-field-cast-report__error-field {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 30px 10px 10px;
}
.brc-field-cast-report__loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.brc-field-cast-report__listing_loader {
  position: fixed;
  left: 54%;
  top: 70%;
  z-index: 1;
  width: 60px;
  height: 60px;
  margin: -75px 0 0 -75px;
  border: 10px solid #DCDCDC;
  border-radius: 50%;
  border-top: 10px solid #000;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.brc-field-cast-report__button {
  color: #fff;
  background-color: #176db7;
  padding: 5px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
  border-radius: 0.6em;
}
.brc-field-cast-report__button:hover {
  cursor: pointer;
  background-color: #0090ff;
}
.brc-field-cast-report__border_rule {
  border-bottom: 1px solid #DCDCDC;
}
.brc-field-cast-report__td_padding {
  margin: 10px;
  padding: 10px 3px;
  text-align: center;
  word-break: break-word;
}
.brc-field-cast-report__th_padding {
  margin: 10px;
  padding: 10px 0px;
  text-align: center;
}
.brc-field-cast-report__pagin_padding {
  display: flex;
  justify-content: center;
  padding-top: 25px;
  font-size: initial;
}
.brc-field-cast-report__arrow_icon {
  color: #add8e6;
}
.brc-field-cast-report__downloadPdf {
  font-size: 35px;
}
.brc-field-cast-report__download {
  position: absolute;
  right: 40px;
}
.brc-field-cast-report__popup-header {
  height: 40px;
}
.brc-field-cast-report__no-record {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.brc-field-cast-report__no-record--hidden {
  display: none;
}

.brc-favourites {
  font-size: 13px;
  margin-bottom: 20px;
}
.brc-favourites__heading {
  font: normal 700 13px/1.5384615385 "Arial", Helvetica, Arial, sans-serif;
  text-decoration: underline;
  margin-bottom: 10px;
}
.brc-favourites__anchor {
  color: #428bca;
}
.brc-favourites__anchor:hover {
  color: #00A6C9;
}
.brc-favourites__column {
  border: 1px solid #000000;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
}
.brc-favourites__heading-column {
  font-weight: 700;
  border: 1px solid #000000;
  padding: 5px;
  text-align: center;
}
.brc-favourites__index-anchor {
  color: #428bca;
  text-decoration: underline;
}
.brc-favourites__index-anchor:hover {
  color: #00A6C9;
}
.brc-favourites__table-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin: 5%;
}
@media screen and (min-width: 980px) {
  .brc-favourites__table-container {
    overflow: visible;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .brc-favourites__table-container {
    overflow: auto;
  }
}
.brc-favourites__index {
  margin-bottom: 20px;
}
.brc-favourites__index__column {
  border: 1px solid #000000;
  padding: 10px;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  width: 1%;
}
.brc-favourites__index--disabled {
  pointer-events: none;
  text-decoration: none;
  color: #5a5a5a;
}
.brc-favourites__column-new {
  border: 1px solid #000000;
  text-align: center;
  vertical-align: middle;
  padding: 4px;
}
.brc-favourites__column-new:first-child {
  width: 0%;
}
.brc-favourites__column-new:nth-child(2) {
  width: 30%;
}
.brc-favourites__column-new:nth-child(3) {
  width: 40%;
}
.brc-favourites__column-new:nth-child(4) {
  width: 9%;
}
.brc-favourites__column-new:nth-child(5) {
  width: 10%;
}
.brc-favourites__column-new:nth-child(6) {
  width: 30%;
  min-width: 90px;
}
.brc-favourites__column-new-last {
  border: 1px solid #000000;
  text-align: center;
  vertical-align: middle;
  padding: 5px;
}
.brc-favourites__column-new-last:nth-last-child(1) {
  width: 15%;
}
.brc-favourites__column-new-last:nth-last-child(2) {
  width: 15%;
}
.brc-favourites__column-new-last:nth-last-child(3) {
  width: 10%;
}
.brc-favourites__column-new-last:nth-last-child(4) {
  width: 30%;
}
.brc-favourites__column-new-last:nth-last-child(5) {
  width: 25%;
}
.brc-favourites__column-new-last:nth-last-child(6) {
  width: 5%;
}
.brc-favourites__star-icon {
  visibility: hidden;
  cursor: pointer;
  font-size: 30px;
  content: "☆";
  height: 50px;
  width: 50px;
  text-align: center;
  font-size: 30px;
  padding: 0 1rem;
}
.brc-favourites__star-icon:before {
  content: "☆";
  visibility: visible;
  cursor: pointer;
}
.brc-favourites__star-red {
  visibility: hidden;
  cursor: pointer;
  font-size: 30px;
  content: "☆";
  height: 50px;
  width: 50px;
  text-align: center;
  font-size: 30px;
  padding: 0 1rem;
}
.brc-favourites__star-red:after {
  content: "★";
  color: red;
  visibility: visible;
  cursor: pointer;
}
.brc-favourites__remove-icon {
  cursor: pointer;
  visibility: hidden;
  content: "✖";
  color: red;
  font-size: 24px;
  height: 24.5px;
}
.brc-favourites__remove-icon:before {
  cursor: pointer;
  content: "✖";
  visibility: visible;
}

@media screen and (min-width: 768px) {
  .brc-featured-promo-list {
    padding-bottom: 0;
  }
}
.brc-featured-promo-list__feature-tiles-module {
  margin-bottom: 35px;
}
.brc-featured-promo-list__section-header {
  margin-bottom: 20px;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
@media screen and (min-width: 768px) {
  .brc-featured-promo-list__section-header {
    margin-bottom: 15px;
  }
}
.brc-featured-promo-list__tout-container {
  font-size: 0;
  text-align: center;
  display: block;
}
.brc-featured-promo-list__tout-container::after {
  clear: both;
  content: "";
  display: block;
}
@media screen and (min-width: 768px) {
  .brc-featured-promo-list__tout-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .brc-featured-promo-list__tout-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.brc-featured-promo-list__tout-container.image-border .responsive-image {
  border: 3px solid #fff;
  box-shadow: 0 2px 3px 2px #a3a3a3;
}
.brc-featured-promo-list__copy-container {
  font: normal 400 18px/1 "corporateacondpro";
  width: inherit;
}
@media screen and (max-width: 767px) {
  .brc-featured-promo-list__copy-container {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .brc-featured-promo-list__copy-container {
    text-align: center;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    display: table;
  }
}
.brc-featured-promo-list__tout {
  padding-top: 20px;
  width: 250px;
  margin: 0 auto;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .brc-featured-promo-list__tout {
    width: 31%;
  }
}
@media screen and (min-width: 768px) {
  .brc-featured-promo-list__tout {
    position: relative;
    padding-top: 0;
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 31.914893617%;
    display: inline-block;
    float: none;
    vertical-align: top;
    margin-left: 0;
  }
  .brc-featured-promo-list__tout:nth-child(7n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list__tout:nth-child(7n+1) {
    clear: none;
  }
  .brc-featured-promo-list__tout:nth-child(6n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list__tout:nth-child(6n+1) {
    clear: none;
  }
  .brc-featured-promo-list__tout:nth-child(5n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list__tout:nth-child(5n+1) {
    clear: none;
  }
  .brc-featured-promo-list__tout:nth-child(4n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list__tout:nth-child(4n+1) {
    clear: none;
  }
  .brc-featured-promo-list__tout:nth-child(3n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list__tout:nth-child(3n+1) {
    clear: none;
  }
  .brc-featured-promo-list__tout:nth-child(2n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list__tout:nth-child(2n+1) {
    clear: none;
  }
  .brc-featured-promo-list__tout:last-child {
    margin-right: 0;
  }
  .brc-featured-promo-list__tout:nth-child(3n) {
    margin-right: 0;
  }
  .brc-featured-promo-list__tout:nth-child(3n+1) {
    clear: left;
  }
}
.brc-featured-promo-list__tout .brc-featured-promo-list__image picture:last-of-type {
  display: none;
}
.brc-featured-promo-list__tout .brc-featured-promo-list__image picture:first-of-type {
  display: block;
}
.brc-featured-promo-list__tout:hover .brc-featured-promo-list__image picture:first-of-type {
  display: none;
}
.brc-featured-promo-list__tout:hover .brc-featured-promo-list__image picture:last-of-type {
  display: block;
}
@media screen and (min-width: 768px) {
  .brc-featured-promo-list__link-container {
    padding: 2%;
  }
}
.brc-featured-promo-list__featured-tiles-container {
  position: absolute;
  width: 100%;
}
.brc-featured-promo-list__tout-heading {
  font: normal 400 24px/1.1666666667 "corporateacondpro";
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
@media screen and (max-width: 767px) {
  .brc-featured-promo-list__tout-heading {
    font: normal 400 16px/1 "corporateacondpro";
  }
}
.brc-featured-promo-list__tout-sub-heading {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.brc-featured-promo-list__tout-desc {
  font: normal 400 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .brc-featured-promo-list__tout-desc {
    min-height: 60px;
  }
}
.brc-featured-promo-list__tout-rte {
  display: inline-block;
  font: normal 400 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .brc-featured-promo-list__tout-rte {
    min-height: 60px;
  }
}
.brc-featured-promo-list__tout-rte ul {
  list-style: disc;
}
.brc-featured-promo-list__tout-rte ol {
  list-style: decimal;
}
.brc-featured-promo-list__description {
  margin-top: 10px;
}
@media screen and (max-width: 767px) {
  .brc-featured-promo-list__image--mobile-wrapper {
    position: relative;
    display: flex;
  }
}
.brc-featured-promo-list__image-container {
  margin-bottom: 10px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .brc-featured-promo-list__image-container {
    margin-bottom: 0;
  }
}
.brc-featured-promo-list__image-container img {
  width: 100%;
  max-width: 300px;
}
.brc-featured-promo-list .button_plain-link {
  color: #fff;
  background-color: #176db7;
}
.brc-featured-promo-list .button_plain-link:hover {
  background-color: #0090ff;
}
.brc-featured-promo-list__cta-alignment--left {
  text-align: left;
}
.brc-featured-promo-list__cta-alignment--right {
  text-align: right;
  width: 100%;
}
.brc-featured-promo-list__cta-alignment--center {
  text-align: center;
  width: 100%;
}
.brc-featured-promo-list__subheading-alignment--left {
  text-align: left;
}
.brc-featured-promo-list__subheading-alignment--right {
  text-align: right;
  width: 100%;
}
.brc-featured-promo-list__subheading-alignment--center {
  text-align: center;
  width: 100%;
}
.brc-featured-promo-list_display-column--1 .brc-featured-promo-list__tout-container {
  display: block;
}
@media screen and (min-width: 768px) {
  .brc-featured-promo-list_display-column--1 .brc-featured-promo-list__tout {
    margin-left: 34.0425531915%;
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .brc-featured-promo-list_display-column--2 .brc-featured-promo-list__tout {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
  }
  .brc-featured-promo-list_display-column--2 .brc-featured-promo-list__tout:last-child {
    margin-right: 0;
  }
  .brc-featured-promo-list_display-column--2 .brc-featured-promo-list__tout:nth-child(2n) {
    margin-right: 0;
  }
  .brc-featured-promo-list_display-column--2 .brc-featured-promo-list__tout:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 768px) {
  .brc-featured-promo-list_display-column--4 .brc-featured-promo-list__tout {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 23.4042553191%;
    display: inline-block;
    float: none;
  }
  .brc-featured-promo-list_display-column--4 .brc-featured-promo-list__tout:nth-child(3n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list_display-column--4 .brc-featured-promo-list__tout:nth-child(3n+1) {
    clear: none;
  }
  .brc-featured-promo-list_display-column--4 .brc-featured-promo-list__tout:nth-child(2n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list_display-column--4 .brc-featured-promo-list__tout:nth-child(2n+1) {
    clear: none;
  }
  .brc-featured-promo-list_display-column--4 .brc-featured-promo-list__tout:last-child {
    margin-right: 0;
  }
  .brc-featured-promo-list_display-column--4 .brc-featured-promo-list__tout:nth-child(4n) {
    margin-right: 0;
  }
  .brc-featured-promo-list_display-column--4 .brc-featured-promo-list__tout:nth-child(4n+1) {
    clear: left;
  }
}
.brc-featured-promo-list_display-column--4 .brc-featured-promo-list__image-container img {
  max-width: 250px;
}
@media screen and (min-width: 768px) {
  .brc-featured-promo-list_display-column--4 .brc-featured-promo-list__copy-container {
    max-width: 250px;
  }
}
@media screen and (min-width: 768px) {
  .brc-featured-promo-list_display-column--5 .brc-featured-promo-list__tout {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 16.5957446809%;
    display: inline-block;
    float: none;
  }
  .brc-featured-promo-list_display-column--5 .brc-featured-promo-list__tout:nth-child(6n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list_display-column--5 .brc-featured-promo-list__tout:nth-child(6n+1) {
    clear: none;
  }
  .brc-featured-promo-list_display-column--5 .brc-featured-promo-list__tout:nth-child(4n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list_display-column--5 .brc-featured-promo-list__tout:nth-child(4n+1) {
    clear: none;
  }
  .brc-featured-promo-list_display-column--5 .brc-featured-promo-list__tout:nth-child(3n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list_display-column--5 .brc-featured-promo-list__tout:nth-child(3n+1) {
    clear: none;
  }
  .brc-featured-promo-list_display-column--5 .brc-featured-promo-list__tout:nth-child(2n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list_display-column--5 .brc-featured-promo-list__tout:nth-child(2n+1) {
    clear: none;
  }
  .brc-featured-promo-list_display-column--5 .brc-featured-promo-list__tout:last-child {
    margin-right: 0;
  }
  .brc-featured-promo-list_display-column--5 .brc-featured-promo-list__tout:nth-child(5n) {
    margin-right: 0;
  }
  .brc-featured-promo-list_display-column--5 .brc-featured-promo-list__tout:nth-child(5n+1) {
    clear: left;
  }
}
@media screen and (min-width: 768px) {
  .brc-featured-promo-list_display-column--6 .brc-featured-promo-list__tout {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 14.8936170213%;
    display: inline-block;
    float: none;
  }
  .brc-featured-promo-list_display-column--6 .brc-featured-promo-list__tout:nth-child(5n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list_display-column--6 .brc-featured-promo-list__tout:nth-child(5n+1) {
    clear: none;
  }
  .brc-featured-promo-list_display-column--6 .brc-featured-promo-list__tout:nth-child(4n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list_display-column--6 .brc-featured-promo-list__tout:nth-child(4n+1) {
    clear: none;
  }
  .brc-featured-promo-list_display-column--6 .brc-featured-promo-list__tout:nth-child(3n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list_display-column--6 .brc-featured-promo-list__tout:nth-child(3n+1) {
    clear: none;
  }
  .brc-featured-promo-list_display-column--6 .brc-featured-promo-list__tout:nth-child(2n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list_display-column--6 .brc-featured-promo-list__tout:nth-child(2n+1) {
    clear: none;
  }
  .brc-featured-promo-list_display-column--6 .brc-featured-promo-list__tout:last-child {
    margin-right: 0;
  }
  .brc-featured-promo-list_display-column--6 .brc-featured-promo-list__tout:nth-child(6n) {
    margin-right: 0;
  }
  .brc-featured-promo-list_display-column--6 .brc-featured-promo-list__tout:nth-child(6n+1) {
    clear: left;
  }
}
@media screen and (min-width: 768px) {
  .brc-featured-promo-list_display-column--7 .brc-featured-promo-list__tout {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 12.3404255319%;
    display: inline-block;
    float: none;
  }
  .brc-featured-promo-list_display-column--7 .brc-featured-promo-list__tout:nth-child(6n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list_display-column--7 .brc-featured-promo-list__tout:nth-child(6n+1) {
    clear: none;
  }
  .brc-featured-promo-list_display-column--7 .brc-featured-promo-list__tout:nth-child(5n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list_display-column--7 .brc-featured-promo-list__tout:nth-child(5n+1) {
    clear: none;
  }
  .brc-featured-promo-list_display-column--7 .brc-featured-promo-list__tout:nth-child(4n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list_display-column--7 .brc-featured-promo-list__tout:nth-child(4n+1) {
    clear: none;
  }
  .brc-featured-promo-list_display-column--7 .brc-featured-promo-list__tout:nth-child(3n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list_display-column--7 .brc-featured-promo-list__tout:nth-child(3n+1) {
    clear: none;
  }
  .brc-featured-promo-list_display-column--7 .brc-featured-promo-list__tout:nth-child(2n) {
    margin-right: 2.1276595745%;
  }
  .brc-featured-promo-list_display-column--7 .brc-featured-promo-list__tout:nth-child(2n+1) {
    clear: none;
  }
  .brc-featured-promo-list_display-column--7 .brc-featured-promo-list__tout:last-child {
    margin-right: 0;
  }
  .brc-featured-promo-list_display-column--7 .brc-featured-promo-list__tout:nth-child(7n) {
    margin-right: 0;
  }
  .brc-featured-promo-list_display-column--7 .brc-featured-promo-list__tout:nth-child(7n+1) {
    clear: left;
  }
}
.brc-featured-promo-list__separator-top {
  border-top: 1px solid #c7c7c7;
  padding: 10px 0;
}
.brc-featured-promo-list__separator-bottom {
  border-bottom: 1px solid #c7c7c7;
  padding: 10px 0;
}
.brc-featured-promo-list__separator-both {
  border-top: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
  padding: 10px 0;
}
.brc-featured-promo-list__text-box-container {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
}
.brc-featured-promo-list__text-box {
  position: absolute;
  opacity: 0.9;
  padding: 10px;
}
.brc-featured-promo-list__text-unselectable {
  color: inherit;
  user-select: none;
}
.brc-featured-promo-list__background--black {
  background-color: #000000;
}
.brc-featured-promo-list__background--white {
  background-color: #fff;
}
.brc-featured-promo-list__background--gray {
  background-color: #7F7F7F;
}
.brc-featured-promo-list__subheading-color--white {
  color: #fff;
}
.brc-featured-promo-list__subheading-color--black {
  color: #000000;
}
.brc-featured-promo-list__subheading-color--blue {
  color: #176db7;
}
.brc-featured-promo-list__subheading-color--red {
  color: #eb0000;
}
.brc-featured-promo-list__subheading-color--gray {
  color: #7F7F7F;
}
.brc-featured-promo-list__pop-up-wrapper {
  position: relative;
}
.brc-featured-promo-list__pop-up-wrapper:hover .brc-featured-promo-list__description-pop-up {
  transform: scaleY(1);
}
.brc-featured-promo-list__description-pop-up {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  overflow: hidden;
  transition: 0.4s ease;
  transform: scaleY(0);
  transform-origin: 0 100%;
}
.brc-featured-promo-list__hide-pop-up {
  display: none;
}
.brc-featured-promo-list__description-pop-up p {
  position: absolute;
  margin: 15px;
}

.wrapper .carousel-nav--sliding .carousel-nav__buttons {
  width: 108%;
}

.page-search {
  position: sticky;
  top: 0;
  background-color: #ebeef1;
  font-size: 20px;
  z-index: 5;
}
.page-search__contained {
  font-size: 12px;
  position: relative;
  display: grid;
  width: 100%;
  top: 0%;
  justify-content: end;
}
.page-search__input-group {
  display: inline-flex;
  width: 50%;
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.page-search__input-group:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .page-search__input-group {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 40.4255319149%;
  }
  .page-search__input-group:last-child {
    margin-right: 0;
  }
}
.page-search__input {
  width: auto;
  padding: 6px 0px 8px 15px;
  float: left;
  color: #000000;
}
.page-search__input:focus {
  border-color: #66AFE9;
}
.page-search__button {
  color: #fff;
  background-color: #5bc0de;
  padding: 4px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
}
.page-search__button:hover {
  cursor: pointer;
  background-color: #39B3D7;
}
.page-search__nextbutton {
  color: #fff;
  background-color: #5bc0de;
  padding: 5px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
}
.page-search__nextbutton:hover {
  cursor: pointer;
  background-color: #39B3D7;
}
.page-search__heading {
  color: #1c1c1f;
  margin: -30px;
  margin-left: -100px;
  font-size: 15px;
}
.page-search__child {
  font: initial;
}
.page-search__pagin_padding {
  display: flex;
  justify-content: center;
  padding-top: 25px;
  font-size: initial;
}

.corp-social-form {
  color: #333;
}
.corp-social-form__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.corp-social-form__group {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.corp-social-form__group_input {
  padding: 10px 0px;
}
.corp-social-form__group_btn {
  padding: 10px 0px;
  text-align: center;
}
.corp-social-form__asterisk_field {
  color: red;
}
.corp-social-form__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.corp-social-form__control {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.corp-social-form__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.corp-social-form__control--error, .corp-social-form__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.corp-social-form__control--success, .corp-social-form__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.corp-social-form__control_textarea {
  height: auto;
}
.corp-social-form__control_document {
  background: #fff;
  padding: 6px 10px;
  width: 100%;
  font-family: "Arial";
}
.corp-social-form__control_table {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.corp-social-form__control_table:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.corp-social-form__control_table--error, .corp-social-form__control_table--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.corp-social-form__control_table--success, .corp-social-form__control_table--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.corp-social-form__title {
  font-size: 20px;
  width: 100%;
}
.corp-social-form__text {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.corp-social-form__hide {
  visibility: hidden;
}
.corp-social-form__table_column {
  width: 35%;
  vertical-align: middle;
}
.corp-social-form__table_column_checkbox {
  width: 35%;
}
.corp-social-form__table_column_padding {
  padding-left: 15px;
}
.corp-social-form__table {
  width: 80%;
}
.corp-social-form__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.corp-social-form__error-field--hidden {
  display: none;
}
.corp-social-form__btn_column {
  text-align: center;
}
.corp-social-form__reset_btn {
  background-color: #D3D3D3;
  color: #333;
  border-color: #ccc;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
}
.corp-social-form__section {
  display: block;
}
.corp-social-form__section .pop-up {
  padding-top: 150px;
}
.corp-social-form__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.corp-social-form__section .pop-up__content-body {
  background: #fff;
}

.vehicle-end-date-update {
  color: #333;
}
.vehicle-end-date-update__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.vehicle-end-date-update__group {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.vehicle-end-date-update__group_input {
  padding: 10px 0px;
}
.vehicle-end-date-update__group_btn {
  padding: 10px 0px;
  text-align: center;
}
.vehicle-end-date-update__asterisk_field {
  color: red;
}
.vehicle-end-date-update__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.vehicle-end-date-update__control {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.vehicle-end-date-update__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.vehicle-end-date-update__control--error, .vehicle-end-date-update__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.vehicle-end-date-update__control--success, .vehicle-end-date-update__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.vehicle-end-date-update__control_textarea {
  height: auto;
}
.vehicle-end-date-update__control_document {
  background: #fff;
  padding: 6px 10px;
  width: 100%;
  font-family: "Arial";
}
.vehicle-end-date-update__control_table {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.vehicle-end-date-update__control_table:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.vehicle-end-date-update__control_table--error, .vehicle-end-date-update__control_table--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.vehicle-end-date-update__control_table--success, .vehicle-end-date-update__control_table--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.vehicle-end-date-update__title {
  font-size: 20px;
  width: 100%;
}
.vehicle-end-date-update__text {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}
.vehicle-end-date-update__hide {
  visibility: hidden;
}
.vehicle-end-date-update__table_column {
  width: 35%;
  vertical-align: middle;
}
.vehicle-end-date-update__table_column_checkbox {
  width: 35%;
}
.vehicle-end-date-update__table_column_padding {
  padding-left: 15px;
}
.vehicle-end-date-update__table {
  width: 80%;
}
.vehicle-end-date-update__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.vehicle-end-date-update__error-field--hidden {
  display: none;
}
.vehicle-end-date-update__btn_column {
  text-align: center;
}
.vehicle-end-date-update__reset_btn {
  background-color: #D3D3D3;
  color: #333;
  border-color: #ccc;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
}
.vehicle-end-date-update__section {
  display: block;
}
.vehicle-end-date-update__section .pop-up {
  padding-top: 150px;
}
.vehicle-end-date-update__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.vehicle-end-date-update__section .pop-up__content-body {
  background: #fff;
}
.vehicle-end-date-update__control_textarea {
  height: auto;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 6px 12px;
  width: 100%;
}
.vehicle-end-date-update__input-symbol-dollar {
  position: relative;
  left: 3%;
}
.vehicle-end-date-update__input-symbol-dollar:after {
  content: "$";
  font-size: 104% !important;
  font-weight: 400;
  left: 3%;
  position: absolute;
  top: -31%;
  padding-right: 3% !important;
}
.vehicle-end-date-update__abc-input {
  padding-left: 5% !important;
}

.cvp-dashboard {
  color: #333;
}
.cvp-dashboard__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.cvp-dashboard__group {
  min-width: 900px;
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.cvp-dashboard__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.cvp-dashboard__index {
  display: inline-block;
  margin: 20px 0 10px 0;
}
.cvp-dashboard__index--anchor {
  color: #428bca;
  float: left;
  padding: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #dddddd;
  width: 55px;
  height: 35px;
  text-align: center;
  margin-bottom: 10px;
}
.cvp-dashboard__index--anchor:hover {
  color: #285E8E;
  background-color: #eeeeee;
  border-color: #dddddd;
  cursor: pointer;
}
.cvp-dashboard__index--anchor:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.cvp-dashboard__index--anchor:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.cvp-dashboard__index--anchor-hidden {
  display: none;
}
.cvp-dashboard__index--active {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.cvp-dashboard__index--active:hover {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.cvp-dashboard__index--disabled {
  pointer-events: none;
  color: grey;
  cursor: default;
}
.cvp-dashboard__index--disabled:hover, .cvp-dashboard__index--disabled:focus {
  color: grey;
  cursor: default;
  border-color: none;
  background-color: #fff;
}
.cvp-dashboard__table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.cvp-dashboard__table .fixed {
  table-layout: fixed;
}
.cvp-dashboard__table .fixed td {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
.cvp-dashboard__table .fixed th {
  cursor: pointer;
}
.cvp-dashboard__table th[data-filter-value] {
  cursor: pointer;
}
.cvp-dashboard__sortable {
  position: relative;
}
.cvp-dashboard__child {
  font: initial;
}
.cvp-dashboard__half_width {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.cvp-dashboard__btn_label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #5cb85c;
}
.cvp-dashboard__control {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.cvp-dashboard__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.cvp-dashboard__control--error, .cvp-dashboard__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.cvp-dashboard__control--success, .cvp-dashboard__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.cvp-dashboard__table_column_padding_left {
  padding-left: 15px;
}
.cvp-dashboard__table_column_padding_right {
  padding-right: 15px;
}
.cvp-dashboard__error-field {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 30px 10px 10px;
}
.cvp-dashboard__loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.cvp-dashboard__loader-submit {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.cvp-dashboard__listing_loader {
  position: relative;
  left: 54%;
  top: 70%;
  z-index: 1;
  width: 60px;
  height: 60px;
  margin: -75px 0 0 -75px;
  border: 10px solid #DCDCDC;
  border-radius: 50%;
  border-top: 10px solid #000;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cvp-dashboard__button {
  color: #fff;
  background-color: #176db7;
  padding: 5px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
  border-radius: 0.6em;
}
.cvp-dashboard__button:hover {
  cursor: pointer;
  background-color: #0090ff;
}
.cvp-dashboard__border_rule {
  border-bottom: 1px solid #DCDCDC;
}
.cvp-dashboard__td_padding {
  margin: 10px;
  padding: 10px 3px;
  text-align: center;
  word-break: break-word;
}
.cvp-dashboard__th_padding {
  margin: 10px;
  padding: 10px 0px;
  text-align: center;
}
.cvp-dashboard__pagin_padding {
  display: flex;
  justify-content: center;
  padding-top: 25px;
  font-size: initial;
}
.cvp-dashboard__arrow_icon {
  color: #add8e6;
}
.cvp-dashboard__downloadPdf {
  font-size: 35px;
}
.cvp-dashboard__download {
  position: absolute;
  right: 40px;
}
.cvp-dashboard__popup-header {
  height: 40px;
}
.cvp-dashboard__design {
  margin-top: 5px;
  padding: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
  text-align: center;
  color: #fff;
  position: relative;
  right: 48px;
}
.cvp-dashboard__button-primary1 {
  text-align: center;
}
.cvp-dashboard__cancel {
  position: relative;
  bottom: 33px;
  left: 420px;
}
.cvp-dashboard__arrow_icon {
  color: #add8e6;
}
.cvp-dashboard__request {
  margin-bottom: -6%;
}
.cvp-dashboard__Requests-style {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.cvp-dashboard__pagin_padding {
  display: flex;
  justify-content: center;
  padding-top: 25px;
  font-size: initial;
}

.fepAndEab__button {
  color: #fff;
  background-color: #176DB7;
  padding: 5px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
  border-radius: 0.6em;
  margin-left: auto;
}
.fepAndEab__button:hover {
  cursor: pointer;
  background-color: #2186dd;
}

.wrapperContent {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 93.4375%;
  margin: 0 auto;
}
.wrapperContent::after {
  clear: both;
  content: "";
  display: block;
}
@media screen and (min-width: 768px) {
  .wrapperContent {
    width: 91.1458333333%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 980px) {
  .wrapperContent {
    width: 90.8163265306%;
    max-width: 1380px;
  }
}
.wrapperContent .wrapperContent {
  width: 100%;
}

.wrapperG-Class {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 93.4375%;
  margin: 0 auto;
}
.wrapperG-Class::after {
  clear: both;
  content: "";
  display: block;
}
@media screen and (min-width: 768px) {
  .wrapperG-Class {
    width: 91.1458333333%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 980px) {
  .wrapperG-Class {
    width: 95.9183673469%;
    max-width: 580px;
  }
}
.wrapperG-Class__display {
  display: flex;
}
.wrapperG-Class .wrapperG-Class {
  width: 100%;
}

@media (max-width: 767px) {
  .wrapperG-Class__display {
    display: block;
  }
}
.wrapperG-Class-sub {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 93.4375%;
  margin: 0 auto;
}
.wrapperG-Class-sub::after {
  clear: both;
  content: "";
  display: block;
}
@media screen and (min-width: 768px) {
  .wrapperG-Class-sub {
    width: 91.1458333333%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 980px) {
  .wrapperG-Class-sub {
    width: 95.9183673469%;
    max-width: 400px;
  }
}
.wrapperG-Class-sub__display {
  display: flex !important;
}
.wrapperG-Class-sub .wrapperG-Class-sub {
  width: 100%;
}

.wrapperContentMax {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 93.4375%;
  margin: 0 auto;
}
.wrapperContentMax::after {
  clear: both;
  content: "";
  display: block;
}
@media screen and (min-width: 768px) {
  .wrapperContentMax {
    width: 91.1458333333%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 980px) {
  .wrapperContentMax {
    width: 90.8163265306%;
    max-width: 1450px;
  }
}
.wrapperContentMax .wrapperContentMax {
  width: 100%;
}

.ncu-submission-report {
  color: #333;
}
.ncu-submission-report__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.ncu-submission-report__group {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.ncu-submission-report__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.ncu-submission-report__index {
  display: inline-block;
  margin: 20px 0 10px 0;
}
.ncu-submission-report__index--anchor {
  color: #428bca;
  float: left;
  padding: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #dddddd;
  width: 55px;
  height: 35px;
  text-align: center;
  margin-bottom: 10px;
}
.ncu-submission-report__index--anchor:hover {
  color: #285E8E;
  background-color: #eeeeee;
  border-color: #dddddd;
  cursor: pointer;
}
.ncu-submission-report__index--anchor:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.ncu-submission-report__index--anchor:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.ncu-submission-report__index--anchor-hidden {
  display: none;
}
.ncu-submission-report__index--active {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.ncu-submission-report__index--active:hover {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.ncu-submission-report__index--disabled {
  pointer-events: none;
  color: grey;
  cursor: default;
}
.ncu-submission-report__index--disabled:hover, .ncu-submission-report__index--disabled:focus {
  color: grey;
  cursor: default;
  border-color: none;
  background-color: #fff;
}
.ncu-submission-report__table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.ncu-submission-report__table .fixed {
  table-layout: fixed;
}
.ncu-submission-report__table .fixed td {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
.ncu-submission-report__table .fixed th {
  cursor: pointer;
}
.ncu-submission-report__table th[data-filter-value] {
  cursor: pointer;
}
.ncu-submission-report__sortable {
  position: relative;
}
.ncu-submission-report__child {
  font: initial;
}
.ncu-submission-report__half_width {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.ncu-submission-report__btn_label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #5cb85c;
}
.ncu-submission-report__control {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.ncu-submission-report__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.ncu-submission-report__control--error, .ncu-submission-report__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.ncu-submission-report__control--success, .ncu-submission-report__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.ncu-submission-report__table_column_padding_left {
  padding-left: 15px;
}
.ncu-submission-report__table_column_padding_right {
  padding-right: 15px;
}
.ncu-submission-report__error-field {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 30px 10px 10px;
}
.ncu-submission-report__loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.ncu-submission-report__listing_loader {
  position: fixed;
  left: 54%;
  top: 70%;
  z-index: 1;
  width: 60px;
  height: 60px;
  margin: -75px 0 0 -75px;
  border: 10px solid #DCDCDC;
  border-radius: 50%;
  border-top: 10px solid #000;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ncu-submission-report__button {
  color: #fff;
  background-color: #176db7;
  padding: 5px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
  border-radius: 0.6em;
}
.ncu-submission-report__button:hover {
  cursor: pointer;
  background-color: #0090ff;
}
.ncu-submission-report__border_rule {
  border-bottom: 1px solid #DCDCDC;
}
.ncu-submission-report__td_padding {
  margin: 10px;
  padding: 10px 3px;
  text-align: center;
  word-break: break-word;
}
.ncu-submission-report__th_padding {
  margin: 10px;
  padding: 10px 0px;
  text-align: center;
}
.ncu-submission-report__pagin_padding {
  display: flex;
  justify-content: center;
  padding-top: 25px;
  font-size: initial;
}
.ncu-submission-report__arrow_icon {
  color: #add8e6;
}
.ncu-submission-report__downloadPdf {
  font-size: 35px;
}
.ncu-submission-report__download {
  position: absolute;
  right: 40px;
}
.ncu-submission-report__popup-header {
  height: 40px;
}
.ncu-submission-report__no-record {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.ncu-submission-report__no-record--hidden {
  display: none;
}

.ncu-form__section {
  display: block;
}
.ncu-form__section .pop-up {
  padding-top: 150px;
}
.ncu-form__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.ncu-form__section .pop-up__content-body {
  background: #fff;
}
.ncu-form__block {
  background-color: #F5F5F5;
  border: 1px solid #e3e3e3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 20px;
}
.ncu-form__title {
  border-bottom: 1px solid #dddddd;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}
.ncu-form__group {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
  margin-bottom: 15px;
}
.ncu-form__group:last-child {
  margin-right: 0;
}
.ncu-form__label {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 31.914893617%;
  padding-left: 5px;
  padding-top: 7px;
  text-align: right;
  margin-right: 5px;
  font-size: 14px;
  font-weight: bold;
}
.ncu-form__label:last-child {
  margin-right: 0;
}
.ncu-form__input-group-container {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 48.9361702128%;
  padding: 5px 5px 0 5px;
}
.ncu-form__input-group-container:last-child {
  margin-right: 0;
}
.ncu-form__input-group {
  display: table;
  font-size: 14px;
}
.ncu-form__icon {
  background: #eeeeee;
  border: 1px solid #cccccc;
  border-right: 0;
  border-radius: 5px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  display: table-cell;
  padding: 5px 10px;
  vertical-align: middle;
  width: 1%;
}
.ncu-form__control {
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  display: table-cell;
  padding: 6px 10px;
  float: left;
  width: 100%;
  font-family: "Arial";
}
.ncu-form__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.ncu-form__control--error, .ncu-form__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.ncu-form__control--success, .ncu-form__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.ncu-form__control-container {
  display: inline-block;
}
.ncu-form__asterisk {
  color: red;
}
.ncu-form__has-error .ncu-form__label {
  color: #e23a39;
}
.ncu-form__has-error .ncu-form__control {
  border-color: #e23a39;
}
.ncu-form__has-error .ncu-form__icon {
  background-color: rgba(226, 58, 57, 0.1);
  border-color: #e23a39;
  color: #e23a39;
}
.ncu-form__help-block {
  color: #e23a39;
}
.ncu-form__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.ncu-form__error-field--hidden {
  display: none;
}
.ncu-form__section {
  display: block;
}
.ncu-form__section .pop-up {
  padding-top: 150px;
}
.ncu-form__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.ncu-form__section .pop-up__content-body {
  background: #fff;
}
.ncu-form__loader-submit {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ncu-form__loader-message {
  text-align: center;
  font-size: 14px;
  color: red;
}

.ncu-form-data {
  color: #333;
}
.ncu-form-data__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.ncu-form-data__group {
  padding: 10px 0px;
}
.ncu-form-data__group:nth-last-child(2) {
  padding-bottom: 20px;
  border-bottom: 1px solid #999;
}
.ncu-form-data__group:last-child {
  padding: 20px 0px;
}
.ncu-form-data__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.ncu-form-data__control {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.ncu-form-data__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.ncu-form-data__control--error, .ncu-form-data__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.ncu-form-data__control--success, .ncu-form-data__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.ncu-form-data textarea {
  resize: none;
  height: auto;
}
.ncu-form-data .button {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  border-radius: 0px;
  padding: 5px 20px;
}
.ncu-form-data .button:hover {
  cursor: pointer;
}
.ncu-form-data__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.ncu-form-data__error-field--hidden {
  display: none;
}

.order-request-listing > .tabs-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  flex-direction: row;
  border-bottom: 1px solid #e3e3e3;
}
.order-request-listing > .tabs-wrapper > .tabs {
  padding: 10px 0;
  margin-right: 40px;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
}
.order-request-listing > .tabs-wrapper > .active-tab {
  border-bottom: 1px solid transparent;
}
.order-request-listing > .tabs-wrapper > .active-tab::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 5px;
  background: #176db7;
}
.order-request-listing .content {
  height: 100%;
  width: 100%;
  display: none;
}
.order-request-listing .active-content {
  display: block;
}
.order-request-listing__title {
  color: #000000;
  margin: 15px 0 20px;
  text-align: center;
  float: unset;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .order-request-listing__title {
    margin: 30px 0;
  }
}
.order-request-listing__title--light {
  color: #ffffff;
}
.order-request-listing__sub-heading {
  float: unset;
  margin-bottom: 20px;
}
.order-request-listing__no-item {
  font-weight: 400;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.order-request-listing__cards {
  display: flex;
  flex-wrap: wrap;
  margin: 5px;
}
.order-request-listing__cards .order-request-card {
  margin-bottom: 30.125px;
}
@media screen and (min-width: 980px) {
  .order-request-listing__cards .order-request-card {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 31.914893617%;
  }
  .order-request-listing__cards .order-request-card:last-child {
    margin-right: 0;
  }
  .order-request-listing__cards .order-request-card:nth-child(3n) {
    margin-right: 0;
  }
  .order-request-listing__cards .order-request-card:nth-child(3n+1) {
    clear: left;
  }
}
@media screen and (min-width: 768px) and (max-width: 979px) {
  .order-request-listing__cards .order-request-card {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 23.4042553191%;
  }
  .order-request-listing__cards .order-request-card:last-child {
    margin-right: 0;
  }
  .order-request-listing__cards .order-request-card:nth-child(2n) {
    margin-right: 0;
  }
  .order-request-listing__cards .order-request-card:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (max-width: 767px) {
  .order-request-listing__cards .order-request-card {
    float: left;
    display: block;
    margin-right: 6.6666666667%;
    width: 46.6666666667%;
  }
  .order-request-listing__cards .order-request-card:last-child {
    margin-right: 0;
  }
  .order-request-listing__cards .order-request-card:nth-child(2n) {
    margin-right: 0;
  }
  .order-request-listing__cards .order-request-card:nth-child(2n+1) {
    clear: left;
  }
}
.order-request-listing__cards .order-request-card .saved-build-card-ap {
  position: relative;
  border: 1px solid #c6c6c6;
  background-color: #ffffff;
  overflow: hidden;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__card-body {
  padding: 20.25px 20.25px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
@media screen and (min-width: 1200px) {
  .order-request-listing__cards .order-request-card .saved-build-card-ap__ctas {
    display: flex;
    gap: 5.1875px;
  }
}
@media screen and (max-width: 767px) {
  .order-request-listing__cards .order-request-card .saved-build-card-ap__ctas {
    display: flex;
    gap: 5.1875px;
  }
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__ctas--wrapper {
  padding: 0 0 10.125px;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__cta {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15.0625px;
  width: 100%;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20.25px;
  max-height: 20px;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__info {
  display: block;
  border-top: 1px solid #c6c6c6;
  text-align: right;
  padding: 15.0625px 0;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__info--recommended {
  text-align: left;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__info-wrapper {
  padding: 0 20.25px;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__info-wrapper--empty {
  padding-top: 52px;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__info-dealer-availability {
  color: #41853d;
  text-align: center;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__info-dealer-name {
  color: #717171;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__info-features {
  min-height: 63px;
}
@media screen and (min-width: 980px) and (max-width: 1199px) {
  .order-request-listing__cards .order-request-card .saved-build-card-ap__info-features {
    min-height: 80px;
  }
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__info-features--heading {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: calc(16px + 8px);
  margin-top: 20.25px;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__info-features--empty {
  min-height: auto;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__info-feature {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: calc(16px + 8px);
  color: #717171;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__hero {
  margin-bottom: 20.25px;
  margin-left: -19.75px;
  margin-right: -19.75px;
  text-align: center;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__hero--no-carousel {
  margin-bottom: 44px;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__hero img {
  height: 180px;
  object-fit: cover;
}
@media screen and (min-width: 980px) and (max-width: 1199px) {
  .order-request-listing__cards .order-request-card .saved-build-card-ap__hero img {
    height: 124px;
  }
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__hero .inventory-favorites-card-ap__backplate {
  height: 124px;
  position: absolute;
  left: 0;
  width: 100%;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__vehicle-info {
  text-align: center;
  color: #000000;
  margin-top: 30.125px;
  margin-bottom: 20.25px;
  min-height: 60px;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__vehicle-info--out-of-date {
  text-align: center;
  margin-bottom: 30.125px;
  min-height: 60px;
  opacity: 0.5;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__vehicle-info--recommended {
  margin-bottom: 69px;
}
@media screen and (min-width: 768px) {
  .order-request-listing__cards .order-request-card .saved-build-card-ap__vehicle-info--recommended {
    margin-bottom: auto;
  }
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__vehicle-info--model-recommended {
  margin-bottom: 83px;
}
@media screen and (min-width: 768px) {
  .order-request-listing__cards .order-request-card .saved-build-card-ap__vehicle-info--model-recommended {
    margin-bottom: auto;
  }
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__vehicle-info-model {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: calc(16px + 8px);
  text-align: center;
  color: #717171;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__vehicle-info-model--recommended {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: calc(24px + 8px);
  margin-bottom: 15.0625px;
  color: #000000;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__vehicle-info-title {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: calc(16px + 8px);
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__vehicle-info-cost {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: calc(32px + 8px);
  margin-top: 15.0625px;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__vehicle-info-cost--wrapper {
  font: normal 400 24px/1.1666666667 "corporateacondpro";
  margin-bottom: 10.125px;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__vehicle-info-cost--coming-soon {
  margin-bottom: 42px;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__mask {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 180px;
}
@media screen and (min-width: 980px) {
  .order-request-listing__cards .order-request-card .saved-build-card-ap__mask {
    height: 163px;
  }
}
@media screen and (min-width: 1200px) {
  .order-request-listing__cards .order-request-card .saved-build-card-ap__mask {
    height: 185px;
  }
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__masked-item {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) scale(1.3);
  width: 100%;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap__label {
  font: normal 400 24px/1.1666666667 "corporateacondpro";
}
.inventory-favorites-card-ap__not-available-overlay .order-request-listing__cards .order-request-card .saved-build-card-ap__label {
  opacity: 0.5;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap--unavailable {
  border: 1px solid #eb0000;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap--unavailable .carousel-nav,
.order-request-listing__cards .order-request-card .saved-build-card-ap--unavailable .vehicle-grid-visuals__image-label,
.order-request-listing__cards .order-request-card .saved-build-card-ap--unavailable .vehicle-grid-visuals__image-detail,
.order-request-listing__cards .order-request-card .saved-build-card-ap--unavailable .vehicle-grid-highlights,
.order-request-listing__cards .order-request-card .saved-build-card-ap--unavailable .vehicle-grid-features {
  opacity: 0.6;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap--unavailable .vehicle-grid-card .inventory-favorites-card-ap__not-available-overlay .vehicle-grid-highlights__info-cost--wrapper {
  padding-bottom: 0;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap--main .vehicle-grid-card .vehicle-grid-highlights__card-type--primary {
  font-size: 24px;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap--recommendation-model .vehicle-grid-features__info-copy--header {
  font-weight: 700;
  margin-bottom: 20px;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap--recommendation-model .vehicle-grid-features__info-copy--description {
  font-size: 10.125px;
  color: #717171;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap--recommendation-model .vehicle-grid-card .vehicle-grid-highlights__info-title--model-title {
  font-size: 30.125px;
  line-height: 200px;
  font-family: MBCorpoA, Georgia, serif;
  font-weight: 400;
  margin-top: 5.1875px;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap--recommendation-model .vehicle-grid-features__info-copy {
  margin-bottom: 200px;
}
.vehicle-grid-card .order-request-listing__cards .order-request-card .saved-build-card-ap__share-cta.vehicle-grid-footer__cta--button {
  width: 100%;
  justify-content: center;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap .vehicle-grid-card .vehicle-grid-footer__cta--button.link {
  font-weight: 400;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap .vehicle-grid-footer__ctas {
  display: flex;
  flex-direction: column;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap .vehicle-grid-footer__cta:nth-child(1), .order-request-listing__cards .order-request-card .saved-build-card-ap .vehicle-grid-footer__cta:nth-child(2), .order-request-listing__cards .order-request-card .saved-build-card-ap .vehicle-grid-footer__cta:nth-child(3):last-child {
  grid-column: 1/-1;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap .vehicle-grid-footer__cta:nth-child(4)::before {
  content: "";
  border-left: solid 10px #c6c6c6;
  height: 20.25px;
}
.order-request-listing__cards .order-request-card .saved-build-card-ap .vehicle-grid-footer__status {
  grid-column: 1/-1;
}
.order-request-listing__cards .order-request-card__lock-icon {
  position: absolute;
}
.order-request-listing__cards .order-request-card__lock-icon:after {
  content: "\ea24";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  font-size: 20px;
  margin-left: 5px;
}
.order-request-listing__cards .order-request-card__status {
  text-align: end;
}
.order-request-listing__error {
  margin-top: 10px;
  color: #cc0000;
  text-align: center;
  font-size: 24px;
  border: 1px solid;
  border-color: #dddddd;
  border-radius: 5px;
  background: #dddddd;
  padding: 10px;
}
.order-request-listing__loadspinner-center {
  display: block;
  margin: auto;
}
.order-request-listing__no-item {
  font-weight: 400;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25vh;
}

.inventory-favorites-card-ap {
  position: relative;
  border: 1px solid #c6c6c6;
  background-color: #ffffff;
  margin-bottom: 32px;
  overflow: hidden;
}
@media screen and (min-width: 980px) {
  .inventory-favorites-card-ap {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 31.914893617%;
  }
  .inventory-favorites-card-ap:last-child {
    margin-right: 0;
  }
  .inventory-favorites-card-ap:nth-child(3n) {
    margin-right: 0;
  }
  .inventory-favorites-card-ap:nth-child(3n+1) {
    clear: left;
  }
}
@media screen and (min-width: 768px) and (max-width: 979px) {
  .inventory-favorites-card-ap {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
  }
  .inventory-favorites-card-ap:last-child {
    margin-right: 0;
  }
  .inventory-favorites-card-ap:nth-child(2n) {
    margin-right: 0;
  }
  .inventory-favorites-card-ap:nth-child(2n+1) {
    clear: left;
  }
}
.inventory-favorites-card-ap__card-body {
  padding: 4.9375px 5px 0;
  height: 100%;
}
.inventory-favorites-card-ap--recommendation .inventory-favorites-card-ap__card-body--type-model .vehicle-grid-card__hero {
  height: auto;
}
.inventory-favorites-card-ap--recommendation .inventory-favorites-card-ap__card-body--type-model .vehicle-grid-card .vehicle-grid-highlights {
  padding-left: 5px;
  padding-right: 5px;
}
.inventory-favorites-card-ap--recommendation .inventory-favorites-card-ap__card-body--type-model .vehicle-grid-card .vehicle-grid-highlights__card-type {
  padding-bottom: 5.1875px;
}
.inventory-favorites-card-ap--recommendation .inventory-favorites-card-ap__card-body--type-model .vehicle-grid-card .vehicle-grid-highlights__info-title--model-title {
  font-family: MBCorpoA, Georgia, serif;
  font-size: 32px;
  font-weight: 400;
  line-height: calc(32px + 8px);
  padding-bottom: 20px;
}
.inventory-favorites-card-ap--recommendation .inventory-favorites-card-ap__card-body--type-model .vehicle-grid-card .vehicle-grid-features {
  padding: 5.1875px 5px 0;
}
.inventory-favorites-card-ap--recommendation .inventory-favorites-card-ap__card-body--type-model .vehicle-grid-features__info--list {
  padding-bottom: 45.1875px;
}
.inventory-favorites-card-ap--recommendation .inventory-favorites-card-ap__card-body--type-model .vehicle-grid-features__info-copy {
  padding-bottom: 40px;
}
.inventory-favorites-card-ap--recommendation .inventory-favorites-card-ap__card-body--type-model .vehicle-grid-features__info-copy--header {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: calc(16px + 8px);
}
.inventory-favorites-card-ap--recommendation .inventory-favorites-card-ap__card-body--type-model .vehicle-grid-features__info-copy--description {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: calc(12px + 8px);
  color: #717171;
}
.inventory-favorites-card-ap--recommendation .inventory-favorites-card-ap__card-body--type-model .vehicle-grid-features__info-copy--features-enabled {
  padding-bottom: 4.9375px;
}
.inventory-favorites-card-ap__wrapper {
  height: 100%;
}
.inventory-favorites-card-ap__wrapper .account-remove-saved-item {
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: none;
  margin-top: 30.125px;
}
.inventory-favorites-card-ap__wrapper .account-remove-saved-item__overlay-body {
  padding-left: 5px;
  padding-right: 5px;
}
.inventory-favorites-card-ap__wrapper .account-remove-saved-item__delete-header {
  color: #000000;
  margin-bottom: 30.125px;
  padding: 0 4.9375px;
}
.inventory-favorites-card-ap__wrapper .account-remove-saved-item__cta {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: calc(12px + 8px);
  margin-bottom: 4.9375px;
  margin-right: 0;
  width: 100%;
}
.inventory-favorites-card-ap__not-available-overlay {
  background: transparent;
  height: 100%;
  pointer-events: none;
}
.inventory-favorites-card-ap--unavailable {
  border: 1px solid #eb0000;
}
.inventory-favorites-card-ap--unavailable .carousel-nav,
.inventory-favorites-card-ap--unavailable .vehicle-grid-visuals__image-label,
.inventory-favorites-card-ap--unavailable .vehicle-grid-visuals__image-detail,
.inventory-favorites-card-ap--unavailable .vehicle-grid-highlights,
.inventory-favorites-card-ap--unavailable .vehicle-grid-features {
  opacity: 0.6;
}
.inventory-favorites-card-ap--unavailable .vehicle-grid-card .inventory-favorites-card-ap__not-available-overlay .vehicle-grid-highlights__info-cost--wrapper {
  padding-bottom: 0;
}
.inventory-favorites-card-ap--cpo .vehicle-grid-card .vehicle-grid-visuals .vehicle-grid-visuals__overlap {
  filter: brightness(60%);
}
.inventory-favorites-card-ap--cpo .vehicle-grid-card .vehicle-grid-visuals .button__cpo-view-more {
  z-index: 1000;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffffff;
  margin-top: -28px;
  margin-left: -59.75px;
  border: #ffffff 1px solid;
  width: 120px;
}
.inventory-favorites-card-ap--cpo .vehicle-grid-card .vehicle-grid-features__info--all-options {
  padding: 4.9375px 0;
}
.inventory-favorites-card-ap--cpo-recommendation .vehicle-grid-card .vehicle-grid-highlights, .inventory-favorites-card-ap--cpo-recommendation-class .vehicle-grid-card .vehicle-grid-highlights {
  padding-bottom: 5.1875px;
}
.inventory-favorites-card-ap--cpo-recommendation .vehicle-grid-card .vehicle-grid-highlights__info-title--model-title, .inventory-favorites-card-ap--cpo-recommendation-class .vehicle-grid-card .vehicle-grid-highlights__info-title--model-title {
  font-family: MBCorpoA, Georgia, serif;
  font-size: 24px;
  font-weight: 400;
  line-height: calc(24px + 8px);
}
.inventory-favorites-card-ap--cpo-recommendation .vehicle-grid-card .vehicle-grid-highlights__info-subtitle, .inventory-favorites-card-ap--cpo-recommendation-class .vehicle-grid-card .vehicle-grid-highlights__info-subtitle {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: calc(16px + 8px);
  margin-top: 4.9375px;
}
.inventory-favorites-card-ap--cpo-recommendation .vehicle-grid-card .vehicle-grid-highlights__card-type, .inventory-favorites-card-ap--cpo-recommendation-class .vehicle-grid-card .vehicle-grid-highlights__card-type {
  margin-bottom: 5.1875px;
}
.inventory-favorites-card-ap .vehicle-grid-highlights__info-cost--wrapper .tool-tip__flyout {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: calc(12px + 8px);
  left: -50%;
  max-width: 300px;
}
@media screen and (min-width: 768px) {
  .inventory-favorites-card-ap .vehicle-grid-highlights__info-cost--wrapper .tool-tip__flyout {
    left: 35%;
    transform: translateX(-35%);
  }
}
@media screen and (min-width: 980px) and (max-width: 1199px) {
  .inventory-favorites-card-ap .vehicle-grid-highlights__info-cost--wrapper .tool-tip__flyout {
    left: 50%;
    max-width: 240px;
    padding: 5.1875px;
    transform: translateX(-50%);
  }
}
@media screen and (min-width: 1200px) {
  .inventory-favorites-card-ap .vehicle-grid-highlights__info-cost--wrapper .tool-tip__flyout {
    left: 30%;
    transform: translateX(-30%);
  }
}
.inventory-favorites-card-ap .vehicle-grid-highlights__info-cost--wrapper .tool-tip--open-up .tool-tip__flyout {
  top: auto;
  bottom: 27px;
}
.inventory-favorites-card-ap .vehicle-grid-highlights__info-cost--secondary:empty {
  display: none;
}

.vehicle-grid-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.vehicle-grid-card__body {
  height: 100%;
  margin-bottom: auto;
  min-height: 90px;
  /*
   * may or may not have a class name, always a div
   * should not affect any "not available" inventory cards
   */
}
.vehicle-grid-card .vehicle-grid-overhead {
  min-height: 30.125px;
}
.vehicle-grid-card .vehicle-grid-overhead__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10.125px;
}
.vehicle-grid-card .vehicle-grid-overhead__eyebrow {
  position: relative;
  min-width: 0;
}
.vehicle-grid-card .vehicle-grid-overhead__tool {
  flex-shrink: 0;
  margin-left: 5.1875px;
}
.vehicle-grid-card .vehicle-grid-overhead__title {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: calc(12px + 8px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.vehicle-grid-card .vehicle-grid-overhead__title--detail {
  font: normal 400 15px/20px "Arial", Helvetica, Arial, sans-serif;
}
.vehicle-grid-card .vehicle-grid-overhead__title--icon {
  padding-left: 15.0625px;
}
.vehicle-grid-card .vehicle-grid-overhead__title--icon:before {
  content: "\e90a";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
}
.vehicle-grid-card .vehicle-grid-overhead__title--icon::before {
  font-size: 16px;
  position: absolute;
  top: -1px;
  left: -10px;
}
.vehicle-grid-card .vehicle-grid-overhead__title--empty {
  padding-left: 0;
}
.vehicle-grid-card .vehicle-grid-overhead__title--empty::before {
  position: relative;
}
.vehicle-grid-card .vehicle-grid-overhead__dealer-info {
  width: 100%;
}
.vehicle-grid-card .vehicle-grid-overhead__dealer-info--empty {
  width: 100%;
  padding-bottom: 21px;
}
.vehicle-grid-card .vehicle-grid-overhead__dealer-info--name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.vehicle-grid-card .vehicle-grid-overhead__best-match-info__category {
  display: flex;
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: calc(12px + 8px);
}
.vehicle-grid-card .vehicle-grid-overhead__best-match-info__category--best-match {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: calc(12px + 8px);
}
.vehicle-grid-card .vehicle-grid-overhead__best-match-info__category--best-match:before {
  content: "\e926";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  font-size: 50%;
  color: #176db7;
  margin-right: 20px;
}
.vehicle-grid-card .vehicle-grid-overhead .toggle-favorites {
  font: normal 400 15px/20px "Arial", Helvetica, Arial, sans-serif;
}
.vehicle-grid-card .vehicle-grid-overhead .toggle-favorites__container {
  height: 20px;
}
.vehicle-grid-card .vehicle-grid-overhead .toggle-favorites::after {
  top: 0;
}
.vehicle-grid-card__hero {
  margin-bottom: 15px;
  margin-left: -16px;
  margin-right: -16px;
  text-align: center;
  position: relative;
}
.vehicle-grid-card__hero img {
  object-fit: cover;
}
@media screen and (min-width: 980px) {
  .vehicle-grid-card__hero img {
    height: 163px;
  }
}
.vehicle-grid-card__hero .carousel {
  height: 100%;
}
.vehicle-grid-card__hero .carousel div[data-carousel-navigation=""] {
  min-height: 55.1875px;
}
.vehicle-grid-card__hero .carousel--sliding {
  height: 89%;
}
.vehicle-grid-card__hero .carousel-nav--bottom {
  padding-top: 0;
}
.vehicle-grid-card__hero--no-carousel {
  height: auto;
  margin-bottom: 0;
}
.vehicle-grid-card .vehicle-grid-visuals {
  position: relative;
}
.vehicle-grid-card .vehicle-grid-visuals__mask {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 164px;
}
.vehicle-grid-card .vehicle-grid-visuals__masked-item {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) scale(1.1);
  width: 100%;
}
.vehicle-grid-card .vehicle-grid-visuals__backplate {
  left: 0;
  position: absolute;
  width: 100%;
  height: 164px;
}
.vehicle-grid-card .vehicle-grid-visuals__labels {
  bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  left: 20.25px;
  position: absolute;
  right: 20.25px;
}
.vehicle-grid-card .vehicle-grid-visuals__image-label {
  font: normal 400 15px/20px "Arial", Helvetica, Arial, sans-serif;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  color: #717171;
  overflow: hidden;
  padding: 1px 5.1875px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.vehicle-grid-card .vehicle-grid-visuals__image-label--recently-viewed {
  background: #e8f1f8;
  color: #000000;
  padding-left: 28px;
}
.vehicle-grid-card .vehicle-grid-visuals__image-label--recently-viewed:before {
  content: "\e90a";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  font-size: 15.0625px;
  left: 5.1875px;
  position: absolute;
}
.vehicle-grid-card .vehicle-grid-visuals__image-detail {
  font: normal 400 15px/20px "Arial", Helvetica, Arial, sans-serif;
  background: #e8f1f8;
  border-radius: 2px;
  color: #000000;
  margin-left: 20px;
  padding: 10px 5.1875px;
  position: relative;
  white-space: nowrap;
}
.vehicle-grid-card .vehicle-grid-visuals__image-detail--no-label {
  margin-left: auto;
}
.vehicle-grid-card .vehicle-grid-highlights {
  color: #000000;
  min-height: 90px;
  padding: 10.125px 20.25px;
  text-align: left;
}
.vehicle-grid-card .vehicle-grid-highlights__info-title {
  margin-bottom: 5px;
}
.vehicle-grid-card .vehicle-grid-highlights__info-title--model-title {
  font-size: 16px;
  font-weight: 700;
}
.vehicle-grid-card .vehicle-grid-highlights__info-title--model-line-name {
  color: #717171;
  min-height: 20.25px;
  font: normal 400 18px/1 "corporateacondpro";
  font-weight: bold;
}
.vehicle-grid-card .vehicle-grid-highlights__card-type {
  display: flex;
  flex-wrap: wrap;
  min-height: 20.25px;
}
.vehicle-grid-card .vehicle-grid-highlights__card-type--divider {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: calc(12px + 8px);
  color: #c6c6c6;
  margin: 0 5.1875px;
}
.vehicle-grid-card .vehicle-grid-highlights__card-type--secondary {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: calc(12px + 8px);
  white-space: nowrap;
  font: normal 400 18px/1 "corporateacondpro";
}
.vehicle-grid-card .vehicle-grid-highlights__card-type--secondary-container {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: calc(16px + 8px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.vehicle-grid-card .vehicle-grid-highlights__card-type .icon-recommendation {
  content: "icon-recommendation";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  color: #41853d;
  margin-left: -10px;
  padding-right: 10px;
}
.vehicle-grid-card .vehicle-grid-highlights__card-type .icon-thumbsup {
  color: #176db7;
  padding-right: 10px;
}
.vehicle-grid-card .vehicle-grid-highlights__info-cost {
  font-size: 24px;
  font-weight: 400;
}
.vehicle-grid-card .vehicle-grid-highlights__info-cost .tool-tip {
  margin-left: 20px;
}
.vehicle-grid-card .vehicle-grid-highlights__info-cost--label, .vehicle-grid-card .vehicle-grid-highlights__info-cost--price-unavailable-label {
  font: normal 400 15px/20px "Arial", Helvetica, Arial, sans-serif;
}
.vehicle-grid-card .vehicle-grid-highlights__info-cost--contact-dealer-label {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: calc(16px + 8px);
}
.vehicle-grid-card .vehicle-grid-highlights__info-cost--msrp-label {
  font: normal 400 15px/20px "Arial", Helvetica, Arial, sans-serif;
  padding-left: 20px;
  color: #717171;
}
.vehicle-grid-card .vehicle-grid-highlights__info-cost--secondary {
  font: normal 400 15px/20px "Arial", Helvetica, Arial, sans-serif;
  color: #393939;
  display: block;
  min-height: 20.25px;
}
.vehicle-grid-card .vehicle-grid-highlights__info-cost--wrapper {
  display: flex;
  justify-content: space-between;
}
.vehicle-grid-card .vehicle-grid-highlights__info-subtitle {
  font: normal 400 24px/1.1666666667 "corporateacondpro";
}
.vehicle-grid-card .vehicle-grid-highlights__info-recommendation-description {
  font: normal 400 15px/20px "Arial", Helvetica, Arial, sans-serif;
  color: #717171;
  margin-top: 20px;
}
.vehicle-grid-card .vehicle-grid-highlights__info-recommendation-description--model-line {
  margin-bottom: 15.0625px;
}
.vehicle-grid-card .vehicle-grid-highlights__vehicle-status {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: calc(16px + 8px);
  text-align: center;
  min-height: 20px;
}
.vehicle-grid-card .vehicle-grid-highlights__vehicle-status.available {
  color: #41853d;
}
.vehicle-grid-card .vehicle-grid-highlights__vehicle-status.sold {
  color: #eb0000;
}
.vehicle-grid-card .vehicle-grid-features {
  text-align: left;
  padding: 0 20.25px;
}
.vehicle-grid-card .vehicle-grid-features__empty {
  height: 62px;
}
.vehicle-grid-card .vehicle-grid-features__info--eyebrow {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: calc(12px + 8px);
}
.vehicle-grid-card .vehicle-grid-features__info--all-options {
  min-height: 180px;
}
.vehicle-grid-card .vehicle-grid-features__info--all-options .vehicle-grid-features__info--list {
  list-style: disc outside none;
}
.vehicle-grid-card .vehicle-grid-features__info--all-options .vehicle-grid-features__info-feature {
  margin-left: 15.0625px;
}
.vehicle-grid-card .vehicle-grid-features__info--all-options .vehicle-grid-features__info-feature--description {
  display: block;
  margin-left: -20px;
}
.vehicle-grid-card .vehicle-grid-features__info-feature {
  font: normal 400 15px/20px "Arial", Helvetica, Arial, sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 40px;
}
.vehicle-grid-card .vehicle-grid-features__info-feature--title {
  font-weight: 700;
}
.vehicle-grid-card .vehicle-grid-features__coming-soon {
  font: normal 400 15px/20px "Arial", Helvetica, Arial, sans-serif;
}
.vehicle-grid-card .vehicle-grid-footer {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .vehicle-grid-card .vehicle-grid-footer {
    align-self: flex-end;
  }
}
.vehicle-grid-card .vehicle-grid-footer__ctas {
  padding: 0;
  height: 75px;
}
.vehicle-grid-card .vehicle-grid-footer__cta {
  font: normal 400 15px/20px "Arial", Helvetica, Arial, sans-serif;
  display: block;
  margin-right: 10.125px;
  margin-bottom: 10.125px;
  text-align: center;
  width: 100%;
}
.vehicle-grid-card .vehicle-grid-footer__cta--button, .vehicle-grid-card .vehicle-grid-footer__cta--link.button {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: calc(12px + 8px);
}
.vehicle-grid-card .vehicle-grid-footer__cta--button, .vehicle-grid-card .vehicle-grid-footer__cta--link {
  min-height: 30.125px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
}
.vehicle-grid-card .vehicle-grid-footer__cta > .button.disabled {
  color: #000000;
}
.vehicle-grid-card .vehicle-grid-footer__cta:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
.vehicle-grid-card .vehicle-grid-footer__cta-container {
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .vehicle-grid-card .vehicle-grid-footer__cta-container {
    display: block;
    width: calc(50% - 5px);
  }
}
.vehicle-grid-card .vehicle-grid-footer__status {
  font: normal 400 15px/20px "Arial", Helvetica, Arial, sans-serif;
  color: #eb0000;
  text-align: center;
}
.vehicle-grid-card .vehicle-grid-footer__info {
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: calc(16px + 8px);
  border-top: 1px solid #c6c6c6;
  color: #717171;
  display: flex;
  justify-content: space-between;
  padding: 10.125px 0;
  min-height: 20.25px;
  font-size: 12px;
}
.vehicle-grid-card .vehicle-grid-footer__info--empty {
  border: none;
  min-height: 45px;
}
.vehicle-grid-card__center-text {
  text-align: center;
}

.order-confirmation-modal__heading {
  color: #000000;
  margin: 15px 0 20px;
  margin-bottom: 10px;
  text-align: center;
  float: unset;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .order-confirmation-modal__heading {
    margin: 30px 0;
    margin-bottom: 10px;
  }
}
.order-confirmation-modal__heading--light {
  color: #ffffff;
}
.order-confirmation-modal__sub-heading {
  float: none;
}
.order-confirmation-modal__action {
  padding: 10px;
  display: flex;
}
.order-confirmation-modal__action > .button {
  margin-right: 10px;
  margin-left: 10px;
}
.order-confirmation-modal__action__button-reject {
  background-color: #eb0000;
  color: #ffffff;
}
.order-confirmation-modal__production-date-input {
  margin: 10px;
}

:host {
  box-sizing: border-box;
}

.email-us-form {
  width: 100%;
  min-height: 20px;
}

.email-us-form-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.email-us-form-header-container {
  width: 100%;
  border: none;
  border-bottom: 1px solid #c6c6c6;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: serif;
  font-size: 16px;
  padding: 64px 0px 32px 0px;
  color: black;
}
.email-us-form-header-container h1 {
  font-family: corporateacondpro, Georgia, serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  overflow-wrap: break-word;
  text-align: center;
  margin: 0 0;
}
.email-us-form-header-container p {
  width: 73%;
  text-align: center;
  display: block;
  font-family: MBCorpoS, Arial, serif;
  font-size: 16px;
  line-height: 24px;
  padding-top: 8px;
}
@media (max-width: 767.98px) {
  .email-us-form-header-container p {
    width: 100%;
  }
}

.inquiry-type-options option div {
  padding: 12px;
}

.options {
  padding: 12px;
}

.email-us-form-content-container {
  width: 81.25%;
  display: flex;
  justify-content: center;
  padding: 32px 0px;
}
@media (max-width: 767.98px) {
  .email-us-form-content-container {
    width: 100%;
  }
}

.email-us-form-content {
  width: 70%;
}
@media (max-width: 767.98px) {
  .email-us-form-content {
    width: 100%;
    padding: 0 15px;
  }
}

.email-us-form-util-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
  color: #5a5a5a;
  height: 80px;
}
.email-us-form-util-container .onLabel {
  padding-left: 1px;
  height: 20px;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  display: block;
  transition: 0.2s;
}
.email-us-form-util-container .onLabelDown {
  padding-top: 12px;
}
.email-us-form-util-container .onLabelUp {
  padding-bottom: 20px;
  color: #176db7;
}
.email-us-form-util-container .onLabelUpValid {
  padding-top: 0px;
  color: #5a5a5a;
}
.email-us-form-util-container .onErrorLabelDown {
  padding-top: 12px;
}
.email-us-form-util-container .onInput {
  background: none;
  font-family: MBCorpoS, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  text-align: left;
  padding: 0 0 4px;
}
.email-us-form-util-container .onInput:focus {
  border: 3px solid black;
  border-radius: 4px;
  line-height: 24px;
  padding: 0 0 4px 0;
}
.email-us-form-util-container .onTextArea {
  height: 100%;
}
.email-us-form-util-container .onSelect {
  border: none;
  border-bottom: 1px solid #999;
  background: none;
  font: inherit;
  height: 30px;
  text-align: left;
  font-size: 16px;
  outline: none;
  width: 100%;
  padding: 0px;
}

.email-us-form-field-container {
  height: 80px;
}

.email-us-form-area-container {
  height: 120px;
}

.email-us-form-submit-container {
  padding: 16px 0px;
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.email-us-form-submit-container.button-with-captcha {
  flex-wrap: wrap;
}
@media (max-width: 837.98px) {
  .email-us-form-submit-container.button-with-captcha {
    row-gap: 16px;
  }
}
.email-us-form-submit-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: #176db7;
  padding: 4px 32px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  color: white;
  line-height: 24px;
  max-height: 32px;
}
.email-us-form-submit-container button:disabled {
  background-color: #717171;
  min-height: 32px;
  min-width: 116px;
}

.email-us-form-upload-container {
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.email-us-form-upload-container label {
  font-family: "micro-icons", Arial, Helvetica, sans-serif;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
}
.email-us-form-upload-container label#email-us-file-attachment {
  padding: 4px 0px;
}
.email-us-form-upload-container label#email-us-file-upload-btn {
  border-radius: 2px;
  background-color: #e3e3e3;
  padding: 4px 32px;
  cursor: pointer;
  font-weight: 700;
  color: black;
  row-gap: 16px;
}
.email-us-form-upload-container .icon-plus {
  font-size: 12px;
  padding-left: 10px;
}

.uploaded-file-container {
  position: relative;
  padding: 16px 16px;
  width: 60%;
  min-height: 48px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 700;
  border: 1px solid #e3e3e3;
}
@media (max-width: 481.98px) {
  .uploaded-file-container {
    width: 100%;
  }
}
.uploaded-file-container span {
  word-break: break-all;
}

.upload-button-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
}
.upload-button-box label:nth-child(2) {
  font-size: 12px;
  overflow-wrap: break-word;
  line-height: 12px;
  padding-left: 6px;
}

.utl-upload {
  width: 100%;
  padding: 4px 0px;
  position: relative;
}

.deleteUpload {
  position: absolute;
  top: 0%;
  right: 0%;
  padding: 4px 8px;
  cursor: pointer;
}

.utl-border-red {
  border: 3px solid rgba(1, 1, 1, 0);
  border-bottom: 1px solid red;
}

.utl-border-gray {
  border: 3px solid rgba(1, 1, 1, 0);
  border-bottom: 1px solid rgb(115, 115, 115);
}

.error-label {
  position: absolute;
  width: 100%;
  top: calc(100% - 14px);
  left: 0%;
  line-height: 24px;
  font-size: 16px;
  color: red;
}

.onErrorLabel {
  height: 20px;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  color: red;
}

.onSelectTouched {
  border-bottom: 1px solid red;
}

.request-label {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 24px 24px;
  background: #f1f1f1;
}
.request-label p {
  line-height: 24px;
  font-size: 16px;
}
.request-label.request-red {
  color: red;
}
.request-label.request-black {
  color: black;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-spinner-react {
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

:host {
  box-sizing: border-box;
}

.bulk-can-upload {
  width: 100%;
  min-height: 20px;
}

.bulk-can-upload-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.bulk-can-upload-header-container {
  width: 100%;
  border: none;
  border-bottom: 1px solid #c6c6c6;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  font-family: serif;
  font-size: 16px;
  color: #5a5a5a;
}
.bulk-can-upload-header-container h1 {
  font-family: corporateacondpro, Georgia, serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 38px;
  overflow-wrap: break-word;
  text-align: center;
  margin: 0 0;
}
.bulk-can-upload-header-container p {
  word-wrap: break-word;
  font-family: MBCorpoS, Arial, serif;
  font-size: 14px;
  line-height: 22px;
}
@media (max-width: 767.98px) {
  .bulk-can-upload-header-container p {
    width: 100%;
  }
}

.bulk-can-upload-content-container {
  width: 81.25%;
  display: flex;
  justify-content: center;
  padding: 16px 0px;
}
@media (max-width: 767.98px) {
  .bulk-can-upload-content-container {
    width: 100%;
  }
}

.bulk-can-upload-content {
  width: 70%;
}
@media (max-width: 767.98px) {
  .bulk-can-upload-content {
    width: 100%;
    padding: 0 15px;
  }
}
.bulk-can-upload-content p#bulk-upload-message {
  font-size: 16px;
  font-family: MBCorpoS, Arial, serif;
  color: black;
}
.bulk-can-upload-content p#bulk-upload-message.error-message {
  color: red;
  text-align: center;
}
.bulk-can-upload-content p#bulk-upload-message.success-message {
  color: green;
  text-align: center;
}

.bulk-can-upload__loader-backdrop {
  height: 100%;
  background: rgba(0, 0, 0, 0.1490196078);
  top: 0px;
  right: 0px;
  position: absolute;
  content: "";
  width: 100%;
}
.bulk-can-upload__loader-backdrop .bulk-can-upload__loader {
  position: fixed;
  left: 54%;
  top: 50%;
  z-index: 1;
  width: 60px;
  height: 60px;
  margin: -75px 0 0 -75px;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.bulk-can-upload-submit-container {
  padding: 16px 0px;
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: end;
}
.bulk-can-upload-submit-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: #176db7;
  padding: 4px 32px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  color: white;
  line-height: 24px;
  max-height: 32px;
}
.bulk-can-upload-submit-container button:disabled {
  background-color: #717171;
  min-height: 32px;
  min-width: 116px;
  cursor: not-allowed;
}

.bulk-can-upload-upload-container {
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.bulk-can-upload-upload-container label {
  font-family: "micro-icons", Arial, Helvetica, sans-serif;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
}
.bulk-can-upload-upload-container label#bulk-can-file-upload-btn {
  border-radius: 2px;
  background-color: #e3e3e3;
  padding: 4px 32px;
  cursor: pointer;
  font-weight: 700;
  color: black;
  row-gap: 16px;
}

.upload-button-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  border-bottom: 1px solid #c6c6c6;
}
.upload-button-box label:nth-child(2) {
  font-size: 12px;
  overflow-wrap: break-word;
  line-height: 12px;
  padding-left: 6px;
}

.bluetecupdate-form__title {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  padding: 40px 0 15px 0;
}
.bluetecupdate-form__container {
  text-align: center;
}
.bluetecupdate-form__container form {
  width: 100%;
  text-align: left;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .bluetecupdate-form__container form {
    width: 53%;
  }
}
.bluetecupdate-form__input {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.bluetecupdate-form__input:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.bluetecupdate-form__input-group .button {
  min-width: 100px;
  opacity: 1;
}
.bluetecupdate-form__input-group .button[disabled=disabled] {
  opacity: 0.5;
}
.bluetecupdate-form__input-group .button[disabled=disabled]:hover {
  cursor: not-allowed;
  background: #176db7;
}
@media screen and (min-width: 768px) {
  .bluetecupdate-form__input-group:last-of-type {
    text-align: center;
    flex-grow: 1;
  }
}
.bluetecupdate-form__input-label {
  display: block;
}
.bluetecupdate-form__input--error, .bluetecupdate-form__input--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.bluetecupdate-form__input--success, .bluetecupdate-form__input--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.bluetecupdate-form__submit-container {
  display: block;
}
@media screen and (min-width: 768px) {
  .bluetecupdate-form__submit-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
}
.bluetecupdate-form__error-field {
  color: #cc0000;
}
.bluetecupdate-form__error-field--hidden {
  opacity: 0;
}
.bluetecupdate-form__recaptcha-container {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .bluetecupdate-form__recaptcha-container {
    margin-bottom: 0;
  }
}
.bluetecupdate-form__recaptcha-container--hidden {
  display: none;
}
.bluetecupdate-form__information {
  text-align: left;
  display: inline-block;
  width: 100%;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .bluetecupdate-form__information {
    width: 53%;
  }
}
.bluetecupdate-form__information-container--hidden {
  display: none;
}
.bluetecupdate-form__information .dealer-widget-container {
  text-align: left;
  padding-top: 20px;
  padding-bottom: 40px;
  margin: 20px 0;
  border-bottom: 1px solid #999;
  border-top: 1px solid #999;
}
.bluetecupdate-form__information .preferred-dealer__address-container-dealer {
  padding-left: 0;
}
.bluetecupdate-form__information .preferred-dealer-plugin__sub-heading-container {
  text-align: center;
}
.bluetecupdate-form__information .preferred-dealer-plugin__sub-heading, .bluetecupdate-form__information .preferred-dealer-plugin__sub-heading-copy {
  display: inline-block;
}
.bluetecupdate-form__table {
  margin: 30px 0;
  border: 1px solid #c7c7c7;
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.bluetecupdate-form__table-container {
  text-align: center;
}
.bluetecupdate-form__table-row {
  display: flex;
  align-items: stretch;
  width: 100%;
  border-top: 1px solid #c7c7c7;
}
.bluetecupdate-form__table-row:last-of-type .bluetecupdate-form__column-info {
  padding: 0 10px;
}
.bluetecupdate-form__table-column {
  width: 80%;
  float: left;
  padding: 5px;
}
.bluetecupdate-form__table-column:first-of-type {
  border-right: 1px solid #c7c7c7;
}
.bluetecupdate-form__table-column:last-of-type {
  width: 20%;
  border-left: 1px solid #c7c7c7;
}
.bluetecupdate-form__table-heading {
  width: 80%;
  float: left;
  border-right: 1px solid #c7c7c7;
  padding: 5px 0;
}
.bluetecupdate-form__table-heading:last-of-type {
  width: 20%;
}
.bluetecupdate-form__table-heading-row {
  background: #dddddd;
  display: inline-block;
  width: 100%;
  font-weight: 700;
}
.bluetecupdate-form__message {
  display: block;
  font: italic 700 18px/1.3333333333 "corpos";
  margin-bottom: 10px;
}
.bluetecupdate-form--recaptcha-hidden .bluetecupdate-form__input-group {
  text-align: left;
}
.bluetecupdate-form__column-info {
  background: #f1f1f1;
  border: 1px solid #c7c7c7;
  display: none;
  text-align: left;
  margin-top: 5px;
}
.bluetecupdate-form__column-info--open {
  display: block;
}
.bluetecupdate-form__column-info-row, .bluetecupdate-form__column-info-heading {
  text-align: center;
  padding: 5px;
  border-bottom: 1px solid #c7c7c7;
  display: inline-block;
  width: 100%;
}
.bluetecupdate-form__column-info-heading {
  background: #dddddd;
  font-weight: 700;
}
.bluetecupdate-form__column-info-column {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 40.4255319149%;
}
.bluetecupdate-form__column-info-column:last-child {
  margin-right: 0;
}
.bluetecupdate-form__column-info-column:nth-child(2n) {
  margin-right: 0;
}
.bluetecupdate-form__column-info-column:nth-child(2n+1) {
  clear: left;
}
.bluetecupdate-form__column-info-column:last-of-type {
  border-bottom: none;
}
.bluetecupdate-form__column-info-column:nth-of-type(2n+1) {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 57.4468085106%;
}
.bluetecupdate-form__column-info-column:nth-of-type(2n+1):last-child {
  margin-right: 0;
}
.bluetecupdate-form__column-info-column .icon-check {
  color: #569C53;
}
.bluetecupdate-form__column-info-column .icon-cross {
  color: #cc0000;
}
.bluetecupdate-form__column-info-icon {
  font-size: 10px;
  display: block;
  margin-top: 5px;
}
.bluetecupdate-form__column-title {
  cursor: pointer;
}
.bluetecupdate-form__column-title .icon-arrow-down-square, .bluetecupdate-form__column-title .view-more-cta .icon-mb, .view-more-cta .bluetecupdate-form__column-title .icon-mb {
  float: right;
  margin: 7px 6px 0px;
  font-size: 11px;
}
.bluetecupdate-form__redirect-link {
  color: #176db7;
  cursor: pointer;
}
.bluetecupdate-form__redirect-link:hover {
  color: #0090ff;
}
.bluetecupdate-form__redirect-link-container {
  margin: 10px 0;
}

form[id=bluetecupdate-form-personal-info] {
  width: 100%;
  visibility: visible;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}
form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__address {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__address:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__address {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
  }
  form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__address:last-child {
    margin-right: 0;
  }
  form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__address:nth-of-type(2n) {
    margin-right: 0;
  }
}
form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__address-subsection {
  display: inline-block;
  width: 100%;
}
form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__address-section {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__address-section:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__address-section {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 31.914893617%;
  }
  form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__address-section:last-child {
    margin-right: 0;
  }
}
form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__input-group {
  padding: 0;
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__input-group:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__input-group {
    padding: 5px 0;
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 48.9361702128%;
  }
  form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__input-group:last-child {
    margin-right: 0;
  }
  form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__input-group:nth-of-type(2n) {
    margin-right: 0;
  }
}
form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__input-group:last-of-type, form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__input-group--address {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__input-group:last-of-type:last-child, form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__input-group--address:last-child {
  margin-right: 0;
}
form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__input-group:last-of-type {
  text-align: left;
}
form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__input-radio-group {
  font: normal 700 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 2px;
}
form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__input-label {
  font: normal 700 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__recaptcha-container--hidden {
  display: none;
}
form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__submit-container {
  width: 100%;
}
@media screen and (min-width: 768px) {
  form[id=bluetecupdate-form-personal-info] .bluetecupdate-form__submit-container .bluetecupdate-form__input-group:last-of-type {
    margin-left: 10px;
  }
}

form[id=bluetecupdate-unsubscribe-form], form[id=bluetecupdate-resend-confirmation-form] {
  width: 100%;
}
form[id=bluetecupdate-unsubscribe-form] .bluetecupdate-form__input-group, form[id=bluetecupdate-resend-confirmation-form] .bluetecupdate-form__input-group {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 65.9574468085%;
}
form[id=bluetecupdate-unsubscribe-form] .bluetecupdate-form__input-group:last-child, form[id=bluetecupdate-resend-confirmation-form] .bluetecupdate-form__input-group:last-child {
  margin-right: 0;
}
form[id=bluetecupdate-unsubscribe-form] .bluetecupdate-form__input-group:last-of-type, form[id=bluetecupdate-resend-confirmation-form] .bluetecupdate-form__input-group:last-of-type {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 31.914893617%;
}
form[id=bluetecupdate-unsubscribe-form] .bluetecupdate-form__input-group:last-of-type:last-child, form[id=bluetecupdate-resend-confirmation-form] .bluetecupdate-form__input-group:last-of-type:last-child {
  margin-right: 0;
}

form[hidden] {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s ease-in-out;
}

@media screen and (min-width: 768px) {
  .preferred-dealer-plugin--search-bar form {
    width: 65%;
  }
}

.recaptcha__error {
  display: none;
  color: #cc0000;
}
.recaptcha.error .recaptcha__error {
  display: block;
}

.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
  z-index: 901;
}
.overlay--hidden {
  display: none;
}
.overlay__loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #fff;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #fff;
  border-left: 16px solid transparent;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.overlay__message {
  color: white;
  margin-top: 20px;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  width: 100%;
  text-align: center;
}
.overlay__container {
  position: fixed;
  z-index: 999;
  top: calc(50% - 80px);
  left: calc(50% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.overlay__container--dog {
  left: calc(50% - 161px);
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.read-only-screen__heading {
  font-size: 20px;
  font-family: inherit;
  font-weight: 500;
  margin-bottom: 10px;
  color: black;
  background: lightgray;
  border: 1px thick red;
  border-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  display: table-cell;
  padding: 6px 10px;
  float: left;
  position: relative;
  width: 102%;
  height: 20%;
  margin-left: -10px;
  margin-top: -5px;
}
.read-only-screen__group {
  display: flex;
  padding-right: 30px;
  flex-direction: row;
  justify-content: start;
  test-align: left;
  width: 900px;
  line-height: 26px;
  margin-bottom: 10px;
}
.read-only-screen__label {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 31.914893617%;
  padding-left: 2px;
  padding-top: 0px;
  text-align: left;
  margin-right: 5px;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
}
.read-only-screen__label:last-child {
  margin-right: 0;
}
.read-only-screen__control {
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  display: table-cell;
  padding: 6px 10px;
  float: left;
  width: 100%;
  font-family: "Arial";
  margin-top: 3%;
  margin-left: -25%;
  margin-right: 7%;
}
.read-only-screen__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.read-only-screen__control-container {
  display: inline-block;
}
.read-only-screen__design {
  background: whitesmoke;
  border: 1px transparent;
  border-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  display: table-cell;
  padding: 6px 10px;
  float: left;
  width: 100%;
}
.read-only-screen__layout {
  background: whitesmoke;
  border: 1px transparent;
  border-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  display: table-cell;
  padding: 6px 10px;
  float: left;
  width: 100%;
}
.read-only-screen__heading1 {
  font-size: 20px;
  font-family: inherit;
  font-weight: 500;
  margin-bottom: 10px;
  color: black;
  background: lightgray;
  border: 1px thick red;
  border-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  display: table-cell;
  padding: 6px 10px;
  float: left;
  position: relative;
  width: 100.8%;
  height: 20%;
  margin-left: -10px;
  margin-top: -5px;
}
.read-only-screen__display {
  background: white;
  display: table-cell;
  padding: 6px 10px;
  float: left;
  width: 100%;
}
.read-only-screen__design1 {
  background: whitesmoke;
  border: 1px transparent;
  border-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  display: table-cell;
  padding: 6px 10px;
  float: left;
  width: 102%;
  position: relative;
  left: -1%;
}
.read-only-screen__group1 {
  padding-right: 30px;
  flex-direction: row;
  justify-content: start;
  test-align: left;
  width: 1000px;
  line-height: 26px;
  margin-bottom: 10px;
}
.read-only-screen__label1 {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 31.914893617%;
  padding-left: 2px;
  padding-top: 0px;
  text-align: left;
  margin-right: 5px;
  font-size: 14px;
  width: 100%;
}
.read-only-screen__label1:last-child {
  margin-right: 0;
}
.read-only-screen__group2 {
  padding-right: 30px;
  flex-direction: row;
  justify-content: start;
  test-align: left;
  width: 150px;
  line-height: 26px;
  margin-bottom: 10px;
}
.read-only-screen__heading2 {
  font-size: 20px;
  font-family: inherit;
  font-weight: 500;
  margin-bottom: 10px;
  color: black;
  background: lightgray;
  border: 1px thick red;
  border-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  display: table-cell;
  padding: 6px 10px;
  float: left;
  position: relative;
  width: 110%;
  height: 20%;
  margin-left: -10px;
  margin-top: -5px;
}

.uis-buttons__label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
.uis-buttons__input[type=file] {
  display: block;
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.uis-buttons__button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  line-height: 1.5;
  border-radius: 4px;
  user-select: none;
}
.uis-buttons__formPopup {
  display: none;
  position: fixed;
  left: 45%;
  width: 75%;
  top: 5%;
  transform: translate(-50%, 5%);
  border: 3px solid #999999;
  z-index: 9;
  padding: 15px;
}
.uis-buttons__confirmButtons {
  border-top: 1px solid #e3e3e3;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
}
.uis-buttons__control {
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  display: table-cell;
  padding: 6px 10px;
  float: left;
  width: 100%;
  height: 25%;
  font-family: "Arial";
}
.uis-buttons__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.uis-buttons__control--error, .uis-buttons__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.uis-buttons__control--success, .uis-buttons__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.uis-buttons__control-container {
  display: inline-block;
}
.uis-buttons__complete-button {
  text-align: right;
  margin-left: 350;
}
.uis-buttons__cancel-button {
  text-align: center;
  padding-left: 300px;
  margin-left: -280;
  padding-right: 10px;
}
.uis-buttons__panel-body {
  padding-right: 15px;
}
.uis-buttons__group1 {
  padding: 10px 0px;
  display: flex;
  margin-top: 3%;
  margin-left: -2%;
}
.uis-buttons__button_primary {
  color: #fff;
  text-align: initial;
  margin: 2px 2px;
}
.uis-buttons__close {
  padding: 10px 0px;
  display: flex;
  margin-top: 3%;
  margin-left: -2%;
}
.uis-buttons__section {
  display: block;
}
.uis-buttons__section .pop-up {
  padding-top: 150px;
}
.uis-buttons__section .pop-up__content-header {
  background-color: #3276B1;
  color: #fff;
}
.uis-buttons__section .pop-up__content-body {
  background: #fff;
  text-align: initial;
}
.uis-buttons__button-block {
  margin: 30px;
}
.uis-buttons__content {
  position: relative;
  background-color: #fff;
  margin: auto;
  padding: 0;
  border-radius: 6px;
  width: 60%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
.uis-buttons__content-header {
  padding: 5px 20px;
  font-size: 24px;
  font-family: "corporateacondpro", sans-serif;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #3276B1;
  color: #fff;
}
.uis-buttons__content-close-icon {
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  font-family: "micro-icons";
  position: absolute;
  right: 20px;
  top: 17px;
}
@media screen and (min-width: 768px) {
  .uis-buttons__content-close-icon {
    top: 15px;
  }
}
.uis-buttons__content-close-icon:before {
  content: "\e91e";
}
.uis-buttons__content-close-icon:hover, .uis-buttons__content-close-icon:focus {
  text-decoration: none;
  cursor: pointer;
  color: #999;
}
.uis-buttons__content-close-button {
  font-size: 14px;
  white-space: nowrap;
  color: #333;
  background-color: #fff;
  border-style: none;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}
.uis-buttons__content-close-button:focus {
  border-color: #a3a3a3;
  outline: 0;
}
.uis-buttons__content-body {
  padding: 10px;
}
@media screen and (min-width: 768px) {
  .uis-buttons__content-body {
    padding: 20px;
  }
}
.uis-buttons__content-body img {
  border: 1px solid #666;
  padding: 2px;
  width: 100%;
}
.uis-buttons__content-body .video {
  position: relative;
  width: 100%;
}
.uis-buttons__content-body .video__img {
  width: 100%;
}
.uis-buttons__content-body .video__img {
  opacity: 0;
}
.uis-buttons__content-body .video__frame {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.uis-buttons__content-footer {
  border-top: 1px solid #e3e3e3;
  margin-top: 10px;
  padding: 10px;
  margin-top: 20px;
  padding: 8px;
  clear: both;
  text-align: center;
  margin-right: 265px;
}
@media screen and (min-width: 768px) {
  .uis-buttons__content-footer {
    margin-top: 15px;
    padding: 20px;
  }
}
.uis-buttons__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.uis-buttons__group:nth-last-child(2) {
  padding-bottom: 20px;
  border-bottom: 1px solid #999;
}
.uis-buttons__group:last-child {
  text-align: right;
  margin-top: -69.5;
}
.uis-buttons__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
  text-align: left;
}
.uis-buttons__control {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.uis-buttons__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.uis-buttons__control--error, .uis-buttons__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.uis-buttons__control--success, .uis-buttons__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.uis-buttons textarea {
  resize: none;
  height: auto;
  width: 100%;
}
.uis-buttons .button {
  font: normal 400 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  border-radius: 0px;
  padding: 5px 20px;
}
.uis-buttons .button:hover {
  cursor: pointer;
}
.uis-buttons__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.uis-buttons__error-field--hidden {
  display: none;
}
.uis-buttons__modal-title {
  font-size: 1rem;
  text-align: left;
}
.uis-buttons__impact {
  font-size: 0.85rem;
}
.uis-buttons__complete {
  background-color: #176db7;
  color: #fff;
}
.uis-buttons__review {
  position: relative;
  margin-left: 87.2%;
  top: 2rem;
}
.uis-buttons__deny {
  position: relative;
  margin-left: 87.2%;
  top: 2rem;
}
.uis-buttons__btn {
  border-radius: 0px;
  display: inline-block;
  font-family: "Arial", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  padding: 7px 26px;
  cursor: not-allowed;
}
.uis-buttons__btn--primary {
  background: #818080;
  border-color: #285E8E;
  color: #fff;
}
.uis-buttons__update-button {
  background: #818080;
  border-color: #285E8E;
  font-size: 12px;
  font-family: "Arial", Helvetica, Arial, sans-serif;
  cursor: not-allowed;
  color: #fff;
  font-weight: 700;
  line-height: 1.83333;
  padding: 5px 10px;
}

.uis-dashboard {
  color: #333;
}
.uis-dashboard__heading {
  font: normal 400 32px/1 "corporateacondpro";
  margin-bottom: 10px;
}
.uis-dashboard__group {
  min-width: 900px;
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.uis-dashboard__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.uis-dashboard__index {
  display: inline-block;
  margin: 20px 0 10px 0;
}
.uis-dashboard__index--anchor {
  color: #428bca;
  float: left;
  padding: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #dddddd;
  width: 55px;
  height: 35px;
  text-align: center;
  margin-bottom: 10px;
}
.uis-dashboard__index--anchor:hover {
  color: #285E8E;
  background-color: #eeeeee;
  border-color: #dddddd;
  cursor: pointer;
}
.uis-dashboard__index--anchor:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.uis-dashboard__index--anchor:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.uis-dashboard__index--anchor-hidden {
  display: none;
}
.uis-dashboard__index--active {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.uis-dashboard__index--active:hover {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.uis-dashboard__index--disabled {
  pointer-events: none;
  color: grey;
  cursor: default;
}
.uis-dashboard__index--disabled:hover, .uis-dashboard__index--disabled:focus {
  color: grey;
  cursor: default;
  border-color: none;
  background-color: #fff;
}
.uis-dashboard__table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.uis-dashboard__table .fixed {
  table-layout: fixed;
}
.uis-dashboard__table .fixed td {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
.uis-dashboard__table .fixed th {
  cursor: pointer;
}
.uis-dashboard__table th[data-filter-value] {
  cursor: pointer;
}
.uis-dashboard__sortable {
  position: relative;
}
.uis-dashboard__child {
  font: initial;
}
.uis-dashboard__half_width {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.uis-dashboard__btn_label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #5cb85c;
}
.uis-dashboard__control {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.uis-dashboard__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.uis-dashboard__control--error, .uis-dashboard__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.uis-dashboard__control--success, .uis-dashboard__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.uis-dashboard__table_column_padding_left {
  padding-left: 15px;
}
.uis-dashboard__table_column_padding_right {
  padding-right: 15px;
}
.uis-dashboard__error-field {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 30px 10px 10px;
}
.uis-dashboard__loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.uis-dashboard__loader-submit {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.uis-dashboard__listing_loader {
  position: relative;
  left: 54%;
  top: 70%;
  z-index: 1;
  width: 60px;
  height: 60px;
  margin: -75px 0 0 -75px;
  border: 10px solid #DCDCDC;
  border-radius: 50%;
  border-top: 10px solid #000;
  background-position: center;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.uis-dashboard__button {
  color: #fff;
  background-color: #176db7;
  padding: 5px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
  border-radius: 0.6em;
}
.uis-dashboard__button:hover {
  cursor: pointer;
  background-color: #0090ff;
}
.uis-dashboard__border_rule {
  border-bottom: 1px solid #DCDCDC;
}
.uis-dashboard__td_padding {
  margin: 10px;
  padding: 10px 3px;
  text-align: center;
  word-break: break-word;
}
.uis-dashboard__th_padding {
  margin: 10px;
  padding: 10px 0px;
  text-align: center;
}
.uis-dashboard__pagin_padding {
  display: flex;
  justify-content: center;
  padding-top: 25px;
  font-size: initial;
}
.uis-dashboard__arrow_icon {
  color: #add8e6;
}
.uis-dashboard__downloadPdf {
  font-size: 35px;
}
.uis-dashboard__download {
  position: absolute;
  right: 40px;
}
.uis-dashboard__popup-header {
  height: 40px;
}
.uis-dashboard__design {
  margin-top: 5px;
  padding: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
  text-align: center;
  color: #fff;
  position: relative;
  right: 48px;
}
.uis-dashboard__button-primary1 {
  text-align: center;
}
.uis-dashboard__cancel {
  position: relative;
  bottom: 33px;
  left: 420px;
}
.uis-dashboard__arrow_icon {
  color: #add8e6;
}
.uis-dashboard__request {
  margin-bottom: -6%;
}
.uis-dashboard__Requests-style {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.check-form__group {
  padding: 10px 0px;
}
.check-form__heading {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.check-form__subheading {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.check-form__label {
  display: block;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.check-form__textarea {
  height: auto;
  width: 100%;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: auto;
}
.check-form__uploadbox {
  box-sizing: border-box;
  display: block;
  font-weight: 400;
  font-size: 0.984375rem;
  line-height: 1.5;
  font-family: Corporate S Pro;
  color: #373a3c;
}
.check-form__uploadboxexpanded {
  margin-top: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px;
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
  display: block;
}
.check-form__data-item {
  transition: border-width 0.25s, border-color 0.25s, background-color 0.25s;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-sizing: border-box;
  display: block;
}
.check-form__item-group {
  margin-bottom: 0 !important;
  box-sizing: border-box;
}
.check-form__final-item {
  background: #fff;
  overflow: hidden;
  box-sizing: border-box;
  display: block;
  text-align: center;
}
.check-form__item-file-list:not(:empty) {
  border-top: 1px solid #eee;
  box-sizing: border-box;
  display: block;
}
.check-form__trigger-input {
  visibility: hidden;
  width: 0;
  height: 0;
}
.check-form__item-file-action {
  padding-right: 1rem !important;
  padding-bottom: 0.75rem !important;
  text-align: right;
  box-sizing: border-box;
  display: block;
}
.check-form__button-forfile {
  border-color: transparent;
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  color: #fff;
  background-color: #5cb85c;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  user-select: none;
  font-family: inherit;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  font: inherit;
  margin: 0;
  box-sizing: border-box;
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-indent: 0px;
  text-shadow: none;
  align-items: flex-start;
}
.check-form__full-page {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
}
.check-form__final-button {
  padding: 0.3rem 1.2rem;
  font-size: inherit;
  border-color: transparent;
  color: #fff;
  background-color: #4285f4;
}
.check-form__data-control {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 34px;
  padding: 6px 12px;
}
.check-form__border {
  padding-left: 2.5%;
}
.check-form__table {
  width: 80%;
}
.check-form__table_column {
  padding-right: 25px;
  width: 35%;
  vertical-align: middle;
}
.check-form__sub-title {
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 20px;
  padding-bottom: 5px;
}
.check-form__asterisk_field {
  color: red;
}
.check-form__control {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  width: 100%;
  padding-left: 5px;
}
.check-form__control:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.check-form__control--error, .check-form__control--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.check-form__control--success, .check-form__control--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.check-form__sample {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  width: 100%;
  padding-left: 5px;
}
.check-form__sample:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.check-form__sample--error, .check-form__sample--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.check-form__sample--success, .check-form__sample--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.check-form__specification {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 34px;
  width: 100%;
  padding-left: 5px;
}
.check-form__specification:focus {
  border-color: #66AFE9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.check-form__specification--error, .check-form__specification--error:focus {
  border-color: #A94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.check-form__specification--success, .check-form__specification--success:focus {
  border-color: #2B542C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67B168;
}
.check-form__error-field {
  color: #A94442;
  font: normal 700 14px/1.5 "Arial", Helvetica, Arial, sans-serif;
  margin-top: 5px;
}
.check-form__error-field--hidden {
  display: none;
}
.check-form__table_column1 {
  padding-right: 25px;
  width: 25%;
  vertical-align: middle;
}
.check-form__has-error .check-form__label {
  color: #e23a39;
}
.check-form__has-error .check-form__control {
  border-color: #e23a39;
}
.check-form__checkbox-label {
  min-height: 20px;
  font-size: larger;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}
.check-form__group-new {
  min-height: 20px;
  min-width: 900px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.check-form__group-space {
  margin-bottom: 20px;
  background-color: #f5f5f5;
}
.check-form__image {
  width: 100%;
}
.check-form__fileName {
  width: 75%;
  font-weight: 700;
  padding-left: 2%;
  padding-bottom: 1.5%;
  padding-top: 1.5%;
}
.check-form__fileSize {
  width: 10%;
  font-weight: 400;
  color: rgba(55, 58, 60, 0.6);
}
.check-form__fileButton {
  text-align: right;
}
.check-form__fileButton1 {
  text-align: right;
  position: relative;
  left: 630%;
}
.check-form__fileButton2 {
  text-align: right;
  position: relative;
  left: 1250%;
}
.check-form__fileList {
  border-bottom: 1px solid #eee;
}
.check-form__button {
  color: #176db7;
}
.check-form__display:hover {
  cursor: not-allowed;
}
.check-form__item-file-download {
  border-top: 1px solid #eee;
  padding-bottom: 0.75rem !important;
  text-align: left;
  box-sizing: border-box;
  display: block;
}
.check-form__warning {
  background-color: #176db7;
  color: #fff;
  text-align: center;
}
.check-form__lastcheck {
  min-height: 20px;
  font-size: larger;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}

.rich-text__headerDiv {
  border-top-color: transparent !important;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  border-top-width: 0;
  background-color: #fff;
  padding: 8px 20px;
  transition: border-width 0.25s, border-color 0.25s, background-color 0.25s;
  font-size: 15px;
}
.rich-text__headerDiv:hover {
  background-color: #F8F8F8;
}
.rich-text__headerDiv1 {
  margin-bottom: 0.375rem;
  justify-content: space-between !important;
  display: flex;
}
.rich-text__rowSpanStyle {
  font-weight: 700;
}
.rich-text__dot {
  height: 4px;
  width: 4px;
  background-color: #E8E8E8;
  border-radius: 50%;
  display: inline-block;
}
.rich-text__dateTimeStyle {
  font-weight: 700;
  align-items: center !important;
  display: flex;
}
.rich-text__messageStyle {
  -webkit-box-pack: justify !important;
  justify-content: space-between !important;
  display: flex;
}
.rich-text__headerDiv2 {
  border-top-color: transparent;
  border-bottom: 1px solid #ddd;
  border-top-width: 0;
  background-color: #fff;
  padding: 8px 0px;
  transition: border-width 0.25s, border-color 0.25s, background-color 0.25s;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  text-align: right;
}
.rich-text__headerDiv5 {
  display: inline-flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  max-width: 25%;
  padding: 0px 20px;
}
.rich-text__ulStyle {
  padding-left: 0;
}
.rich-text__liStyle {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
.rich-text__liItems {
  font-weight: 700;
  color: #373a3c;
  font-size: 0.9140625rem;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.rich-text__applicationStatus {
  color: rgba(55, 58, 60, 0.6);
  margin-left: 7px;
}
.rich-text__appDtls {
  color: #888;
}
.rich-text__pointer {
  color: #dedede;
}
.rich-text__AssetsList {
  color: #4285f4;
  font-weight: 400;
  font-size: 0.984375rem;
  line-height: 1.5;
  font-family: Corporate S Pro;
}
.rich-text__AssetsDates {
  color: rgba(55, 58, 60, 0.6);
  font-size: 0.984375rem;
  font-weight: 400;
  line-height: 1.5;
  font-family: Corporate S Pro;
}
.rich-text__DateHeading {
  text-align: right;
}
.rich-text__cmtBox-container {
  width: 940px;
  display: flex;
  flex-direction: column;
  margin: auto;
  border-top: none;
  box-shadow: 0px 0px 4px #888888;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  font-family: Corporate S Pro;
  border-top: 1px solid #d1cccc !important;
}
.rich-text__vue-container {
  border-top: 1px solid #d1cccc !important;
}
.rich-text__ui-data-item {
  background-color: #ececec;
  padding: 8px 20px;
}
.rich-text__ui-media {
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
}
.rich-text__ui-conversation-message {
  padding: 0;
}
.rich-text__ui-media-figure {
  color: #fff;
  background-color: #4285f4;
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 5px;
  min-width: 2.4rem;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rich-text__ui-conversation-message-sent {
  color: #a9a9a9;
  margin-top: 1rem !important;
  font-size: 14px;
  padding-top: 1%;
}
.rich-text__body-style {
  background-color: whitesmoke;
  margin-top: 100px;
}
.rich-text__div-style {
  width: 92.9% !important;
  font-family: sans-serif;
  padding-bottom: 5px;
  padding-top: 20px;
}
.rich-text__extErrorStyle {
  margin-left: -25%;
  position: relative;
  top: 32px;
  left: 56px;
  color: #A94442;
  font: normal 700 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.rich-text__addFileStyle {
  margin-left: -12%;
  position: relative;
  top: 32px;
  left: -8px;
  color: #A94442;
  font: normal 700 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
}
.rich-text__span-style {
  margin: 5px;
  display: inline-block;
  vertical-align: middle;
}
.rich-text__button-style {
  border: none;
  background: none;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 24px;
  padding: 3px 5px;
  width: 28px;
  stroke-linecap: round;
  fill: none;
  stroke-width: 2;
  margin-left: 11px;
}
.rich-text__button-style:hover {
  color: blue;
}
.rich-text__span-style:hover {
  color: blue;
}
.rich-text__textarea-style {
  position: relative;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e3dede;
  background-color: #fff;
  color: #000000;
  border-top: 0px;
  font-size: 16px;
  min-height: 100%;
  box-sizing: border-box;
  line-height: 1.42;
  height: 150px;
  outline: none;
  overflow-y: auto;
  tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.rich-text__placeholderCls:empty:before {
  content: attr(data-placeholder);
  font-style: italic;
  pointer-events: none;
  left: 15px;
  position: absolute;
  right: 15px;
  color: #b8b8b8;
  font-size: 15px;
}
.rich-text__select-style {
  border: none;
  background: none;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 24px;
  padding: 3px 5px;
  line-height: 22px;
}
.rich-text__attachWarning {
  text-align: center;
  font-size: 14px;
  color: red;
}
.rich-text__child {
  font: initial;
}
.rich-text__loader-submit {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.rich-text__small-style {
  font-size: 12px;
}
.rich-text__normal-style {
  font-size: 15px;
}
.rich-text__large-style {
  font-size: 20px;
}
.rich-text__huge-style {
  font-size: 24px;
}
.rich-text__file-label {
  font-size: 98%;
  cursor: pointer;
  color: rgba(55, 58, 60, 0.6);
  padding: 4px;
  line-height: 2.5;
  text-align: center;
  font-weight: 700;
  background: #fff;
  overflow: hidden;
  border-bottom: 1px solid #ddd;
}
.rich-text__edit-file {
  font-size: 98%;
  cursor: pointer;
  color: rgba(55, 58, 60, 0.6);
  padding: 4px;
  line-height: 2.5;
  text-align: center;
  font-weight: 700;
  background: #fff;
  overflow: hidden;
}
.rich-text__Submitbtn {
  font-weight: 100;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  user-select: none;
  font-size: 13px;
  padding: 4px;
  width: 117px;
  color: #fff;
  background-color: #4285f4;
  margin-top: 1%;
}
.rich-text__m-r-a {
  margin-right: 150px !important;
}
.rich-text__filecolor {
  color: blue;
}
.rich-text__ChooseFiles {
  margin-top: 1%;
}
.rich-text__footer-btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0px;
}
.rich-text__sizeValidation {
  font-size: 12px;
  margin-left: -25%;
  position: relative;
  top: 32px;
  left: 56px;
}

.uis-navigation {
  position: relative;
  margin-bottom: 0;
}
.uis-navigation *:focus {
  animation: none;
  outline: none;
}
@media screen and (min-width: 768px) {
  .uis-navigation {
    margin-bottom: 30px;
  }
}
.uis-navigation__container {
  background-color: transparent;
  transition: background-color 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  width: 100%;
}
@media screen and (min-width: 768px) {
  .uis-navigation__container {
    background-color: #fff;
  }
}
@media screen and (min-width: 768px) {
  .uis-navigation__container:after {
    width: 91.1458333333%;
    margin: 0 auto;
    content: "";
    height: 1px;
    background-color: #c7c7c7;
    display: block;
    margin: auto;
    transition: width 1s cubic-bezier(0.25, 0.04, 0.22, 0.97);
  }
}
@media screen and (min-width: 980px) {
  .uis-navigation__container:after {
    width: 940px;
  }
}
.uis-navigation__container.uis-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .uis-navigation__container.uis-sticky:after {
    width: 100%;
    transition: width 1s cubic-bezier(0.25, 0.04, 0.22, 0.97);
  }
}
.uis-navigation__container.absolute {
  position: absolute;
  top: initial;
  bottom: 200px;
}
.uis-navigation__container.borderless {
  border: none;
}
.uis-navigation__container.borderless:after {
  display: none;
}
.uis-navigation__container[data-page-level-sticky-nav-state=collapsed] {
  border-bottom: 1px solid #c7c7c7;
  border-top: 1px solid #c7c7c7;
}
.uis-navigation__container[data-page-level-sticky-nav-state=collapsed].uis-sticky {
  border-top-color: #fff;
  position: fixed;
}
@media screen and (min-width: 768px) {
  .uis-navigation__container[data-page-level-sticky-nav-state=collapsed] {
    border: none;
  }
}
.uis-navigation__container[data-page-level-sticky-nav-state=expanded] {
  background-color: #fff;
  height: 100vh;
  overflow: scroll;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 901;
}
@media screen and (min-width: 768px) {
  .uis-navigation__container[data-page-level-sticky-nav-state=expanded] {
    height: auto;
    max-height: 100vh;
    overflow: auto;
    position: absolute;
  }
  .uis-navigation__container[data-page-level-sticky-nav-state=expanded].uis-sticky {
    position: fixed;
  }
  .uis-navigation__container[data-page-level-sticky-nav-state=expanded]:after {
    width: 100%;
    transition: width 1s cubic-bezier(0.25, 0.04, 0.22, 0.97);
  }
}
.uis-navigation__wrapper {
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .uis-navigation__wrapper {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .uis-navigation__wrapper {
    overflow: hidden;
  }
}
.uis-navigation__items-list {
  font: normal 400 13px/1.5384615385 "Arial", Helvetica, Arial, sans-serif;
  display: flex;
  justify-content: space-between;
}
@media screen and (min-width: 768px) {
  .uis-navigation__items-list {
    font: normal 400 12px/1.5 "Arial", Helvetica, Arial, sans-serif;
    flex-direction: row;
    align-items: stretch;
  }
}
[data-page-level-sticky-nav-state=collapsed] .uis-navigation__items-list {
  display: block;
}
[data-page-level-sticky-nav-state=expanded] .uis-navigation__items-list {
  display: block;
}
.uis-navigation__item {
  width: 93.4375%;
  margin: 0 auto;
  text-align: center;
  transition: color 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95), max-height 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95), border 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .uis-navigation__item {
    align-items: center;
    color: #222;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    transition: color 0.15s cubic-bezier(0.445, 0.05, 0.55, 0.95), height 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95), border 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    position: relative;
  }
}
[data-page-level-sticky-nav-state=collapsed] .uis-navigation__item {
  max-height: 0;
  border-bottom: 0 solid transparent;
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=collapsed] .uis-navigation__item {
    height: 0;
    max-height: none;
  }
}
@media screen and (max-width: 767px) {
  [data-page-level-sticky-nav-state=expanded] .uis-navigation__item {
    max-height: 60px;
    border-bottom: 1px solid #f1f1f1;
  }
  [data-page-level-sticky-nav-state=expanded] .uis-navigation__item:first-child {
    border-bottom-color: #c7c7c7;
  }
  [data-page-level-sticky-nav-state=expanded] .uis-navigation__item:last-child:after {
    border-bottom: 1px solid #c7c7c7;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .uis-navigation__item {
    border-bottom: 1px solid #c7c7c7;
    height: 45px;
  }
}
[data-page-level-sticky-nav-state=expanded] .uis-navigation__item:last-of-type {
  border-bottom: none;
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .uis-navigation__item:last-of-type:before {
    border-bottom: none;
  }
}
@media screen and (min-width: 768px) {
  .uis-navigation__item:before {
    content: "";
    border-bottom: 3px solid transparent;
    bottom: -1px;
    left: 0;
    right: auto;
    top: auto;
    transform: none;
    transition: border-color 0.15s cubic-bezier(0.25, 0.04, 0.22, 0.97);
    position: absolute;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .uis-navigation__item:before {
    content: none;
  }
}
@media screen and (min-width: 768px) {
  .uis-navigation__item:after {
    border-right: 1px solid #c7c7c7;
    content: "";
    height: 28px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=collapsed] .uis-navigation__item:after {
    border-right: none;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .uis-navigation__item:after {
    border-right: none;
  }
}
@media screen and (min-width: 768px) {
  .uis-navigation__item:last-of-type:after {
    content: none;
  }
}
@media screen and (min-width: 768px) {
  .uis-navigation__item--last-visible:after {
    content: none;
  }
}
.uis-navigation__item--active {
  color: #222;
  display: block;
  max-height: none;
}
@media screen and (min-width: 768px) {
  .uis-navigation__item--active {
    display: flex;
    height: auto;
    max-height: none;
  }
}
[data-page-level-sticky-nav-state=collapsed] .uis-navigation__item--active {
  display: block;
  max-height: none;
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=collapsed] .uis-navigation__item--active {
    display: flex;
    height: auto;
  }
}
[data-page-level-sticky-nav-state=expanded] .uis-navigation__item--active {
  color: #0090ff;
}
@media screen and (min-width: 768px) {
  .uis-sticky .uis-navigation__item--active {
    color: #0090ff;
  }
}
@media screen and (min-width: 768px) {
  .uis-sticky .uis-navigation__item--active:before {
    border-bottom-color: #0090ff;
  }
}
[data-page-level-sticky-nav-state=collapsed].sticky .uis-navigation__item--active {
  color: #222;
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=collapsed].sticky .uis-navigation__item--active:before {
    border-bottom-color: transparent;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded].sticky .uis-navigation__item--active:before {
    border-bottom-color: #c7c7c7;
  }
}
.uis-navigation__item-link {
  color: currentColor;
  display: inline-block;
  line-height: 15px;
  padding: 12px 0;
  position: relative;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .uis-navigation__item-link {
    display: block;
    padding: 20px 10px;
    transition: color 0.15s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=collapsed] .uis-navigation__item-link {
    padding: 15px 30px;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .uis-navigation__item-link {
    padding: 15px 30px;
  }
}
.uis-navigation__item-link:hover, .uis-navigation__item-link:focus {
  animation: none;
  color: #0090ff;
}
.uis-navigation__item-link-label {
  display: inline-block;
  padding: 0 20px;
  position: relative;
}
@media screen and (min-width: 768px) {
  .uis-navigation__item-link-label {
    padding: 0;
    display: block;
    word-break: break-word;
  }
}
[data-page-level-sticky-nav-state=collapsed] .uis-navigation__item--active .uis-navigation__item-link-label:after {
  content: "\e902";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  color: #176db7;
  display: inline-block;
  margin-left: 10px;
}
[data-page-level-sticky-nav-state=expanded] .uis-navigation__item--active .uis-navigation__item-link-label:after {
  content: none;
}
.uis-navigation__item-skip-to {
  color: #0090ff;
  display: inline-block;
  font-size: 100%;
  line-height: 15px;
  padding: 12px 20px;
  position: relative;
}
.uis-navigation__item-skip-to:after {
  content: "\e907";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  color: #0090ff;
  display: inline-block;
  margin-left: 10px;
}
@media screen and (min-width: 768px) {
  .uis-navigation__item-skip-to {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=collapsed] .uis-navigation__item-skip-to {
    display: inline-block;
  }
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .uis-navigation__item-skip-to {
    display: inline-block;
    padding: 15px 30px;
  }
}
.uis-navigation__nav-toggle {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .uis-navigation__nav-toggle {
    cursor: pointer;
  }
}
[data-page-level-sticky-nav-state=collapsed] .uis-navigation__nav-toggle {
  height: 100%;
}
[data-page-level-sticky-nav-state=expanded] .uis-navigation__nav-toggle {
  height: 40px;
}
@media screen and (min-width: 768px) {
  [data-page-level-sticky-nav-state=expanded] .uis-navigation__nav-toggle {
    height: 45px;
  }
}
.uis-navigation__wrapper {
  overflow: visible;
  max-width: 100%;
  width: 100%;
}
.uis-navigation__container {
  position: relative;
}
.uis-navigation__container:after {
  width: 100%;
}
.uis-navigation__container.sticky {
  position: fixed;
}
.uis-navigation__item {
  overflow: visible;
}
.uis-navigation__item--active {
  color: #0090ff;
}
.uis-navigation__item--active:before {
  border-bottom-color: #0090ff;
}
.uis-navigation__item-switch {
  cursor: pointer;
}
.uis-navigation__item-switch-label {
  opacity: 0;
  position: absolute;
}
.uis-navigation__item-switch-label:checked + .uis-navigation__item-switch .uis-navigation__item-slider:before {
  background-color: black;
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.uis-navigation__item-slider {
  position: relative;
  cursor: pointer;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
  display: inline-block;
  width: 40px;
  height: 10px;
  vertical-align: middle;
  border-radius: 34px;
}
.uis-navigation__item-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 0;
  bottom: -3px;
  background-color: red;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.uis-navigation__dropdown {
  position: relative;
}
.uis-navigation__dropdown-menu {
  background: #fff;
  border: 1px solid #c7c7c7;
  border-top: none;
  display: none;
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
}
.uis-navigation__dropdown-menu .uis-navigation__item {
  border-top: 1px solid #c7c7c7;
}
.uis-navigation__dropdown-menu .uis-navigation__item:after {
  border-right: none;
}
.uis-navigation__dropdown-menu .uis-navigation__item-link {
  padding: 10px 5px;
}
.uis-navigation__dropdown > .uis-navigation__item-link:after {
  content: "\e913";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  margin-left: 5px;
  vertical-align: middle;
}
.uis-navigation__dropdown.uis-navigation__item--open .uis-navigation__dropdown-menu {
  display: block;
}
.uis-navigation__section-container {
  padding-top: 3%;
  width: 100%;
}
.uis-navigation__items-list {
  display: none;
}
@media screen and (min-width: 980px) {
  .uis-navigation__items-list {
    display: flex;
  }
}
.uis-navigation__button {
  border-bottom: 1px solid #c7c7c7;
  border-top: 1px solid #c7c7c7;
  color: #222;
  cursor: pointer;
  line-height: 15px;
  padding: 15px 10px;
  width: 100%;
}
.uis-navigation__button:after {
  content: "\e902";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
  color: #176db7;
  display: inline-block;
  margin-left: 10px;
}
@media screen and (min-width: 980px) {
  .uis-navigation__button {
    display: none;
  }
}
.uis-navigation__button.active {
  border-color: #0090ff;
  border-top: none;
  color: #0090ff;
}
.uis-navigation__button.active:after {
  content: "\e907";
  font-family: "micro-icons";
  font-weight: normal;
  font-size: 100%;
}
.uis-navigation__button.active + .uis-navigation__items-list {
  display: block;
}
.uis-navigation__button.active + .uis-navigation__items-list .uis-navigation__items-list {
  display: block;
}
.uis-navigation__button.active + .uis-navigation__items-list .uis-navigation__item:not(:last-of-type) {
  border-bottom: 1px solid #f1f1f1;
}
.uis-navigation__button.active + .uis-navigation__items-list .uis-navigation__item:after {
  display: none;
}
.uis-navigation__button.active + .uis-navigation__items-list .uis-navigation__dropdown-menu {
  border: none;
  position: relative;
  left: auto;
  top: auto;
}
.uis-navigation__button.active + .uis-navigation__items-list .uis-navigation__dropdown-menu .uis-navigation__item {
  border-top: none;
}

.chalkboard__chalkboardTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.chalkboard__chalkboardTable .fixed {
  table-layout: fixed;
  border: 1px solid #ddd;
  padding: 8px;
}
.chalkboard__chalkboardTable .fixed td {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
.chalkboard__chalkboardTable .fixed th {
  cursor: pointer;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}
.chalkboard__chalkboardTable .fixed tr:hover {
  background-color: #ddd;
}