$transition-duration: 0.3s;

.modal {
    background: $white;
    border: 0;
    height: auto;
    max-height: 75vh;
    max-width: 940px;
    padding: 0;
    position: fixed;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    width: calc(700/768 * 100%);
    z-index: $z-modal;

    @include media($tablet) {
        max-height: 90vh;
    }

    @include media($desktop) {
        width: calc(940/980 * 100%);
    }

    &--transitions {

        @include media($tablet) {
            opacity: 0;
            transition: opacity $transition-duration linear;
        }
    }

    &--playin {

        @include media($tablet) {
            opacity: 1;
        }
    }

    &--inset {
        @include media($tablet) {
            max-width: 768px;
        }

        @include media($desktop) {
            max-width: 720px;
        }
    }

    &--full-overlay {
        @include media($desktop) {
            min-width: 940px;
            max-width: 75%;
        }

        @include variant(largeformat) {
            max-width: 90%;
        }
    }

    &--full-screen {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
    }

    &--dialog {
        @include media($tablet) {
            width: 44%;
            max-width: 460px;
        }
    }

    &__overlay {
        background: $black;
        bottom: 0;
        cursor: pointer;
        left: 0;
        opacity: 0.8;
        position: fixed;
        right: 0;
        top: 0;
        z-index: $z-modal - 1;

        &--light {
            background: $white;
            opacity: 0.5;
        }

        &--transitions {

            @include media($tablet) {
                opacity: 0;
                transition: opacity $transition-duration linear;
            }
        }

        &--playin {

            @include media($tablet) {
                opacity: 1;
            }
        }
    }

    &__close {
        background-color: $silver;
        border-radius: 50%;
        color: $blitz;
        cursor: pointer;
        height: 32px;
        left: 100%;
        position: absolute;
        top: -16px;
        transform: translateX(-50%);
        width: 32px;
        z-index: $z-content + 3;

        &:hover {
            animation: none;
            background-color: $azure-radiance;
            color: $white;
        }

        .modal--full-screen & {
            background-color: $black;
            border-radius: 0;
            color: $white;
            font-size: 14px;
            height: 45px;
            left: 0;
            padding-right: $space-3;
            position: fixed;
            text-align: right;
            top: 0;
            transform: none;
            width: 100%;

            &:hover {
                background-color: $black;
            }
        }
    }

    &__image {
        display: block;
        width: 100%;
    }

    &__container {
        display: block;
        overflow-y: auto;
        width: 100%;
    }

    &__content {
        overflow: auto;
        overflow: overlay;
        position: static;
        width: 100%;

        @include media($tablet) {
            max-height: 90vh;
        }

        .modal--disable-overflow & {
            overflow: visible;
        }

        .modal--light & {
            border: 1px solid $mercury;
        }

        .modal--full-screen & {
            border: none;
            padding-top: $space * 9;
        }
    }
}
