.button-video {
    &__popup-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        display: none;
        justify-content: center;
        align-items: center;
    }

    &__close {
        position: absolute;
        top: 15%;
        right: 24%;
        font-size: 20px
    }
}