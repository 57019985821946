.preferred-dealer {
    position: relative;
    padding-top: $space*2;
    padding-bottom: $space*4;

    &__address-container-dealer {
        padding-top: $space*2;
        padding-left: $space*7;
        text-align: center;
    }

    &__dealer-name {
        @include copy-narrow-bold;
        color: $denim;

        &-label {
            @include copy-narrow-bold;
            color: $scorpion;
        }
    }

    &__address {
        @include copy-narrow-reg;
    }

    &__phone-number {
        @include copy-narrow-reg;
        color: $denim;
    }

    &__icon {
        font-size: 6px;
    }

    &__link {
        font-weight: bold;
    }

    &__map {
        height: 145px;
        margin-top: $space;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d
