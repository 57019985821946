.alphabetical-search {
    font-size: 13px;
    margin-bottom: $space-4;

    &__heading {
        @include highlight-copy-bold;
        text-decoration: underline;
        margin-bottom: $space-2;
    }

    &__anchor {
        color: $boston-blue;

        &:hover {
            color: $pacific-blue;
        }
    }

    &__column {
        border: 1px solid $black;
        text-align: center;
        vertical-align: middle;

        &:first-child {
            width: 20%;
            padding: $space-3;
        }

        &:first-child:nth-last-child(3),
        &:first-child:nth-last-child(3) ~ & {
            width: 33.3333%;
        }
    }

    &__heading-column {
        font-weight: $font-weight-bold;
        border: 1px solid $black;
        padding: $space-3;
        text-align: center;
    }

    &__index-anchor {
        color: $boston-blue;
        text-decoration: underline;

        &:hover {
            color: $pacific-blue;
        }
    }

    &__table-container {
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        @include media($desktop) {
            overflow: visible;
        }

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            overflow: auto;
        }
    }

    &__index {
        margin-bottom: $space-4;

        &__column {
            border: 1px solid $black;
            padding: $space-2;
            text-align: center;
            font-size: 13px;
            font-weight: 700;
            min-width: 34px;
        }

        &--disabled {
            pointer-events: none;
            text-decoration: none;
            color: $scorpion;
        }
    }

    &__column-new {
        border: 1px solid $black;
        text-align: center;
        vertical-align: middle;

        &:first-child {
            width: 10%;
        }

        &:nth-last-child(2) {
            width: 50%;
        }

        &:nth-last-child(3) {
            width: 30%;
        }

        &:nth-last-child(3):nth-last-child(4),
        &:nth-last-child(3):nth-last-child(4) ~ & {
            width: 25%;
        }
    }

    &__column-new-last {
        border: 1px solid $black;
        text-align: center;
        vertical-align: middle;

        &:first-child {
            width: 20%;
        }

        &:nth-last-child(2) {
            width: 12%;
        }

        &:nth-last-child(3) {
            width: 30%;
        }

        &:nth-last-child(4) {
            width: 30%;
        }

        &:nth-last-child(5) {
            width: 10%;
        }

        &:nth-last-child(5):nth-last-child(6),
        &:nth-last-child(5):nth-last-child(6) ~ & {
            width: 25%;
        }
    }

    &__star-icon {
        visibility: hidden;
        cursor: pointer;
        font-size: 30px;
        content: '\2606';
        height: 50px;
        width: 50px;
        text-align: center;
        font-size: 30px;
        padding: 0 1rem;

        &:before {

            content: '\2606';
            visibility: visible;
            cursor: pointer;
        }
    }

    &__star-red {
        visibility: hidden;
        cursor: pointer;
        font-size: 30px;
        content: '\2606';
        height: 50px;
        width: 50px;
        text-align: center;
        font-size: 30px;
        padding: 0 1rem;

        &:after {

            content: '\2605';
            color: red;
            visibility: visible;
            cursor: pointer;
        }
    }

    &__remove-icon {
        cursor: pointer;
        visibility: hidden;
        content: '\2716';
        color: red;
        font-size: 24px;
        height: 24.5px;

        &:before {

            cursor: pointer;
            content: '\2716';
            visibility: visible;
        }
    }
}
