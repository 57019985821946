.dealer-result-list-item {
    border-bottom: 1px dotted $mercury;
    padding-bottom: $space*2;
    position: relative;
    cursor: pointer;
    min-height: 65px;

    &__suggested {
        padding-top: $space*3;
    }

    &:last-of-type {
        border-bottom: none;
    }

    &__address-container {
        padding-top: $space*2;
        padding-left: $space*7;

        &::before {
            @include icon($icon-mb-dealer-location, 25px);
            position: absolute;
            width: $space;
            margin-top: -$space;
            right: 0;
            left: 0;
        }
    }

    &__dealer-name {
        @include copy-narrow-bold;
    }

    &__dealer-distance {
        @include copy-narrow-reg;
        float: right;
        margin-right: 0;
    }

    &__address {
        @include copy-narrow-reg;
    }

    &__suggested-dealer {
        @include copy-narrow-reg;
        color: $denim;
        padding-top: $space*3;

        &:before {
            @include icon($icon-checkmark-disc);
            margin-right: $space;
        }
    }

    &__error {
        color: $guardsman-red;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d
