.dealer-lookup {
    color: $mine-shaft;
    width: 100%;
    padding: 1%;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        width: 100%;
        padding: 1%;
    }

    &__mbusatitle {
        color: #333333;
        font-family: corporateacondpro;
        font-size: 26px;
        padding: 3px 5px 2px 0px;
        margin-bottom: 10px;
    }

    &__group {
        padding: $space-2 0px;
    }

    &__control {
        width: 100%;
        border: 1px solid $very-light-grey;
        border-radius: $space;
        height: 32px;
        padding: $space+1 $space-2+2;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }
    }

    &__error-field {
        color: $apple-blossom;
        @include featured-copy-bold;
        margin-top: $space;

        &--hidden {
            display: none;
        }
    }

    &__result-table {
        border-collapse: collapse;
        inline-size: -webkit-fill-available;
        text-align: center;
        width: 100%;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            width: 100%;
        }

        &-padding {
            padding: 1.2%;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                padding: 1.2%;
            }
        }
    }

    &__line_separator {
        border-top: 1.5px solid grey
    }

    &__hr_margin {
        margin-top: 1%;
    }

    &__downloadPdf {
        font-size: 35px;
    }

    &__download {
        position: absolute;
        right: 130px;
        top: 96px;
    }

    &__loader {
        position: fixed;
        left: 54%;
        top: 80%;
        z-index: 1;
        width: 60px;
        height: 60px;
        margin: -75px 0 0 -75px;
        border: 5px solid #f3f3f3;
        border-radius: 50%;
        border-top: 5px solid #3498db;
        background-position: center;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    &__button {
        color: $white;
        background-color: $denim;
        padding: $space $space-2;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        font-size: 14px;
        border-radius: .6em;
        position: absolute;
        right: 190px;
        top: 96px;

        &:hover {
            cursor: pointer;
            background-color: $azure-radiance;
        }
    }

}

td {
    font: initial;
    font-size: 100%;
    font-family: inherit;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        font-size: 100%;
        font-family: inherit;
        font-variant: normal;
        font-weight: normal;
        line-height: normal;
        font-style: normal;
    }
}