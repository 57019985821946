.article-module {
    color: $mine-shaft;

    &__section-header {
        @include section-reg;
        text-align: center;
        padding: $space-2 0px;

        @include media($tablet) {
            font-size: 34px;
        }
    }

    &__content {
        @include span-columns(12);
        display: inline-block;
        vertical-align: top;
        padding: $space-2 $space-2 $space-2 $space-5 ;
        box-shadow: 3px 3px 5px 0 $silver;
        min-height: 250px;

        @include media($tablet) {
            // @include span-columns(3);
            // @include omega(4n);
            width: 25%;
            margin: 0px;
            margin-bottom: $space-3;
        }

        &-icon {
            font-family: 'micro-icons';
            position: absolute;
            right: 0;

            &:before {
                content: $icon-pdf;
            }
        }

        &-heading {
            @include featured-copy-bold;
            font-size: $space-3+1;
            @include span-columns(10);
            margin: $space-2 0px;
        }

        &-link {
            @include span-columns(1);
            float: right;
            font-size: 22px;
            display: inline-block;
            position: relative;
        }

        &-icon {
            color: $monza;
        }

        &-desc {
            display: inline-block;
            margin-right: $space-4;
            @include copy-wide-reg;

            @include media($desktop) {
                font-size: 14px;
            }
        }
    }

    &--border-bottom {
        border-bottom: 1px solid $dusty-gray;
    }
}
