.brc-form-report {
    color: $mine-shaft;

    &__heading {
        @include featured-heading;
        margin-bottom: $space-2;
    }

    &__group {
        min-height: 20px;
        padding: 19px;
        margin-bottom: 20px;
        background-color: #f5f5f5;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    }

    &__label {
        display: block;
        @include featured-copy-bold;
        margin-bottom: $space;
    }

    &__index {
        display: inline-block;
        margin: $space-4 0 $space-2 0;

        &--anchor {
            color: $boston-blue;
            float: left;
            padding: $space;
            text-decoration: none;
            transition: background-color .3s;
            border: 1px solid $alto;
            width: 55px;
            height: 35px;
            text-align: center;
            margin-bottom: $space-2;

            &:hover {
                color: $st-tropaz;
                background-color: $gallery;
                border-color: $alto;
                cursor: pointer;
            }

            &:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            &:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }

        &--anchor-hidden {
            display: none;
        }

        &--active {
            color: $white;
            background-color: $boston-blue;
            border-color: $boston-blue;

            &:hover {
                color: $white;
                background-color: $boston-blue;
                border-color: $boston-blue;
            }
        }

        &--disabled {
            pointer-events: none;
            color: grey;
            cursor: default;

            &:hover, &:focus {
                color: grey;
                cursor: default;
                border-color: none;
                background-color: $white;
            }
        }
    }

    &__table {
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;

        .fixed {
            table-layout: fixed;

            td {
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: break-word;
            }

            th {
                cursor: pointer;
            }
        }

        th {
            &[data-filter-value] {
                cursor: pointer;
            }
        }
    }

    &__sortable {
        position: relative;
    }

    &__child {
        font: initial;
    }

    &__half_width {
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }

    &__btn_label {
        display: inline;
        padding: .2em .6em .3em;
        font-size: 75%;
        font-weight: bold;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .25em;
        background-color: #5cb85c;
    }

    &__control {
        border: 1px solid $very-light-grey;
        border-radius: $space;
        height: $space-6+4;
        padding: $space+1 $space-2+2;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }
    }

    &__table_column_padding_left {
        padding-left: 15px;
    }

    &__table_column_padding_right {
        padding-right: 15px;
    }

    &__error-field {
        font-size: 20px;
        font-weight: $font-weight-bold;
        text-align: center;
        padding: 30px 10px 10px;
    }

    &__loader {
        position: fixed;
        left: 50%;
        top: 50%;
        z-index: 1;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 16px solid #3498db;
        background-position: center;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    &__listing_loader {
        position: fixed;
        left: 54%;
        top: 70%;
        z-index: 1;
        width: 60px;
        height: 60px;
        margin: -75px 0 0 -75px;
        border: 10px solid #DCDCDC;
        border-radius: 50%;
        border-top: 10px solid #000;
        background-position: center;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    &__button {
        color: $white;
        background-color: $denim;
        padding: $space $space-2;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        font-size: 14px;
        border-radius: .6em;

        &:hover {
            cursor: pointer;
            background-color: $azure-radiance;
        }
    }

    &__border_rule {
        border-bottom: 1px solid #DCDCDC;
    }

    &__td_padding {
        margin: 10px;
        padding: 10px 3px;
        text-align: center;
    }

    &__th_padding {
        margin: 10px;
        padding: 10px 0px;
        text-align: center;
    }

    &__pagin_padding {
        display: flex;
        justify-content: center;
        padding-top: 25px;
        font-size: initial;
    }

    &__arrow_icon {
        color: #add8e6;
    }

    &__downloadPdf {
        font-size: 35px;
    }

    &__download {
        position: absolute;
        right: 40px;
    }

    &__popup-header {
        height: 40px;
    }
}
