.accordion-table {
    &__container {
        color: $mine-shaft;
        @include featured-copy-reg;
    }

    &__heading {
        @include featured-heading;
        margin-bottom: $space-2;
    }

    &__cta-link {
        font-size: 14px;
        border-radius: 0px;
        margin-right: $space-6;
        margin-bottom: $space-4;
    }

    &__download-link {
        display: inline-block;

        &--text {
            color: $blue-light;

            &:hover {
                color: $bay-of-many;
                text-decoration: underline;
            }
        }

        &--icon {
            color: $guardsman-red;
        }
    }

    &__list {
        &__item {
            list-style: none;
            padding: $space-2 0px $space 0px;

            &-text {
                @include section-reg;
                cursor: pointer;
                color: $mine-shaft;

                &:hover {
                    color: $mine-shaft;
                }

                &:before {
                    font-family: 'micro-icons';
                    font-size: 17px;
                    content: $icon-arrow-right-square;
                    float: right;
                }
            }
        }
    }

    &__table {
        width: 100%;

        &__container {
            overflow: auto;
        }

        &__heading {
            padding: $space-4 $space+3 $space-2 $space+3;
            font-weight: $font-weight-bold;
            text-align: left;
        }

        &__body {
            @include copy-narrow-reg;
        }

        &__row {
            border-top: 1px solid $silver;

            &--full-content {
                border-top: 2px solid $silver;
            }
        }

        &__column {
            padding: $space+3;

            &:nth-child(2) {
                width: 25%;
            }

            &--bold {
                font-weight: $font-weight-bold;
            }
        }

        &__custom_width {
            width: 20%;
        }
    }

    .accordion__active {
        .accordion-table__list__item-text:before {
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
        }
    }
}