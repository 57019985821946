.quiz-container {
    &__form {
        font-size: 0;
    }

    &__btn {
        padding: 5px 17px;
        display: inline-block;
        border: 1px solid #b1b1b2;
        border-radius: 7px;
        width: 100%;
        margin-top: 10px;
        margin-right: 4px;
        margin-bottom: 4px;
        transition: all ease 0s;
    }

    &__btnTest {
        box-shadow: inset 934px 0 0 0 blue;
    }

    &__parentdiv {
        display: flex;
        justify-content: center;
    }

    &__block {
        display: inline-block;
        width: 100%;

        @include media($tablet) {
            margin-right: 4%;
            width: 48%;
        }

        &:nth-child(even) {
            margin-right: 0;
        }

        &:nth-last-child(2) {
            border-bottom: none;
        }

        &:last-child {
            text-align: center;
        }
    }

    &__recaptcha {
        &-container {
            margin-bottom: $space-4;

            @include media($tablet) {
                margin-bottom: 0;
            }

            &--hidden {
                display: none;
            }

            #form-resubmit-recaptcha {
                display: inline-block;
            }
        }
    }

    &__assessment {
        &-details {
            text-align: center;
        }
    }
}

.question {
    &__block {
        box-shadow: inset 0px 0px 5px 2px $silver;
        margin-bottom: $space-2;
        padding: $space-4 $space-4 $space-3 0;
    }

    &__para {
        margin-bottom: $space-4;
    }

    &__label {
        font-size: 14px;
        display: block;
        margin-left: $space-4;
    }

    &__options {
        margin-left: $space-4;
        margin-bottom: $space-4;

        @include media($tablet) {
            margin-left: $space-20;
        }
    }

    &__textarea_border {
        width: 100%;
        border: 2px solid black;
        margin-left: -60px;
    }

    &__option-block {
        display: block;
        margin-right: $space;
        position: relative;

        input {
            position: absolute;

            @include media($desktop) {
                cursor: pointer;
                display: inline-block;
            }
        }

        label {
            display: block;
            padding-left: $space-5;

            @include media($desktop) {
                cursor: pointer;
                display: inline-block;
                font-size: 14px;
            }
        }
    }

    &__button-container {
        text-align: right;

        button {
            cursor: pointer;
        }
    }

    &__error-container {
        color: $guardsman-red;
        margin-left: $space-20;
    }

    &__submit-button-container {
        display: block;
        width: 100%;

        @include media($tablet) {
            justify-content: space-around;
            align-items: center;
            display: flex;
            float: right;
            width: 50%;
        }
    }

    &__range {
        display: block;

        @include media($tablet) {
            display: flex;
            align-items: center;
            padding-top: $space-6;
        }

        &-text {
            font-size: 14px;
            text-align: center;
            width: 100%;

            @include media($tablet) {
                width: 30%;
            }
        }

        &-input {
            width: 100%;
            padding: $space-5 0;

            @include media($tablet) {
                width: 40%;
                padding: 0;
            }

            input {
                width: 100%;
            }
        }

        input[type=range] {
            -webkit-appearance: none;
            margin: $space 0;
            width: 100%;
        }

        input[type=range]:focus {
            outline: none;
        }

        input[type=range]::-webkit-slider-runnable-track {
            width: 100%;
            height: 4px;
            cursor: pointer;
            animate: 0.2s;
            background: $denim;
            border-radius: $space-5;
        }

        input[type=range]::-webkit-slider-thumb {
            height: $space-3;
            width: $space-3;
            border-radius: 50%;
            background: $white;
            box-shadow: 0 0 4px 0 rgba(0,0,0, 1);
            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -($space+3);
        }

        input[type=range]::-ms-thumb {
            margin: -($space) 1px 0 1px;
        }

        input[type=range]:focus::-webkit-slider-runnable-track {
            background: $denim;
        }

        input[type=range]::-ms-track {
            height: 4px;
            background: transparent;
            border-color: transparent;
            border-width: $space+1 0;
            color: transparent;
        }

        input[type=range]::-ms-fill-upper {
            background: $denim;
            border-radius: $space+1;
        }

        input[type=range]::-ms-fill-lower {
            background: $denim;
            border-radius: $space+1;
        }

        .range-wrap {
            position: relative;
            padding: 0 $space;
            padding-top: $space-4;
        }

        .range-value {
            position: absolute;
            margin-left: 2px;
            bottom: 100%;
            padding-bottom: $space-4;
        }

        .range-value span {
            width: $space-6;
            height: $space-4+4;
            line-height: $space-4+4;
            text-align: center;
            background: $denim;
            color: $white;
            font-size: $space-2+2;
            display: block;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            border-radius: $space+1;
        }

        .range-value span:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-top: 10px solid $denim;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            top: 100%;
            left: 50%;
            margin-left: -$space;
            margin-top: -1px;
        }

        .range__datalist {
            display: flex;
            justify-content: space-between;
            padding: $space 4px;
        }
    }

    &__message-container {
        padding: $space-4 0;

        .success__message-container {
            background-color: $white-sand;
            box-shadow: inset 0px 0px 5px 2px $silver;
            margin-bottom: $space-2;
            padding: $space-4;

            .success__button-container {
                text-align: right;

                .start_assessmment1 {
                    margin-bottom: $space-2;
                }
            }
        }

        .failure__message-container {
            background-color: $white-sand;
            box-shadow: inset 0px 0px 5px 2px $silver;
            margin-bottom: $space-2;
            padding: $space-4;
            text-align: center;
        }

        p {
            font-size: 14px;
            margin-bottom: $space-3;
            line-height: normal;
        }
    }
}