.print-form {
    .fleet-tab, .form__message-container, .header, .footer, .canApplication__left, .pop-up__content-header, .pop-up__content-footer {
        display: none;
    }

    .pop-up {
        padding: 0;
        position: initial;
        height: auto;

        &__content {
            border-radius: 0;
            margin: 0;
            width: auto;

            &-body {
                max-height: none;
            }
        }
    }

    .canApplication {
        &__right {
            margin: 0;
            width: auto;
        }

        &__accordion-panel {
            display: block;
        }

        &__footer {
            display: none;
        }
    }

    .title {
        margin-top: $space-4;
    }
}
