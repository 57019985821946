.vehicle-grid-card {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__body {
        height: 100%;
        margin-bottom: auto;
        min-height: 90px;

        /*
         * may or may not have a class name, always a div
         * should not affect any "not available" inventory cards
         */
        // &>div:not(.inventory-favorites-card-ap__not-available-overlay) {
        // height: 100%;
        // }
    }

    .vehicle-grid-overhead {
        min-height: $space-6 + 0.125;

        &__wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: $space-2 + 0.125;
        }

        &__eyebrow {
            position: relative;
            min-width: 0; // required to get the ellipsis working with flexbox
        }

        &__tool {
            flex-shrink: 0;
            margin-left: $space + 0.1875;
        }

        &__title {
            @include font('20-12-bold');
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            &--detail {
                @include paragraph-wide-reg;
            }

            &--icon {
                @include pseudo-icon($icon-pin);
                padding-left: $space-3 + 0.0625;

                &::before {
                    font-size: 16px;
                    position: absolute;
                    top: -1px;
                    left: -$space-2;
                }
            }

            &--empty {
                padding-left: 0;

                &::before {
                    position: relative;
                }
            }
        }

        &__dealer-info {
            width: 100%;

            &--empty {
                width: 100%;
                padding-bottom: 21px;
            }

            &--name {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        &__best-match-info {
            &__category {
                display: flex;
                @include font('20-12-bold');

                &--best-match {
                    @include font('20-12-bold');

                    &:before {
                        @include icon($icon-solid-circle);
                        font-size: 50%;
                        color: $color-mbusa-primary-10;
                        margin-right: $space-4;
                    }
                }
            }
        }

        .toggle-favorites {
            @include paragraph-wide-reg;

            &__container {
                height: 20px;
            }

            &::after {
                top: 0;
            }
        }
    }

    &__hero {
        // height: 100%;
        margin-bottom: $space-3;
        margin-left: -16px;
        margin-right: -16px;
        text-align: center;
        position: relative;

        img {
            object-fit: cover;

            @include media($xlarge) {
                height: 163px;
            }
        }

        // &>div {
        //     height: 100%;
        // }

        .carousel {
            height: 100%;

            div[data-carousel-navigation=""] {
                min-height: $space-9 + 0.1875 + $space-2;
            }

            &--sliding {
                height: 89%;
            }

            &-nav {
                &--bottom {
                    padding-top: 0;
                }
            }
        }

        &--no-carousel {
            height: auto;
            margin-bottom: 0;
        }
    }

    .vehicle-grid-visuals {
        position: relative;

        &__mask {
            position: relative;
            width: 100%;
            overflow: hidden;
            height: 164px;
        }

        &__masked-item {
            position: absolute;
            top: 50%;
            transform: translateY(-50%) scale(1.1);
            width: 100%;
        }

        &__backplate {
            left: 0;
            position: absolute;
            width: 100%;
            height: 164px;
        }

        &__labels {
            bottom: $space-4;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            left: $space-4 + 0.25;
            position: absolute;
            right: $space-4 + 0.25;
        }

        &__image-label {
            @include paragraph-wide-reg;
            background: $color-neutral-10;
            border: 1px solid $color-neutral-30;
            border-radius: 2px;
            color: $color-neutral-60;
            overflow: hidden;
            padding: 1px $space + 0.1875;
            position: relative;
            text-overflow: ellipsis;
            white-space: nowrap;

            &--recently-viewed {
                background: $color-mbusa-secondary-10;
                color: $color-neutral-80;
                padding-left: 28px;

                &:before {
                    @include icon($icon-pin);
                    font-size: $space-3 + 0.0625;
                    left: $space + 0.1875;
                    position: absolute;
                }
            }
        }

        &__image-detail {
            @include paragraph-wide-reg;
            background: $color-mbusa-secondary-10;
            border-radius: 2px;
            color: $color-neutral-80;
            margin-left: $space-4;
            padding: $space-2 $space + 0.1875;
            position: relative;
            white-space: nowrap;

            &--no-label {
                margin-left: auto;
            }
        }
    }

    .vehicle-grid-highlights {
        color: $color-neutral-80;
        min-height: 90px;
        padding: $space-2 + 0.125 $space-4 + 0.25;
        text-align: left;

        &__info-title {
            margin-bottom: $space;

            &--model-title {
                font-size: 16px;
                font-weight: $font-weight-bold;
            }

            &--model-line-name {
                color: $color-neutral-60;
                min-height: $space-4 + 0.25;
                @include subsection-reg;
                font-weight: bold;
            }
        }

        &__card-type {
            display: flex;
            flex-wrap: wrap;
            min-height: $space-4 + 0.25;

            &--divider {
                @include font('20-12-bold');
                color: $color-neutral-40;
                margin: 0 $space + 0.1875;
            }

            &--secondary {
                @include font('20-12-bold');
                white-space: nowrap;

                &-container {
                    @include font('20-16');
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }

                @include subsection-reg;
            }

            .icon-recommendation {
                @include icon('icon-recommendation');
                color: $color-utility-20;
                margin-left: -$space-2;
                padding-right: $space-2;
            }

            .icon-thumbsup {
                color: $color-mbusa-primary-10;
                padding-right: $space-2;
            }
        }

        &__info-cost {
            font-size: 24px;
            font-weight: $font-weight-regular;

            .tool-tip {
                margin-left: $space-4;
            }

            &--label,
            &--price-unavailable-label {
                @include paragraph-wide-reg;
            }

            &--contact-dealer-label {
                @include font('20-16-bold');
            }

            &--msrp-label {
                @include paragraph-wide-reg;
                padding-left: $space-4;
                color: $color-neutral-60;
            }

            &--secondary {
                @include paragraph-wide-reg;
                color: $color-neutral-70;
                display: block;
                min-height: $space-4 + 0.25;
            }

            &--wrapper {
                display: flex;
                justify-content: space-between;
            }
        }

        &__info-subtitle {
            @include section-reg;
        }

        &__info-recommendation-description {
            @include paragraph-wide-reg;
            color: $color-neutral-60;
            margin-top: $space-4;

            &--model-line {
                margin-bottom: $space-3 + 0.0625;
            }
        }

        &__vehicle-status {
            @include font('20-16');
            text-align: center;
            min-height: 20px;

            &.available {
                color: $color-utility-20;
            }

            &.sold {
                color: $color-utility-10;
            }
        }
    }

    .vehicle-grid-features {
        text-align: left;
        padding: 0 $space-4 + 0.25;

        &__empty {
            height: 62px;
        }

        &__info {
            &--eyebrow {
                @include font('20-12-bold');
            }

            &--all-options {
                min-height: 180px;

                .vehicle-grid-features__info--list {
                    list-style: disc outside none;
                }

                .vehicle-grid-features__info-feature {
                    margin-left: $space-3 + 0.0625;

                    &--description {
                        display: block;
                        margin-left: -$space-4;
                    }
                }
            }
        }

        &__info-feature {
            @include paragraph-wide-reg;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            min-height: $space-8;

            &--title {
                font-weight: 700;
            }
        }

        &__coming-soon {
            @include paragraph-wide-reg;
        }
    }

    .vehicle-grid-footer {
        width: 100%;

        @include media($large) {
            align-self: flex-end;
        }

        &__ctas {
            padding: 0;
            height: $space-15; // This is calculated by adding up the two button heights + height of CTA link
        }

        &__cta {
            @include paragraph-wide-reg;
            display: block;
            margin-right: $space-2 + 0.125;
            margin-bottom: $space-2 + 0.125;
            text-align: center;
            width: 100%;

            &--button,
            &--link.button {
                @include font('20-12-bold');
            }

            &--button,
            &--link {
                min-height: $space-6 + 0.125;
                display: inline-flex;
                align-items: center;
                justify-content: space-around;
            }

            &>.button.disabled {
                color: $black;
            }

            &:last-child {
                margin-right: 0;
                margin-bottom: 0;
            }

            &-container {
                width: 100%;

                @include media($xxlarge) {
                    display: block;
                    width: calc(50% - 5px);
                }
            }
        }

        &__status {
            @include paragraph-wide-reg;
            color: $color-utility-10;
            text-align: center;
        }

        &__info {
            @include font('20-16');
            border-top: 1px solid $color-neutral-40;
            color: $color-neutral-60;
            display: flex;
            justify-content: space-between;
            padding: $space-2 + 0.125 0;
            min-height: $space-4 + 0.25;

            font-size: 12px;
            &--empty {
                border: none;
                min-height: 45px;
            }
        }

        // &__timestamp {
        //     @include font('20-16');
        // }
    }

    &__center-text {
        text-align: center;
    }
}