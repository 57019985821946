// Platform configs, mixins and utils
@import "modules/dealer-results";
@import "modules/dealer-results-list-items";
@import "modules/preferred-dealer";
@import "modules/location-search";
@import "modules/input";
@import "modules/loading-spinner";
@import "../dealer-locator/index";

.preferred-dealer-plugin {
    &__sub-heading {
        @include featured-copy-bold;
        margin-bottom: $space*2;
    }

    &__sub-heading-copy {
        @include featured-copy-reg;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d
