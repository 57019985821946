#activate-deactivate {
    label {
        display: block;
    }

    .activate-deactivate__input-field {
        display: block;
        width: 100%;
        padding: $space;
    }
}