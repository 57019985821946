.accordion-banner {
    $fixed-width-small: 768px;
    $fixed-width-large: 1440px;

    background-color: $black;
    overflow: hidden;
    position: relative;
    width: 100%;

    &__interior {
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 650px;
        padding-top: $space;
        padding-bottom: $space;

        @include media($tablet) {
            background-size: cover;
        }

        @include media($desktop) {
            background-size: auto;
            padding-bottom: $space;
            padding-top: $space;
            width: 100%;
        }

        &-wrapper {
            max-width: 1170px;
        }
    }

    &__copy-container {
        overflow: hidden;
        padding: $space-4 $space-6 $space-4 $space-2;
        text-align: left;

        &.right {
            @include shift(6);
        }

        &.dark {
            color: $black;
        }

        &.light {
            color: white;
        }

        @include media($tablet) {
            text-align: left;

            @include span-columns(7);

            &--wide {
                @include span-columns(5);
            }
        }
    }

    &__eyebrow {
        @include featured-copy-reg;
        @include uppercase;
    }

    &__heading {
        @include main-heading;
        //@include subhead-reg;
        margin-bottom: $space;

        @include media($large) {
            margin-bottom: $space-4;
        }
    }

    &__sub-heading {
        @include featured-copy-reg;
        margin-bottom: $space-3;
        line-height: calc(18/12);

        @include media($large) {
            display: initial;
            margin-bottom: $space-4;
        }
    }

    .button {
        font-size: 18px;
        border-radius: $space;
        padding: 3px $space-3;
    }

    &__container {
        padding: $space-4 $space-6 $space-4 $space-2;

        &.dark {
            color: $black;
        }

        &.light {
            color: white;
        }

        @include media($tablet) {
            @include span-columns(7);
        }
    }

    &__list {
        margin-bottom: $space-4;
        padding-left: $space-4;
        position: relative;

        &:before {
            position: absolute;
            left: 0;
            top: 8px;
            font-family: 'micro-icons';
            content: $icon-caret-down-thin;
            -webkit-transition: all 0.3s ease-in 0s;
            -moz-transition: all 0.3s ease-in 0s;
            -o-transition: all 0.3s ease-in 0s;
            transition: all 0.3s ease-in 0s;
            line-height: normal;
        }

        &--active {
            .accordion-banner {
                &__description {
                    display: block;
                }
            }

            &:before {
                content: $icon-caret-up-thin;
            }
        }
    }

    &__title {
        @include section-reg;
        cursor: pointer;
        margin-bottom: $space-2;
    }

    &__description {
        @include highlight-copy-reg;
        display: none;
        padding-left: $space-2;
    }
}
