.adminview {
    &__btn1 {
        background-color: #008CBA; /* Green */
        border: none;
        color: white;
        padding: 6px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 4px;
    }

    &__btn2 {
        background-color: #808080; /* Green */
        border: none;
        color: white;
        padding: 6px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 4px;
    }

    &__custom-padding {
        width: 100%;
        padding-right: 20%;
        padding-left: 20%;
        margin-right: auto;
        margin-left: auto;
    }
}