.wrapperG-Class-sub {
    @include outer-container(100%);
    @include mobile-container-width();
    margin: 0 auto;

    @include media($tablet) {
        @include tablet-container-width();
    }

    @include media($desktop) {
        @include desktop-container-width();
        max-width: 400px;
    }

    &__display {
        display: flex !important;
    }

    .wrapperG-Class-sub & {
        width: 100%;
    }
}
