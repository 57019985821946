.fleet-employee-program {
    &__container {
        margin: 0 auto;
        width: 80%;
    }
}

.fleet-customers {
    float: left;
    margin-bottom: $space-4;
    width: 40%;

    .contact-form__input-group-container {
        display: block;
        padding-left: 0;
        width: auto;

        .button {
            cursor: pointer;
        }
    }
}

.fleet-members {
    float: left;
    margin-left: 20%;
    margin-bottom: $space-4;
    position: relative;
    width: 40%;

    .contact-form__input-group-container {
        display: block;
        float: right;
        padding-right: 0;
        width: auto;

        .button {
            cursor: pointer;
        }
    }
}

.label {
    padding-top: 2px;
}

.contact-form {
    &__control-container {
        padding-top: 2px;

        input, input + label {
            cursor: pointer;
        }
    }
}

.error_message {
    color: $guardsman-red;
    display: none;

    &.show {
        display: block;
    }
}

.fleet-program-status-maintenance {
    .form__error-container {
        background-color: $bizarre;
        border: 1px solid $apple-blossom;
        color: $apple-blossom;
        font-weight: $font-weight-bold;
        margin-bottom: $space-2;
        padding: $space-2 0;
        text-align: center;
    }
}
