// refer: http://archive.is/ezJ7B
.loading-spinner {
    &:before {
        content: '';
        position: absolute;
        width: 40px;
        height: 40px;
        left: -$space*4;
        top: -$space*4;
        border-radius: 50%;
        border: 5px solid transparent;
        border-top-color: $silver-chalice;
        animation: spinner $speed-8 linear infinite;
    }

    &--large {
        &:before {
            width: 60px;
            height: 60px;
            left: -$space*6;
            top: -$space*6;
        }
    }

    &--inline {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 40px;

        &.loading-spinner--large {
            width: 60px;
            height: 60px;
        }

        &:before,
        &.loading-spinner--large:before {
            top: 0;
            left: 0;
        }
    }

    @keyframes spinner {
        to {
            transform: rotate(360deg)
        }
    }
}