.button-link-list {
    margin-bottom: 20px;

    &__item {
        float: none;
        border-bottom: 1px solid $alto;

        @include media($tablet) {
            border-bottom: none;
            float: left;
            border-right: 1px solid $alto;
        }

        &-container {
            border: 1px solid $alto;
            border-radius: 3px;

            @include media($tablet) {
                display: inline-block;
            }
        }

        &__anchor {
            display: block;
            color: $mine-shaft;
            text-align: center;
            padding: $space $space-2;

            &:hover {
                background-color: $boston-blue;
                color: $white;
            }

            &-disabled {
                color: $dusty-gray;

                &:hover {
                    background-color: $white;
                    color: $dusty-gray;
                }
            }
        }

        &:last-child {
            border-bottom: none;

            @include media($tablet) {
                border-right: none;
            }
        }
    }

    &__active {
        .button-link-list__item__anchor {
            color: white;
            background-color: $boston-blue;
        }
    }
}
