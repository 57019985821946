.button-component {
    width: 100%;

    &__container {
        margin-bottom: $space;
    }

    &__btn {
        border-radius: $space;
        display: inline-block;
        font-family: $default-font-family;
        font-size: 18px;
        font-weight: 500;
        text-decoration: none;
        padding: $space $space-3;
        cursor: pointer;

        &--primary {
            background: linear-gradient(to bottom, $boston-blue 0px, $jelly-bean 100%) repeat-x;
            border-color: $st-tropaz;
            color: $white;

            &:hover, &:focus {
                background: $astral;
                border-color: $st-tropaz;
                color: $white;
            }
        }

        &--wide {
            padding: $space $space-16;
        }
    }

    &__heading {
        font-family: $secondary-font-name;
        font-size: 24px;
        font-weight: 500;
    }

    &__pointer {
        cursor: pointer;
    }

}