.contact-form {
    &__section {
        display: block;

        .pop-up {
            padding-top: 150px;

            &__content-header {
                background-color: $astral;
                color: $white;
            }

            &__content-body {
                background: $white;
            }

        }
    }

    &__block {
        background-color: $wild-sand;
        border: 1px solid $mercury;
        box-shadow: inset 0 1px 1px rgba($black,.05);
        padding: $space-4;
    }

    &__title {
        border-bottom: 1px solid $alto;
        font-size: $space-4;
        text-align: center;
        margin-bottom: $space-4;
        width: 100%;
    }

    &__group {
        @include span-columns(12);
        margin-bottom: $space-3;
    }

    &__label {
        @include span-columns(4);
        padding-left: $space;
        padding-top: 7px;
        text-align: right;
        margin-right: $space;
        font-size: 14px;
        font-weight: bold;
    }

    &__input-group-container {
        @include span-columns(6);
        padding: $space $space 0 $space;
    }

    &__input-group {
        display: table;
        font-size: 14px;
    }

    &__icon {
        background: $gallery;
        border: 1px solid $very-light-grey;
        border-right: 0;
        border-radius: $space;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        display: table-cell;
        padding: $space $space-2;
        vertical-align: middle;
        width: 1%;
    }

    &__control {
        background: $white;
        border: 1px solid $very-light-grey;
        border-radius: $space;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        display: table-cell;
        padding: 6px $space-2;
        float: left;
        width: 100%;
        font-family: $primary-font-name;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }

        &-container {
            display: inline-block;
        }
    }

    &__asterisk {
        color: red;
    }

    &__has-error {
        .contact-form__label {
            color: $cinnabar;
        }

        .contact-form__control {
            border-color: $cinnabar;
        }

        .contact-form__icon {
            background-color: rgba($cinnabar, 0.1);
            border-color: $cinnabar;
            color: $cinnabar;
        }
    }

    &__help-block {
        color: $cinnabar;
    }

    &__error-field {
        color: $apple-blossom;
        @include featured-copy-bold;
        margin-top: $space;

        &--hidden {
            display: none;
        }
    }

    &__section {
        display: block;

        .pop-up {
            padding-top: 150px;

            &__content-header {
                background-color: $astral;
                color: $white;
            }

            &__content-body {
                background: $white;
            }
        }
    }
}
