.grid-content {
    &__loader {
        position: fixed;
        left: 50%;
        top: 50%;
        z-index: 10;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 16px solid #3498db;
        background-position: center;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
}