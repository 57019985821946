.brc-featured-promo-list {
    @include media($large) {
        padding-bottom: 0;
    }

    &__feature-tiles-module {
        margin-bottom: $space-7;
    }

    &__section-header {
        margin-bottom: $space-4;
        @include featured-copy-bold;

        @include media($tablet) {
            margin-bottom: $space-3;
        }
    }

    &__tout-container {
        @include clearfix;
        font-size: 0;
        text-align: center;
        display: block;

        @include media($tablet) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        @include media($mobile-only) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        &.image-border {
            .responsive-image {
                border: 3px solid $white;
                box-shadow: 0 2px 3px 2px $silver-chalice;
            }
        }


    }

    &__copy-container {
        @include subsection-reg;
        // position: absolute;
        width: inherit;

        @include media($mobile-only) {
            width: 100%;
        }


        @include media($large) {
            text-align: center;
            width: 100%;
            max-width: 300px;
            margin: 0 auto;
            display: table;
        }
    }

    &__tout {
        padding-top: $space-4;
        width: 250px;
        margin: 0 auto;
        margin-bottom: $space-4;

        @include media($mobile-only) {
            width: 31%;
        }

        &:first-of-type {
            // padding-top: 0;
        }

        @include media($tablet) {
            position: relative;
            @include omega-reset(7n);
            @include omega-reset(6n);
            @include omega-reset(5n);
            @include omega-reset(4n);
            @include omega-reset(3n);
            @include omega-reset(2n);
            padding-top: 0;
            @include span-columns(4);
            display: inline-block;
            float: none;
            vertical-align: top;
            @include omega(3n);
            margin-left: 0;
        }

        .brc-featured-promo-list__image {
            picture {
                &:last-of-type {
                    display: none;
                }

                &:first-of-type {
                    display: block;
                }
            }
        }

        &:hover {
            .brc-featured-promo-list__image {
                picture {
                    &:first-of-type {
                        display: none;
                    }

                    &:last-of-type {
                        display: block;
                    }
                }
            }
        }
    }

    &__link-container {
        @include media($tablet) {
            padding: 2%;
        }
    }

    &__featured-tiles-container {
        position: absolute;
        width: 100%;
    }

    &__tout {
        &-heading {
            @include section-reg;
            margin-top: $space-2;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;

            @include media($mobile-only) {
                @include small-subhead-reg;
            }
        }

        &-sub-heading {
            @include featured-copy-bold;
        }
    }

    &__tout-desc {
        @include copy-narrow-reg;
        margin-bottom: $space-2;

        @include media($large) {
            min-height: $space-12;
        }
    }

    &__tout-rte {
        display: inline-block;
        @include copy-narrow-reg;
        margin-bottom: $space-2;

        @include media($large) {
            min-height: $space-12;
        }

        ul {
            list-style: disc;
        }

        ol {
            list-style: decimal;
        }
    }

    &__description {
        margin-top: $space-2;
    }

    &__image {

        &--mobile-wrapper {
            @include media($mobile-only) {
                position: relative;
                display: flex;
            }
        }

        &-container {
            margin-bottom: $space-2;
            width: 100%;

            @include media($tablet) {
                margin-bottom: 0;
            }

            img {
                width: 100%;
                max-width: 300px;
            }
        }
    }

    .button_plain-link {
        color: $white;
        background-color: $denim;

        &:hover {
            background-color: $azure-radiance;
        }
    }

    &__cta-alignment {
        &--left {
            text-align: left;
        }

        &--right {
            text-align: right;
            width: 100%;
        }

        &--center {
            text-align: center;
            width: 100%;
        }
    }

    &__subheading-alignment {
        &--left {
            text-align: left;
        }

        &--right {
            text-align: right;
            width: 100%;
        }

        &--center {
            text-align: center;
            width: 100%;
        }
    }

    &_display-column {
        &--1 {
            .brc-featured-promo-list__tout-container {
                display: block;
            }

            .brc-featured-promo-list__tout {
                @include media($tablet) {
                    @include shift(4);
                    display: block;
                }
            }
        }

        &--2 {
            .brc-featured-promo-list__tout {
                @include media($tablet) {
                    @include span-columns(6);
                    @include omega(2n);
                }
            }


        }

        &--4 {
            .brc-featured-promo-list__tout {
                @include media($tablet) {
                    @include omega-reset(3n);
                    @include omega-reset(2n);
                    @include span-columns(3);
                    display: inline-block;
                    float: none;
                    @include omega(4n);
                }
            }

            .brc-featured-promo-list {
                &__image-container {
                    img {
                        max-width: 250px;
                    }
                }

                &__copy-container {
                    @include media($large) {
                        max-width: 250px;
                    }
                }
            }
        }

        &--5 {
            .brc-featured-promo-list__tout {
                @include media($tablet) {
                    @include omega-reset(6n);
                    @include omega-reset(4n);
                    @include omega-reset(3n);
                    @include omega-reset(2n);
                    @include span-columns(2.2);
                    display: inline-block;
                    float: none;
                    @include omega(5n);
                }
            }
        }

        &--6 {
            .brc-featured-promo-list__tout {
                @include media($tablet) {
                    @include omega-reset(5n);
                    @include omega-reset(4n);
                    @include omega-reset(3n);
                    @include omega-reset(2n);
                    @include span-columns(2);
                    display: inline-block;
                    float: none;
                    @include omega(6n);
                }
            }
        }

        &--7 {
            .brc-featured-promo-list__tout {
                @include media($tablet) {
                    @include omega-reset(6n);
                    @include omega-reset(5n);
                    @include omega-reset(4n);
                    @include omega-reset(3n);
                    @include omega-reset(2n);
                    @include span-columns(1.7);
                    display: inline-block;
                    float: none;
                    @include omega(7n);
                }
            }
        }
    }

    &__separator {
        &-top {
            border-top: 1px solid $silver;
            padding: $space-2 0;
        }

        &-bottom {
            border-bottom: 1px solid $silver;
            padding: $space-2 0;
        }

        &-both {
            border-top: 1px solid $silver;
            border-bottom: 1px solid $silver;
            padding: $space-2 0;
        }
    }

    &__text-box-container {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        max-width: 100%;
    }

    &__text-box {
        position: absolute;
        opacity: 0.9;
        padding: 10px;
    }

    &__text-unselectable {
        color:inherit;
        user-select: none;
    }

    &__background--black {
        background-color: #000000;
    }

    &__background--white {
        background-color: #fff;
    }

    &__background--gray {
        background-color: $gray;
        ;
    }

    &__subheading-color--white {
        color: #fff;
    }

    &__subheading-color--black {
        color: #000000;
    }

    &__subheading-color--blue {
        color: $color-mbusa-primary-10;
    }

    &__subheading-color--red {
        color: $color-utility-10;
    }

    &__subheading-color--gray {
        color: $gray;
    }

    &__pop-up-wrapper {
        position: relative;

        &:hover .brc-featured-promo-list__description-pop-up {
            transform: scaleY(1);
        }
    }

    &__description-pop-up {
        height: 50%;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        overflow: hidden;
        transition: .4s ease;
        transform: scaleY(0);
        transform-origin: 0 100%;
    }

    &__hide-pop-up {
        display: none;
    }

    &__description-pop-up p {
        position: absolute;
        margin: $space-3;
    }
}


.wrapper .carousel-nav--sliding .carousel-nav__buttons {
    width: 108%;
}