:host {
    box-sizing: border-box;
}

.email-us-form {
    width: 100%;
    min-height: 20px;
}

.email-us-form-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.email-us-form-header-container {
    width: 100%;
    border: none;
    border-bottom: 1px solid #c6c6c6;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: serif;
    font-size: 16px;
    padding: 64px 0px 32px 0px;

    h1 {
        font-family: corporateacondpro, Georgia, serif;
        font-size: 40px;
        font-weight: 400;
        line-height: calc(40px + 8px);
        overflow-wrap: break-word;
        text-align: center;
        margin: 0 0;
    }

    p {
        width: 73%;
        text-align: center;
        display: block;
        font-family: MBCorpoS, Arial, serif;
        font-size: 16px;
        line-height: calc(16px + 8px);
        padding-top: 8px;
        @media (max-width: 767.98px) {
            width: 100%;
        }
    }

    color: black;
}

.inquiry-type-options {
    option {
        div {
            padding: 12px;
        }
    }
}

.options {
    padding: 12px;
}

.email-us-form-content-container {
    width: 81.25%;
    display: flex;
    justify-content: center;
    padding: 32px 0px;

    @media (max-width: 767.98px) {
        width: 100%;
    }
}

.email-us-form-content {
    width: 70%;
    @media (max-width: 767.98px) {
        width: 100%;
        padding: 0 15px;
    }
}

.email-us-form-util-container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px 0px;
    color: #5a5a5a;
    height: 80px;

    .onLabel {
        padding-left: 1px;
        height: 20px;
        font-size: 16px;
        line-height: 24px;
        white-space: nowrap;
        display: block;
        transition: 0.2s;
    }

    .onLabelDown {
        padding-top: 12px;
    }

    .onLabelUp {
        padding-bottom: 20px;
        color: #176db7;
    }

    .onLabelUpValid {
        padding-top: 0px;
        color: #5a5a5a;
    }

    .onErrorLabelDown {
        padding-top: 12px;
    }

    & .onInput {
        background: none;
        font-family: MBCorpoS, Arial, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        width: 100%;
        text-align: left;
        padding: 0 0 4px;

        &:focus {
            border: 3px solid black;
            border-radius: 4px;
            line-height: 24px;
            padding: 0 0 4px 0;
        }
    }

    & .onTextArea {
        height: 100%;
    }

    .onSelect {
        border: none;
        border-bottom: 1px solid #999;
        background: none;
        font: inherit;
        height: 30px;
        text-align: left;
        font-size: 16px;
        outline: none;
        width: 100%;
        padding: 0px;
    }
}

.email-us-form-field-container {
    height: 80px;
}

.email-us-form-area-container {
    height: 120px;
}

.email-us-form-submit-container {
    padding: 16px 0px;
    display: flex;
    justify-content: space-between;
    align-items: end;

    &.button-with-captcha {
        flex-wrap: wrap;
        @media (max-width: 837.98px) {
            row-gap: 16px;
        }
    }

    & button {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        background-color: #176db7;
        padding: 4px 32px;
        text-decoration: none;
        cursor: pointer;
        font-weight: 700;
        font-size: 16px;
        color: white;
        line-height: calc(16px + 8px);
        max-height: 32px;

        &:disabled {
            background-color: #717171;
            min-height: 32px;
            min-width: 116px;
        }
    }
}

.email-us-form-upload-container {
    padding: 16px 0px;
    display: flex;
    flex-direction: column;
    align-items: start;

    & label {
        font-family: "micro-icons", Arial, Helvetica, sans-serif;
        text-decoration: none;
        font-size: 16px;
        line-height: calc(16px + 8px);
    }

    & label#email-us-file-attachment {
        padding: 4px 0px;
    }

    & label#email-us-file-upload-btn {
        border-radius: 2px;
        background-color: #e3e3e3;
        padding: 4px 32px;
        cursor: pointer;
        font-weight: 700;
        color: black;
        row-gap: 16px;
    }

    & .icon-plus {
        font-size: 12px;
        padding-left: 10px;
    }
}

.uploaded-file-container {
    position: relative;
    padding: 16px 16px;
    width: 60%;
    min-height: 48px;
    line-height: calc(16px + 8px);
    font-size: 16px;
    font-weight: 700;
    border: 1px solid #e3e3e3;
    @media (max-width: 481.98px) {
        width: 100%;
    }

    & span {
        word-break: break-all;
    }
}

.upload-button-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px;

    & label {
        &:nth-child(2) {
            font-size: 12px;
            overflow-wrap: break-word;
            line-height: 12px;
            padding-left: 6px;
        }
    }
}

.utl-upload {
    width: 100%;
    padding: 4px 0px;
    position: relative;
}

.deleteUpload {
    position: absolute;
    top: 0%;
    right: 0%;
    padding: 4px 8px;
    cursor: pointer;
}

.utl-border-red {
    border: 3px solid rgba(1, 1, 1, 0);
    border-bottom: 1px solid red;
}

.utl-border-gray {
    border: 3px solid rgba(1, 1, 1, 0);
    border-bottom: 1px solid rgb(115, 115, 115);
}

.error-label {
    position: absolute;
    width: 100%;
    top: calc(100% - 14px);
    left: 0%;
    line-height: calc(16px + 8px);
    font-size: 16px;
    color: red;
}

.onErrorLabel {
    height: 20px;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    color: red;
}

.onSelectTouched {
    border-bottom: 1px solid red;
}

.request-label {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 24px 24px;
    background: #f1f1f1;

    & p {
        line-height: 24px;
        font-size: 16px;
    }

    &.request-red {
        color: red;
    }

    &.request-black {
        color: black;
    }
}
