.menu-toggle {
    background-color: $black;
    height: $space-8;
    padding-top: $space-2;
    position: relative;
    display: block;

    @include media($desktop) {
        display: none;
    }

    @media only print {
        display: none;

        & * {
            display: none;
        }
    }

    &__cta {
        color: $white-sand;
    }

    &__hamburger {
        display: inline-block;
        margin-right: 10px;
    }

    &__hamburger-line {
        width: 17px;
        height: 2px;
        background-color: #ecf0f1;
        display: block;
        transition: all $speed-4 ease-in-out;

        &:nth-of-type(2) {
            margin: 3px auto;
        }

        &:nth-child(2) {
            @include open-nav() {
                opacity: 0;
            }
        }

        &:nth-child(1) {
            @include open-nav() {
                transform: translateY(5px) rotate(45deg);
                -webkit-transform: translateY(5px) rotate(45deg);
            }
        }

        &:nth-child(3) {
            @include open-nav() {
                transform: translateY(-5px) rotate(-45deg);
                -webkit-transform: translateY(-5px) rotate(-45deg);
            }
        }

    }

    &__text {
        @include featured-copy-reg;
    }

    &__logo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        width: auto;
        height: 25px;
        max-width: 150px;
    }

    &__logo-img {
        width: auto;
        height: 25px;
        max-width: 100%;
    }

    &__text {
        @include open-nav() {
            visibility: hidden;
        }
    }
}
