.inventory-favorites-card-ap {
    position: relative;
    border: 1px solid $color-neutral-40;
    background-color: $color-neutral-10;
    margin-bottom: $space-6 + 2;
    overflow: hidden;

    @include media($xlarge) {
        @include span-columns(4);
        @include omega(3n);
    }

    @include media($large-only) {
        @include span-columns(6);
        @include omega(2n);
    }

    &__card-body {
        padding: $space - 0.1875 + 0.125 $space - 0.1875 + 0.1875 0;
        height: 100%;
    }

    &--recommendation .inventory-favorites-card-ap__card-body--type-model {
        .vehicle-grid-card {
            &__hero {
                height: auto;
            }

            .vehicle-grid-highlights {
                padding-left: $space - 0.1875 + 0.1875;
                padding-right: $space - 0.1875 + 0.1875;

                &__card-type {
                    padding-bottom: $space + 0.1875;
                }

                &__info-title--model-title {
                    @include font('10-32');
                    padding-bottom: $space-4;
                }
            }

            .vehicle-grid-features {
                padding: $space + 0.1875 $space - 0.1875 + 0.1875 0;
            }
        }

        .vehicle-grid-features {
            &__info {
                &--list {
                    padding-bottom: $space-9 + 0.1875;
                }
            }

            &__info-copy {
                padding-bottom: $space-8;

                &--header {
                    @include font('20-16-bold');
                }

                &--description {
                    @include font('20-12');
                    color: $color-neutral-60;
                }

                &--features-enabled {
                    padding-bottom: $space - 0.1875 + 0.125;
                }
            }
        }
    }

    &__wrapper {
        height: 100%;

        .account-remove-saved-item {
            background-color: rgba(255, 255, 255, 0.95);
            backdrop-filter: none;
            margin-top: $space-6 + 0.125;

            &__overlay-body {
                padding-left: $space - 0.1875 + 0.1875;
                padding-right: $space - 0.1875 + 0.1875;
            }

            &__delete-header {
                color: $color-neutral-80;
                margin-bottom: $space-6 + 0.125;
                padding: 0 $space - 0.1875 + 0.125;
            }

            &__cta {
                @include font('20-12');
                margin-bottom: $space - 0.1875 + 0.125;
                margin-right: 0;
                width: 100%;
            }
        }
    }

    &__not-available-overlay {
        background: transparent;
        height: 100%;
        pointer-events: none;
    }

    &--unavailable {
        border: 1px solid $color-utility-10;

        .carousel-nav,
        .vehicle-grid-visuals__image-label,
        .vehicle-grid-visuals__image-detail,
        .vehicle-grid-highlights,
        .vehicle-grid-features {
            opacity: 0.6;
        }

        .vehicle-grid-card
        .inventory-favorites-card-ap__not-available-overlay
        .vehicle-grid-highlights__info-cost--wrapper {
            padding-bottom: 0;
        }
    }

    &--cpo {
        .vehicle-grid-card {
            .vehicle-grid-visuals {
                .vehicle-grid-visuals__overlap {
                    filter: brightness(60%)
                }

                .button__cpo-view-more {
                    z-index: 1000;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    color: $color-neutral-10;
                    margin-top: -28px;
                    margin-left: -$space-12 + 0.25;
                    border: $color-neutral-10 1px solid;
                    width: 120px;
                }
            }

            .vehicle-grid-features {
                &__info {
                    &--all-options {
                        padding: $space - 0.1875 + 0.125 0;
                    }
                }
            }
        }

        &-recommendation .vehicle-grid-card,
        &-recommendation-class .vehicle-grid-card {
            .vehicle-grid {
                &-highlights {
                    padding-bottom: $space + 0.1875;

                    &__info {
                        &-title {
                            &--model-title {
                                @include font('10-24');
                            }
                        }

                        &-subtitle {
                            @include font('20-16-bold');
                            margin-top: $space - 0.1875 + 0.125;
                        }
                    }

                    &__card-type {
                        margin-bottom: $space + 0.1875;
                    }
                }
            }
        }
    }

    .vehicle-grid-highlights {
        &__info-cost--wrapper {
            .tool-tip {
                &__flyout {
                    @include font('20-12');
                    left: -50%;
                    max-width: 300px;

                    @include media($large) {
                        left: 35%;
                        transform: translateX(-35%);
                    }

                    @include media($xlarge-only) {
                        left: 50%;
                        max-width: 240px;
                        padding: $space + 0.1875;
                        transform: translateX(-50%);
                    }

                    @include media($xxlarge) {
                        left: 30%;
                        transform: translateX(-30%);
                    }
                }

                &--open-up .tool-tip__flyout {
                    top: auto;
                    bottom: 27px;
                }
            }
        }

        &__info-cost--secondary:empty {
            display: none;
        }
    }
}
