.contact-form-dropdown {
    &__label {
        display: inline-block;
        @include featured-copy-reg;
        color: $mine-shaft;
    }
}

.contact-form-data {
    color: $mine-shaft;

    &__heading {
        @include featured-heading;
        margin-bottom: $space-2;
    }

    &__group {
        padding: $space-2 0px;

        &:nth-last-child(2) {
            padding-bottom: $space-4;
            border-bottom: 1px solid $dusty-gray;
        }

        &:last-child {
            padding: $space-4 0px;
        }
    }

    &__label {
        display: block;
        @include featured-copy-bold;
        margin-bottom: $space;
    }

    &__control {
        width: 100%;
        border: 1px solid $very-light-grey;
        border-radius: $space;
        height: $space-6+4;
        padding: $space+1 $space-2+2;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }
    }

    textarea {
        resize: none;
        height: auto;
    }

    .button {
        @include featured-copy-reg;
        border-radius: 0px;
        padding: $space $space-4;

        &:hover {
            cursor: pointer;
        }
    }

    &__error-field {
        color: $apple-blossom;
        @include featured-copy-bold;
        margin-top: $space;

        &--hidden {
            display: none;
        }
    }
}
