// scss for FAQ List View.
.faq {
    padding: $space-4 0;

    &__title {
        font-size: 24px;
        font-family: $secondary-font-family;
        font-weight: 500;
        margin-bottom: $space-2;
    }

    &__subtitle {
        font-size: 20px;
        font-family: $secondary-font-family;
        font-weight: 500;
        margin-bottom: $space-2;
    }

    &__question {
        @include featured-copy-bold;
        margin-bottom: $space;
        cursor: default;
    }

    &__link {
        font-size: 14px;
    }

    &__list {
        margin-bottom: $space-3;
    }

    &__spacing {
        &--top {
            padding-top: $space-4;
        }

        &--bottom {
            padding-bottom: $space-4;
        }

        &--both {
            padding-top: $space-4;
            padding-bottom: $space-4;
        }
    }

    &__panel {
        ul {
            margin: $space 0;
        }
    }
}

//scss for FAQ Accordion View.
.faq-accordion {
    .faq {
        margin-bottom: $space-4;

        &__list {
            list-style: none;
            margin: 0;
        }

        &__title {
            @include subhead-reg;
            margin-bottom: $space-2;
        }

        &__question {
            @include featured-copy-bold;
            margin-bottom: $space-2;
            background-color: $gallery;
            color: $tundora;
            cursor: pointer;
            border: none;
            padding: $space-4;
            outline: none;

            &:hover {
                background-color: $very-light-grey;
            }

            &:before {
                font-family: 'micro-icons';
                content: $icon-caret-down-thin;
                font-size: 75%;
                padding-top: 2px;
                -webkit-transition: all 0.3s ease-in 0s;
                -moz-transition: all 0.3s ease-in 0s;
                -o-transition: all 0.3s ease-in 0s;
                transition: all 0.3s ease-in 0s;
                float: right;
            }
        }

        &__icon {
            &--down-arrow-key {
                &:before {
                    content: $icon-caret-down-thin;
                    padding-top: 0.7%;
                }
            }

            &--plus {
                &:before {
                    content: $icon-plus;
                }
            }
        }

        &__panel {
            padding: 0 $space-6;
            background-color: $white;
            overflow: hidden;
            margin-bottom: $space-2;

            &-content {
                padding: $space-4 0px;
                margin-bottom: $space-2;
            }
        }
    }

    .accordion__active {
        .faq__question:before {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
        }

        .faq__icon--plus:before {
            transform: none;
            content: $icon-minus;
        }
    }

    .faq-accordion-display--without-background-color {
        .faq {
            &__subtitle {
                @include highlight-copy-reg;
            }

            &__question {
                background-color: $white;
                padding: 0px;

                &:hover {
                    background-color: $white;
                }

                &:before {
                    float: left;
                    margin-right: $space-2;
                }
            }

            &__panel-content {
                padding: 0;
                font-size: $space-2+3;
            }
        }
    }
}
