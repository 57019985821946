.uis-buttons {
    &__label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 5px;
        font-weight: 700;
    }

    &__input[type=file] {
        display: block;
        height: 30px;
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
    }

    &__button {
        display: inline-block;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        line-height: 1.5;
        border-radius: 4px;
        user-select: none;
    }

    &__formPopup {
        display: none;
        position: fixed;
        left: 45%;
        width: 75%;
        top: 5%;
        transform: translate(-50%, 5%);
        border: 3px solid #999999;
        z-index: 9;
        padding: 15px;
    }

    &__confirmButtons {
        border-top: 1px solid $mercury;
        margin-top: $space-2;
        padding: $space-2;
        text-align: center;
    }

    &__control {
        background: $white;
        border: 1px solid $very-light-grey;
        border-radius: $space;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        display: table-cell;
        padding: 6px $space-2;
        float: left;
        width: 100%;
        height: 25%;
        font-family: $primary-font-name;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }

        &-container {
            display: inline-block;
        }
    }

    &__complete-button {
        text-align: right;
        margin-left: 350;
    }

    &__cancel-button {
        text-align: center;
        padding-left: 300px;
        margin-left: -280;
        padding-right: 10px;
    }

    &__panel-body {
        padding-right: 15px;
    }

    &__group1 {
        padding: 10px 0px;
        display: flex;
        margin-top: 3%;
        margin-left: -2%;
    }

    &__button_primary {
        color: #fff;
        text-align: initial;
        margin: 2px 2px;
    }

    &__close {
        padding: 10px 0px;
        display: flex;
        margin-top: 3%;
        margin-left: -2%;
    }

    &__section {
        display: block;

        .pop-up {
            padding-top: 150px;

            &__content-header {
                background-color: $astral;
                color: $white;
            }

            &__content-body {
                background: $white;
                text-align: initial;
            }
        }
    }

    &__button-block {
        margin: 30px;
    }

    &__content {
        position: relative;
        background-color: $white;
        margin: auto;
        padding: 0;
        border-radius: 6px;
        width: 60%;
        box-shadow: 0 $space $space-3 rgba(0,0,0,.5);

        &-header {
            padding: $space $space-4;
            font-size: 24px;
            font-family: $secondary-font-family;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            background-color: $astral;
            color: $white;
        }

        &-close {
            &-icon {
                color: $white;
                font-size: $space-2;
                font-weight: bold;
                font-family: "micro-icons";
                position: absolute;
                right: $space-4;
                top: 17px;

                @include media($tablet) {
                    top: $space-3;
                }

                &:before {
                    content: '\e91e';
                }

                &:hover,&:focus {
                    text-decoration: none;
                    cursor: pointer;
                    color: $dusty-gray;
                }
            }

            &-button {
                font-size: 14px;
                white-space: nowrap;
                color: $mine-shaft;
                background-color: $white;
                border-style: none;
                border: 1px solid $very-light-grey;
                border-radius: 4px;
                padding: $space $space-2;
                cursor: pointer;

                &:focus {
                    border-color: $silver-chalice;
                    outline: 0;
                }
            }
        }

        &-body {
            padding: $space-2;

            @include media($tablet) {
                padding: $space-4;
            }

            img {
                border: 1px solid $dove-gray;
                padding: 2px;
                width: 100%;
            }

            .video {
                position: relative;
                width: 100%;

                &__img {
                    width: 100%;
                }

                &__img {
                    opacity: 0;
                }

                &__frame {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        &-footer {
            border-top: 1px solid $mercury;
            margin-top: $space-2;
            padding: $space-2;
            margin-top: 20px;
            padding: 8px;
            clear: both;
            @include media($tablet) {
                margin-top: $space-3;
                padding: $space-4;
            }
            text-align: center;
            margin-right: 265px;
        }
    }

    &__heading {
        @include featured-heading;
        margin-bottom: $space-2;
    }

    &__group {
        &:nth-last-child(2) {
            padding-bottom: $space-4;
            border-bottom: 1px solid $dusty-gray;
        }

        &:last-child {
            text-align: right;
            margin-top: -69.5;
        }
    }

    &__label {
        display: block;
        @include featured-copy-bold;
        margin-bottom: $space;
        text-align: left;
    }

    &__control {
        width: 100%;
        border: 1px solid $very-light-grey;
        border-radius: $space;
        height: $space-6+4;
        padding: $space+1 $space-2+2;

        &:focus {
            border-color: $cornflower-blue;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

        &--error, &--error:focus {
            border-color: $apple-blossom;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }

        &--success, &--success:focus {
            border-color: $killarney;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px $fern;
        }
    }

    textarea {
        resize: none;
        height: auto;
        width: 100%;
    }

    .button {
        @include featured-copy-reg;
        border-radius: 0px;
        padding: $space $space-4;

        &:hover {
            cursor: pointer;
        }
    }

    &__error-field {
        color: $apple-blossom;
        @include featured-copy-bold;
        margin-top: $space;

        &--hidden {
            display: none;
        }
    }

    &__modal-title {
        font-size: 1rem;
        text-align: left;
    }

    &__impact {
        font-size: 0.85rem;
    }

    &__complete {
        background-color: #176db7;
        color: #fff;
    }

    &__review {
        position: relative;
        margin-left: 87.2%;
        top: 2rem;
    }

    &__deny {
        position: relative;
        margin-left: 87.2%;
        top: 2rem;
    }

    &__btn {
        border-radius: 0px;
        display: inline-block;
        font-family: $default-font-family;
        font-size: 15px;
        font-weight: 500;
        text-decoration: none;
        padding: 7px 26px;
        cursor: not-allowed;

        &--primary {
            background: #818080;
            border-color: #285E8E;
            color: $white;
        }
    }

    &__update-button {
        background: #818080;
        border-color: #285E8E;
        font-size: 12px;
        font-family: "Arial",Helvetica,Arial,sans-serif;
        cursor: not-allowed;
        color: #fff;
        font-weight: 700;
        line-height: 1.83333;
        padding: 5px 10px;
    }
}