.dealer-results-list {
    &__loading-list {
        text-align: center;
    }

    &__empty-list {
        &-copy {
            .dealer-location-search & {
                padding: $space*2;
            }
        }
    }
}
