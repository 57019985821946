.tabular-image-text {
    &__table {
        border-collapse: collapse;
    }

    &__cell {
        border: 1px solid $black;
        max-width: 100%;
        padding: $space-4;
        vertical-align: middle;
        font-size: 14px;
        line-height: $space-4;

        ol {
            list-style: decimal;
        }

        ul {
            list-style: disc;
        }

        @include media($mobile-only) {
            width: 100%;
        }

    }

    &__heading {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        margin-bottom: $space-2;
    }

    &__para {
        display: block;
        margin-bottom: $space-2;
        padding: 10px;

    }

    a {
        color: $boston-blue;

        &:hover {
            color: $pacific-blue;
        }
    }

    &__media {
        width: 100%;
    }
}
