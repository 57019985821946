.fep-eab-filter {
    margin: $space-4 0;

    fieldset {
        border: 1px solid $silver;
        padding: 6px 12px;

        legend {
            padding: 2px;
        }

        .form-input {
            width: 100%;
            border: 1px solid #cccccc;
            border-radius: $space;
            height: $space-6+4;
            padding: $space+1 $space-2+2;
        }

        .form-left {
            display: inline-block;
            font-size: 0;
            padding-top: $space;
            vertical-align: top;
            width: 66%;

            .form-input {
                &__group {
                    display: inline-block;
                    font-size: 12px;
                    margin-bottom: $space-3;
                    margin-right: 5%;
                    vertical-align: top;
                    width: 45%;
                }
            }
        }

        .form-right {
            border: 1px solid $silver;
            display: inline-block;
            padding: $space-2 $space-4;
            width: 33%;

            label {
                display: inline-block;
                min-width: $space-7;
            }

            .form-input__group {
                margin-top: $space-2;
            }

            .form-input {
                width: 160px;
            }

            .icon-calendar {
                font-size: 22px;
            }
        }
    }
}
